import { memo } from 'react';

import { alpha, Box, Skeleton, type SxProps, type Theme } from '@mui/material';

import { UserAvatar } from '@ll-platform/frontend/components/User/UserAvatar';
import { useHoveredCommentContext } from '@ll-platform/frontend/features/projectComments/contexts/HoveredCommentContext';
import type { ProjectComment } from '@ll-platform/frontend/features/projectComments/types';
import { useGetUserById } from '@ll-platform/frontend/features/users/async/useUsersQueries';

type CommentAvatarButtonProps = {
  comment: ProjectComment;
  sx?: SxProps<Theme>;
  onClick?: () => void;
};

export const avatarSize = 18;

export const CommentAvatarButton = memo(
  ({ comment, sx, onClick }: CommentAvatarButtonProps) => {
    const { userId } = comment;
    const userQuery = useGetUserById(
      { userId },
      { meta: { supressErrorToast: true }, enabled: !!userId },
    );
    const { isHovered } = useHoveredCommentContext({
      threadId: comment.threadId,
    });

    const hoveredStyles: SxProps<Theme> = {
      zIndex: 1,
      boxShadow: (theme) =>
        `0 0 0 2px ${alpha(theme.palette.secondary.main, 0.8)}`,
    };

    return (
      <Box
        sx={{
          cursor: 'pointer',
          boxShadow: (theme) =>
            `0 0 0 1px ${alpha(theme.palette.common.white, 0.5)}`,
          borderRadius: '50%',
          ...(isHovered && hoveredStyles),
          ':hover': hoveredStyles,
          transition: (theme) => theme.transitions.create(['box-shadow']),
          ...sx,
        }}
      >
        {userQuery.data ? (
          <UserAvatar
            size={avatarSize}
            user={userQuery.data}
            onClick={onClick}
          />
        ) : (
          <Skeleton
            variant="circular"
            sx={{
              minWidth: avatarSize,
              width: avatarSize,
              height: avatarSize,
            }}
          />
        )}
      </Box>
    );
  },
);

CommentAvatarButton.displayName = 'CommentAvatarButton';
