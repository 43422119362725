import { FALLBACK_ORGANIZATION_NAME } from '@ll-platform/frontend/consts/organization';
import type { BrandWithOrganization } from '@ll-platform/frontend/features/brands/types';
import type { ProjectWithDeliverables } from '@ll-platform/frontend/features/projects/types';
import { REVIEW_FORM_BLOCKING_PHRASES } from '@ll-platform/frontend/features/reviews/consts';
import type { ReviewEmailTemplateArgs } from '@ll-platform/frontend/features/reviews/types';
import {
  emailGreeting,
  emailSignature,
  htmlEmailBody,
} from '@ll-platform/frontend/utils/helpers/emails';
import {
  propertiesDefined,
  type Nullable,
} from '@ll-platform/frontend/utils/types/types';

export const editGeneratedDocInternalEmail = ({
  brand,
  generatedDocName,
  generatedDocUrl,
  generatedDocLinkText,
  project,
  reviewEmailTemplateUsers,
}: ReviewEmailTemplateArgs<{
  brand: Nullable<BrandWithOrganization>;
  generatedDocName: string | null;
  generatedDocUrl: string;
  generatedDocLinkText: string;
  project?: ProjectWithDeliverables;
}>) => {
  const requiredBodyParams = {
    organizationName: brand?.organization.name ?? FALLBACK_ORGANIZATION_NAME,
    projectName: project?.title,
    generatedDocName,
    generatedDocUrl,
    generatedDocLinkText,
  };

  return {
    recipientUserIds: reviewEmailTemplateUsers.recipientUserIds,
    title: `Great news! ${generatedDocName}${requiredBodyParams.projectName ? ` for ${requiredBodyParams.projectName}` : ''} is ready for final approval`,
    body: emailBody({
      requiredBodyParams,
      reviewEmailTemplateUsers,
    }),
  };
};

const emailBody = ({
  requiredBodyParams,
  reviewEmailTemplateUsers: { defaultRecipient },
}: ReviewEmailTemplateArgs<{
  requiredBodyParams: {
    organizationName: string;
    projectName: Nullable<string>;
    generatedDocName: Nullable<string>;
    generatedDocUrl: Nullable<string>;
    generatedDocLinkText: Nullable<string>;
  };
}>) => {
  if (!propertiesDefined(requiredBodyParams)) {
    return '';
  }

  return htmlEmailBody(`${emailGreeting('', defaultRecipient?.firstName)}
<br/><br/>I'm excited to let you know that the ${requiredBodyParams.generatedDocName} for your ${requiredBodyParams.projectName} project is now finalized. Please take a moment to review:
<br><br><a href="${requiredBodyParams.generatedDocUrl}">${requiredBodyParams.generatedDocName}</a>: ${requiredBodyParams.generatedDocLinkText}
<br><br>You can approve the documents by ${REVIEW_FORM_BLOCKING_PHRASES.date.text}. If you have any additional feedback before you approve, you may also leave comments directly in the platform.
<br><br>Let us know if you have any questions. Looking forward to your feedback!
<br><br>${emailSignature('Best', requiredBodyParams.organizationName)}`);
};
