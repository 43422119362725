import {
  mapCutDownDtoToForm,
  mapCutDownFormToDto,
} from '@ll-platform/frontend/features/internalProjects/projectForm/cutDown/mapCutDownFormToDto';
import type { HeroVideoFormValues } from '@ll-platform/frontend/features/internalProjects/projectForm/heroVideos/heroVideoSchema';
import {
  mapAlternateVersionDtoToForm,
  mapAlternateVersionFormToDto,
} from '@ll-platform/frontend/features/internalProjects/projectForm/heroVideos/mapAlternateVersionFormToDto';
import type { ProjectHeroVideo } from '@ll-platform/frontend/features/projects/types';

export const mapHeroVideoFormToDto = ({
  id,
  alternateVersions,
  aspectRatio,
  audio,
  videoLength,
  videoType,
  graphics,
  footageSource,
  cutDowns,
  wardrobeType,
  propsType,
}: HeroVideoFormValues): ProjectHeroVideo => {
  return {
    id: id!,
    alternativeVersion: alternateVersions.map(mapAlternateVersionFormToDto),
    ...mapAlternateVersionFormToDto({ aspectRatio: aspectRatio ?? null }),
    duration: videoLength,
    audio,
    graphics,
    footageSource,
    type: videoType,
    cutDowns: cutDowns.map(mapCutDownFormToDto),
    ...(wardrobeType ? { wardrobeType } : {}),
    ...(propsType ? { propsType } : {}),
  };
};

export const mapHeroVideoDtoToForm = ({
  id,
  alternativeVersion,
  aspectRatioCount,
  duration,
  audio,
  graphics,
  footageSource,
  type,
  cutDowns,
  wardrobeType,
  propsType,
}: ProjectHeroVideo): HeroVideoFormValues => ({
  id,
  alternateVersions:
    alternativeVersion?.map(mapAlternateVersionDtoToForm) ?? [],
  aspectRatio: aspectRatioCount,
  audio,
  graphics: graphics!,
  videoLength: duration,
  videoType: type,
  footageSource,
  cutDowns: cutDowns?.map(mapCutDownDtoToForm) ?? [],
  isInEditMode: false,
  wardrobeType,
  propsType,
});
