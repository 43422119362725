import * as yup from 'yup';

import {
  basicInfoFields,
  basicInfoSchema,
  type BasicInfoFormValues,
  type PrefixedBasicInfoFields,
} from '@ll-platform/frontend/features/internalProjects/projectForm/basicInfo/basicInfoSchema';
import {
  droneProductionSchema,
  type DroneProductionFormValues,
} from '@ll-platform/frontend/features/internalProjects/projectForm/droneProduction/droneProductionSchema';
import {
  heroVideoSchema,
  type HeroVideoFormValues,
} from '@ll-platform/frontend/features/internalProjects/projectForm/heroVideos/heroVideoSchema';
import {
  projectNotesSchema,
  type ProjectNotesFormValues,
} from '@ll-platform/frontend/features/internalProjects/projectForm/notes/projectNotesSchema';
import {
  projectUsersSchema,
  type ProjectUsersFormValues,
} from '@ll-platform/frontend/features/internalProjects/projectForm/projectUsers/projectUsersSchema';
import {
  contextIsCreativeProposalFlow,
  contextProjectStyle,
} from '@ll-platform/frontend/features/internalProjects/projectForm/validationContext';
import { ProjectStyleEnum } from '@ll-platform/frontend/features/projects/enums';

import {
  productionDaySchema,
  type ProductionDayFormValues,
} from './productionDays/productionDaysSchema';
import {
  talentsSchema,
  type TalentsFormValues,
} from './talents/talentsInfoSchema';

/* PROJECT FORM IMPLEMENTATIONS DETAILS:
 *
 * - Project style-specific validation and other conditionals are handled using yup's `.when()`
 * - Project style is provided to the schema using a yup context
 * - Field options (like toggle buttons and select) are retrieved automatically from the schema, ensuring only the correct options are shown
 * - Dynamic fields are rendered using conditions in JSX
 * - Default values, initial values (when editing), and adding new items to field arrays are dynamically generated using yup's `schema.getDefault(...)` and `schema.cast(...)`
 * - All fields should have a `.default(...)` set!
 *
 */

export const PROJECT_FIELDS = {
  id: 'id',
  basicInfo: 'basicInfo',
  projectUsers: 'projectUsers',
  productionDays: 'productionDays',
  droneProduction: 'droneProduction',
  heroVideos: 'heroVideos',
  characters: 'characters',
  notes: 'notes',
} as const;

export const fieldsSpecificToVideoStyle = [
  PROJECT_FIELDS.heroVideos,
  PROJECT_FIELDS.productionDays,
  PROJECT_FIELDS.droneProduction,
] satisfies (keyof typeof PROJECT_FIELDS)[];

export const fieldsSpecificToDocStyle = [
  `${PROJECT_FIELDS.basicInfo}.${basicInfoFields.deliverables}`,
  `${PROJECT_FIELDS.basicInfo}.${basicInfoFields.rawFootageType}`,
] satisfies PrefixedBasicInfoFields[];

export const fieldsSpecificToDocAndScriptedStyle = [
  `${PROJECT_FIELDS.basicInfo}.${basicInfoFields.footageType}`,
] satisfies PrefixedBasicInfoFields[];

export const fieldsSpecificToWithRawFootage = [
  `${PROJECT_FIELDS.basicInfo}.${basicInfoFields.rawFootageType}`,
] satisfies PrefixedBasicInfoFields[];

export const fieldsSpecificToWithRawFootageOnly = [
  `${PROJECT_FIELDS.heroVideos}`,
] satisfies (keyof typeof PROJECT_FIELDS)[];

export type ContextSpecificField =
  | (typeof fieldsSpecificToVideoStyle)[number]
  | (typeof fieldsSpecificToDocStyle)[number]
  | (typeof fieldsSpecificToDocAndScriptedStyle)[number]
  | (typeof fieldsSpecificToWithRawFootage)[number]
  | (typeof fieldsSpecificToWithRawFootageOnly)[number];

// We can't infer the correct types from complex conditional schema
export type ProjectFormValues = {
  [PROJECT_FIELDS.id]?: string;
  [PROJECT_FIELDS.basicInfo]: BasicInfoFormValues;
  [PROJECT_FIELDS.projectUsers]?: ProjectUsersFormValues;
  [PROJECT_FIELDS.productionDays]?: ProductionDayFormValues[];
  [PROJECT_FIELDS.droneProduction]?: DroneProductionFormValues[];
  [PROJECT_FIELDS.heroVideos]?: HeroVideoFormValues[];
  [PROJECT_FIELDS.characters]?: TalentsFormValues;
  [PROJECT_FIELDS.notes]?: ProjectNotesFormValues;
};

export const PROJECT_SCHEMA = yup
  .object({
    [PROJECT_FIELDS.id]: yup.string().notRequired().default(undefined),
    [PROJECT_FIELDS.basicInfo]: basicInfoSchema,
    [PROJECT_FIELDS.notes]: projectNotesSchema,
  })
  .when(contextIsCreativeProposalFlow, {
    is: false,
    then: (schema) => {
      return schema.concat(
        yup.object({
          [PROJECT_FIELDS.projectUsers]: projectUsersSchema,
        }),
      );
    },
  })
  .when(contextProjectStyle, {
    is: ProjectStyleEnum.DocStyle,
    then: (schema) => {
      return schema.concat(
        yup.object({
          [PROJECT_FIELDS.productionDays]: yup
            .array()
            .of(productionDaySchema)
            .min(1)
            .required()
            .default((options) => [
              productionDaySchema.getDefault(
                options,
              ) as unknown as ProductionDayFormValues,
            ]),
          [PROJECT_FIELDS.droneProduction]: yup
            .array()
            .of(droneProductionSchema)
            .required()
            .default([]),
          [PROJECT_FIELDS.heroVideos]: yup
            .array()
            .of(heroVideoSchema)
            .min(1)
            .required()
            .default((options) => [
              heroVideoSchema.getDefault(
                options,
              ) as unknown as HeroVideoFormValues,
            ]),
        }),
      );
    },
  })
  .when(contextProjectStyle, {
    is: ProjectStyleEnum.Curated,
    then: (schema) =>
      schema.concat(
        yup.object({
          [PROJECT_FIELDS.heroVideos]: yup
            .array()
            .of(heroVideoSchema)
            .min(1)
            .required()
            .default((options) => [
              heroVideoSchema.getDefault(
                options,
              ) as unknown as HeroVideoFormValues,
            ]),
        }),
      ),
  })
  .when(contextProjectStyle, {
    is: ProjectStyleEnum.Animated,
    then: (schema) =>
      schema.concat(
        yup.object({
          [PROJECT_FIELDS.heroVideos]: yup
            .array()
            .of(heroVideoSchema)
            .min(1)
            .required()
            .default((options) => [
              heroVideoSchema.getDefault(
                options,
              ) as unknown as HeroVideoFormValues,
            ]),
        }),
      ),
  })
  .when(contextProjectStyle, {
    is: ProjectStyleEnum.Scripted,
    then: (schema) => {
      return schema.concat(
        yup.object({
          [PROJECT_FIELDS.productionDays]: yup
            .array()
            .of(productionDaySchema)
            .min(1)
            .required()
            .default((options) => [
              productionDaySchema.getDefault(
                options,
              ) as unknown as ProductionDayFormValues,
            ]),
          [PROJECT_FIELDS.droneProduction]: yup
            .array()
            .of(droneProductionSchema)
            .required()
            .default([]),
          [PROJECT_FIELDS.heroVideos]: yup
            .array()
            .of(heroVideoSchema)
            .min(1)
            .required()
            .default((options) => [
              heroVideoSchema.getDefault(
                options,
              ) as unknown as HeroVideoFormValues,
            ]),
        }),
      );
    },
  })
  .when(contextProjectStyle, {
    is: ProjectStyleEnum.Scripted,
    then: (schema) => {
      return schema.concat(
        yup.object({
          [PROJECT_FIELDS.characters]: talentsSchema.default(
            talentsSchema.getDefault(),
          ),
        }),
      );
    },
  }) as yup.ObjectSchema<ProjectFormValues>;
