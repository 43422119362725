import type { DocumentNode } from '@ll-platform/frontend/hero/documentBuilder/core/nodes/nodeTypes';

export const MediaNodeName = 'Media';
export type MediaObject = {
  id: string;
  url: string;
  type: 'image' | 'video';
  provider: 'file' | 'youtube' | 'vimeo';
  metadata: {
    source?: 'ai' | 'user' | 'default';
    prompt?: string;
  };
  history: Omit<MediaObject, 'history'>[];
};

export type MediaNodeContent = {
  media: MediaObject;
};

export type MediaNodeType = DocumentNode<
  typeof MediaNodeName,
  MediaNodeContent
>;
