import type React from 'react';
import { useCallback } from 'react';

import {
  alpha,
  Box,
  Typography,
  type SxProps,
  type Theme,
  type TypographyProps,
} from '@mui/material';
import {
  ReactEditor,
  useSelected,
  useSlateStatic,
  type RenderElementProps,
} from 'slate-react';

import { useHoveredCommentContext } from '@ll-platform/frontend/features/projectComments/contexts/HoveredCommentContext';
import type { CommentElement as CommentElementType } from '@ll-platform/frontend/features/textEditor/comments/types';
import { InlineChromiumBugfix } from '@ll-platform/frontend/features/textEditor/components/InlineChromiumBugfix';
import { useToolbarEditModeContext } from '@ll-platform/frontend/features/textEditor/contexts/ToolbarEditModeContext';
import { activeElementSelectionClassName } from '@ll-platform/frontend/features/textEditor/hooks/useDecorateSelection';

const idleSelectionSx: SxProps<Theme> = {
  background: (theme) => alpha(theme.palette.secondary.main, 0.12),
  borderBottom: '2px solid',
  borderColor: (theme) => alpha(theme.palette.secondary.main, 0.5),
};

const activeSelectionSx: SxProps<Theme> = {
  background: (theme) => alpha(theme.palette.secondary.main, 0.3),
  borderBottom: '2px solid',
  borderColor: (theme) => theme.palette.secondary.dark,
};

type CommentElementProps = RenderElementProps & {
  textNodeProps?: TypographyProps;
};

export const CommentElement = ({
  attributes,
  children,
  element: _element,
  textNodeProps,
}: CommentElementProps) => {
  const editor = useSlateStatic();
  const selected = useSelected();
  const element = _element as CommentElementType;

  const { setEditMode } = useToolbarEditModeContext();

  const handleClick = useCallback(
    (e: React.MouseEvent) => {
      // Added to prevent calling mouse handler from a input element which enters edit mode when someone clicks comment
      e.stopPropagation();
      setEditMode('viewComment');
      const path = ReactEditor.findPath(editor, element);
      editor.select(path);
    },
    [setEditMode, element, editor],
  );

  const hoveredCommentContext = useHoveredCommentContext({
    threadId: element.comment.id,
  });

  if (!children[0].props.text.text) {
    return children;
  }

  const conditionalProps = element.comment.isResolved
    ? {}
    : {
        onMouseDown: handleClick,
        className: activeElementSelectionClassName,
        sx: {
          cursor: 'pointer',
          ':hover': activeSelectionSx,
          ...(selected || hoveredCommentContext.isHovered
            ? activeSelectionSx
            : idleSelectionSx),
        },
      };

  return (
    <Box component="span" {...attributes}>
      <Typography
        component="span"
        {...textNodeProps}
        {...conditionalProps}
        sx={{
          ...conditionalProps.sx,
          ...(textNodeProps?.sx ?? {}),
        }}
        ref={hoveredCommentContext.threadElementRef}
      >
        <InlineChromiumBugfix />
        {children}
        <InlineChromiumBugfix />
      </Typography>
    </Box>
  );
};
