import { AxiosError, type AxiosResponse } from 'axios';

import { APP_CONFIG } from '@ll-platform/frontend/config/app.config';
import { ORGANIZATION_HTTP_HEADER } from '@ll-platform/frontend/consts/organization';
import { authenticationService } from '@ll-platform/frontend/core/auth/async/AuthenticationService';
import type { UserTokens } from '@ll-platform/frontend/core/auth/types';
import { organizationService } from '@ll-platform/frontend/core/organization/async/OrganizationsService';
import { Pages } from '@ll-platform/frontend/core/router/pages';
import { makeNextParamForCurrent } from '@ll-platform/frontend/utils/helpers/navigation';

import { HttpClient, type RequestConfig } from './HttpClient';

export type ApiResponse<T> = {
  status: string;
  data: T;
  code: number;
};

export type HttpRequestArgs = {
  config: RequestConfig;
  withAuth?: boolean;
  handleUnauthorized?: () => void;
};

class HeroHttpClient extends HttpClient {
  public async unwrappedHttpRequest<T>(args: HttpRequestArgs): Promise<T> {
    const response = await heroHttpClient.httpRequest<ApiResponse<T>>(args);

    return response.data?.data;
  }

  public async httpRequest<T>({
    config,
    withAuth = true,
    handleUnauthorized = this.defaultHandleUnauthorized.bind(this),
  }: HttpRequestArgs): Promise<AxiosResponse<T>> {
    let authHeaders = {};
    if (withAuth) {
      try {
        authHeaders = await this.getAuthHeaders();
      } catch (e) {
        handleUnauthorized();
      }
    }
    const mergedConfig = {
      baseURL: APP_CONFIG.REACT_APP_API_URL,
      ...config,
      headers: {
        ...authHeaders,
        ...config.headers,
      },
    } satisfies RequestConfig;
    try {
      const axiosResponse = await super.httpRequest<T>({
        config: mergedConfig,
      });

      return axiosResponse;
    } catch (error) {
      if (
        withAuth &&
        error instanceof AxiosError &&
        [401].includes(error.response?.status ?? 0)
      ) {
        handleUnauthorized();
      }

      throw error;
    }
  }

  public async defaultHandleUnauthorized(): Promise<void> {
    authenticationService.clearUserTokens();
    window.location.href = `${Pages.Login}?${makeNextParamForCurrent()}`;
    throw new Error('Unauthorized. Please log in again');
  }

  protected getUserTokens(): UserTokens | null {
    return authenticationService.getUserTokens();
  }

  public async getAuthHeaders(): Promise<Record<string, string>> {
    const tokens = this.getUserTokens();

    if (!tokens?.accessToken) {
      throw new Error('Unauthorized. Please log in again.');
    }

    const headers = new Headers();
    headers.set('Authorization', `Bearer ${tokens.accessToken}`);
    const organizationId = organizationService.getOrganizationId();
    if (organizationId) {
      headers.set(ORGANIZATION_HTTP_HEADER, organizationId);
    }

    return Object.fromEntries(headers.entries());
  }
}

export const heroHttpClient = new HeroHttpClient();
