import { createQueryKeys } from '@lukemorales/query-key-factory';
import { useQuery } from '@tanstack/react-query';

import type { UniversalQueryOptions } from '@ll-platform/frontend/core/api/reactQuerySetup';
import { usersService } from '@ll-platform/frontend/features/users/async/UsersService';
import type { GetUserByIdArgs } from '@ll-platform/frontend/features/users/types';
import { createQueryHook } from '@ll-platform/frontend/utils/factories/createQueryHook';
import { createQueryOption } from '@ll-platform/frontend/utils/factories/createQueryOption';

export const UsersQueries = createQueryKeys('users', {
  list: createQueryOption(usersService.list.bind(usersService)),
  getById: createQueryOption(usersService.getById.bind(usersService)),
});

export function useGetUserById(
  args: Partial<GetUserByIdArgs>,
  options?: UniversalQueryOptions,
) {
  return useQuery({
    ...UsersQueries.getById({ userId: args.userId! }),
    enabled: !!args.userId,
    ...options,
  });
}

export const useListUsers = createQueryHook(UsersQueries.list);
