import { styled } from '@mui/material';
import { MediaPlayer } from '@vidstack/react';

// https://www.vidstack.io/docs/player/components/layouts/default-layout#video-layout
export const StyledMediaPlayer = styled(MediaPlayer)`
  max-width: 100%;
  height: auto;
  max-height: 100%;

  & video {
    max-height: 100%;
  }

  &,
  .vds-video-layout,
  .vds-menu-items {
    --video-bg: var(
      --player-bg,
      ${({ theme }) => theme.palette.backgroundPrimary}
    );
    --accent-color: ${({ theme }) => theme.palette.blue[200]};
    --video-brand: var(--accent-color);
    --video-font-family: ${({ theme }) => theme.typography.fontFamily};

    --video-border-radius: 0px;
    --video-border: none;

    --checkbox-active-bg: var(--accent-color);
    --default-inverse: var(--accent-color);
  }
`;
