import type { User } from '@ll-platform/frontend/core/auth/types';
import type { ProjectWithDeliverables } from '@ll-platform/frontend/features/projects/types';
import type { ReviewEmailTemplateArgs } from '@ll-platform/frontend/features/reviews/types';
import {
  emailGreeting,
  htmlEmailBody,
} from '@ll-platform/frontend/utils/helpers/emails';
import {
  propertiesDefined,
  type Nullable,
} from '@ll-platform/frontend/utils/types/types';

export const editGeneratedDocExternalEmail = ({
  generatedDocName,
  generatedDocUrl,
  project,
  activeUser,
  reviewEmailTemplateUsers,
}: ReviewEmailTemplateArgs<{
  generatedDocName: string | null;
  generatedDocUrl: string;
  project?: ProjectWithDeliverables;
  activeUser: User;
}>) => {
  const requiredBodyParams = {
    projectName: project?.title,
    generatedDocName,
    generatedDocUrl,
  };

  return {
    recipientUserIds: reviewEmailTemplateUsers.recipientUserIds,
    title: `${generatedDocName} edited/submitted for review${requiredBodyParams.projectName ? ` for ${requiredBodyParams.projectName}` : ''}`,
    body: emailBody({
      requiredBodyParams,
      activeUser,
      reviewEmailTemplateUsers,
    }),
  };
};

const emailBody = ({
  requiredBodyParams,
  reviewEmailTemplateUsers: { defaultRecipient },
}: ReviewEmailTemplateArgs<
  {
    requiredBodyParams: {
      projectName: Nullable<string>;
      generatedDocName: Nullable<string>;
      generatedDocUrl: Nullable<string>;
    };
  } & {
    activeUser: User;
  }
>) => {
  if (!propertiesDefined(requiredBodyParams)) {
    return '';
  }

  return htmlEmailBody(`${emailGreeting('Hi', defaultRecipient?.firstName)}
<br/><br/>I have reviewed the ${requiredBodyParams.generatedDocName} for ${requiredBodyParams.projectName} and applied some edits/comments to it.
<br/>Please take a moment to check the details.
${
  requiredBodyParams.generatedDocUrl
    ? `<br/><br/>You can view the ${requiredBodyParams.generatedDocName} <a href="${requiredBodyParams.generatedDocUrl}">here.</a>`
    : ''
}`);
};
