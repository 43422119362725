import { Box, type BoxProps } from '@mui/material';

type ThreadLineProps = BoxProps;

export const ThreadLine = (props: ThreadLineProps) => {
  return (
    <Box
      {...props}
      sx={{
        width: '2px',
        background: (theme) => theme.palette.divider,
        zIndex: 0,
        ...props.sx,
      }}
    />
  );
};
