import { useActiveCreator } from '@ll-platform/frontend/creator/auth/hooks/useActiveCreator';
import {
  CreatorAvatar,
  type CreatorAvatarProps,
} from '@ll-platform/frontend/creator/components/CreatorAvatar';

export type ActiveCreatorAvatarProps = Omit<CreatorAvatarProps, 'creator'>;

export const ActiveCreatorAvatar = ({ ...props }: ActiveCreatorAvatarProps) => {
  const { activeCreator } = useActiveCreator();

  if (!activeCreator) {
    return null;
  }

  return <CreatorAvatar {...props} creator={activeCreator} />;
};
