import { makeNode } from '@ll-platform/frontend/hero/documentBuilder/core/nodes/nodeHelpers';

import { RichTextNodeName, type RichTextNodeType } from './richTextNodeTypes';

export function makeRichTextNode({
  content,
  style,
}: Pick<RichTextNodeType, 'content' | 'style'>) {
  return makeNode({
    type: RichTextNodeName,
    content,
    style,
  });
}
