import {
  createContext,
  useCallback,
  useContext,
  useState,
  type PropsWithChildren,
} from 'react';

import { defaultEditMode } from '@ll-platform/frontend/features/textEditor/consts';
import type { TextEditorEditMode } from '@ll-platform/frontend/features/textEditor/types';

type EditModeContextType = {
  editMode: TextEditorEditMode;
  setEditMode: (mode: TextEditorEditMode) => void;
  handleFinishEdit: () => void;
  editorReadOnlyOverride: boolean | null;
};

const ToolbarEditModeContext = createContext<EditModeContextType>({
  editMode: defaultEditMode,
  setEditMode: () => {},
  handleFinishEdit: () => {},
  editorReadOnlyOverride: null,
});

ToolbarEditModeContext.displayName = 'ToolbarEditModeContext';

export function ToolbarEditModeContextProvider({
  children,
}: PropsWithChildren) {
  const [editMode, setEditMode] = useState(defaultEditMode);

  // on some browsers readOnly editor loses selection on blur
  const editorReadOnlyOverride = editMode === 'hover' ? null : false;

  const handleFinishEdit = useCallback(() => {
    setEditMode(defaultEditMode);
  }, []);

  return (
    <ToolbarEditModeContext.Provider
      value={{
        editMode,
        setEditMode,
        handleFinishEdit,
        editorReadOnlyOverride,
      }}
    >
      {children}
    </ToolbarEditModeContext.Provider>
  );
}

export const useToolbarEditModeContext = () =>
  useContext(ToolbarEditModeContext);
