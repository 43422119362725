import { useCallback, useMemo } from 'react';

import { isEqual } from 'lodash-es';
import {
  useLocation,
  useSearchParams,
  type NavigateOptions,
} from 'react-router-dom';
import type { ConditionalExcept, Merge } from 'type-fest';
import type { ObjectSchema } from 'yup';

import type { $TSFixMe } from '@ll-platform/frontend/utils/types/types';

export function useTypedSearchParams<
  T extends Record<string, string | number | boolean | null | undefined>,
  D extends T | undefined,
>(
  schema: ObjectSchema<T>,
  defaultValues?: D,
  { stripUnknown = true }: { stripUnknown?: boolean } = {},
) {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const params = useMemo(() => {
    const paramsObject = Object.fromEntries(searchParams.entries());
    const casted = schema.cast(paramsObject, {
      stripUnknown,
    });
    const withDefault = {
      ...defaultValues,
      ...casted,
    } as unknown as D extends T ? Merge<T, ConditionalExcept<D, undefined>> : T;

    return withDefault;
  }, [defaultValues, schema, searchParams, stripUnknown]);

  const setParams = useCallback(
    (newParams: T, navigateOptions?: NavigateOptions) => {
      const filteredParams = Object.fromEntries(
        Object.entries(newParams).filter(([key, value]) =>
          defaultValues
            ? // eslint-disable-next-line eqeqeq
              value != defaultValues[key]
            : value !== undefined && value !== null,
        ),
      );

      const stringifiedParams = Object.fromEntries(
        Object.entries(filteredParams).map(([key, value]) => [
          key,
          String(value),
        ]),
      );

      setSearchParams(new URLSearchParams(stringifiedParams), {
        state: location.state,
        ...navigateOptions,
      });
    },
    [defaultValues, setSearchParams, location],
  );

  const updateParams = useCallback(
    (updatedParams: Partial<T>, navigateOptions?: NavigateOptions) => {
      const newParams = { ...params, ...updatedParams } as $TSFixMe;
      if (isEqual(newParams, params)) {
        return;
      }
      setParams(newParams, navigateOptions);
    },
    [setParams, params],
  );

  return {
    params,
    setParams,
    updateParams,
  };
}
