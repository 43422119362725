import type { Descendant } from 'slate';

import { TextEditorElementEnum } from '@ll-platform/frontend/features/textEditor/types';

export const plainTextToSlate = (plainText: string): Descendant[] => {
  return [
    {
      type: TextEditorElementEnum.Paragraph,
      children: [{ text: plainText }],
    },
  ];
};
