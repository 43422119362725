import { AccountType, type User } from '@ll-platform/frontend/core/auth/types';
import type { ProjectWithDeliverables } from '@ll-platform/frontend/features/projects/types';
import { editGeneratedDocExternalEmail } from '@ll-platform/frontend/features/projectSpace/reviews/emailTemplates/editGeneratedDocExternalEmail';
import type { ReviewEditGeneratedDocsKey } from '@ll-platform/frontend/features/projectSpace/reviews/reviewGeneratedDocsKeys';
import {
  reviewDefaultHeading,
  ReviewModalTitle,
} from '@ll-platform/frontend/features/reviews/consts';
import type { ReviewModalTemplate } from '@ll-platform/frontend/features/reviews/types';
import { getReviewEmailUsers } from '@ll-platform/frontend/features/reviews/utils/getReviewEmailUsers';

import { reviewEditDocumentsToastParams } from './common/reviewEditDocsTexts';
import { generatedDocData } from './common/reviewGeneratedDocsUtils';

type EditGeneratedDocExternalModalTemplateArgs = {
  users: User[];
  project: ProjectWithDeliverables;
  activeUser: User;
  reviewKey: ReviewEditGeneratedDocsKey;
};

export const editGeneratedDocExternalModalTemplate = ({
  reviewKey,
  users,
  project,
  activeUser,
}: EditGeneratedDocExternalModalTemplateArgs): ReviewModalTemplate => {
  const { generatedDocName, generatedDocUrl } = generatedDocData({
    notificationType: reviewKey,
    projectId: project.id,
  });

  return {
    reviewEmailData: editGeneratedDocExternalEmail({
      ...getReviewEmailUsers({
        users,
        recipientsTypes: [AccountType.Producer, AccountType.AccountExecutive],
      }),
      generatedDocName,
      generatedDocUrl,
      project,
      activeUser,
    }),
    titleContent: ReviewModalTitle.notifyProducerForReview,
    headingContent: reviewDefaultHeading,
    toastParams: generatedDocName
      ? reviewEditDocumentsToastParams(generatedDocName)
      : undefined,
  };
};
