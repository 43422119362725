import { object, string } from 'yup';

import { makeConfig } from '@ll-platform/frontend/config/env-config';

const firebaseConfigSchema = object({
  REACT_APP_FIRESTORE_APIKEY: string().required(),
  REACT_APP_FIRESTORE_AUTH_DOMAIN: string().required(),
  REACT_APP_FIRESTORE_PROJECT_ID: string().required(),
  REACT_APP_FIRESTORE_STORAGE_BUCKET: string().required(),
  REACT_APP_FIRESTORE_MESSAGING_SENDER_ID: string().required(),
  REACT_APP_FIRESTORE_APP_ID: string().required(),
  REACT_APP_FIRESTORE_MEASUREMENT_ID: string().required(),
});

export const FIREBASE_CONFIG = makeConfig({
  schema: firebaseConfigSchema,
  name: 'FIREBASE',
});
