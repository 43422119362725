import { useMemo } from 'react';

import {
  alpha,
  Step,
  StepLabel,
  Stepper,
  ThemeProvider,
  useTheme,
  type Theme,
} from '@mui/material';
import { merge } from 'lodash-es';

export type StepperStep<T extends string> = {
  key: T;
  label: string;
};

type StepperProps<T extends string> = {
  steps: StepperStep<T>[];
  activeStep: string;
  onClick?: (step: StepperStep<T>) => void;
  variant?: 'dark' | 'light';
};

export const CustomStepper = <T extends string>({
  steps,
  activeStep,
  onClick,
  variant = 'dark',
}: StepperProps<T>) => {
  const activeStepIndex = steps.findIndex((step) => step.key === activeStep);
  const theme = useTheme();

  const themeOverride = useMemo<Partial<Theme>>(() => {
    switch (variant) {
      case 'dark':
        return merge({}, theme, {
          components: {
            MuiStepConnector: {
              styleOverrides: {
                line: {
                  borderColor: theme.palette.blue[0],
                },
              },
            },
            MuiStepIcon: {
              styleOverrides: {
                root: {
                  height: 32,
                  width: 32,
                  color: theme.palette.blue[0],
                  '& .MuiStepIcon-text': {
                    fontWeight: 600,
                    fill: theme.palette.primary.light,
                  },

                  '&.Mui-active': {
                    color: theme.palette.avatarFill,
                    '& .MuiStepIcon-text': {
                      fill: theme.palette.primary.main,
                    },
                  },

                  '&.Mui-completed': {
                    color: theme.palette.green[400],
                    background: theme.palette.primary.contrastText,
                    borderRadius: '50%',
                  },
                },
              },
            },
            MuiStepLabel: {
              styleOverrides: {
                label: () => {
                  const sharedStyles = {
                    fontWeight: 600,
                    color: alpha(theme.palette.primary.contrastText, 0.4),
                  };

                  return {
                    fontSize: 16,
                    ...sharedStyles,
                    '&.Mui-completed': {
                      ...sharedStyles,
                    },
                    '&.Mui-active': {
                      ...sharedStyles,
                      color: theme.palette.primary.contrastText,
                    },
                  };
                },
              },
            },
          },
        });
      case 'light': // default style from theme
      default:
        return theme;
    }
  }, [variant, theme]);

  return (
    <ThemeProvider theme={themeOverride}>
      <Stepper activeStep={activeStepIndex} orientation="vertical">
        {steps.map((step, i) => {
          const clickHandler =
            onClick && i < activeStepIndex ? onClick : undefined;

          return (
            <Step
              key={step.key}
              onClick={() => clickHandler?.(step)}
              sx={{ cursor: clickHandler ? 'pointer' : 'default' }}
            >
              <StepLabel>{step.label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </ThemeProvider>
  );
};
