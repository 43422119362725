import { PriceAdjustmentTypeEnum } from './enums';

export const budgetDiscountTypeLabelMap: Record<
  | PriceAdjustmentTypeEnum.FixedDiscount
  | PriceAdjustmentTypeEnum.PercentageDiscount,
  string
> = {
  [PriceAdjustmentTypeEnum.PercentageDiscount]: 'Percentage',
  [PriceAdjustmentTypeEnum.FixedDiscount]: 'Amount',
};
