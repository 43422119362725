import { collection, getDocs, orderBy, query, where } from 'firebase/firestore';

import { heroHttpClient } from '@ll-platform/frontend/core/api/HeroHttpClient';
import type { PaginatedResponse } from '@ll-platform/frontend/core/api/pagination/pagination.types';
import { firestore } from '@ll-platform/frontend/core/firebase/firebaseService';
import { FirestoreCollections } from '@ll-platform/frontend/core/firebase/types';
import { ignoreUnauthorizedOnSharedEndpoint } from '@ll-platform/frontend/features/auth/utils/auth';
import { projectsService } from '@ll-platform/frontend/features/projects/async/ProjectsService';
import type {
  ProjectData,
  ProjectWithDeliverables,
} from '@ll-platform/frontend/features/projects/types';
import type {
  ByProposalIdParams,
  CreateProposalPayload,
  Proposal,
  ProposalsFindAllQueryDto,
  ProposalWithBrand,
  UpdateProposalPayload,
} from '@ll-platform/frontend/features/proposals/types';
import type { ByIdParams } from '@ll-platform/frontend/utils/types/types';

class ProposalsService {
  async findProposalProjects({
    proposalId,
  }: ByProposalIdParams): Promise<ProjectWithDeliverables[]> {
    const collectionRef = collection(firestore, FirestoreCollections.Projects);
    const result = await getDocs(
      query(
        collectionRef,
        where('proposal.proposalId', '==', proposalId),
        orderBy('lastUpdated' satisfies keyof ProjectData, 'desc'),
      ),
    );

    const projects = await Promise.all(
      result.docs.map(async (doc) => {
        const [heroes, productions, drones] = await Promise.all([
          projectsService.getHeroVideosByProjectId({ id: doc.id }),
          projectsService.getProductionDaysByProjectId({ id: doc.id }),
          projectsService.getDroneProductionDaysByProjectId({ id: doc.id }),
        ]);

        return {
          id: doc.id,
          ...(doc.data() as ProjectData),
          heroes,
          productions,
          drones,
        } as ProjectWithDeliverables;
      }),
    );

    return projects;
  }

  async findAll(
    args: ProposalsFindAllQueryDto,
  ): Promise<PaginatedResponse<ProposalWithBrand>> {
    const paginatedProposals = await heroHttpClient.unwrappedHttpRequest<
      PaginatedResponse<ProposalWithBrand>
    >({
      config: {
        method: 'GET',
        url: '/v1/proposals',
        params: args,
      },
    });

    return paginatedProposals;
  }

  async getById(args: ByIdParams): Promise<ProposalWithBrand> {
    const proposal = heroHttpClient.unwrappedHttpRequest<ProposalWithBrand>({
      config: {
        method: 'GET',
        url: `/v1/proposals/${args.id}`,
      },
      handleUnauthorized: ignoreUnauthorizedOnSharedEndpoint,
    });

    return proposal;
  }

  async create(args: CreateProposalPayload): Promise<Proposal> {
    const proposal = await heroHttpClient.unwrappedHttpRequest<Proposal>({
      config: {
        method: 'POST',
        url: '/v1/proposals',
        data: args,
      },
    });

    return proposal;
  }

  async update({ id, ...payload }: UpdateProposalPayload): Promise<Proposal> {
    const updatedProposal = await heroHttpClient.unwrappedHttpRequest<Proposal>(
      {
        config: {
          method: 'PATCH',
          url: `/v1/proposals/${id}`,
          data: payload,
        },
      },
    );

    return updatedProposal;
  }

  async deleteById(args: ByIdParams): Promise<void> {
    await heroHttpClient.unwrappedHttpRequest<void>({
      config: {
        method: 'DELETE',
        url: `/v1/proposals/${args.id}`,
      },
    });
  }
}

export const proposalsService = new ProposalsService();
