export enum PriceAdjustmentTypeEnum {
  Markup = 'markup',
  PercentageDiscount = 'percentage-discount',
  FixedDiscount = 'fixed-discount',
}

export enum BillingUnitEnum {
  Minute = 'minute',
  Day = 'day',
  Video = 'video',
  Unit = 'unit',
}

export enum BudgetLineItemTypeEnum {
  Custom = 'custom',
  Standard = 'standard',
}

export enum BudgetPhaseEnum {
  PreProduction = 'pre-production',
  Production = 'production',
  PostProduction = 'post-production',
}
