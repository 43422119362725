import { createQueryKeys } from '@lukemorales/query-key-factory';

import { documentBuilderService } from '@ll-platform/frontend/hero/documentBuilder/async/DocumentBuilderService';
import { createQueryHook } from '@ll-platform/frontend/utils/factories/createQueryHook';
import { createQueryOption } from '@ll-platform/frontend/utils/factories/createQueryOption';

export const DocumentBuilderQueries = createQueryKeys('documentBuilder', {
  getById: createQueryOption(
    documentBuilderService.getById.bind(documentBuilderService),
  ),
});

export const useGetDocumentById = createQueryHook(
  DocumentBuilderQueries.getById,
);
