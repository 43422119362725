import {
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
} from 'firebase/firestore';
import type { EmptyObject } from 'type-fest';

import {
  automapDates,
  type SerializedJson,
} from '@ll-platform/frontend/core/api/helpers/dateMapper';
import { heroHttpClient } from '@ll-platform/frontend/core/api/HeroHttpClient';
import type { PaginatedResponse } from '@ll-platform/frontend/core/api/pagination/pagination.types';
import {
  dataWithIdConverterFactory,
  defaultFirestoreConverterFactory,
} from '@ll-platform/frontend/core/firebase/converters';
import { firestore } from '@ll-platform/frontend/core/firebase/firebaseService';
import { FirestoreCollections } from '@ll-platform/frontend/core/firebase/types';
import type {
  ByProjectIdVideoIdParams,
  SubmitCreativeDeckEditDto,
  SubmitForReviewDto,
  UpdateDroneProductionDayDto,
  UpdateHeroVideoDto,
  UpdateProductionDayDto,
} from '@ll-platform/frontend/features/internalProjects/types';
import { ProjectSubCollections } from '@ll-platform/frontend/features/projects/enums';
import type {
  DeleteProjectUserParams,
  InviteProjectUsersDto,
  ProjectCharacter,
  ProjectData,
  ProjectDataWithId,
  ProjectDrone,
  ProjectHeroVideo,
  ProjectProductionDay,
  ProjectUserInvitesDto,
  ProjectUsersAndInvitesDto,
  ProjectUsersDto,
  ProjectWithDeliverables,
  ProjectWithDeliverablesAndBrand,
  UpdateProductionStatusDto,
} from '@ll-platform/frontend/features/projects/types';
import type { ProjectIdParams } from '@ll-platform/frontend/features/projectWizard/types';
import type { ByIdParams } from '@ll-platform/frontend/utils/types/types';

// Project related operations accessible by Client or Internal users
class ProjectsService {
  async findAll(args: EmptyObject) {
    const response = await heroHttpClient.unwrappedHttpRequest<
      PaginatedResponse<ProjectWithDeliverablesAndBrand>
    >({
      config: {
        method: 'GET',
        url: '/v1/projects',
        params: args,
      },
    });

    return response.items;
  }

  async submitProjectForReview(args: SubmitForReviewDto) {
    await heroHttpClient.unwrappedHttpRequest<void>({
      config: {
        method: 'POST',
        url: '/v1/projects/review',
        data: args,
      },
    });
  }

  async submitCreativeDeckEdit(args: SubmitCreativeDeckEditDto) {
    await heroHttpClient.unwrappedHttpRequest<void>({
      config: {
        method: 'POST',
        url: '/v1/projects/creative-deck/submit-edit',
        data: args,
      },
    });
  }

  async getById({ id }: ByIdParams): Promise<ProjectWithDeliverables> {
    const subCollectionData = await Promise.all([
      {
        subcollection: ProjectSubCollections.HeroVideos,
        result: await this.getSubCollection(
          id,
          ProjectSubCollections.HeroVideos,
        ),
      },
      {
        subcollection: ProjectSubCollections.ProductionDays,
        result: await this.getProductionDaysByProjectId({ id }),
      },
      {
        subcollection: ProjectSubCollections.DroneProductionDays,
        result: await this.getDroneProductionDaysByProjectId({ id }),
      },
    ]);

    const projectData = await this.getBaseDataById({ id });

    const formattedSubCollectionData = Object.fromEntries(
      subCollectionData.map((item) => [item.subcollection, item.result ?? []]),
    );

    return {
      ...projectData,
      ...formattedSubCollectionData,
    } as ProjectWithDeliverables;
  }

  async getBaseDataById({ id }: ByIdParams): Promise<ProjectDataWithId> {
    const projectData = (
      await getDoc(doc(firestore, FirestoreCollections.Projects, id))
    ).data();

    // Checking these required properties because there might be cases where data with lastUpdated may still exist after a project has been deleted
    if (!projectData?.title || !projectData?.style) {
      throw new Error('Project not found');
    }

    return {
      id,
      ...projectData,
    } as ProjectDataWithId;
  }

  async getHeroVideosByProjectId({
    id,
  }: ByIdParams): Promise<ProjectHeroVideo[]> {
    const heroesRef = collection(
      firestore,
      FirestoreCollections.Projects,
      id,
      ProjectSubCollections.HeroVideos,
    ).withConverter(defaultFirestoreConverterFactory<ProjectHeroVideo>());
    const result = await getDocs(query(heroesRef));

    return result.docs.map((doc) => doc.data());
  }

  async getHeroVideoById({
    projectId,
    videoId,
  }: ByProjectIdVideoIdParams): Promise<ProjectHeroVideo> {
    const docRef = doc(
      firestore,
      FirestoreCollections.Projects,
      projectId,
      ProjectSubCollections.HeroVideos,
      videoId,
    );
    const result = await getDoc(docRef);

    if (!result.exists()) {
      throw new Error('Hero video does not exist');
    }

    const video: ProjectHeroVideo = {
      ...(result.data() as ProjectHeroVideo),
      id: result.id,
    };

    return video;
  }

  async getProductionDaysByProjectId({
    id,
  }: ByIdParams): Promise<ProjectProductionDay[]> {
    const productionsRef = collection(
      firestore,
      FirestoreCollections.Projects,
      id,
      ProjectSubCollections.ProductionDays,
    ).withConverter(defaultFirestoreConverterFactory<ProjectProductionDay>());
    const result = await getDocs(
      query(
        productionsRef,
        orderBy('dateTime' satisfies keyof ProjectProductionDay, 'asc'),
      ),
    );

    return result.docs.map((doc) => {
      return doc.data();
    });
  }

  async getDroneProductionDaysByProjectId({
    id,
  }: ByIdParams): Promise<ProjectDrone[]> {
    const productionsRef = collection(
      firestore,
      FirestoreCollections.Projects,
      id,
      ProjectSubCollections.DroneProductionDays,
    ).withConverter(defaultFirestoreConverterFactory<ProjectDrone>());
    const result = await getDocs(
      query(
        productionsRef,
        orderBy('dateTime' satisfies keyof ProjectDrone, 'asc'),
      ),
    );

    return result.docs.map((doc) => {
      return doc.data();
    });
  }

  async getCharactersByProjectId({
    id,
  }: ByIdParams): Promise<ProjectCharacter[]> {
    const charactersRef = collection(
      firestore,
      FirestoreCollections.Projects,
      id,
      ProjectSubCollections.Characters,
    ).withConverter(dataWithIdConverterFactory<ProjectCharacter>());
    const result = await getDocs(query(charactersRef));

    return result.docs.map((doc) => {
      return doc.data();
    });
  }

  async updateProjectDocument({
    id,
    data,
  }: {
    id: string;
    data: Partial<ProjectData>;
  }): Promise<void> {
    const docRef = doc(firestore, FirestoreCollections.Projects, id);
    await setDoc(
      docRef,
      {
        ...data,
        ['lastUpdated' satisfies keyof ProjectData]: serverTimestamp(),
      },
      { merge: true },
    );
  }

  async updateProductionDay({
    projectId,
    id,
    data,
  }: {
    projectId: string;
    id: string;
    data: UpdateProductionDayDto;
  }) {
    const docRef = doc(
      firestore,
      FirestoreCollections.Projects,
      projectId,
      ProjectSubCollections.ProductionDays,
      id,
    );
    await setDoc(
      docRef,
      {
        ...data,
      },
      { merge: true },
    );
  }

  async updateProductionStatus({
    projectId,
    productionId,
    ...args
  }: UpdateProductionStatusDto): Promise<ProjectProductionDay> {
    const updatedProduction =
      await heroHttpClient.unwrappedHttpRequest<ProjectProductionDay>({
        config: {
          method: 'PUT',
          url: `/v1/projects/${projectId}/productions/${productionId}`,
          data: args,
        },
      });

    return updatedProduction;
  }

  async updateHeroVideo({
    projectId,
    id,
    data,
  }: {
    projectId: string;
    id: string;
    data: UpdateHeroVideoDto;
  }) {
    const docRef = doc(
      firestore,
      FirestoreCollections.Projects,
      projectId,
      ProjectSubCollections.HeroVideos,
      id,
    );
    await setDoc(
      docRef,
      {
        ...data,
      },
      { merge: true },
    );
  }

  async updateDroneProductionDay(
    projectId: string,
    id: string,
    data: UpdateDroneProductionDayDto,
  ) {
    const docRef = doc(
      firestore,
      FirestoreCollections.Projects,
      projectId,
      ProjectSubCollections.DroneProductionDays,
      id,
    );
    await setDoc(
      docRef,
      {
        ...data,
      },
      { merge: true },
    );
  }

  async getSubCollection<T>(
    projectId: string,
    subcollection: ProjectSubCollections,
  ) {
    const subcollectionRef = collection(
      firestore,
      FirestoreCollections.Projects,
      projectId,
      subcollection,
    );

    const snapshot = await getDocs(query(subcollectionRef));

    return snapshot.docs.map((doc) => {
      return { id: doc.id, ...(doc.data() as T) };
    });
  }

  async getProjectUsers(args: ProjectIdParams): Promise<ProjectUsersDto> {
    const response = await heroHttpClient.unwrappedHttpRequest<
      SerializedJson<ProjectUsersDto>
    >({
      config: {
        method: 'GET',
        url: `/v1/projects/${args.projectId}/users`,
      },
    });

    return automapDates<ProjectUsersDto>(response);
  }

  async getProjectUserInvites(
    args: ProjectIdParams,
  ): Promise<ProjectUserInvitesDto> {
    const response = await heroHttpClient.unwrappedHttpRequest<
      SerializedJson<ProjectUserInvitesDto>
    >({
      config: {
        method: 'GET',
        url: `/v1/projects/${args.projectId}/invites`,
      },
    });

    return automapDates<ProjectUserInvitesDto>(response);
  }

  async inviteProjectUsers({
    projectId,
    ...dto
  }: InviteProjectUsersDto & {
    projectId: string;
  }): Promise<ProjectUsersAndInvitesDto> {
    const response = await heroHttpClient.unwrappedHttpRequest<
      SerializedJson<ProjectUsersAndInvitesDto>
    >({
      config: {
        method: 'POST',
        url: `/v1/projects/${projectId}/invites`,
        data: dto,
      },
    });

    return automapDates<ProjectUsersAndInvitesDto>(response);
  }

  async deleteProjectUser({
    projectId,
    projectUserId,
  }: DeleteProjectUserParams): Promise<void> {
    await heroHttpClient.unwrappedHttpRequest<void>({
      config: {
        method: 'DELETE',
        url: `/v1/projects/${projectId}/users/${projectUserId}`,
      },
    });
  }
}

export const projectsService = new ProjectsService();
