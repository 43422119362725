import { createQueryKeys } from '@lukemorales/query-key-factory';

import { createQueryHook } from '@ll-platform/frontend/utils/factories/createQueryHook';
import { createQueryOption } from '@ll-platform/frontend/utils/factories/createQueryOption';

import { brandsService } from './BrandsService';

export const BrandsQueries = createQueryKeys('brands', {
  getBrandByProjectId: createQueryOption(
    brandsService.getBrandByProjectId.bind(brandsService),
  ),
  getBrand: createQueryOption(brandsService.getBrand.bind(brandsService)),
  findAll: createQueryOption(brandsService.findAll.bind(brandsService)),
  getBrandUsers: createQueryOption(
    brandsService.getBrandUsers.bind(brandsService),
  ),
});

export const useFindAllBrands = createQueryHook(BrandsQueries.findAll);

export const useGetBrandByProjectId = createQueryHook(
  BrandsQueries.getBrandByProjectId,
);

export const useBrandUsers = createQueryHook(BrandsQueries.getBrandUsers);

export const useGetBrandById = createQueryHook(BrandsQueries.getBrand);
