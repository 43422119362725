import type { InterviewsOutput } from '@ll-platform/frontend/features/llm/prompts/Interviews/types';
import type { ScheduleOutput } from '@ll-platform/frontend/features/llm/prompts/Schedule/types';
import type { VideoSummaryOutputWithoutImages } from '@ll-platform/frontend/features/llm/prompts/VideoSummary/types';
import type { VisualsOutput } from '@ll-platform/frontend/features/llm/prompts/Visuals/types';
import type { VoiceoverOutput } from '@ll-platform/frontend/features/llm/prompts/Voiceover/types';
import type { ScriptedScriptOutput } from '@ll-platform/frontend/features/llmGenerators/scriptedScript/ScriptedScriptLlmGenerator';
import type { ScriptedVideoSummaryOutput } from '@ll-platform/frontend/features/llmGenerators/scriptedVideoSummary/ScriptedVideoSummaryLlmGenerator';
import type { EditableCharactersOutput } from '@ll-platform/frontend/features/projectWizard/pages/steps/characters/CharactersGenerator';

type InlineEditMaxCharLimits = {
  comment: number;
  videoSummary: {
    [K in keyof VideoSummaryOutputWithoutImages]: number;
  };
  scriptedVideoSummary: {
    [K in keyof Omit<ScriptedVideoSummaryOutput, 'lookAndFeelImages'>]: number;
  };
  characters: {
    [K in keyof EditableCharactersOutput]: number;
  };
  interviews: {
    [K in keyof InterviewsOutput[number]]: number;
  };
  voiceover: {
    [K in keyof VoiceoverOutput[number]]: number;
  };
  visuals: {
    [K in keyof VisualsOutput[number]]: number;
  };
  schedule: {
    [K in keyof Omit<ScheduleOutput[number], 'details'>]: number;
  } & {
    detailItem: number;
  };
  scriptedScript: {
    [K in keyof Required<ScriptedScriptOutput[number]>]: number;
  };
};

export const INLINE_EDIT_MAX_CHAR_LIMITS: InlineEditMaxCharLimits = {
  comment: 1000,
  videoSummary: {
    project: 600,
    objective: 1400,
    targetAudience: 1000,
    keyMessage: 1400,
    animationStyle: 1000,
    footageSource: 400,
    approach: 2000,
    lookAndFeel: 3000,
    callToAction: 800,
  },
  scriptedVideoSummary: {
    project: 600,
    objective: 1400,
    approach: 2000,
    lookAndFeel: 3000,
  },
  characters: {
    name: 99,
    summary: 1500,
    wardrobe: 1500,
  },
  interviews: {
    question: 1000,
  },
  voiceover: {
    voiceover: 2000,
  },
  visuals: {
    onScreenTitle: 400,
    visualDescription: 1400,
  },
  schedule: {
    title: 60,
    time: 40,
    detailItem: 1000,
  },
  scriptedScript: {
    frame: 4,
    titles: 400,
    audio: 2000,
    visual: 2500,
    frameId: 36,
  },
};

type InlineEditMinCharLimits = {
  visuals: {
    [K in keyof VisualsOutput[number]]?: number;
  };
  voiceover: {
    [K in keyof VoiceoverOutput[number]]: number;
  };
  scriptedScript: {
    [K in keyof Required<ScriptedScriptOutput>[number]]: number;
  };
  characters: {
    [K in keyof EditableCharactersOutput]?: number;
  };
};

export const INLINE_EDIT_MIN_CHAR_LIMITS: InlineEditMinCharLimits = {
  visuals: {
    onScreenTitle: 0,
  },
  characters: {
    name: 2,
  },
  voiceover: {
    voiceover: 2,
  },
  scriptedScript: {
    frame: 1,
    visual: 20,
    frameId: 8,
  },
};

export const INLINE_EDIT_DEFAULT_MINCHARS = 2;
