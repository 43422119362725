import { memo, useCallback, useMemo } from 'react';

import '@vidstack/react/player/styles/default/theme.css';
import '@vidstack/react/player/styles/default/layouts/video.css';

import type { SxProps, Theme } from '@mui/material';
import { MediaProvider } from '@vidstack/react';
import { DefaultVideoLayout } from '@vidstack/react/player/layouts/default';
import { isEqual, merge } from 'lodash-es';

import { useVideoPlayerRef } from '@ll-platform/frontend/features/videoPlayer/contexts/VideoPlayerRefContext';
import { useVideoPlayerConfig } from '@ll-platform/frontend/features/videoPlayer/hooks/useVideoPlayerConfig';
import { StyledMediaPlayer } from '@ll-platform/frontend/features/videoPlayer/styles';
import type { VideoPlayerConfig } from '@ll-platform/frontend/features/videoPlayer/types';

export type VideoPlayerProps = VideoPlayerConfig & {
  className?: string;
  sx?: SxProps<Theme>;
};

// Remember to wrap the video player in a VideoPlayerRefContextProvider
export const VideoPlayer = memo(
  ({ className, sx, ...config }: VideoPlayerProps) => {
    const { videoPlayerRef, setIsReady } = useVideoPlayerRef();
    const { playerConfig, layoutConfig, styleOverrides } =
      useVideoPlayerConfig(config);

    const onCanPlay = useCallback(() => setIsReady(true), [setIsReady]);

    const mergedSx = useMemo(() => {
      return merge({}, sx, ...styleOverrides);
    }, [styleOverrides, sx]);

    return (
      <StyledMediaPlayer
        {...playerConfig}
        ref={videoPlayerRef}
        className={className}
        onCanPlay={onCanPlay}
        sx={mergedSx}
      >
        <MediaProvider />
        <DefaultVideoLayout {...layoutConfig} />
      </StyledMediaPlayer>
    );
  },
  isEqual,
);
VideoPlayer.displayName = 'VideoPlayer';
