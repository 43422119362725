import {
  automapDates,
  type SerializedJson,
} from '@ll-platform/frontend/core/api/helpers/dateMapper';
import { heroHttpClient } from '@ll-platform/frontend/core/api/HeroHttpClient';
import type {
  DocumentData,
  DocumentDto,
} from '@ll-platform/frontend/hero/documentBuilder/core/builder/documentBuilderTypes';
import type { ByIdParams } from '@ll-platform/frontend/utils/types/types';

type UpdateDocumentArgs = DocumentData & {
  id: string;
};

class DocumentBuilderService {
  async update({ id, ...data }: UpdateDocumentArgs): Promise<DocumentDto> {
    const response = await heroHttpClient.unwrappedHttpRequest<
      SerializedJson<DocumentDto>
    >({
      config: {
        method: 'PUT',
        url: `/v1/document-builder/${id}`,
        data,
      },
    });

    return automapDates<DocumentDto>(response);
  }

  async getById({ id }: ByIdParams): Promise<DocumentDto> {
    const response = await heroHttpClient.unwrappedHttpRequest<
      SerializedJson<DocumentDto>
    >({
      config: {
        method: 'GET',
        url: `/v1/document-builder/${id}`,
      },
    });

    return automapDates<DocumentDto>(response);
  }
}

export const documentBuilderService = new DocumentBuilderService();
