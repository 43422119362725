import { File, Info } from 'lucide-react';

import {
  Card,
  CardContent,
} from '@ll-platform/frontend/components/shadcn/ui/card';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@ll-platform/frontend/components/shadcn/ui/tooltip';
import { TwTypography } from '@ll-platform/frontend/components/tw/TwTypography/TwTypography';

import { ConceptAssetsUploader } from './ConceptAssetsUploader';

type ConceptUploadAssetsProps = {
  allowedFormats?: string[];
};

export const ConceptUploadAssets = ({
  allowedFormats = ['PDF', 'DOCX', 'TXT', 'PPTX'],
}: ConceptUploadAssetsProps) => {
  return (
    <Card className="mx-6 p-0 bg-card-lighten">
      <CardContent className="py-4 px-4">
        <div className="flex items-center gap-4">
          <div className="p-[6px] bg-sidebar-primary rounded-md">
            <File width={14} height={14} />
          </div>
          <TwTypography variant="small" className="font-semibold">
            Upload context
          </TwTypography>
        </div>
        <TwTypography
          variant="small"
          className="text-muted-foreground leading-5 font-medium mt-4"
        >
          Add supporting materials to guide concept generation, like a creative
          brief, script, or mood board.
        </TwTypography>
        <div className="mt-3 mb-4">
          <ConceptAssetsUploader allowedFormats={allowedFormats} />
        </div>
        <Tooltip>
          <TooltipTrigger className="" asChild>
            <div className="flex items-center gap-1">
              <TwTypography variant="small" className="text-muted-foreground">
                Supported formats
              </TwTypography>
              <Info height={12} width={12} className="text-muted-foreground" />
            </div>
          </TooltipTrigger>
          <TooltipContent>
            Supported formats: {allowedFormats.join(', ')}
          </TooltipContent>
        </Tooltip>
      </CardContent>
    </Card>
  );
};
