import { Editor } from "@tiptap/react"

export const isTableGripSelected = (node: HTMLElement) => {
  let container = node

  while (container && !["TD", "TH"].includes(container.tagName)) {
    container = container.parentElement!
  }

  const gripColumn =
    container &&
    container.querySelector &&
    container.querySelector("a.grip-column.selected")
  const gripRow =
    container &&
    container.querySelector &&
    container.querySelector("a.grip-row.selected")

  if (gripColumn || gripRow) {
    return true
  }

  return false
}

export const isCustomNodeSelected = (editor: Editor, node: HTMLElement) => {
  return isTableGripSelected(node)
}

export default isCustomNodeSelected
