import type { ProjectFormValues } from '@ll-platform/frontend/features/internalProjects/projectForm/projectFormSchema';
import type { ProjectFormOutput } from '@ll-platform/frontend/features/internalProjects/types';
import { defined } from '@ll-platform/frontend/utils/types/types';

import { mapProjectFormToDto } from './mapFormToDto';

type MapProjectFormForBudgetArgs = {
  project: ProjectFormValues;
  includeCharacters: boolean;
};

export const mapProjectFormForBudget = ({
  includeCharacters,
  project,
}: MapProjectFormForBudgetArgs): ProjectFormOutput => {
  const projectFormWithoutDefaults = {
    ...project,
    productionDays: project.productionDays?.filter((productionDay) =>
      defined(productionDay.productionLength),
    ),
    heroVideos: project.heroVideos?.filter((heroVideo) =>
      defined(heroVideo.videoLength),
    ),
    characters: includeCharacters ? project.characters : undefined,
  };

  return {
    ...mapProjectFormToDto(projectFormWithoutDefaults),
  };
};
