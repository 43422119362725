import type { EmptyObject } from 'type-fest';

import { controlsBelowPlayerStyleOverrides } from '@ll-platform/frontend/features/videoPlayer/controlsBelowPlayer/styles';
import type { PluginConfigFactory } from '@ll-platform/frontend/features/videoPlayer/types';

export const withControlsBelowPlayerPlugin: PluginConfigFactory<EmptyObject> = (
  config,
) => {
  config.playerConfig.hideControlsOnMouseLeave = false;

  config.styleOverrides.push(controlsBelowPlayerStyleOverrides);

  return config;
};
