import type { User, UserGroup } from '@ll-platform/frontend/core/auth/types';

import { getUserGroup } from './getUserGroup';

export function userGroupSwitch<T>({
  user,
  map,
}: {
  user: Pick<User, 'accountType'>;
  map: Partial<Record<UserGroup, T>>;
}): T {
  if (!user) {
    throw new Error('[userGroupSwitch] requires a user');
  }

  const group = getUserGroup(user);
  const value = map[group];
  if (!value) {
    throw new Error(
      `[userGroupSwitch] missing value for ${group}/${user.accountType} account type`,
    );
  }

  return value;
}
