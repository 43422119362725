// Reload the app when a new version has been deployed and files are missing

const LAST_RELOAD_LS_KEY = 'loadErrorHandler:lastReload';
const RELOAD_MIN_INTERVAL = 30 * 1000;

export function handleLoadError() {
  const lastReloadString = localStorage.getItem(LAST_RELOAD_LS_KEY);
  const lastReloadDate = lastReloadString ? new Date(lastReloadString) : null;
  if (
    lastReloadDate &&
    Date.now() - lastReloadDate.getTime() < RELOAD_MIN_INTERVAL
  ) {
    return;
  }
  localStorage.setItem(LAST_RELOAD_LS_KEY, new Date().toISOString());

  window.alert(
    'A new version of the app has been released. The page will now reload.',
  );

  window.location.reload();
}

// https://vitejs.dev/guide/build#load-error-handling
window.addEventListener('vite:preloadError', handleLoadError);
