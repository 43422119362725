import { Pages } from '@ll-platform/frontend/core/router/pages';
import type { UserSettings } from '@ll-platform/frontend/features/auth/userSettings/types';

export const defaultUserSettings = {
  hasWatchedIntroVideo: false,
  internalDashboardDefaultPage: Pages.InternalProjects,
  isInternalDashboardSidebarOpen: true,
  viewedTours: [],
  disableTours: false,
  firstWizardViewAt: undefined,
} as const satisfies Partial<UserSettings>;
