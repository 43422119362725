import { type ReactNode } from 'react';

import SuspenseLoader from '@ll-platform/frontend/components/SuspenseLoader';
import { useCreator } from '@ll-platform/frontend/creator/auth/hooks/useCreator';
import { AuthRedirectController } from '@ll-platform/frontend/creator/auth/pages/AuthRedirectController';

type IsLoggedInGuardProps = {
  children: ReactNode;
};

export const IsLoggedInGuard = ({ children }: IsLoggedInGuardProps) => {
  const { isLoading, isAuthorized } = useCreator();

  if (isLoading) {
    return <SuspenseLoader />;
  }

  if (!isAuthorized) {
    return <AuthRedirectController />;
  }

  return children;
};
