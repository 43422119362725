import { useFormContext } from 'react-hook-form';

import { basicInfoFields } from '@ll-platform/frontend/features/internalProjects/projectForm/basicInfo/basicInfoSchema';
import { makeBasicInfoName } from '@ll-platform/frontend/features/internalProjects/projectForm/basicInfo/makeBasicInfoName';
import type { ProjectStyleEnum } from '@ll-platform/frontend/features/projects/enums';

export function useProjectStyle(): ProjectStyleEnum {
  const { watch } = useFormContext();

  const style = watch(makeBasicInfoName({ field: basicInfoFields.style }));

  return style;
}
