import {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  type ReactNode,
} from 'react';

import { ChevronRight } from '@mui/icons-material';
import { alpha, Button } from '@mui/material';

import { ContainerWithEllipsis } from '@ll-platform/frontend/components/ContainerWithElipsis/ContainerWithElipsis';
import { nextTick } from '@ll-platform/frontend/utils/helpers/helpers';

export type StepsBreadcrumbProps = {
  steps: { content: ReactNode; onClick?: () => void }[];
  currentStepIndex: number | null;
};

export const StepsBreadcrumb = ({
  steps,
  currentStepIndex,
}: StepsBreadcrumbProps) => {
  const currentButtonRef = useRef<HTMLButtonElement | null>(null);

  const stepsWithMetadata = steps.map((step, i) => ({
    ...step,
    originalIndex: i,
    isSelected: i === currentStepIndex,
  }));

  const scrollIntoCurrentStepView = useCallback(() => {
    currentButtonRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center',
    });
  }, []);

  useEffect(() => {
    nextTick(() => {
      scrollIntoCurrentStepView();
    });
  }, [currentStepIndex, scrollIntoCurrentStepView]);

  return (
    <ContainerWithEllipsis
      enableLeadingEllipsis={true}
      onResize={scrollIntoCurrentStepView}
      leadingEllipsisProps={{
        sx: {
          alignItems: 'center',
          fontWeight: 700,
        },
      }}
      trailingEllipsisProps={{
        sx: {
          alignItems: 'center',
          color: (theme) => theme.palette.action.disabled,
          fontWeight: 700,
        },
      }}
      sx={{
        alignItems: 'center',
      }}
    >
      {stepsWithMetadata.map((step, i) => (
        <Fragment key={`${steps.length}-${step.originalIndex}`}>
          {i !== 0 && (
            <ChevronRight sx={{ width: 32, height: 28, opacity: 0.38 }} />
          )}
          <Button
            onClick={step.onClick}
            variant={step.isSelected ? 'contained' : 'text'}
            disabled={!step.onClick && !step.isSelected}
            sx={{
              boxShadow: 'none',
              borderRadius: 2,
              whiteSpace: 'nowrap',
              ':not(:disabled)': step.isSelected
                ? {
                    color: (theme) => theme.palette.primary.main,
                    background: (theme) =>
                      alpha(
                        theme.palette.primary.main,
                        theme.palette.action.focusOpacity,
                      ),
                  }
                : {
                    color: (theme) => theme.palette.text.primary,
                  },
              ':hover': {
                boxShadow: 'none',
              },
              minWidth: 'auto',
              ':disabled': {
                color: (theme) => theme.palette.text.disabled,
              },
            }}
            ref={step.isSelected ? currentButtonRef : null}
          >
            {step.content}
          </Button>
        </Fragment>
      ))}
    </ContainerWithEllipsis>
  );
};
