import { type QueryClient } from '@tanstack/react-query';

import { createMutationHook } from '@ll-platform/frontend/utils/factories/createMutationHook';

import { creativeProposalsService } from './CreativeProposalsService';
import { CreativeProposalsQueries } from './useCreativeProposalsQueries';

export const useCreateCreativeProposal = createMutationHook(
  creativeProposalsService.create.bind(creativeProposalsService),
  (queryClient: QueryClient) => ({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: CreativeProposalsQueries._def,
      });
    },
  }),
);

export const useCloneCreativeProposal = createMutationHook(
  creativeProposalsService.cloneById.bind(creativeProposalsService),
  (queryClient: QueryClient) => ({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: CreativeProposalsQueries._def,
      });
    },
  }),
);

export const useUpdateCreativeProposal = createMutationHook(
  creativeProposalsService.update.bind(creativeProposalsService),
  (queryClient: QueryClient) => ({
    onSuccess: (data, variables) => {
      queryClient.setQueryData(
        CreativeProposalsQueries.getById({ id: variables.id }).queryKey,
        data,
      );
      // No need to invalidate proposal findAll as it refetches on mount
    },
  }),
);

export const useDeleteCreativeProposalById = createMutationHook(
  creativeProposalsService.deleteById.bind(creativeProposalsService),
  (queryClient: QueryClient) => ({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: CreativeProposalsQueries._def,
      });
    },
  }),
);

export const useCreateProposalConcept = createMutationHook(
  creativeProposalsService.createConcept.bind(creativeProposalsService),
  (queryClient: QueryClient) => ({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: CreativeProposalsQueries._def,
      });
    },
  }),
);

export const useDeleteConceptsByIds = createMutationHook(
  creativeProposalsService.deleteConceptById.bind(creativeProposalsService),
  (queryClient: QueryClient) => ({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: CreativeProposalsQueries._def,
      });
    },
  }),
);

export const useUpdateConcept = createMutationHook(
  creativeProposalsService.updateConceptById.bind(creativeProposalsService),
  (queryClient: QueryClient) => ({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: CreativeProposalsQueries._def,
      });
    },
  }),
);

export const useDeleteProposalFileById = createMutationHook(
  creativeProposalsService.removeFile.bind(creativeProposalsService),
  (queryClient: QueryClient) => ({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: CreativeProposalsQueries._def,
      });
    },
  }),
);

export const useAddProposalFile = createMutationHook(
  creativeProposalsService.addFile.bind(creativeProposalsService),
  (queryClient: QueryClient) => ({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: CreativeProposalsQueries._def,
      });
    },
  }),
);

export const useGenerateProposalSummary = createMutationHook(
  creativeProposalsService.generateSummary.bind(creativeProposalsService),
);
