import { MAX_BACKEND_TIMEOUT } from '@ll-platform/frontend/core/api/consts';
import { heroHttpClient } from '@ll-platform/frontend/core/api/HeroHttpClient';
import type { PaginatedResponse } from '@ll-platform/frontend/core/api/pagination/pagination.types';
import type {
  ConceptsParams,
  ConceptUpdatePayload,
  CreateConceptPayload,
  CreateVectorFileDto,
  CreativeProposal,
  CreativeProposalFindAllQueryDto,
  GenerateSummaryDto,
  PaginatedCreativeProposalItemDto,
  PaginatedCreativeProposalsDto,
  ProposalByIdAndConceptByIdParams,
  ProposalByIdParams,
  UcFileIdParams,
  UpdateCreativeProposalPayload,
} from '@ll-platform/frontend/hero/creativeProposal/types';

class CreativeProposalsService {
  async findAll(
    args: CreativeProposalFindAllQueryDto,
  ): Promise<PaginatedCreativeProposalsDto> {
    const paginatedCreativeProposals =
      await heroHttpClient.unwrappedHttpRequest<
        PaginatedResponse<PaginatedCreativeProposalItemDto>
      >({
        config: {
          method: 'GET',
          url: '/v1/creative-proposals',
          params: args,
        },
      });

    return paginatedCreativeProposals;
  }

  async getByProposalId(args: ProposalByIdParams): Promise<CreativeProposal> {
    const creativeProposal =
      await heroHttpClient.unwrappedHttpRequest<CreativeProposal>({
        config: {
          method: 'GET',
          url: `/v1/creative-proposals/${args.id}`,
        },
      });

    return creativeProposal;
  }

  async create(): Promise<CreativeProposal> {
    const creativeProposal =
      await heroHttpClient.unwrappedHttpRequest<CreativeProposal>({
        config: {
          method: 'POST',
          url: '/v1/creative-proposals',
        },
      });

    return creativeProposal;
  }

  async cloneById(args: ProposalByIdParams): Promise<CreativeProposal> {
    const creativeProposal =
      await heroHttpClient.unwrappedHttpRequest<CreativeProposal>({
        config: {
          method: 'POST',
          url: `/v1/creative-proposals/${args.id}/clone`,
        },
      });

    return creativeProposal;
  }

  async generateSummary(args: ProposalByIdParams): Promise<GenerateSummaryDto> {
    const proposalSummaryOutput =
      await heroHttpClient.unwrappedHttpRequest<GenerateSummaryDto>({
        config: {
          method: 'PUT',
          url: `/v1/creative-proposals/${args.id}/summary`,
          timeout: 20000,
        },
      });

    return proposalSummaryOutput;
  }

  async update(
    args: { id: string } & Partial<UpdateCreativeProposalPayload>,
  ): Promise<CreativeProposal> {
    const { id, ...data } = args;

    const creativeProposal =
      await heroHttpClient.unwrappedHttpRequest<CreativeProposal>({
        config: {
          method: 'PATCH',
          url: `/v1/creative-proposals/${id}`,
          data,
        },
      });

    return creativeProposal;
  }

  async createConcept({
    id,
    ...args
  }: {
    id: string;
  } & Partial<CreateConceptPayload>): Promise<CreativeProposal> {
    const creativeProposal =
      await heroHttpClient.unwrappedHttpRequest<CreativeProposal>({
        config: {
          method: 'POST',
          url: `/v1/creative-proposals/${id}/concepts`,
          data: args,
        },
      });

    return creativeProposal;
  }

  async deleteById(args: ProposalByIdParams): Promise<void> {
    await heroHttpClient.unwrappedHttpRequest<void>({
      config: {
        method: 'DELETE',
        url: `/v1/creative-proposals/${args.id}`,
      },
    });
  }

  async deleteConceptById(
    args: ProposalByIdParams & ConceptsParams,
  ): Promise<void> {
    const conceptIdsParam = args.concepts.map((id) => `ids=${id}`).join('&');

    await heroHttpClient.unwrappedHttpRequest<void>({
      config: {
        method: 'DELETE',
        url: `/v1/creative-proposals/${args.id}/concepts?${conceptIdsParam}`,
      },
    });
  }

  async updateConceptById(
    args: ProposalByIdAndConceptByIdParams & ConceptUpdatePayload,
  ): Promise<void> {
    const { conceptId, proposalId, ...rest } = args;

    await heroHttpClient.unwrappedHttpRequest<void>({
      config: {
        method: 'PATCH',
        url: `/v1/creative-proposals/${proposalId}/concepts/${conceptId}`,
        data: rest,
      },
    });
  }

  async addFile({
    id,
    ...fileData
  }: ProposalByIdParams & CreateVectorFileDto): Promise<void> {
    await heroHttpClient.unwrappedHttpRequest<void>({
      config: {
        method: 'POST',
        url: `/v1/creative-proposals/${id}/files`,
        timeout: MAX_BACKEND_TIMEOUT,
        data: {
          fileUrl: fileData.fileUrl,
          filename: fileData.fileName,
          mimeType: fileData.mimeType,
        },
      },
    });
  }

  async removeFile(args: ProposalByIdParams & UcFileIdParams): Promise<void> {
    await heroHttpClient.unwrappedHttpRequest<void>({
      config: {
        method: 'DELETE',
        url: `/v1/creative-proposals/${args.id}/files/${args.fileId}`,
      },
    });
  }
}

export const creativeProposalsService = new CreativeProposalsService();
