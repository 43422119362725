export const NEW_CONCEPTS_COUNT = 3;
export const DEFAULT_MOODBOARD_LENGTH = 3;
export const LOADING_SCOPE_GENERATING = 'generating';
export const LOADING_SCOPE_PROCESSING_FILES = 'processing-files';

export enum GeneratingLoadingScopeClaims {
  SaveForm = 'save-form',
  CreateConcepts = 'create-concepts',
  GenerateConcept = `generate-concept/:id`,
}
