import { useCallback, useEffect, useMemo, useState } from 'react';

import { SentimentDialog } from './SentimentDialog';
import { useIsSentimentDialogAvailable } from './useIsSentimentDialogAvailable';

const SATISFACTION_MODAL_SHOW_TIMEOUT = 800;

type UseSentimentDialogProps = {
  modalTriggerFlag: boolean;
  onFinalize?: () => void;
};

export const useSentimentDialog = ({
  modalTriggerFlag,
  onFinalize,
}: UseSentimentDialogProps) => {
  const isEnabled = useIsSentimentDialogAvailable();
  const [isOpen, setOpen] = useState(false);

  const openModal = useCallback(() => {
    setOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    setOpen(false);
  }, []);

  useEffect(() => {
    if (!modalTriggerFlag) {
      return;
    }

    const sentimentTimeout = setTimeout(() => {
      if (isEnabled) {
        openModal();
      }
    }, SATISFACTION_MODAL_SHOW_TIMEOUT);

    return () => {
      sentimentTimeout && clearTimeout(sentimentTimeout);
    };
  }, [isEnabled, modalTriggerFlag, openModal]);

  const handleSubmit = useCallback(() => {
    onFinalize?.();
    closeModal();
  }, [onFinalize, closeModal]);

  const node = useMemo(() => {
    return <SentimentDialog isOpen={isOpen} onSubmit={handleSubmit} />;
  }, [isOpen, handleSubmit]);

  return useMemo(
    () => ({
      node,
      isEnabled,
    }),
    [node, isEnabled],
  );
};
