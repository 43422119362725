import {
  createContext,
  useContext,
  useMemo,
  useRef,
  useState,
  type PropsWithChildren,
  type RefObject,
} from 'react';

import type { MediaPlayerInstance } from '@vidstack/react';

type VideoPlayerRefContextType = {
  videoPlayerRef: RefObject<MediaPlayerInstance>;
  isReady: boolean;
  setIsReady: (isReady: boolean) => void;
};

const VideoPlayerRefContext = createContext<VideoPlayerRefContextType>({
  videoPlayerRef: { current: null },
  isReady: false,
  setIsReady: () => {},
});

VideoPlayerRefContext.displayName = 'VideoPlayerRefContext';

export const VideoPlayerRefProvider = ({ children }: PropsWithChildren) => {
  const videoPlayerRef = useRef<MediaPlayerInstance>(null);
  const [isReady, setIsReady] = useState(false);

  const value = useMemo(
    () => ({ videoPlayerRef, isReady, setIsReady }),
    [isReady],
  );

  return (
    <VideoPlayerRefContext.Provider value={value}>
      {children}
    </VideoPlayerRefContext.Provider>
  );
};

export function useVideoPlayerRef() {
  return useContext(VideoPlayerRefContext);
}
