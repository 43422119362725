import { doc, getDoc, setDoc } from 'firebase/firestore';
import { merge } from 'lodash-es';
import type { ConditionalExcept, SetRequired } from 'type-fest';

import { firestore } from '@ll-platform/frontend/core/firebase/firebaseService';
import { FirestoreCollections } from '@ll-platform/frontend/core/firebase/types';
import type { defaultUserSettings } from '@ll-platform/frontend/features/auth/userSettings/config/defaultUserSettings';
import type {
  GetActiveUserSettingsArgs,
  UpdateUserSettingsArgs,
  UserSettings,
} from '@ll-platform/frontend/features/auth/userSettings/types';

class UserSettingsService {
  async getActiveUserSettingsByUserId({ userId }: GetActiveUserSettingsArgs) {
    const docRef = doc(firestore, FirestoreCollections.UserSettings, userId);
    const result = await getDoc(docRef);

    const settings = (result.data() ?? {}) as UserSettings;

    const localStorageOverrides =
      JSON.parse(localStorage.getItem('userSettings') || '{}') || {};

    const mergedSettings = merge(
      {},
      settings,
      localStorageOverrides,
    ) as UserSettings &
      SetRequired<
        UserSettings,
        keyof ConditionalExcept<typeof defaultUserSettings, null | undefined>
      >;

    return mergedSettings;
  }

  async updateUserSettingsDocument({
    userId,
    settingsData,
  }: UpdateUserSettingsArgs): Promise<void> {
    const docRef = doc(firestore, FirestoreCollections.UserSettings, userId);
    await setDoc(
      docRef,
      {
        ...settingsData,
      },
      { merge: true },
    );
  }
}

export const userSettingsService = new UserSettingsService();
