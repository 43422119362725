import { Base64 } from 'js-base64';
import type { Jsonifiable, JsonValue } from 'type-fest';

export function makeNextParam(next: string) {
  const encoded = Base64.encodeURI(next);

  return `next=${encoded}`;
}

export function getNextParam(searchParams: URLSearchParams) {
  const nextParam = searchParams.get('next');
  if (nextParam) {
    return decodeNextParam(nextParam);
  }

  return null;
}

export function decodeNextParam(base64String: string) {
  if (base64String) {
    const decoded = Base64.decode(base64String);

    return decoded;
  }

  return null;
}

export function makeNextParamForCurrent() {
  const currentPath = `${window.location.pathname}${window.location.search}`;

  return makeNextParam(currentPath);
}

export function encodeJsonParam(data: Jsonifiable) {
  const encoded = Base64.encodeURI(JSON.stringify(data));

  return encoded;
}

export function decodeJsonParam(
  base64String: string | null | undefined,
): JsonValue | null {
  if (base64String) {
    try {
      const decoded = Base64.decode(base64String);

      return JSON.parse(decoded);
    } catch (error) {
      console.warn('Failed to decode JSON Param', error);

      return null;
    }
  }

  return null;
}
