import {
  htmlToSlate as convertHtmlToSlate,
  htmlToSlateConfig,
} from '@slate-serializers/html';
import type { Descendant } from 'slate';

import { TextEditorElementEnum } from '@ll-platform/frontend/features/textEditor/types';

export const htmlToSlate = (html: string): Descendant[] => {
  return convertHtmlToSlate(html, {
    ...htmlToSlateConfig,
    elementTags: {
      ...htmlToSlateConfig.elementTags,
      p: () => ({
        type: TextEditorElementEnum.Paragraph,
      }),
    },
  }) as Descendant[];
};
