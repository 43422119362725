import { merge } from 'lodash-es';

import type { SlideTemplateConfig } from '@ll-platform/frontend/hero/documentBuilder/core/slideTemplates/slideTemplateTypes';
import { makeDefaultImageObject } from '@ll-platform/frontend/hero/documentBuilder/shared/nodes/MediaNode/mediaNodeHelpers';
import { textImageSlideTemplate } from '@ll-platform/frontend/hero/documentBuilder/shared/slideTemplates/textImageSlideTemplate';

const overviewSlideTemplateDefaults: Partial<
  typeof textImageSlideTemplate.templateDefaults
> = {
  text: `Explain in one or two concise sentences how your solution transforms users' challenges into positive outcomes. Focus on the end benefits that matter most to your target audience. Keep it clear and compelling.Explain in one or two concise sentences how your solution transforms users' challenges into positive outcomes. Focus on the end benefits that matter most to your target audience. Keep it clear and compelling.Explain in one or two concise sentences how your solution transforms users' challenges into positive outcomes. Focus on the end benefits that matter most to your target audience. Keep it clear and compelling.Explain in one or two concise sentences how your solution transforms users' challenges into positive outcomes. Focus on the end benefits that matter most to your target audience. Keep it clear and compelling.Explain in one or two concise sentences how your solution transforms users' challenges into positive outcomes. Focus on the end benefits that matter most to your target audience. Keep it clear and compelling.`.trim(),
  media: makeDefaultImageObject('https://placehold.co/660x704'),
};

const overviewSlideDefaults = merge(
  textImageSlideTemplate.templateDefaults,
  overviewSlideTemplateDefaults,
);

export const overviewSlideTemplate: SlideTemplateConfig<
  typeof textImageSlideTemplate.templateDefaults
> = {
  templateDefaults: overviewSlideDefaults,
  makeDocumentSlide: textImageSlideTemplate.makeDocumentSlide,
  creator: {
    name: 'Overview',
    create: async () => ({
      slides: [textImageSlideTemplate.makeDocumentSlide(overviewSlideDefaults)],
    }),
  },
};
