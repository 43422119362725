import { useEffect, useMemo } from 'react';

import { Stack, TextField } from '@mui/material';
import { useMediaRemote } from '@vidstack/react';
import { merge } from 'lodash-es';
import * as yup from 'yup';

import SuspenseLoader from '@ll-platform/frontend/components/SuspenseLoader';
import { useGetDeliverableVideoReviewComments } from '@ll-platform/frontend/features/projectComments/async/useProjectCommentsQueries';
import { CommentsSidebar } from '@ll-platform/frontend/features/projectComments/components/CommentsSidebar/CommentsSidebar';
import { HoveredCommentContextProvider } from '@ll-platform/frontend/features/projectComments/contexts/HoveredCommentContext';
import { useFilterCommentThreads } from '@ll-platform/frontend/features/projectComments/hooks/useFilterCommentThreads';
import { useGetProjectById } from '@ll-platform/frontend/features/projects/async/useProjectsQueries';
import {
  useVideoPlayerRef,
  VideoPlayerRefProvider,
} from '@ll-platform/frontend/features/videoPlayer/contexts/VideoPlayerRefContext';
import { makeUploadcareThumbnails } from '@ll-platform/frontend/features/videoPlayer/helpers/uploadcareThumbnails';
import { pauseAndSeek } from '@ll-platform/frontend/features/videoPlayer/helpers/videoPlayer';
import { VideoPlayerPlugins } from '@ll-platform/frontend/features/videoPlayer/types';
import {
  VideoPlayer,
  type VideoPlayerProps,
} from '@ll-platform/frontend/features/videoPlayer/VideoPlayer';
import { useEffectOnce } from '@ll-platform/frontend/utils/hooks/useStrictModeEffect';
import { useTypedSearchParams } from '@ll-platform/frontend/utils/hooks/useTypedSearchParams';
import { defined } from '@ll-platform/frontend/utils/types/types';

const defaultParams = {
  projectId: '9qh2HMZM4QF1vCEeXkSP',
  deliverableId: 'video-key-with-version',
} as const;

const VideoPlayerPlaygroundInner = () => {
  const { videoPlayerRef, isReady } = useVideoPlayerRef();
  const mediaRemote = useMediaRemote(videoPlayerRef);
  const { params, updateParams } = useTypedSearchParams(
    yup.object({
      projectId: yup.string().optional(),
      videoId: yup.string().optional(),
      t: yup.number().optional(),
      commentId: yup.string().optional(),
    }),
  );

  const { projectId, deliverableId } = merge({}, defaultParams, params);
  const projectQuery = useGetProjectById({
    id: projectId,
  });
  const playerProps = useMemo<VideoPlayerProps>(
    () => ({
      playerConfig: {
        src: [
          {
            src: 'https://ucarecdn.com/3598e833-b9ef-4350-b47c-26b6d337c507/',
            type: 'video/mp4',
            width: 1920,
            height: 1080,
          },
          {
            src: 'https://ucarecdn.com/ea8228d1-c494-4cea-acdf-ef30247859a1/',
            type: 'video/mp4',
            width: 1280,
            height: 720,
          },
          {
            src: 'https://ucarecdn.com/a6707af9-397b-4392-a70a-a3e28cc336e9/',
            type: 'video/mp4',
            width: 640,
            height: 360,
          },
        ],
        poster:
          'https://ucarecdn.com/5f8d6885-b541-4bdd-9f69-024e06b950b7~50/nth/0/',
      },
      layoutConfig: {
        thumbnails: makeUploadcareThumbnails({
          thumbnailGroupUrl:
            'https://ucarecdn.com/5f8d6885-b541-4bdd-9f69-024e06b950b7~50/',
          duration: 30,
        }),
      },
      pluginsConfig: {
        [VideoPlayerPlugins.Comments]: {
          projectId,
          deliverableId,
          canAddNewDeliverableComments: true,
        },
        [VideoPlayerPlugins.ControlsBelowPlayer]: {},
      },
    }),
    [projectId, deliverableId],
  );

  const commentsQuery = useGetDeliverableVideoReviewComments({
    projectId,
    deliverableId,
  });
  const threads = useFilterCommentThreads({
    comments: commentsQuery.data,
  });

  useEffectOnce(() => {
    if (!params.projectId) {
      updateParams(defaultParams);
    }
  });

  useEffect(() => {
    if (!defined(params.t) || !isReady) {
      return;
    }

    pauseAndSeek(mediaRemote, params.t);
    updateParams({ t: undefined });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.t, isReady]);

  if (projectQuery.isPending) {
    return <SuspenseLoader />;
  }

  return (
    <Stack
      gap={2}
      sx={{
        background: '#161618',
        '--player-bg': '#212123',
        flexGrow: 1,
        overflow: 'hidden',
      }}
    >
      <Stack direction="row" gap={2}>
        <Stack sx={{ mt: 4, ml: 4, flexGrow: 1 }}>
          <Stack
            direction="row"
            gap={2}
            alignItems="center"
            justifyContent="space-between"
          >
            <TextField
              label="projectId"
              value={projectId}
              onChange={(e) => updateParams({ projectId: e.target.value })}
              sx={{ '*': { color: 'white !important', width: 250 } }}
            />
          </Stack>
          <VideoPlayer {...playerProps} />
        </Stack>
        <CommentsSidebar threads={threads} sidebarMarginFromBottom="0px" />
      </Stack>
    </Stack>
  );
};

export const VideoPlayerPlayground = () => {
  return (
    <HoveredCommentContextProvider>
      <VideoPlayerRefProvider>
        <VideoPlayerPlaygroundInner />
      </VideoPlayerRefProvider>
    </HoveredCommentContextProvider>
  );
};
