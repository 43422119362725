import { useCallback } from 'react';

import { useLocation, type Path } from 'react-router-dom';

type FromState = Path;

export function useMakeFromState() {
  const location = useLocation();

  return useCallback(
    (anchor?: string): { from: FromState } => {
      return {
        from: {
          ...location,
          ...(anchor && { hash: `#${anchor}` }),
        },
      };
    },
    [location],
  );
}

export function useFromState() {
  const location = useLocation();

  return location.state?.from ?? (null as FromState | null);
}
