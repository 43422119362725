import { createQueryKeys } from '@lukemorales/query-key-factory';
import { keepPreviousData } from '@tanstack/query-core';

import { createQueryHook } from '@ll-platform/frontend/utils/factories/createQueryHook';
import { createQueryOption } from '@ll-platform/frontend/utils/factories/createQueryOption';

import { creativeProposalsService } from './CreativeProposalsService';

export const CreativeProposalsQueries = createQueryKeys('creativeProposals', {
  findAll: createQueryOption(
    creativeProposalsService.findAll.bind(creativeProposalsService),
  ),
  getById: createQueryOption(
    creativeProposalsService.getByProposalId.bind(creativeProposalsService),
  ),
});

export const useFindAllCreativeProposals = createQueryHook(
  CreativeProposalsQueries.findAll,
  {
    refetchOnMount: 'always',
  },
);

export const useGetCreativeProposalByProposalId = createQueryHook(
  CreativeProposalsQueries.getById,
);

export const useCreativeProposalsList = createQueryHook(
  CreativeProposalsQueries.findAll,
  {
    placeholderData: keepPreviousData,
    staleTime: 60_000,
    refetchOnMount: 'always',
  },
);
