import type * as mdast from 'mdast';
import markdownParser from 'remark-parse';
import type { Descendant, Text } from 'slate';
import { unified } from 'unified';

import type { CommentMessageType } from '@ll-platform/frontend/features/projectComments/types';
import type { CommentElement } from '@ll-platform/frontend/features/textEditor/comments/types';
import {
  TextEditorElementEnum,
  type ParagraphElement,
} from '@ll-platform/frontend/features/textEditor/types';

const specialCharsInMarkdown = [
  '\\',
  '-',
  '_',
  '*',
  '#',
  '+',
  '<',
  '>',
  '!',
  '.',
  '`',
  '|',
];

const escapeMarkdownSpecialChars = (
  pseudoMarkdown: string,
  specialChars: string[],
) => {
  let escapedMarkdown = pseudoMarkdown;

  specialChars.forEach((char) => {
    const escapedChar = `\\${char}`;
    escapedMarkdown = escapedMarkdown.replaceAll(char, escapedChar);
  });

  return escapedMarkdown;
};

// pseudoMarkdown is plaintext with only markdown links
export const pseudoMarkdownToSlate = (pseudoMarkdown: string) => {
  const markdown = escapeMarkdownSpecialChars(
    pseudoMarkdown,
    specialCharsInMarkdown,
  )
    .split('\n')
    .join('\\\n');

  return markdownToSlate(markdown);
};

const markdownToSlate = (markdown: string): Descendant[] => {
  const node = unified().use(markdownParser).parse(markdown);
  if (!markdown) {
    return [];
  }

  return node.children.map((subNode) => {
    return {
      type: TextEditorElementEnum.Paragraph,
      children: convertToSlate(subNode),
    };
  }) as Descendant[];
};

const convertToSlate = (root: mdast.RootContent): Descendant[] => {
  return convertChildren([root]);
};

const convertChildren = (nodes: mdast.RootContent[]): Descendant[] => {
  return nodes?.reduce<Descendant[]>((acc, node) => {
    acc.push(...makeSlateNode(node));

    return acc;
  }, []);
};

const makeSlateNode = (node: mdast.RootContent): Descendant[] => {
  switch (node.type) {
    case 'link':
      return [parseLink(node)].flat();
    case 'break':
      return [makeText({ value: '\n' })];
    case 'paragraph': {
      return convertChildren(node.children);
    }
    case 'text':
      return [makeText(node)];
    default:
      // empty text for unhandled types:
      return [makeParagraph({ children: [{ type: 'text', value: '' }] })];
  }
};

const parseLink = ({ children, title, url }: mdast.Link) => {
  switch (url) {
    case TextEditorElementEnum.Comment:
      return parseCommentItem({ children, title });
    default:
      // TODO display actual link
      return convertChildren(children);
  }
};

const parseCommentItem = ({
  children,
  title: metadata,
}: Pick<mdast.Link, 'children' | 'title'>) => {
  let comment: CommentMessageType;

  if (!metadata) {
    return convertChildren(children);
  }

  try {
    comment = JSON.parse(metadata);
  } catch {
    return convertChildren(children);
  }

  return makeComment({ children, comment });
};

const makeText = ({ value }: { value: string }) => {
  return {
    text: value,
  } as Text;
};

const makeParagraph = ({ children }: Pick<mdast.Paragraph, 'children'>) => {
  return {
    type: TextEditorElementEnum.Paragraph,
    children: convertChildren(children),
  } as ParagraphElement;
};

const makeComment = ({
  children,
  comment,
}: Pick<mdast.Link, 'children'> & { comment: CommentMessageType }) => {
  return {
    type: TextEditorElementEnum.Comment,
    comment,
    children: convertChildren(children),
  } as CommentElement;
};
