import { Typography, type TypographyProps } from '@mui/material';
import { omit } from 'lodash-es';
import type { RenderLeafProps } from 'slate-react';

import { selectionSx } from '@ll-platform/frontend/features/textEditor/hooks/useDecorateSelection';

export const Leaf = ({
  attributes,
  leaf,
  children,
  textNodeProps,
}: RenderLeafProps & { textNodeProps?: TypographyProps }) => (
  <Typography
    component="span"
    {...textNodeProps}
    sx={{
      ...(textNodeProps?.sx ? omit(textNodeProps.sx, 'padding') : {}),
      ...(leaf.keepSelected ? selectionSx : {}),
      // The following is a workaround for a Chromium bug where,
      // if you have an inline at the end of a block,
      // clicking the end of a block puts the cursor inside the inline
      // instead of inside the final {text: ''} node
      // https://github.com/ianstormtaylor/slate/issues/4704#issuecomment-1006696364
      paddingLeft: '0.1px',
    }}
    {...attributes}
  >
    {children}
  </Typography>
);
