import { EmailOutlined } from '@mui/icons-material';
import { Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import { Link } from 'react-router-dom';

import { LemonlightLogo } from '@ll-platform/frontend/components/LemonlightLogo/LemonlightLogo';
import { HERO_SUPPORT_EMAIL } from '@ll-platform/frontend/consts/hero';
import { ActiveCreatorWithMenu } from '@ll-platform/frontend/creator/components/ActiveCreator/ActiveCreatorWithMenu';
import { CreatorPages } from '@ll-platform/frontend/creator/pages';
import { useResponsiveView } from '@ll-platform/frontend/utils/hooks/useResponsiveView';

type NavigationBarProps = {
  //
};

// eslint-disable-next-line no-empty-pattern
export function NavigationBar({}: NavigationBarProps) {
  const { isMobile } = useResponsiveView();

  return (
    <Box
      sx={{
        backgroundColor: 'white',
        height: '64px',
        flexShrink: 0,
        px: 1,
        borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        zIndex: 1,
      }}
      component="nav"
    >
      <Toolbar
        sx={{
          gap: 2,
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          padding: '0 !important',
        }}
      >
        <Stack direction="row" alignItems="baseline" gap={1}>
          <Link to={CreatorPages.Home}>
            <LemonlightLogo />
          </Link>
        </Stack>
        <Box sx={{ flexGrow: 1 }} />
        {!isMobile && (
          <Button
            href={`mailto:${HERO_SUPPORT_EMAIL}`}
            variant="outlined"
            color={'inherit'}
            startIcon={<EmailOutlined />}
            sx={{
              fontWeight: '600',
              textTransform: 'capitalize',
            }}
          >
            Get Assistance
          </Button>
        )}
        <ActiveCreatorWithMenu />
      </Toolbar>
    </Box>
  );
}
