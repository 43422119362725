import { useCallback, type KeyboardEvent } from 'react';

import { Range, Transforms, type Editor } from 'slate';

export const useKeydownHandler = (editor: Editor) => {
  const onKeyDown = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      const { selection } = editor;

      // Default left/right behavior is unit:'character'.
      // This fails to distinguish between two cursor positions, such as
      // <inline>foo<cursor/></inline> vs <inline>foo</inline><cursor/>.
      // Here we modify the behavior to unit:'offset'.
      // This lets the user step into and out of the inline without stepping over characters.
      // You may wish to customize this further to only use unit:'offset' in specific cases.
      if (selection && Range.isCollapsed(selection)) {
        const { key, ctrlKey, metaKey, shiftKey, altKey } = event.nativeEvent;
        const isModifierKey = ctrlKey || metaKey || shiftKey || altKey;

        if (!isModifierKey && ['ArrowLeft', 'ArrowRight'].includes(key)) {
          event.preventDefault();
          switch (key) {
            case 'ArrowLeft': {
              Transforms.move(editor, { unit: 'offset', reverse: true });

              return;
            }
            case 'ArrowRight': {
              Transforms.move(editor, { unit: 'offset' });
            }
          }
        }
      }
    },
    [editor],
  );

  return onKeyDown;
};
