import type { ProjectHeroVideo } from '@ll-platform/frontend/features/projects/types';

import type { HeroVideoFormValues } from './heroVideoSchema';

export const mapAlternateVersionFormToDto = ({
  aspectRatio,
}: HeroVideoFormValues['alternateVersions'][number]): Required<ProjectHeroVideo>['alternativeVersion'][number] => {
  return {
    aspectRatioCount: aspectRatio!,
  };
};

export const mapAlternateVersionDtoToForm = ({
  aspectRatioCount,
}: Required<ProjectHeroVideo>['alternativeVersion'][number]): HeroVideoFormValues['alternateVersions'][number] => ({
  aspectRatio: aspectRatioCount,
});
