import type { PropsWithChildren } from 'react';

import { Navigate } from 'react-router-dom';

import { FeatureFlagName } from '@ll-platform/frontend/config/featureFlags/featureFlags';
import { useAppFlags } from '@ll-platform/frontend/core/featureFlags/useAppFlags';

export const ProjectSpaceFeatureFlagGuard = ({
  children,
}: PropsWithChildren) => {
  const flags = useAppFlags();
  if (!flags[FeatureFlagName.ProjectSpace]) {
    return <Navigate to="/" replace />;
  }

  return children;
};
