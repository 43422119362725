export type UserAvatarDto = {
  url: string;
};

export type Creator = {
  id: string;
  userId: string;
  city?: string;
  country?: string;
  state?: string;
  email: string;
  accountType: AccountType.Creator;
  firstName?: string;
  lastName?: string;
  isActive: boolean;
  phoneNumber?: string;
  avatar?: UserAvatarDto;
};

export type LocalAuthPayloadDto = {
  email: string;
  password: string;
};

export type UserJwtDto = {
  sub: string;
  email: string;
  accountType: AccountType;
};

export type RequestResetPasswordDto = {
  email: string;
};

export type UpdatePasswordDto = {
  token: string;
  password: string;
};

export enum TokenAction {
  ResetPassword = 'reset',
}

export type ResetPasswordJwtDto = {
  sub: string;
  email: string;
  action: TokenAction;
};

export enum AuthType {
  EmailPassword = 'email-password',
  OAuth2 = 'oauth2',
}

export enum AuthProvider {
  Local = 'local',
  Jwt = 'jwt',
  GoogleOAuth = 'google-oauth',
}

type AuthProviderData = Record<string, unknown>;

type PartialProfileDto = {
  email: string;
  accountType?: AccountType;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  avatar?: UserAvatarDto;
};

export type AuthMethodDto = {
  type: AuthType;
  provider: AuthProvider;
  data: AuthProviderData;
};

export type RegisterDto = {
  email: string;
  accountType: AccountType.Creator;
  firstName: string;
  lastName: string;
  phoneNumber?: string;
  avatarUrl?: string;
  authMethod: AuthMethodDto;
  prefersEmailContact?: boolean;
  token: string;
  country: string;
  city: string;
  state?: string;
  roles: string[];
  equipment?: string[];
  portfolioUrl?: string;
};

export type InviteDto = {
  email: string;
  token: string;
};

export type CreatorRegisterPageState = {
  profile: PartialProfileDto;
  authMethod?: AuthMethodDto;
};

export type UserTokens = {
  accessToken: string;
};

export enum RegisterSteps {
  PersonalInfo = 'personalInfo',
  CreatorProfile = 'creatorProfile',
  LocationInfo = 'locationInfo',
}

export enum ResetPasswordSteps {
  ResetEmailRequested = 'ResetEmailRequested',
  SetNewPassword = 'SetNewPassword',
  RequestResetPasswordEmail = 'RequestResetPasswordEmail',
  PasswordChanged = 'PasswordChanged',
}

export enum AccountType {
  User = 'User',
  Producer = 'Producer',
  AccountExecutive = 'Account Executive',
  Creative = 'Creative',
  Editor = 'Editor',
  Creator = 'Creator',
}
