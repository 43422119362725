import { useParams, useSearchParams } from 'react-router-dom';

import { assertDefined } from '@ll-platform/frontend/utils/types/types';

import { useProposal } from './useProposal';

export const useActiveProposal = () => {
  const proposalId = useActiveProposalId();

  return useProposal(proposalId);
};

export const useActiveProposalId = (): string => {
  const pathParams = useParams();
  const [queryParams] = useSearchParams();

  const projectId =
    pathParams.id ?? pathParams.proposalId ?? queryParams.get('proposalId');
  assertDefined(projectId, 'Missing active proposal id in path or query');

  return projectId;
};
