import { type PropsWithChildren } from 'react';

import Status404 from '@ll-platform/frontend/components/statusPages/Status404';
import SuspenseLoader from '@ll-platform/frontend/components/SuspenseLoader';
import { useGetProposalById } from '@ll-platform/frontend/features/proposals/async/useProposalsQueries';
import { useActiveProposalId } from '@ll-platform/frontend/features/proposals/hooks/useActiveProposal';

const ActiveProposalContainer = ({ children }: PropsWithChildren) => {
  const proposalId = useActiveProposalId();
  const { data: activeProposal, isPending } = useGetProposalById({
    id: proposalId,
  });

  if (isPending) {
    return <SuspenseLoader />;
  }

  if (!activeProposal) {
    return <Status404 />;
  }

  return children;
};

export default ActiveProposalContainer;
