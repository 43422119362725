import { forwardRef } from 'react';

import {
  FormHelperText,
  Stack,
  TextField,
  type TextFieldProps,
} from '@mui/material';

import { CharacterCounter } from '@ll-platform/frontend/components/form/CharacterCounter/CharacterCounter';

export type SmallWhiteTextFieldProps = Omit<TextFieldProps, 'error'> & {
  error?: string;
};

export const SmallWhiteTextField = forwardRef<
  HTMLInputElement,
  SmallWhiteTextFieldProps
>(({ error, ...props }, ref) => {
  const isCharacterCounterEnabled =
    props.inputProps?.maxLength && typeof props.value === 'string';

  return (
    <TextField
      error={!!error}
      helperText={
        <Stack direction="row" justifyContent="space-between">
          {error && <FormHelperText color="error">{error}</FormHelperText>}
          {isCharacterCounterEnabled && (
            <CharacterCounter
              value={props.value as string}
              maxLength={props.inputProps?.maxLength}
              sx={{
                marginLeft: 'auto',
                marginRight: 0,
                flex: 0,
              }}
            />
          )}
        </Stack>
      }
      size="small"
      {...props}
      sx={{
        '.MuiInputBase-root': {
          background: (theme) => theme.palette.background.paper,
        },
        ...props.sx,
      }}
      FormHelperTextProps={{
        ...props.FormHelperTextProps,
        sx: {
          ...(isCharacterCounterEnabled
            ? {
                marginRight: 0,
              }
            : {}),
          ...props.FormHelperTextProps?.sx,
        },
      }}
      ref={ref}
    />
  );
});

SmallWhiteTextField.displayName = 'SmallWhiteTextField';
