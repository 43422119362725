import {
  createContext,
  useContext,
  useMemo,
  useState,
  type ReactNode,
} from 'react';

const CommentNumberRegisterContext = createContext<{
  commentNumberRegister: Record<string, number | undefined>;
  setCommentNumberRegister: (value: Record<string, number | undefined>) => void;
}>({
  commentNumberRegister: {},
  setCommentNumberRegister: () => {},
});
CommentNumberRegisterContext.displayName = 'CommentNumberRegisterContext';

export function CommentNumberRegisterProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [commentNumberRegister, setCommentNumberRegister] = useState<
    Record<string, number | undefined>
  >({});

  const value = useMemo(
    () => ({
      commentNumberRegister,
      setCommentNumberRegister,
    }),
    [commentNumberRegister, setCommentNumberRegister],
  );

  return (
    <CommentNumberRegisterContext.Provider value={value}>
      {children}
    </CommentNumberRegisterContext.Provider>
  );
}

export const useCommentNumberRegister = () =>
  useContext(CommentNumberRegisterContext);
