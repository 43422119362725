import { useCallback, useEffect, useRef, useState } from 'react';

import { Paper, Popper, type PopperProps } from '@mui/material';
import { noop } from 'lodash-es';
import { useClickAway } from 'react-use';
import { Range } from 'slate';
import { ReactEditor, useSlateSelection, useSlateStatic } from 'slate-react';

import type { ProjectCommentMetadata } from '@ll-platform/frontend/features/projectComments/types';
import { CommentButton } from '@ll-platform/frontend/features/textEditor/comments/components/CommentButton';
import { useToolbarEditModeContext } from '@ll-platform/frontend/features/textEditor/contexts/ToolbarEditModeContext';
import type { TextEditorConfig } from '@ll-platform/frontend/features/textEditor/types';
import { getSelectionText } from '@ll-platform/frontend/features/textEditor/utils/editor';

type SelectionToolbarProps = {
  config?: TextEditorConfig;
  metadata: ProjectCommentMetadata;
  isEnabled?: boolean;
  disablePartialCommenting?: boolean;
};

export const SelectionToolbar = ({
  config,
  metadata,
  isEnabled,
  disablePartialCommenting,
}: SelectionToolbarProps) => {
  const [anchorEl, setAnchorEl] = useState<PopperProps['anchorEl']>(null);
  const editor = useSlateStatic();
  const { editMode } = useToolbarEditModeContext();
  const containerRef = useRef<HTMLDivElement>(null);
  const timeoutIdRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const selection = useSlateSelection();
  const selectionStr =
    selection && !Range.isCollapsed(selection) && JSON.stringify(selection);

  useClickAway(containerRef, anchorEl ? () => setAnchorEl(null) : noop);

  const handleCloseToolbar = useCallback(() => {
    setAnchorEl(null);

    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current);
      timeoutIdRef.current = null;
    }
  }, []);

  useEffect(() => {
    if (editMode === 'hover' && selectionStr && isEnabled) {
      // temp disable adding comments on text with [] metadata as it messes with comments boundaries
      const selectionText = getSelectionText(editor);
      if (selectionText.includes('[') || selectionText.includes(']')) {
        handleCloseToolbar();

        return;
      }

      const openToolbar = () => {
        // we can't use DOM selection as Chrome seems to not select just the click target node
        // but also the next one on triple left click leading to wrong positioning of the toolbar
        const domRange = selection
          ? ReactEditor.toDOMRange(editor, selection)
          : null;

        if (domRange === null) {
          handleCloseToolbar();

          return;
        }

        setAnchorEl({
          nodeType: 1,
          getBoundingClientRect: () => {
            return domRange.getBoundingClientRect();
          },
        });
      };

      timeoutIdRef.current = setTimeout(openToolbar, 200);

      return () => {
        if (timeoutIdRef.current) {
          clearTimeout(timeoutIdRef.current);
          timeoutIdRef.current = null;
        }
      };
    }

    handleCloseToolbar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor, editMode, selectionStr, handleCloseToolbar, isEnabled]);

  return (
    <Popper
      open={!!anchorEl && editMode === 'hover'}
      anchorEl={anchorEl}
      onClick={(ev) => ev.preventDefault()}
      placement="top-start"
      disablePortal
      sx={{
        zIndex: 10,
      }}
    >
      <Paper sx={{ p: 0.5 }} ref={containerRef}>
        {!!config?.plugins?.comments && (
          <CommentButton
            metadata={metadata}
            disablePartialCommenting={disablePartialCommenting}
          />
        )}
      </Paper>
    </Popper>
  );
};
