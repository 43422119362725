import { type PropsWithChildren } from 'react';

import Status404 from '@ll-platform/frontend/components/statusPages/Status404';
import SuspenseLoader from '@ll-platform/frontend/components/SuspenseLoader';
import { AnalyticsProvider } from '@ll-platform/frontend/core/analytics/AnalyticsContext';
import { mapProjectToAnalytics } from '@ll-platform/frontend/features/analytics/eventUtils';
import { useActiveProposalProjects } from '@ll-platform/frontend/features/projectWizard/hooks/useActiveProposalProjects';

const ActiveProposalProjectsContainer = ({ children }: PropsWithChildren) => {
  const { activeProjects, isPending } = useActiveProposalProjects();

  if (isPending) {
    return <SuspenseLoader />;
  }

  if (!activeProjects.length) {
    return <Status404 />;
  }

  return (
    <AnalyticsProvider metadata={mapProjectToAnalytics(activeProjects[0])}>
      {children}
    </AnalyticsProvider>
  );
};

export default ActiveProposalProjectsContainer;
