import { Chip, type ChipProps } from '@mui/material';

import { PriceAdjustmentTypeEnum } from '@ll-platform/frontend/features/budget/enums';
import type { PriceAdjustment } from '@ll-platform/frontend/features/budget/types';

type BudgetDiscountButtonProps = ChipProps & {
  discountData?: PriceAdjustment;
};

export const BudgetDiscountBadge = ({
  discountData,
  ...props
}: BudgetDiscountButtonProps) => {
  if (
    !discountData?.value ||
    discountData.type !== PriceAdjustmentTypeEnum.PercentageDiscount
  ) {
    return null;
  }

  return (
    <Chip
      color="success"
      variant="outlined"
      label={`${discountData.value}% off`}
      {...props}
      sx={{ fontWeight: 500, borderRadius: 2, ...props.sx }}
    />
  );
};
