import type { User } from '@ll-platform/frontend/core/auth/types';
import type { Organization } from '@ll-platform/frontend/core/organization/types';

export enum BrandRoleEnum {
  Owner = 'owner',
  Editor = 'editor',
  // Internal meaning internal account types, not internal to the brand
  Internal = 'internal',
}

export const ExternalBrandRoleTypes: BrandRoleEnum[] = [
  BrandRoleEnum.Owner,
  BrandRoleEnum.Editor,
];
export const InternalBrandRoleTypes: BrandRoleEnum[] = [BrandRoleEnum.Internal];

export enum BusinessTypeEnum {
  B2B = 'B2B',
  B2C = 'B2C',
  Other = 'Other',
}

export enum BrandToneEnum {
  Serious = 'Serious',
  Authoritative = 'Authoritative',
  Classic = 'Classic',
  Intricate = 'Intricate',
  Professional = 'Professional',
  Fun = 'Fun',
  Approachable = 'Approachable',
  Modern = 'Modern',
  Simple = 'Simple',
  Casual = 'Casual',
}

export enum BusinessIndustryEnum {
  SoftwareAndTech = 'Software & Tech',
  Education = 'Education',
  RetailAndEcommerce = 'Retail & Ecommerce',
  BeautyAndFashion = 'Beauty & Fashion',
  HealthAndFitness = 'Health & Fitness',
  FoodAndRestaurant = 'Food & Restaurant',
  ProfessionalServices = 'Professional Services',
  HomeAndGarden = 'Home & Garden',
  Other = 'Other',
}

export enum CompanyTypeEnum {
  SmallMediumBusiness = 'smb',
  Enterprise = 'ent',
}

export type Brand = {
  _id: string;
  organizationId: string;
  name: string;
  bio?: string;
  motto?: string;
  companyType?: CompanyTypeEnum;
  website?: string;
  tone?: BrandToneEnum[];
  additionalInfo?: string;
  producer?: {
    calendarUrl?: string;
  };
  logoUrl?: string | null;
  businessType?: BusinessTypeEnum;
  industry?: BusinessIndustryEnum | string;
};

/** @deprecated */
type BrandUser = {
  _id: string;
  brandId: string;
  userId: string;
  role: BrandRoleEnum;
  createdAt: string;
  updatedAt: string;
};

/** @deprecated */
export type BrandUsersResponse = {
  items: BrandUser[];
};

export type BrandProjectData = {
  _id: string;
  organizationId: string;
  project: string;
  brand: string;
  createdAt: string;
};

export type BrandByIdUsersArgs = {
  brandId: string;
};

/** @deprecated */
type BrandUserWithProfile = BrandUser & {
  // user profile may be missing if the user is not found
  user?: User;
};

/** @deprecated */
export type BrandWithTeam = Brand & {
  team: BrandUserWithProfile[];
};
export type BrandWithTeamAndOrganization = BrandWithTeam & {
  organization: Organization;
};
export type BrandWithOrganization = Brand & {
  organization: Organization;
};

export interface CreateBrand {
  name: string;
  website: string;
  /** @deprecated */
  clientMainContactId?: string | null;
  /** @deprecated */
  producerId?: string | null;
  /** @deprecated */
  accountExecutiveId?: string | null;
  /** @deprecated */
  creativeProducerId?: string | null;
  /** @deprecated */
  editorId?: string | null;
  companyType: CompanyTypeEnum;
}

export type UpdateBrandPayload = Partial<Omit<Brand, '_id'>> & {
  // Array of users ids, owner must be first
  /** @deprecated */
  users?: string[];
};

export interface UpdateBrandArg extends UpdateBrandPayload {
  id: string;
  keepExistingEditors?: boolean;
}

export interface FindAllBrandsDto {
  projectId?: string;
  userId?: string;
  query?: string;
  limit?: number;
}

export interface BrandByIdQuery {
  brandId: string;
}

export interface GetBrandsByProjectId {
  projectId: string;
}

export interface AddProjectToBrand {
  brandId: string;
  isProposal?: boolean;
  projectId: string;
}
export type CreateBrandPayload = {
  name: string;
  website: string;
  companyType: string;
  /** @deprecated */
  users: string[];
};
