import {
  createContext,
  useContext,
  useMemo,
  useState,
  type PropsWithChildren,
} from 'react';

import { useDeepCompareEffect } from 'react-use';

import { useDocumentBuilderState } from '@ll-platform/frontend/hero/documentBuilder/core/builder/useDocumentBuilderState';
import { assertDefined } from '@ll-platform/frontend/utils/types/types';

import {
  DocumentBuilderViewModeEnum,
  type DocumentBuilderConfig,
  type DocumentBuilderContextType,
  type DocumentData,
} from './documentBuilderTypes';

const DocumentBuilderContext = createContext<DocumentBuilderContextType | null>(
  null,
);
DocumentBuilderContext.displayName = 'DocumentBuilderContext';

const DocumentDataContext = createContext<DocumentData | null>(null);
DocumentDataContext.displayName = 'DocumentDataContext';

const defaultDocumentBuilderConfig: DocumentBuilderConfig = {
  documentId: '',
  mode: DocumentBuilderViewModeEnum.Edit,
  nodes: [],
  slideTemplates: [],
};

export const DocumentBuilderProvider = ({
  children,
  config: configFromProps,
}: PropsWithChildren<{ config: DocumentBuilderConfig | null }>) => {
  const [config, setConfig] = useState<DocumentBuilderConfig>(() => ({
    ...defaultDocumentBuilderConfig,
    ...configFromProps,
  }));

  const {
    document,
    isLoading,
    isSaving,
    isDirty,
    isInitialized,
    updateDocument,
    triggerSave,
  } = useDocumentBuilderState(config);

  const value = useMemo((): DocumentBuilderContextType => {
    return {
      config,
      setConfig,
      documentId: config.documentId,
      mode: config.mode,
      availableNodes: config.nodes,
      slideTemplates: config.slideTemplates,
      isInitialized,
      isLoading,
      isSaving,
      isDirty,
      triggerSave,
      updateDocument,
    };
  }, [
    config,
    isLoading,
    isSaving,
    isDirty,
    isInitialized,
    updateDocument,
    triggerSave,
    setConfig,
  ]);

  useDeepCompareEffect(() => {
    setConfig({ ...defaultDocumentBuilderConfig, ...configFromProps });
  }, [configFromProps]);

  return (
    <DocumentBuilderContext.Provider value={value}>
      <DocumentDataContext.Provider value={document}>
        {children}
      </DocumentDataContext.Provider>
    </DocumentBuilderContext.Provider>
  );
};

export const useDocumentBuilder = () => {
  const context = useContext(DocumentBuilderContext);
  assertDefined(context, 'DocumentBuilderContext in useDocumentBuilder');

  return context;
};

export const useDocumentData = () => {
  const context = useContext(DocumentDataContext);
  assertDefined(context, 'DocumentDataContext in useDocumentData');

  return context;
};
