import { zodResolver } from '@hookform/resolvers/zod';
import { isEmpty } from 'lodash-es';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { toast } from 'sonner';
import { z } from 'zod';

import { cn } from '@ll-platform/frontend/components/shadcn/lib/utils';
import {
  Form,
  FormField,
  FormItem,
  FormMessage,
} from '@ll-platform/frontend/components/shadcn/ui/form';
import { Input } from '@ll-platform/frontend/components/shadcn/ui/input';
import { TwSpinner } from '@ll-platform/frontend/components/tw/TwSpinner/TwSpinner';
import { typographyVariants } from '@ll-platform/frontend/components/tw/TwTypography/utils';
import { useUpdateCreativeProposal } from '@ll-platform/frontend/hero/creativeProposal/async/useCreativeProposalsMutations';
import { useGetCreativeProposalByProposalId } from '@ll-platform/frontend/hero/creativeProposal/async/useCreativeProposalsQueries';
import { assertDefined } from '@ll-platform/frontend/utils/types/types';

const PROPOSAL_TITLE_MAX_LENGTH = 100;

const ProposalTitleFormSchema = z.object({
  name: z
    .string({
      // eslint-disable-next-line camelcase
      required_error: 'Proposal name is required',
    })
    .max(
      PROPOSAL_TITLE_MAX_LENGTH,
      `Proposal name cannot exceed ${PROPOSAL_TITLE_MAX_LENGTH} characters`,
    )
    .min(1, `Proposal name is required`),
});

type ProposalTitleFormSchemaValues = z.infer<typeof ProposalTitleFormSchema>;

export const ProposalTitle = () => {
  const { id } = useParams<{ id: string }>();
  const { data, isFetching } = useGetCreativeProposalByProposalId(
    {
      id: id!,
    },
    {
      enabled: !!id,
    },
  );
  const { mutateAsync: mutateUpdateProposal } = useUpdateCreativeProposal();

  const form = useForm<ProposalTitleFormSchemaValues>({
    values: {
      name: data?.name || '',
    },
    resolver: zodResolver(ProposalTitleFormSchema),
  });

  if (!data && isFetching) {
    return <TwSpinner />;
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = form;

  const onSubmit = (values: ProposalTitleFormSchemaValues) => {
    assertDefined(id);

    if (!isEmpty(errors)) {
      return;
    }

    toast.promise(() => mutateUpdateProposal({ id, name: values.name }), {
      error: 'Failed to update proposal name',
      loading: 'Updating proposal naame...',
      success: 'Proposal name updated',
    });
  };

  return (
    <Form {...form}>
      <div className="group relative px-3 py-2 text-foreground">
        <div className="flex flex-row gap-2">
          <FormField
            control={form.control}
            name="name"
            render={() => (
              <FormItem className="flex flex-col space-y-0 w-full">
                <Input
                  type="text"
                  {...register('name', { required: true })}
                  onBlur={handleSubmit(onSubmit)}
                  maxLength={PROPOSAL_TITLE_MAX_LENGTH + 1}
                  aria-invalid={!!errors.name}
                  aria-errormessage={errors.name?.message}
                  className={cn(
                    typographyVariants.h4,
                    typographyVariants.h4
                      .split(' ')
                      .map((c) => `md:${c}`)
                      .join(' '),
                    'bg-transparent',
                    errors.name
                      ? 'border-red-500 '
                      : 'border-transparent hover:border-foreground focus:ring-0 focus-visible:ring-offset-0',
                  )}
                />
                <div className="absolute right-0 translate-x-[100%] top-1/2 pl-2 -translate-y-1/2">
                  <FormMessage />
                </div>
              </FormItem>
            )}
          />
        </div>
      </div>
    </Form>
  );
};
