import { type ReactNode } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Button, DialogActions, Stack, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { Controller, FormProvider, useForm } from 'react-hook-form';

import { SmallWhiteTextField } from '@ll-platform/frontend/components/form/SmallWhiteTextField/SmallWhiteTextField';
import { TextEditorInline } from '@ll-platform/frontend/components/form/TextEditorInlineWrapper/TextEditorInlineWrapper';
import type { User } from '@ll-platform/frontend/core/auth/types';
import type { ReviewEmailData } from '@ll-platform/frontend/features/reviews/types';
import { TextEditorPluginsEnum } from '@ll-platform/frontend/features/textEditor/types';
import { type XOR } from '@ll-platform/frontend/utils/types/types';

import { MultiUserAutocomplete } from './MultiUserAutocomplete';
import { notifyForReviewSchema } from './notify-for-review.schema';

type NotifyModalFormProps = {
  defaultReceiversData: User[];
  reviewEmailData: ReviewEmailData;
  headingContent: ReactNode;
  submitButtonContent: string;
  onSubmitForReview: (payload: ReviewEmailData) => Promise<void>;
  onClose: () => void;
} & XOR<
  {
    /** @deprecated */
    brandId: string;
  },
  {
    projectId: string;
  }
>;

/**
 * TODO: For future reference - possible refactor: could use a CustomDialog
 * Also this is dialog content rendered in dialog parent component - we should avoid this practice
 */
export const NotifyModalForm = ({
  defaultReceiversData,
  brandId,
  projectId,
  reviewEmailData,
  headingContent,
  submitButtonContent,
  onSubmitForReview,
  onClose,
}: NotifyModalFormProps) => {
  if (!brandId && !projectId) {
    // sanity check
    throw new Error('brandId or projectId is required');
  }

  const { mutateAsync: doSubmit, isPending } = useMutation({
    mutationFn: onSubmitForReview,
    meta: {
      supressErrorToast: true,
    },
  });

  const methods = useForm<ReviewEmailData>({
    defaultValues: reviewEmailData,
    resolver: yupResolver(notifyForReviewSchema),
    mode: 'onBlur',
  });

  const handleClose = () => {
    methods.reset();
    onClose();
  };

  const handleSubmit = (data: ReviewEmailData) => {
    doSubmit(data);
  };

  return (
    <Stack component="form" onSubmit={methods.handleSubmit(handleSubmit)}>
      <FormProvider {...methods}>
        <Stack
          sx={{
            flexGrow: 1,
            flexDirection: 'column',
            gap: 3,
          }}
        >
          <Typography>{headingContent}</Typography>
          <MultiUserAutocomplete
            label="To"
            defaultReceiversData={defaultReceiversData}
            brandId={brandId}
            projectId={projectId}
          />
          <Controller
            control={methods.control}
            name="title"
            render={({ field: { ref: _, ...field }, fieldState }) => (
              <SmallWhiteTextField
                {...field}
                error={fieldState.error?.message}
                size="medium"
                label="Title"
              />
            )}
          />
          <Controller
            control={methods.control}
            name="body"
            render={({ field: { ref: _, ...field }, fieldState }) => (
              <TextEditorInline
                label="Message"
                error={fieldState.error?.message}
                type="html"
                config={{
                  plugins: {
                    [TextEditorPluginsEnum.RichText]: true,
                    [TextEditorPluginsEnum.Comments]: false,
                    [TextEditorPluginsEnum.History]: true,
                  },
                }}
                value={reviewEmailData.body}
                onChange={field.onChange}
              />
            )}
          />
        </Stack>
      </FormProvider>
      <DialogActions sx={{ py: 3, px: 0 }}>
        <Button
          onClick={handleClose}
          variant="outlined"
          color="inherit"
          disabled={isPending}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={isPending}
        >
          {submitButtonContent}
        </Button>
      </DialogActions>
    </Stack>
  );
};
