import { useMemo } from 'react';

import { FeatureFlagName } from '@ll-platform/frontend/config/featureFlags/featureFlags';
import { LEMONLIGHT_DEFAULT_ORGANIZATION } from '@ll-platform/frontend/consts/organization';
import { useAppFlags } from '@ll-platform/frontend/core/featureFlags/useAppFlags';
import { organizationService } from '@ll-platform/frontend/core/organization/async/OrganizationsService';
import type { Organization } from '@ll-platform/frontend/core/organization/types';
import { useUser } from '@ll-platform/frontend/features/auth/hooks/useUser';
import { isUserInternal } from '@ll-platform/frontend/features/auth/utils/isInternal';

export function useOptionalActiveOrganization() {
  const flags = useAppFlags();
  const { activeUser } = useUser();

  const activeOrganization = useMemo<Organization | null>(() => {
    if (!activeUser || !isUserInternal(activeUser)) {
      return null;
    }

    if (!flags[FeatureFlagName.Hero]) {
      organizationService.setBackdoorOrganizationId(
        LEMONLIGHT_DEFAULT_ORGANIZATION.id,
      );

      return LEMONLIGHT_DEFAULT_ORGANIZATION;
    }

    const initialOrganizationId = organizationService.getOrganizationId();
    const organization = activeUser.organizations?.find(
      (org) => org.organization.id === initialOrganizationId,
    )?.organization;

    if (!organization) {
      if (
        initialOrganizationId &&
        organizationService.isBackdoorOrganization()
      ) {
        return organizationService.makeFakeOrganization(initialOrganizationId);
      }

      const firstOrganization = activeUser.organizations?.[0]?.organization;
      if (firstOrganization) {
        organizationService.setOrganizationId(firstOrganization.id);

        return firstOrganization;
      } else {
        organizationService.setOrganizationId(null);

        return null;
      }
    }

    return organization;
  }, [activeUser, flags]);

  return {
    activeOrganization,
  };
}

/**
 * Use only on internal pages with the OrganizationGuard
 */
export function useActiveOrganization() {
  const { activeOrganization } = useOptionalActiveOrganization();

  if (!activeOrganization) {
    throw new Error('Active organization is missing');
  }

  return {
    activeOrganization,
  };
}
