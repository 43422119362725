import * as Sentry from '@sentry/react';

import { APP_CONFIG, AppEnvEnum } from './app.config';

const sampleRateMap: Record<AppEnvEnum, number> = {
  [AppEnvEnum.Production]: 0.1,
  [AppEnvEnum.Staging]: 1.0,
  [AppEnvEnum.Development]: 1.0,
  [AppEnvEnum.E2E]: 1.0,
};

const ENV = APP_CONFIG.REACT_APP_ENV;
const SENTRY_DEBUG = APP_CONFIG.REACT_APP_SENTRY_DEBUG;

Sentry.init({
  dsn: APP_CONFIG.REACT_APP_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    'localhost',
    new RegExp(`^${APP_CONFIG.REACT_APP_API_URL}`),
  ],
  environment: ENV,
  // Performance Monitoring
  tracesSampleRate: sampleRateMap[ENV], // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: sampleRateMap[ENV], // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  beforeSend(event) {
    const warningLevel: Sentry.SeverityLevel = 'warning';
    const isKnownException = event.exception?.values?.some(
      (exception) =>
        exception?.value && knownExceptions.includes(exception?.value),
    );
    if (SENTRY_DEBUG) {
      console.debug('[Sentry]', event);
    }

    return isKnownException ? { ...event, level: warningLevel } : event;
  },
});

const knownExceptions = [
  'Unauthorized',
  'Error: Unauthorized',
  'ResizeObserver loop completed with undelivered notifications.', // benign error
  'MediaSource.addSourceBuffer: Type not supported in MediaSource', // firefox mp3 streaming error
  'timeout of 30000ms exceeded', // backend timeout
  'Object captured as promise rejection with keys: config, data, headers, request, status', // no error message from the backend

  // cancelled streaming,
  'signal is aborted without reason',
  'AbortError: signal is aborted without reason',
  'BodyStreamBuffer was aborted', // TTS streaming

  // cancelled request
  'The operation was aborted.',
  'Request aborted',

  // form validation errors
  'This field is required',
  'This contract already exists',
  'Form is not valid',

  // user was offline or had intermittent connection issues
  'Network Error',
  'NetworkError when attempting to fetch resource.',
  'network error (Error)', // LaunchDarkly error for network error
];
