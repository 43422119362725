import { useMemo } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  type DialogProps,
} from '@mui/material';
import { Controller, FormProvider, useForm } from 'react-hook-form';

import { SmallWhiteTextField } from '@ll-platform/frontend/components/form/SmallWhiteTextField/SmallWhiteTextField';
import { budgetDiscountTypeLabelMap } from '@ll-platform/frontend/features/budget/consts';
import { PriceAdjustmentTypeEnum } from '@ll-platform/frontend/features/budget/enums';
import type { ProposalBudgetDiscount } from '@ll-platform/frontend/features/budget/types';

import { budgetDiscountSchema, type DiscountSchemaType } from './schema';

const budgetTypeOptions = (
  [
    PriceAdjustmentTypeEnum.FixedDiscount,
    PriceAdjustmentTypeEnum.PercentageDiscount,
  ] as const
).map((discountType) => ({
  value: discountType,
  label: budgetDiscountTypeLabelMap[discountType],
}));

export type BudgetDiscountDialogProps = Omit<DialogProps, 'onSubmit'> & {
  onSubmit: (args: ProposalBudgetDiscount) => Promise<void>;
  onRemove: () => Promise<void>;
  onClose: () => void;
  discount?: ProposalBudgetDiscount;
  maxDiscountValue?: number;
};

/**
 * TODO: For future reference - possible refactor: could use a CustomDialog with custom actions
 */
export const BudgetDiscountDialog = ({
  onSubmit,
  onRemove,
  onClose,
  discount,
  maxDiscountValue,
  ...props
}: BudgetDiscountDialogProps) => {
  const mode = discount ? 'Edit' : 'Add';
  const defaultValues = useMemo(() => {
    return {
      type: discount?.fixed
        ? PriceAdjustmentTypeEnum.FixedDiscount
        : PriceAdjustmentTypeEnum.PercentageDiscount,
      value:
        (discount?.fixed ? discount.fixed / 100 : discount?.percentage) ?? null,
    } as DiscountSchemaType;
  }, [discount]);

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(budgetDiscountSchema(maxDiscountValue)),
    mode: 'onBlur',
  });
  const type = methods.watch('type');

  const close = () => {
    onClose();
    methods.reset(defaultValues);
  };

  const submit = async ({ type, value }: DiscountSchemaType) => {
    try {
      const payload: ProposalBudgetDiscount =
        type === PriceAdjustmentTypeEnum.PercentageDiscount
          ? {
              percentage: Number(value),
              fixed: null,
            }
          : {
              percentage: null,
              fixed: Number(value) * 100,
            };
      await onSubmit(payload);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Dialog onClose={close} fullWidth maxWidth="sm" {...props}>
      <DialogTitle sx={{ px: 3, py: 2 }}>
        {mode}
        {mode === 'Add' && ' a'} Discount
      </DialogTitle>

      <DialogContent>
        <FormProvider {...methods}>
          <Stack direction="row" gap={4}>
            <Stack gap={1}>
              <FormLabel>Discount Type</FormLabel>
              <Controller
                name="type"
                control={methods.control}
                render={({ field: { ref: _ref, ...field } }) => (
                  <RadioGroup
                    sx={{ flexDirection: 'row', gap: 1, alignItems: 'center' }}
                    value={field.value}
                    onChange={(event) =>
                      event.target.value && field.onChange(event.target.value)
                    }
                  >
                    {budgetTypeOptions.map(({ label, value }) => (
                      <FormControlLabel
                        key={value}
                        control={<Radio />}
                        label={label}
                        value={value}
                        onClick={() => {
                          methods.reset({
                            value: null,
                            type: value,
                          } as unknown as DiscountSchemaType);
                        }}
                        sx={{
                          m: 0,
                        }}
                      />
                    ))}
                  </RadioGroup>
                )}
              />
            </Stack>

            <Stack gap={1} flexGrow={1} maxWidth="50%">
              <FormLabel>Discount Value</FormLabel>
              <SmallWhiteTextField
                {...methods.register('value')}
                error={methods.formState.errors.value?.message}
                fullWidth
                InputProps={{
                  ...(type === PriceAdjustmentTypeEnum.PercentageDiscount
                    ? {
                        endAdornment: '%',
                      }
                    : {
                        startAdornment: '$',
                      }),
                  type: 'number',
                }}
                sx={{
                  '& .MuiInputBase-input::-webkit-outer-spin-button': {
                    display: 'none',
                  },
                  '& .MuiInputBase-input::-webkit-inner-spin-button': {
                    display: 'none',
                  },
                  '& .MuiInputBase-input': {
                    px:
                      type === PriceAdjustmentTypeEnum.PercentageDiscount
                        ? 0.25
                        : 1,
                    textAlign:
                      type === PriceAdjustmentTypeEnum.PercentageDiscount
                        ? 'end'
                        : 'start',
                  },
                  '& .MuiFormHelperText-root': {
                    ml: 0,
                  },
                }}
              />
            </Stack>
          </Stack>
        </FormProvider>
      </DialogContent>

      <DialogActions
        sx={{
          p: 2,
          justifyContent: mode === 'Edit' ? 'space-between' : undefined,
        }}
      >
        {mode === 'Edit' && (
          <Button onClick={onRemove} color="error" variant="contained">
            Remove Discount
          </Button>
        )}
        <Stack direction="row" gap={2}>
          <Button onClick={close} variant="outlined" color="inherit">
            Cancel
          </Button>
          <LoadingButton
            disabled={!methods.formState.isValid}
            onClick={methods.handleSubmit(submit)}
            loading={methods.formState.isSubmitting}
            variant="contained"
          >
            Apply
          </LoadingButton>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};
