// Letest Figma UI Kit v5.16.0: https://www.figma.com/design/Ou7RmFBoNfHYu6LP06dW6h/Lemonlight-Material-UI-5.16.0?node-id=11611-166760&m=dev
// Legacy Figma UI Kit v5.11.1: https://www.figma.com/file/ocAvHH2idVWLTctW3PeFOL/Lemonlight-MUI-(v5.11.1)?type=design&node-id=4662-14&mode=dev
// Theme Playground: http://localhost:3000/internal/theme-playground
// MUI Theming Docs: https://mui.com/material-ui/customization/theming/
// MUI Default Theme: https://mui.com/material-ui/customization/default-theme/
import { alpha, createTheme, type ThemeOptions } from '@mui/material';

import type {
  ColorPalette,
  CustomElevation,
  CustomShape,
  MuiPalette,
  OtherColors,
} from '@ll-platform/frontend/core/theme/themes/LemonlightMui/types';

const white = '#FFFFFF';
const black = '#000000';
const primaryTextColor = '#1D1D1F';

export const customPalette: ColorPalette = {
  blue: {
    0: '#1A2569',
    50: '#EFF4FF',
    100: '#DAE5FF',
    200: '#BED2FF',
    300: '#91B7FF',
    400: '#5E90FC',
    500: '#3868F9',
    600: '#2247EE',
    700: '#1A34DB',
    800: '#1C2BB1',
    900: '#1C2A8C',
    950: '#161C55',
  },
  yellow: {
    50: '#FEFAE8',
    100: '#FDF3C4',
    200: '#FCE48C',
    300: '#F9C938',
    400: '#F6B519',
    500: '#E69E0C',
    600: '#C67808',
    700: '#9E540A',
    800: '#834310',
    900: '#6F3714',
  },
  grey: {
    50: '#F9FAFB',
    100: '#E1E1E3',
    200: '#C8C9CA',
    300: '#B0B0B2',
    400: '#979899',
    500: '#7F7F81',
    600: '#666768',
    700: '#4E4E50',
    800: '#353637',
    900: '#1D1D1F',
  },
  green: {
    50: '#F1FCF2',
    100: '#DEFAE2',
    200: '#BFF3C7',
    300: '#8DE89C',
    400: '#54D469',
    500: '#33CE4D',
    600: '#1F9A33',
    700: '#1C792C',
    800: '#1B6027',
    900: '#184F23',
  },
  red: {
    50: '#FFF0F0',
    100: '#FFE3E3',
    200: '#FFCACD',
    300: '#FF9FA3',
    400: '#FF6973',
    500: '#FE4656',
    600: '#EC1230',
    700: '#C70927',
    800: '#A70A29',
    900: '#8E0D2A',
  },
  orange: {
    50: '#FFF6ED',
    100: '#FFECD5',
    200: '#FED4AA',
    300: '#FEB673',
    400: '#FC8C3B',
    500: '#FA6C15',
    600: '#EB510B',
    700: '#C33C0B',
    800: '#9B3011',
    900: '#7C2A12',
  },
  lightBlue: {
    50: '#F0F7FF',
    100: '#E0EFFE',
    200: '#BADFFD',
    300: '#7EC6FB',
    400: '#39A8F7',
    500: '#1193EF',
    600: '#0370C6',
    700: '#0459A0',
    800: '#084C84',
    900: '#0D406D',
  },
  blueGrey: {
    50: '#ECEFF1',
    100: '#CFD8DC',
    200: '#B0BEC5',
    300: '#90A4AE',
    400: '#B0BEC5',
    500: '#B0BEC5',
    600: '#546E7A',
    700: '#455A64',
    800: '#37474F',
    900: '#263238',
  },
  deepPurple: {
    50: '#EDE7F6',
    100: '#D1C4E9',
    200: '#B39DDB',
    300: '#9575CD',
    400: '#7E57C2',
    500: '#673AB7',
    600: '#5E35B1',
    700: '#512DA8',
    800: '#4527A0',
    900: '#311B92',
  },
  legacyGrey: {
    50: '#FAFAFA',
    100: '#F5F5F5',
    200: '#EEEEEE',
    300: '#E0E0E0',
    400: '#BDBDBD',
    500: '#BDBDBD',
    600: '#757575',
    700: '#4C4D52',
    800: '#BDBDBD',
    900: '#212121',
  },
};

const muiPalette = {
  common: {
    black,
    white,
  },
  text: {
    primary: primaryTextColor,
    secondary: alpha(black, 0.6),
    disabled: alpha(black, 0.38),
  },
  primary: {
    main: customPalette.blue[500],
    dark: customPalette.blue[600],
    light: customPalette.blue[400],
    contrastText: white,
  },
  secondary: {
    main: customPalette.yellow[300],
    dark: customPalette.yellow[500],
    light: customPalette.yellow[200],
    contrastText: customPalette.grey[900],
  },
  background: {
    paper: white,
    default: white,
  },
  error: {
    main: customPalette.red[600],
    dark: customPalette.red[800],
    light: customPalette.red[400],
    contrastText: white,
  },
  warning: {
    main: customPalette.orange[500],
    dark: customPalette.orange[800],
    light: customPalette.orange[300],
    contrastText: white,
  },
  info: {
    main: customPalette.lightBlue[500],
    dark: customPalette.lightBlue[800],
    light: customPalette.lightBlue[300],
    contrastText: white,
  },
  success: {
    main: customPalette.green[600],
    dark: customPalette.green[800],
    light: customPalette.green[300],
    contrastText: white,
  },
  action: {
    activatedOpacity: 0.56,
    active: alpha(primaryTextColor, 0.56),
    hoverOpacity: 0.04,
    hover: alpha(primaryTextColor, 0.04),
    selectedOpacity: 0.08,
    selected: alpha(primaryTextColor, 0.08),
    focusOpacity: 0.12,
    focus: alpha(primaryTextColor, 0.12),
    disabledOpacity: 0.3,
    disabled: alpha(primaryTextColor, 0.3),
    disabledBackground: alpha(primaryTextColor, 0.12),
  },
  divider: alpha(primaryTextColor, 0.12),
} satisfies MuiPalette;

// Miscellaneous, non-standard values
export const otherColors: OtherColors = {
  greyBackground: customPalette.grey[50],
  backgroundPrimary: white,
  backgroundSecondary: customPalette.grey[50],
  greyOverlayBackground: '#272727',
  avatarFill: '#E8EAFE',
  avatarFillDarker: customPalette.blue[100],
  actionIconBackground: 'rgba(29, 29, 31, 0.06)',
  alert: {
    errorFill: '#FDEDED',
  },
  shadow: '#101828',
  pinkAccent: '#FF80AB',
  navyBlue: '#213D93',
  darkPurple: '#5721B7',
  lightPurple: '#D668CD',
  disabledOverlay: alpha(white, 0.6),
  colorSets: {
    red: {
      color: '#EF5350',
      background: '#FCE4EC',
    },
    lime: {
      color: '#C0CA33',
      background: '#F9FBE7',
    },
    indigo: {
      color: '#5C6BC0',
      background: '#E8EAF6',
    },
    amber: {
      color: '#FFCA28',
      background: '#FFF8E1',
    },
    teal: {
      color: '#26A69A',
      background: '#E0F2F1',
    },
    deepPurple: {
      color: '#7E57C2',
      background: '#EDE7F6',
    },
    aquamarine: {
      color: '#0097A7',
      background: '#E0F7FA',
    },
    green: {
      color: '#689F38',
      background: '#F1F8E9',
    },
    purple: {
      color: '#8E24AA',
      background: '#E1BEE7',
    },
    gray: {
      color: '#546E7A',
      background: '#ECEFF1',
    },
    yellow: {
      color: '#F9A825',
      background: '#FFF9C4',
    },
    orange: {
      color: '#FF7043',
      background: '#FFF3E0',
    },
    deepOrange: {
      color: '#FF8A65',
      background: '#FBE9E7',
    },
    turquoise: {
      color: '#006D6F',
      background: '#B8E0E1',
    },
    blueGrey: {
      color: customPalette.blueGrey[300],
      background: customPalette.blueGrey[50],
    },
    blue: {
      color: muiPalette.primary.main,
      background: '#E8EAFE', // otherColors.avatarFill
    },
  },
  proposalStatusColors: {
    violet: '#777AE5',
    yellow: '#FFD533',
    skyBlue: '#85D6FF',
    cyan: '#71D6D1',
    pink: '#FF7BD0',
    roseRed: '#E8697D',
    green: '#B0DC51',
    orange: '#FF8358',
    darkSky: '#79AFFD',
    palePink: '#FF9191',
  },
  gradients: {
    midnightBlue: `${customPalette.blue[950]} 55.33%, ${primaryTextColor} 203.85%`,
  },
  customIconColors: {
    default: { bgColor: alpha(primaryTextColor, 0.06), iconColor: undefined },
    primary: {
      bgColor: alpha(customPalette.blue[500], 0.04),
      iconColor: undefined,
    },
    secondary: { bgColor: undefined, iconColor: undefined },
    error: { bgColor: undefined, iconColor: undefined },
    warning: { bgColor: undefined, iconColor: undefined },
    success: { bgColor: undefined, iconColor: undefined },
    info: { bgColor: undefined, iconColor: undefined },
    inherit: { bgColor: alpha(primaryTextColor, 0.06), iconColor: undefined },
    inheritWhite: { bgColor: white, iconColor: undefined },
  },
};

const customShape: CustomShape = {
  borderRadius4: '4px',
  borderRadius8: '8px',
  borderRadius16: '16px',
  borderRadius100: '100px',
};

const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1200,
    xl: 1536,
  },
} satisfies ThemeOptions['breakpoints'];

const elevation: CustomElevation = {
  xs: `0px 1px 2px 0px ${alpha(otherColors.shadow, 0.05)}`,
  sm: `0px 1px 3px 0px ${alpha(
    otherColors.shadow,
    0.1,
  )}, 0px 1px 2px 0px ${alpha(otherColors.shadow, 0.06)}`,
  md: `0px 4px 8px -2px ${alpha(
    otherColors.shadow,
    0.1,
  )}, 0px 2px 4px -2px ${alpha(otherColors.shadow, 0.06)}`,
  lg: `0px 12px 16px -4px ${alpha(
    otherColors.shadow,
    0.08,
  )}, 0px 4px 6px -2px ${alpha(otherColors.shadow, 0.03)}`,
  xl: `0px 20px 24px -4px ${alpha(
    otherColors.shadow,
    0.08,
  )}, 0px 8px 8px -4px ${alpha(otherColors.shadow, 0.03)}`,
  '2xl': `0px 24px 48px -12px ${alpha(otherColors.shadow, 0.18)}`,
  '3xl': `0px 32px 64px -12px ${alpha(otherColors.shadow, 0.14)}`,
};

const typography = {
  fontFamily: 'Proxima Nova, Helvetica, Arial, sans-serif',
  h1: {
    fontSize: 96,
    fontWeight: 500,
    lineHeight: '116.7%', // 112.032px
    letterSpacing: '-1.5px',
  },
  h2: {
    fontSize: 60,
    fontWeight: 500,
    lineHeight: '120%', // 72px
    letterSpacing: '-0.5px',
  },
  h3: {
    fontSize: 48,
    fontWeight: 500,
    lineHeight: '116.7%', // 56.016px
  },
  h4: {
    fontSize: 34,
    fontWeight: 600,
    lineHeight: '123.5%', // 41.99px
    letterSpacing: '0.25px',
  },
  h5: {
    fontSize: 24,
    fontWeight: 600,
    lineHeight: '133.4%', // 32.016px
  },
  h6: {
    fontSize: 20,
    fontWeight: 600,
    lineHeight: '160%', // 32px
    letterSpacing: '0.15px',
  },
  subtitle1: {
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '175%', // 28px
    letterSpacing: '0.15px',
  },
  subtitle2: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '157%', // 21.98px
    letterSpacing: '0.1px',
  },
  body1: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '150%', // 24px
    letterSpacing: '0.15px',
  },
  body2: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '143%', // 20.02px
    letterSpacing: '0.17px',
  },
  caption: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '166%', // 19.92px
    letterSpacing: '0.4px',
  },
  overline: {
    fontSize: 12,
    fontWeight: 700,
    lineHeight: '266%', // 31.92px
    letterSpacing: '1px',
    textTransform: 'uppercase',
  },
  button: {
    fontWeight: 600,
    textTransform: 'none',
  },
} satisfies ThemeOptions['typography'];

const components = {
  MuiCssBaseline: {
    styleOverrides: {
      ':root': {
        '--toastify-toast-width': '350px',
        '--toastify-color-info': muiPalette.info.main,
        '--toastify-color-success': muiPalette.success.main,
        '--toastify-color-warning': muiPalette.warning.main,
        '--toastify-color-error': muiPalette.error.main,
        '--toastify-font-family': typography.fontFamily,
      },
      body: {
        width: '100%',
        height: '100%',
        minHeight: '100%',
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        background: otherColors.greyBackground,
      },
      '#root': {
        width: '100%',
        height: '100%',
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
      },
      html: {
        width: '100%',
        height: '100%',
        minHeight: '100%',
        display: 'flex',
        flexDirection: 'column',
        MozOsxFontSmoothing: 'grayscale',
        WebkitFontSmoothing: 'antialiased',
        fontFamily: typography.fontFamily,
        fontFeatureSettings: "'clig' off, 'liga' off",
      },
    },
  },
  MuiButton: {
    defaultProps: {
      disableElevation: true,
    },
    variants: [
      {
        props: {
          variant: 'contained',
          color: 'inherit',
        },
        style: {
          backgroundColor: customPalette.grey[100],
          '&:hover': {
            backgroundColor: customPalette.grey[200],
          },
        },
      },
    ],
  },
  MuiIconButton: {
    styleOverrides: {
      sizeSmall: {
        padding: 4,
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      rounded: {
        borderRadius: 8,
      },
    },
  },
  MuiTooltip: {
    styleOverrides: {
      tooltip: ({ ownerState, theme }) => {
        const commonStyles = {
          fontSize: '13px',
          borderRadius: '4px',
          lineHeight: '18px',
        };

        if (ownerState.variant === 'light') {
          return {
            ...commonStyles,
            boxShadow: theme.shadows[6],
            color: theme.palette.common.black,
            background: theme.palette.common.white,
            padding: '8px 16px',
            maxWidth: '376px',
          };
        }

        return {
          ...commonStyles,
          background: alpha(customPalette.grey[800], 0.9),
          padding: '4px 8px',
        };
      },
      arrow: ({ ownerState, theme }) => {
        if (ownerState.variant === 'light') {
          return {
            color: theme.palette.common.white,
          };
        }

        return {
          color: customPalette.grey[800],
        };
      },
    },
  },
  MuiStepConnector: {
    styleOverrides: {
      root: {
        marginLeft: 16,
      },
      line: ({ theme }) => ({
        minHeight: 40,
        borderLeftWidth: 2,
        marginLeft: -1,
        borderLeftColor: theme.palette.divider,
      }),
    },
  },
  MuiStepIcon: {
    styleOverrides: {
      root: ({ theme }) => {
        return {
          height: 32,
          width: 32,
          color: theme.palette.action.focus,
          fontWeight: 600,
          '& .MuiStepIcon-text': {
            fill: theme.palette.text.disabled,
          },

          '&.Mui-active': {
            color: alpha(theme.palette.primary.main, 0.3),
            '& .MuiStepIcon-text': {
              fill: theme.palette.primary.dark,
            },
          },

          '&.Mui-completed': {
            color: customPalette.green[600],
          },
        };
      },
    },
  },
  MuiStepLabel: {
    styleOverrides: {
      root: {
        padding: 0,
      },
      iconContainer: {
        paddingRight: 24,
      },
      label: () => {
        const sharedStyles = {
          fontWeight: 600,
        };

        return {
          fontSize: 16,
          ...sharedStyles,
          '&.Mui-completed': {
            ...sharedStyles,
          },
          '&.Mui-active': {
            ...sharedStyles,
          },
        };
      },
    },
  },
  MuiAvatar: {
    styleOverrides: {
      rounded: {
        borderRadius: 4,
      },
    },
  },
  MuiTextField: {
    styleOverrides: {
      root: ({ ownerState, theme }) => ({
        ...(ownerState.input_bg && {
          backgroundColor: theme.palette.common.white,
        }),
      }),
    },
  },
  MuiAutocomplete: {
    styleOverrides: {
      inputRoot: ({ ownerState, theme }) => ({
        ...(ownerState.input_bg && {
          backgroundColor: theme.palette.common.white,
        }),
      }),
      option: {
        fontSize: 15,
        wordBreak: 'break-word',
      },
      noOptions: {
        fontSize: 15,
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        borderColor: muiPalette.divider,
        padding: '8px 16px',
      },
    },
  },
  MuiListItemButton: {
    styleOverrides: {
      root: ({ ownerState, theme }) => ({
        py: theme.spacing(ownerState.dense ? 0.5 : 1),
      }),
    },
  },
} satisfies ThemeOptions['components'];

export const lemonlightMuiLightThemeOptions: ThemeOptions = {
  palette: {
    ...muiPalette,
    ...customPalette,
    ...otherColors,
  },
  typography,
  components,
  shape: customShape,
  breakpoints,
  elevation,
};

export const lemonlightMuiLightTheme = createTheme(
  lemonlightMuiLightThemeOptions,
);
