import { useCallback, useEffect, useRef, useState } from 'react';

import { isEqual, pick } from 'lodash-es';
import { useUpdateEffect } from 'react-use';

import { useUpdateDocument } from '@ll-platform/frontend/hero/documentBuilder/async/useDocumentBuilderMutations';
import { useGetDocumentById } from '@ll-platform/frontend/hero/documentBuilder/async/useDocumentBuilderQueries';
import type {
  DocumentBuilderConfig,
  DocumentData,
  DocumentDto,
} from '@ll-platform/frontend/hero/documentBuilder/core/builder/documentBuilderTypes';
import { useDebounceFn } from '@ll-platform/frontend/utils/hooks/useDebounceFn';

const DOCUMENT_BUILDER_AUTOSAVE_INTERVAL = 3 * 1000;
const DOCUMENT_BUILDER_REFETCH_INTERVAL = 10 * 1000;

const defaultDocumentData: DocumentData = {
  slides: [],
  metadata: {},
};

export function useDocumentBuilderState(config: DocumentBuilderConfig) {
  const [document, setDocument] = useState<DocumentData>(defaultDocumentData);
  const documentDataRef = useRef<DocumentData>(defaultDocumentData);
  documentDataRef.current = document;
  const [isInitialized, setIsInitialized] = useState(false);
  const [isDirty, setIsDirty] = useState(false);

  const documentQuery = useGetDocumentById(
    { id: config.documentId },
    {
      enabled: !!config.documentId,
      staleTime: 0,
      gcTime: 0,
      refetchInterval: DOCUMENT_BUILDER_REFETCH_INTERVAL,
      refetchOnWindowFocus: 'always',
    },
  );
  const { mutateAsync: mutateUpdateDocument, isPending: isSaving } =
    useUpdateDocument();

  const triggerSave = useCallback(async () => {
    if (!isDirty) {
      return;
    }
    if (!isInitialized) {
      console.warn(
        `[documentBuilder] Document is not initialized. Skipped saving`,
      );

      return;
    }

    await mutateUpdateDocument({
      id: config.documentId,
      ...documentDataRef.current,
    });
    setIsDirty(false);
    try {
      config.onSave?.(documentDataRef.current);
    } catch (error) {
      console.error('[documentBuilder] onSave callback failed', error);
    }
  }, [mutateUpdateDocument, config, isInitialized, isDirty]);

  const updateDocument = useCallback(
    (updater: (doc: DocumentData) => DocumentData) => {
      setDocument((current) => {
        const newDocument = updater(current);
        if (isEqual(current, newDocument)) {
          return current;
        }
        setIsDirty(true);
        try {
          config?.onUpdate?.(newDocument);
        } catch (error) {
          console.error('[documentBuilder] onUpdate callback failed', error);
        }

        return newDocument;
      });
    },
    [config],
  );

  // Pseudo collaboration with polling
  const syncServerDataToLocalState = useCallback(
    (serverData: DocumentDto) => {
      const serverDocumentData = pick(serverData, ['slides', 'metadata']);
      if (!isDirty) {
        setDocument((current) => {
          if (isEqual(current, serverDocumentData)) {
            return current;
          }

          return serverDocumentData;
        });
        setIsInitialized(true);
      }
    },
    [isDirty],
  );

  const debouncedTriggerSave = useDebounceFn(triggerSave, {
    delay: DOCUMENT_BUILDER_AUTOSAVE_INTERVAL,
    maxWait: DOCUMENT_BUILDER_AUTOSAVE_INTERVAL * 3,
  });

  useUpdateEffect(() => {
    if (isInitialized) {
      debouncedTriggerSave();
    }
  }, [document]);

  useEffect(() => {
    if (documentQuery.data) {
      syncServerDataToLocalState(documentQuery.data);
    }
    // Only react to query data
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentQuery.data]);

  useEffect(() => {
    if (isInitialized && isDirty) {
      const handleBeforeUnload = (event: BeforeUnloadEvent) => {
        event.preventDefault();
        event.returnValue = '';
      };

      window.addEventListener('beforeunload', handleBeforeUnload);

      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
      };
    }
  }, [isInitialized, isDirty]);

  useEffect(() => {
    // Save when leaving the page
    if (isInitialized) {
      return () => {
        debouncedTriggerSave();
      };
    }
    // Using debouncedTriggerSave as it's ref is stabilized
  }, [isInitialized, debouncedTriggerSave]);

  return {
    document,
    isLoading: documentQuery.isPending,
    isInitialized,
    isSaving,
    isDirty,
    updateDocument,
    triggerSave,
  };
}
