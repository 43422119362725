import { AccountCircle, Logout } from '@mui/icons-material';
import {
  Divider,
  Menu,
  MenuItem,
  MenuList,
  Stack,
  Typography,
  type MenuProps,
  type SvgIconTypeMap,
} from '@mui/material';
import type { OverridableComponent } from '@mui/material/OverridableComponent';
import { Link } from 'react-router-dom';

import { UserAvatar } from '@ll-platform/frontend/components/User/UserAvatar';
import { organizationService } from '@ll-platform/frontend/core/organization/async/OrganizationsService';
import { Pages } from '@ll-platform/frontend/core/router/pages';
import { useActiveUser } from '@ll-platform/frontend/features/auth/hooks/useActiveUser';
import { useLogOut } from '@ll-platform/frontend/features/auth/hooks/useLogOut';
import { isUserInternal } from '@ll-platform/frontend/features/auth/utils/isInternal';
import { useOptionalActiveOrganization } from '@ll-platform/frontend/features/organizations/hooks/useActiveOrganization';

type MenuIconProps = {
  Icon: OverridableComponent<SvgIconTypeMap>;
};

const MenuIcon = ({ Icon }: MenuIconProps) => {
  return <Icon sx={{ mr: 2, color: (theme) => theme.palette.action.active }} />;
};

export type ActiveUserMenuProps = MenuProps;

export const ActiveUserMenu = ({ ...props }: ActiveUserMenuProps) => {
  const { activeUser } = useActiveUser();
  const { activeOrganization } = useOptionalActiveOrganization();
  const logOut = useLogOut({ activeUser });

  return (
    <Menu {...props} elevation={4}>
      <Stack
        direction="row"
        gap={2}
        sx={{
          minWidth: '240px',
          p: 2,
          pb: 1,
        }}
      >
        <UserAvatar user={activeUser} size={40} />
        <Stack justifyContent="center">
          <Typography variant="body1" fontWeight={700}>
            {activeUser.firstName} {activeUser.lastName}
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            onDoubleClick={(ev) =>
              isUserInternal(activeUser) &&
              (ev.altKey || ev.metaKey) &&
              organizationService.promptForBackdoorOrganizationId()
            }
          >
            {isUserInternal(activeUser)
              ? activeOrganization?.name || 'No Organization'
              : ''}
          </Typography>
        </Stack>
      </Stack>
      <MenuList>
        <Divider sx={{ mb: 1 }} />
        <MenuItem component={Link} to={Pages.SettingsMyAccount}>
          <MenuIcon Icon={AccountCircle} />
          My Account
        </MenuItem>
        <Divider />

        <MenuItem onClick={() => logOut()}>
          <MenuIcon Icon={Logout} />
          Log Out
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
