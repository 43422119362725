import { createRandomId } from '@ll-platform/frontend/core/firebase/firebaseService';
import {
  mapAlternateVersionDtoToForm,
  mapAlternateVersionFormToDto,
} from '@ll-platform/frontend/features/internalProjects/projectForm/heroVideos/mapAlternateVersionFormToDto';

import type { CutDownsInput } from './cutDownDto';
import type { CutDownSubFormValues } from './cutDownSchema';

export const mapCutDownFormToDto = ({
  id,
  alternateVersions,
  aspectRatio,
  videoLength,
}: CutDownSubFormValues): CutDownsInput => {
  return {
    id: id || createRandomId(),
    alternativeVersion: alternateVersions.map(mapAlternateVersionFormToDto),
    ...mapAlternateVersionFormToDto({ aspectRatio }),
    duration: videoLength,
  };
};

export const mapCutDownDtoToForm = ({
  alternativeVersion,
  aspectRatioCount,
  duration,
  id,
}: CutDownsInput): CutDownSubFormValues => ({
  id,
  alternateVersions:
    alternativeVersion?.map(mapAlternateVersionDtoToForm) ?? [],
  aspectRatio: aspectRatioCount ?? 1,
  videoLength: duration,
  isInEditMode: false,
});
