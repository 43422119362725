import {
  UserAvatar,
  type UserAvatarProps,
} from '@ll-platform/frontend/components/User/UserAvatar';
import { useActiveUser } from '@ll-platform/frontend/features/auth/hooks/useActiveUser';

export type ActiveUserAvatarProps = Omit<UserAvatarProps, 'user'>;

export const ActiveUserAvatar = ({ ...props }: ActiveUserAvatarProps) => {
  const { activeUser } = useActiveUser();

  if (!activeUser) {
    return null;
  }

  return <UserAvatar {...props} user={activeUser} />;
};
