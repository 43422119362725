import * as yup from 'yup';

import { REVIEW_FORM_BLOCKING_PHRASES } from '@ll-platform/frontend/features/reviews/consts';
import type { ReviewEmailData } from '@ll-platform/frontend/features/reviews/types';

export const notifyForReviewSchema = yup
  .object()
  .shape({
    title: yup.string().required(),
    body: yup
      .string()
      .required()
      .min(1, 'Email body cannot be empty')
      .test((value, test) => {
        if (!value) {
          return true;
        }

        if (value === '<p></p>') {
          return test.createError({ message: 'Email body cannot be empty' });
        }

        for (const key in REVIEW_FORM_BLOCKING_PHRASES) {
          if (REVIEW_FORM_BLOCKING_PHRASES.hasOwnProperty(key)) {
            const phrase =
              REVIEW_FORM_BLOCKING_PHRASES[
                key as keyof typeof REVIEW_FORM_BLOCKING_PHRASES
              ];
            if (value.includes(phrase.text)) {
              return test.createError({ message: phrase.errorMessage });
            }
          }
        }

        return true;
      }),
    recipientUserIds: yup.array().required().of(yup.string().required()).min(1),
  })
  .required();

export type NotifyForReviewFormValues = yup.InferType<
  typeof notifyForReviewSchema
>;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type EnsureSameType = NotifyForReviewFormValues extends ReviewEmailData
  ? true
  : false;
