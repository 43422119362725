import * as Popover from "@radix-ui/react-popover"
import { LinkEditorPanel } from "@shadcn/custom/tiptap/panels"
import { Icon } from "@shadcn/custom/tiptap/ui/Icon"
import { Toolbar } from "@shadcn/custom/tiptap/ui/Toolbar"

export type EditLinkPopoverProps = {
  onSetLink: (link: string, openInNewTab?: boolean) => void
}

export const EditLinkPopover = ({ onSetLink }: EditLinkPopoverProps) => {
  return (
    <Popover.Root>
      <Popover.Trigger asChild>
        <Toolbar.Button tooltip="Set Link">
          <Icon name="Link" />
        </Toolbar.Button>
      </Popover.Trigger>
      <Popover.Content>
        <LinkEditorPanel onSetLink={onSetLink} initialOpenInNewTab={true} />
      </Popover.Content>
    </Popover.Root>
  )
}
