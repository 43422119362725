import type { DocumentNode } from '@ll-platform/frontend/hero/documentBuilder/core/nodes/nodeTypes';

export const RichTextNodeName = 'RichText';

export type RichTextNodeContent = {
  text: string;
};

export type RichTextNodeType = DocumentNode<
  typeof RichTextNodeName,
  RichTextNodeContent
>;
