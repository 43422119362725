import { Box, Typography } from '@mui/material';

import { TextLink } from '@ll-platform/frontend/components/TextLink';
import { UserAvatar } from '@ll-platform/frontend/components/User/UserAvatar';
import type { User } from '@ll-platform/frontend/core/auth/types';

type UserContactInfoProps = {
  user: User;
  brandName?: string;
};

export const UserContactInfo = ({ user, brandName }: UserContactInfoProps) => {
  return (
    <Box sx={{ display: 'flex', gap: 2.5 }}>
      <UserAvatar user={user} size={64} />
      <Box sx={{ pr: 2 }}>
        <Typography variant="h6">
          {user.firstName} {user.lastName}
        </Typography>
        {brandName && (
          <Typography sx={{ mb: 1.5 }} variant="body1" color="text.secondary">
            {brandName}
          </Typography>
        )}

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            gap: 0.5,
            mt: 1,
            minHeight: '40px',
          }}
        >
          {user.email && (
            <TextLink url={`mailto:${user.email}`} newTab>
              {user.email}
            </TextLink>
          )}
          {user.phoneNumber && (
            <TextLink
              url={`tel:${user.phoneNumber}`}
              withCopy={user.phoneNumber}
            >
              {user.phoneNumber}
            </TextLink>
          )}
        </Box>
      </Box>
    </Box>
  );
};
