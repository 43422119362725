import type { ReactNode } from 'react';

import { Link } from 'react-router-dom';

import { HeroLogo } from '@ll-platform/frontend/components/HeroLogo/HeroLogo';
import { ActiveUserWithMenu } from '@ll-platform/frontend/components/User/ActiveUser/ActiveUserWithMenu';

type NavigationBarProps = {
  children?: ReactNode;
};

export const NavigationBar = ({ children }: NavigationBarProps) => {
  return (
    <nav className="sticky top-0 z-50 border-b border-border bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60">
      <div className="mx-auto flex h-16 items-center justify-between px-4">
        {children ?? (
          <Link to="/">
            <HeroLogo />
          </Link>
        )}
        <div className="flex items-center gap-2">
          {/* TODO: Replace with Tailwind component */}
          <ActiveUserWithMenu avatarProps={{ size: 40 }} />
        </div>
      </div>
    </nav>
  );
};
