import { assertDefined } from '@ll-platform/frontend/utils/types/types';

/**
 * Helper to wait and cast the last chunk of an async iterable
 * @param asyncIterable - Async iterable to wait for
 * @param previousChunk - Previous chunk of the iterable. Must be passed if iterable was already drained before
 * @returns Return value of the iterable
 */
export async function waitForAsyncGeneratorReturn<T>(
  asyncIterable: AsyncGenerator<unknown, T>,
  // must be passed if iterable was already drained before
  previousChunk: unknown,
): Promise<T> {
  let chunk = previousChunk;
  for await (chunk of asyncIterable) {
    // drain till the end
  }

  const finalChunk = ((await asyncIterable.next())?.value ?? chunk) as T;

  assertDefined(finalChunk, 'finalChunk at waitForAsyncGeneratorReturn');

  return finalChunk;
}
