import { useCallback, useMemo, useState } from 'react';

import { toast } from 'react-toastify';

import { activityTracker } from '@ll-platform/frontend/core/analytics/activityTracker';
import { NotifyForReviewModal } from '@ll-platform/frontend/features/reviews/components/NotifyForReviewModal';
import { defaultReviewToastParams } from '@ll-platform/frontend/features/reviews/consts/defaultReviewToastParams';
import type {
  ReviewEmailData,
  ReviewModalTemplate,
} from '@ll-platform/frontend/features/reviews/types';
import { UsersQueries } from '@ll-platform/frontend/features/users/async/useUsersQueries';
import { useBatchQuery } from '@ll-platform/frontend/utils/hooks/useBatchQueries';
import {
  defined,
  type Nullable,
  type XOR,
} from '@ll-platform/frontend/utils/types/types';

type UseNotifyForReviewDialogArgs<T> = {
  onSubmit: (payload: ReviewEmailData) => Promise<void>;
  onCancel?: VoidFunction;
  onOpen?: (args?: T) => void;
  reviewModalTemplate: ReviewModalTemplate;
  isLoading: boolean;
} & XOR<
  {
    /** @deprecated */
    brandId: Nullable<string>; // undefined during loading
  },
  {
    projectId: string;
  }
>;

export const useNotifyForReviewDialog = <T = unknown,>({
  onSubmit,
  onCancel,
  onOpen,
  reviewModalTemplate: { toastParams, analyticsMetadata, ...reviewModalProps },
  brandId,
  projectId,
  isLoading,
}: UseNotifyForReviewDialogArgs<T>) => {
  const [isNotifyModalOpen, setIsNotifyModalOpen] = useState(false);

  const defaultUsersQuery = useBatchQuery(
    reviewModalProps.reviewEmailData.recipientUserIds.map((userId) =>
      UsersQueries.getById({ userId }),
    ),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  );

  const defaultReceiversData = useMemo(() => {
    return defaultUsersQuery.data?.filter(defined) ?? [];
  }, [defaultUsersQuery.data]);

  const openNotifyModal = useCallback(
    (args?: T) => {
      onOpen?.(args);
      setIsNotifyModalOpen(true);
    },
    [onOpen],
  );

  const handleConfirm = useCallback(
    async (payload: ReviewEmailData) => {
      if (analyticsMetadata?.confirm) {
        activityTracker.log(analyticsMetadata?.confirm);
      }

      await onSubmit(payload);
      setIsNotifyModalOpen(false);
    },
    [analyticsMetadata, onSubmit],
  );

  const handleCancel = useCallback(() => {
    if (analyticsMetadata?.cancel) {
      activityTracker.log(analyticsMetadata?.cancel);
    }

    setIsNotifyModalOpen(false);
    onCancel?.();
  }, [analyticsMetadata, onCancel]);

  const onSubmitForReview = useCallback(
    async (payload: ReviewEmailData) => {
      await toast.promise(
        handleConfirm(payload),
        toastParams ?? defaultReviewToastParams,
      );
    },
    [toastParams, handleConfirm],
  );

  const notifyForReviewDialogNode = useMemo(() => {
    return (
      <NotifyForReviewModal
        {...reviewModalProps}
        onSubmitForReview={onSubmitForReview}
        open={isNotifyModalOpen}
        close={handleCancel}
        defaultReceiversData={defaultReceiversData}
        isLoading={!!isLoading || defaultUsersQuery.isFetching}
        {...(brandId ? { brandId } : { projectId: projectId! })}
      />
    );
  }, [
    onSubmitForReview,
    isNotifyModalOpen,
    handleCancel,
    defaultUsersQuery.isFetching,
    defaultReceiversData,
    reviewModalProps,
    brandId,
    projectId,
    isLoading,
  ]);

  return useMemo(
    () => ({
      openNotifyModal,
      notifyForReviewDialogNode,
      isNotifyModalOpen,
    }),
    [openNotifyModal, notifyForReviewDialogNode, isNotifyModalOpen],
  );
};
