import { memo } from 'react';

import { BottomSlideActionsBar } from '@ll-platform/frontend/hero/documentBuilder/core/slides/actionBars/BottomSlideActionsBar';
import { TopSlideActionsBar } from '@ll-platform/frontend/hero/documentBuilder/core/slides/actionBars/TopSlideActionsBar';
import { DocumentSlide } from '@ll-platform/frontend/hero/documentBuilder/core/slides/DocumentSlide';

export const DocumentSlideContainer = memo(() => {
  return (
    <div className="w-full">
      <TopSlideActionsBar />
      <DocumentSlide />
      <BottomSlideActionsBar />
    </div>
  );
});
DocumentSlideContainer.displayName = 'DocumentSlideContainer';
