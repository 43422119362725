import { useMemo } from 'react';

import { mapProjectDtoToForm } from '@ll-platform/frontend/features/internalProjects/projectForm/utils/mapFormToDto';
import {
  useGetCharactersByProjectId,
  useGetProjectById,
  useGetProjectUserInvites,
  useGetProjectUsers,
} from '@ll-platform/frontend/features/projects/async/useProjectsQueries';

export function useEditProjectFormInitialValues(projectId: string) {
  const { data, isPending, error } = useGetProjectById({ id: projectId });

  const {
    data: projectUsers,
    isPending: isProjectUsersPending,
    error: projectUsersError,
  } = useGetProjectUsers({ projectId });

  const {
    data: projectUserInvites,
    isPending: isProjectUserInvitesPending,
    error: projectUserInvitesError,
  } = useGetProjectUserInvites({ projectId });

  const {
    data: characters,
    isPending: isCharactersPending,
    error: charactersError,
  } = useGetCharactersByProjectId({ id: projectId });

  const initialValues = useMemo(() => {
    if (!data || !projectUsers || !projectUserInvites) {
      return null;
    }

    return mapProjectDtoToForm({
      ...data,
      characters,
      projectUsers: [...projectUsers.items, ...projectUserInvites.items],
    });
  }, [data, characters, projectUsers, projectUserInvites]);

  return {
    initialValues,
    isPending:
      isPending ||
      isProjectUsersPending ||
      isProjectUserInvitesPending ||
      isCharactersPending,
    error:
      error || projectUsersError || projectUserInvitesError || charactersError,
  };
}
