import { memo, useMemo, type CSSProperties } from 'react';

import { ErrorBoundary } from 'react-error-boundary';

import { isDev } from '@ll-platform/frontend/config/isDev';
import { useDocumentBuilder } from '@ll-platform/frontend/hero/documentBuilder/core/builder/DocumentBuilderContext';
import { useManageNode } from '@ll-platform/frontend/hero/documentBuilder/core/nodes/useManageNode';

import { type DocumentNode } from './nodeTypes';

type NodeContainerProps = {
  node: DocumentNode;
};

export const NodeContainer = memo(({ node }: NodeContainerProps) => {
  const { availableNodes } = useDocumentBuilder();

  const { updateNodeContent, updateNodeAttributes } = useManageNode(node);

  const renderContent = () => {
    const nodeConfig = availableNodes.find(
      (config) => config.type === node.type,
    );

    if (!nodeConfig) {
      console.warn(`Unknown node type: ${node.type}`);

      return null;
    }

    return nodeConfig.render({
      ...node,
      onContentChange: updateNodeContent,
      onAttributesChange: updateNodeAttributes,
    });
  };

  const style = useMemo((): CSSProperties => {
    return {
      position: 'absolute',
      ...node.style,
    };
  }, [node.style]);

  return (
    <ErrorBoundary
      fallback={
        isDev() ? (
          <div className="p-4 border border-red-300 text-red-500">
            Error rendering node: {node.type}
          </div>
        ) : null
      }
    >
      <div
        style={style}
        data-node-type={node.type}
        data-node-id={node.id}
        className="flex"
      >
        {renderContent()}
      </div>
    </ErrorBoundary>
  );
});
NodeContainer.displayName = 'NodeContainer';
