import { FormProvider } from 'react-hook-form';

import { ConceptSidebar } from '@ll-platform/frontend/hero/creativeProposal/concept/components/ConceptSidebar';
import { ConceptsList } from '@ll-platform/frontend/hero/creativeProposal/concept/components/ConceptsList';
import { LOADING_SCOPE_GENERATING } from '@ll-platform/frontend/hero/creativeProposal/concept/consts';
import { useConceptFormRoot } from '@ll-platform/frontend/hero/creativeProposal/concept/hooks/useConceptForm';
import {
  LoadingContextProvider,
  useLoadingContextToast,
} from '@ll-platform/frontend/utils/contexts/LoadingContext';

const Inner = () => {
  const methods = useConceptFormRoot();
  useLoadingContextToast(LOADING_SCOPE_GENERATING, 'Generating...');

  return (
    <FormProvider {...methods}>
      <ConceptSidebar />
      <ConceptsList />
    </FormProvider>
  );
};

export const NewConceptPage = () => {
  return (
    <LoadingContextProvider>
      <Inner />
    </LoadingContextProvider>
  );
};
