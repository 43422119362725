import type { ReactNode } from 'react';

import { Box, Stack } from '@mui/material';

import { UserAvatarWithPopover } from '@ll-platform/frontend/components/User/UserAvatarWithPopover';
import type { User } from '@ll-platform/frontend/core/auth/types';
import {
  fullAvatarSize,
  threadNumberAvatarSize,
} from '@ll-platform/frontend/features/projectComments/consts';

type ThreadAvatar = {
  bubbleNumber: number | undefined;
  display: boolean;
  user: User;
};

export const ThreadAvatar = ({ display, bubbleNumber, user }: ThreadAvatar) => (
  <ThreadNumber
    number={bubbleNumber ?? 0}
    display={bubbleNumber !== undefined && display}
  >
    <UserAvatarWithPopover
      size={display ? threadNumberAvatarSize : fullAvatarSize}
      user={user}
    />
  </ThreadNumber>
);

type ThreadNumberProps = {
  number: number;
  display: boolean;
  children: ReactNode;
};
const ThreadNumber = ({ children, display, number }: ThreadNumberProps) => {
  if (!display) {
    return children;
  }

  return (
    <Box position="relative">
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{
          position: 'absolute',
          backgroundColor: (theme) => theme.palette.backgroundSecondary,
          borderColor: (theme) => theme.palette.blue[200],
          width: 32,
          height: 32,
          borderStyle: 'solid',
          borderWidth: 1,
          borderRadius: '50%',
          top: -10,
          left: -10,
        }}
      >
        {number}
      </Stack>
      <Stack
        justifyContent="end"
        alignItems="end"
        sx={{
          width: fullAvatarSize,
          height: fullAvatarSize,
          left: fullAvatarSize / 2,
          top: fullAvatarSize / 2,
        }}
      >
        {children}
      </Stack>
    </Box>
  );
};
