import { makeReviewNotificationKey } from '@ll-platform/frontend/features/reviews/utils/makeReviewNotificationKey';

export enum ReviewEditGeneratedDocsKey {
  CreativeDeckEditInternal = 'creativeDeckEdit:internal',
  CreativeDeckEditExternal = 'creativeDeckEdit:external',
  CallSheetEditInternal = 'callSheetEdit:internal',
  CallSheetEditExternal = 'callSheetEdit:external',
}

export enum ReviewCreativeDeckNotificationType {
  SubmitForReviewExternal = 'creativeDeck:submit:external',
  ApproveExternal = 'creativeDeck:approve:external',
  SubmitForReviewInternal = 'creativeDeck:submit:internal',
  ApproveInternal = 'creativeDeck:approve:internal',
}

export enum ReviewCallSheetNotificationType {
  SubmitForReviewExternal = 'callSheet:submit:external',
  ApproveExternal = 'callSheet:approve:external',
  SubmitForReviewInternal = 'callSheet:submit:internal',
  ApproveInternal = 'callSheet:approve:internal',
}

export enum CreativeDeckReviewApprovalKey {
  NotifyInternal = 'creativeDeck:notifyInternal',
  Approve = 'creativeDeck:approve',
}

export enum CallSheetReviewApprovalKey {
  NotifyInternal = 'callSheet:notifyInternal',
  Approve = 'callSheet:approve',
}

// submitting for review by the client is mapped to a different review key than the rest as it is a isPingOnly review request
const approvalNotificationToReviewKeyMap: Record<
  ReviewCallSheetNotificationType | ReviewCreativeDeckNotificationType,
  CallSheetReviewApprovalKey | CreativeDeckReviewApprovalKey
> = {
  [ReviewCallSheetNotificationType.ApproveExternal]:
    CallSheetReviewApprovalKey.Approve,
  [ReviewCallSheetNotificationType.ApproveInternal]:
    CallSheetReviewApprovalKey.Approve,
  [ReviewCallSheetNotificationType.SubmitForReviewExternal]:
    CallSheetReviewApprovalKey.NotifyInternal,
  [ReviewCallSheetNotificationType.SubmitForReviewInternal]:
    CallSheetReviewApprovalKey.Approve,
  [ReviewCreativeDeckNotificationType.ApproveExternal]:
    CreativeDeckReviewApprovalKey.Approve,
  [ReviewCreativeDeckNotificationType.ApproveInternal]:
    CreativeDeckReviewApprovalKey.Approve,
  [ReviewCreativeDeckNotificationType.SubmitForReviewExternal]:
    CreativeDeckReviewApprovalKey.NotifyInternal,
  [ReviewCreativeDeckNotificationType.SubmitForReviewInternal]:
    CreativeDeckReviewApprovalKey.Approve,
};

type MakeDocumentNotificationKeyArgs<
  T extends
    | ReviewCreativeDeckNotificationType
    | ReviewCallSheetNotificationType,
> = {
  projectId: string;
  reviewNotificationType: T;
  resourceId?: string;
};

export const makeGeneratedDocReviewKey = ({
  projectId,
  reviewNotificationType,
  resourceId,
}: MakeDocumentNotificationKeyArgs<
  ReviewCreativeDeckNotificationType | ReviewCallSheetNotificationType
>) => {
  const actionKey = approvalNotificationToReviewKeyMap[reviewNotificationType];

  if (
    Object.values(ReviewCreativeDeckNotificationType).includes(
      reviewNotificationType as ReviewCreativeDeckNotificationType,
    )
  ) {
    return makeReviewNotificationKey(['project', projectId, actionKey]);
  } else if (
    Object.values(ReviewCallSheetNotificationType).includes(
      reviewNotificationType as ReviewCallSheetNotificationType,
    )
  ) {
    return makeReviewNotificationKey([
      'project',
      projectId,
      actionKey,
      resourceId,
    ]);
  }

  throw new Error('Generated document type is not supported');
};
