import {
  Alert,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  type DialogProps,
} from '@mui/material';

import { Spinner } from '@ll-platform/frontend/components/Spinner/Spinner';
import type { User } from '@ll-platform/frontend/core/auth/types';
import type {
  ReviewEmailData,
  ReviewModalPassedProps,
} from '@ll-platform/frontend/features/reviews/types';
import type { Nullable, XOR } from '@ll-platform/frontend/utils/types/types';

import { NotifyModalForm } from './NotifyModalForm/NotifyModalForm';

type NotifyForReviewModalProps = DialogProps &
  ReviewModalPassedProps & {
    defaultReceiversData: User[];
    isLoading: boolean;
    close: () => void;
    onSubmitForReview: (payload: ReviewEmailData) => Promise<void>;
  } & XOR<
    {
      /** @deprecated */
      brandId: Nullable<string>; // undefined during loading
    },
    {
      projectId: string;
    }
  >;

/**
 * TODO: For future reference - possible refactor: could use a CustomDialog
 */
export const NotifyForReviewModal = ({
  defaultReceiversData,
  brandId,
  projectId,
  isLoading,
  reviewEmailData,
  warningContent,
  titleContent,
  headingContent,
  submitButtonContent = 'Submit for Review',
  close,
  onSubmitForReview,
  ...dialogProps
}: NotifyForReviewModalProps) => {
  return (
    <Dialog fullWidth maxWidth="sm" {...dialogProps}>
      <DialogTitle sx={{ py: 2, px: 3 }}>{titleContent}</DialogTitle>
      {warningContent && (
        <Alert severity="warning" sx={{ mx: 3 }}>
          {warningContent}
        </Alert>
      )}
      <DialogContent
        sx={{
          py: 0,
        }}
      >
        {isLoading ? (
          <Stack
            justifyContent="center"
            alignItems="center"
            alignSelf="center"
            height="100%"
            width="100%"
          >
            <Spinner size={64} />
          </Stack>
        ) : (
          <NotifyModalForm
            defaultReceiversData={defaultReceiversData}
            reviewEmailData={reviewEmailData}
            headingContent={headingContent}
            submitButtonContent={submitButtonContent}
            onSubmitForReview={onSubmitForReview}
            onClose={close}
            {...(brandId ? { brandId } : { projectId: projectId! })}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};
