import { type ProductionDayFormValues } from '@ll-platform/frontend/features/internalProjects/projectForm/productionDays/productionDaysSchema';
import type { ProjectProductionDay } from '@ll-platform/frontend/features/projects/types';
import { mapUnknownToDayjs } from '@ll-platform/frontend/utils/helpers/date';
import { defined } from '@ll-platform/frontend/utils/types/types';

export const mapProductionDayFormToDto = ({
  id,
  crewMembers,
  productionLength,
  tentativeCity,
  dateTime,
  isTimeSet,
  locationType,
}: ProductionDayFormValues): ProjectProductionDay => {
  return {
    id: id!,
    address: tentativeCity,
    crew: crewMembers.filter(defined),
    duration: productionLength,
    locationType,
    dateTime: dateTime?.toDate() ?? null,
    isTimeSet,
  };
};

export const mapProductionDaysDtoToForm = ({
  id,
  address,
  crew,
  duration,
  dateTime,
  isTimeSet,
  locationType,
  scheduleStatus,
}: ProjectProductionDay): ProductionDayFormValues => ({
  id,
  crewMembers: crew ?? [],
  productionLength: duration,
  tentativeCity:
    typeof address === 'string' ? { description: address } : (address ?? null),
  dateTime: mapUnknownToDayjs(dateTime),
  isTimeSet,
  locationType,
  scheduleStatus,
  isInEditMode: false,
});
