import { BudgetQueries } from '@ll-platform/frontend/features/budget/async/useBudgetQueries';
import { InternalProjectsQueries } from '@ll-platform/frontend/features/internalProjects/async/useInternalProjectsQueries';
import type {
  UpdateHeroVideoDto,
  UpdateProductionDayDto,
} from '@ll-platform/frontend/features/internalProjects/types';
import { projectsService } from '@ll-platform/frontend/features/projects/async/ProjectsService';
import { ProjectsQueries } from '@ll-platform/frontend/features/projects/async/useProjectsQueries';
import type { ProjectWithDeliverables } from '@ll-platform/frontend/features/projects/types';
import { ProjectTimelineQueries } from '@ll-platform/frontend/features/projectTimeline/async/useProjectTimelineQueries';
import { ProposalsQueries } from '@ll-platform/frontend/features/proposals/async/useProposalsQueries';
import { createMutationHook } from '@ll-platform/frontend/utils/factories/createMutationHook';

export const useUpdateProjectFields = createMutationHook(
  projectsService.updateProjectDocument.bind(projectsService),
  (queryClient) => ({
    onSuccess: (_, { id, data: { lastUpdated: _lastUpdated, ...params } }) => {
      queryClient.invalidateQueries({ queryKey: InternalProjectsQueries._def });
      queryClient.invalidateQueries({
        queryKey: ProjectsQueries.findAll._def,
      });
      queryClient.invalidateQueries({ queryKey: ProjectTimelineQueries._def });
      queryClient.invalidateQueries({ queryKey: BudgetQueries._def });
      queryClient.invalidateQueries({ queryKey: ProposalsQueries._def });
      queryClient.setQueryData<ProjectWithDeliverables>(
        ProjectsQueries.getById({ id }).queryKey,
        (previousData) => {
          if (!previousData) {
            return previousData;
          }

          const newData = {
            ...previousData,
            ...params,
          } as ProjectWithDeliverables;
          if (previousData.proposal || params.proposal) {
            newData.proposal = {
              ...previousData?.proposal,
              ...params.proposal,
            };
          }

          return newData;
        },
      );
    },
  }),
);

export const useUpdateProductionDayMutation = createMutationHook(
  ({
    projectId,
    id,
    ...data
  }: UpdateProductionDayDto & {
    projectId: string;
    id: string;
  }) => projectsService.updateProductionDay({ projectId, id, data }),
  (queryClient) => ({
    onSuccess: (_, params) => {
      queryClient.invalidateQueries({ queryKey: InternalProjectsQueries._def });
      queryClient.invalidateQueries({ queryKey: ProjectsQueries._def });
      queryClient.invalidateQueries({ queryKey: ProjectTimelineQueries._def });
      queryClient.refetchQueries({
        queryKey: ProjectsQueries.getProductionDaysByProjectId({
          id: params.projectId,
        }).queryKey,
      });
    },
  }),
);

export const useUpdateProductionStatusMutation = createMutationHook(
  projectsService.updateProductionStatus.bind(projectsService),
  (queryClient) => ({
    onSuccess: (_, params) => {
      queryClient.invalidateQueries({ queryKey: InternalProjectsQueries._def });
      queryClient.invalidateQueries({ queryKey: ProjectsQueries._def });
      queryClient.invalidateQueries({ queryKey: ProjectTimelineQueries._def });
      queryClient.refetchQueries({
        queryKey: ProjectsQueries.getProductionDaysByProjectId({
          id: params.projectId,
        }).queryKey,
      });
    },
  }),
);

export const useUpdateProjectHeroVideo = createMutationHook(
  ({
    projectId,
    id,
    ...data
  }: UpdateHeroVideoDto & { projectId: string; id: string }) =>
    projectsService.updateHeroVideo({
      projectId,
      id,
      data,
    }),
  (queryClient) => ({
    onSuccess: (_, payload) => {
      queryClient.invalidateQueries({ queryKey: ProjectsQueries._def });
      queryClient.invalidateQueries({
        queryKey: ProjectsQueries.getHeroVideosByProjectId({
          id: payload.projectId,
        }).queryKey,
      });
      queryClient.invalidateQueries({
        queryKey: ProjectsQueries.getHeroVideoById({
          projectId: payload.projectId,
          videoId: payload.id,
        }).queryKey,
      });
    },
  }),
);

export const useSubmitCreativeDeckEditMutation = createMutationHook(
  projectsService.submitCreativeDeckEdit.bind(projectsService),
);

export const useInviteProjectUsersMutation = createMutationHook(
  projectsService.inviteProjectUsers.bind(projectsService),
  (queryClient) => ({
    onSuccess: (_) => {
      queryClient.invalidateQueries({ queryKey: ProjectsQueries._def });
      queryClient.invalidateQueries({ queryKey: InternalProjectsQueries._def });
    },
  }),
);

export const useDeleteProjectUserMutation = createMutationHook(
  projectsService.deleteProjectUser.bind(projectsService),
  (queryClient) => ({
    onSuccess: (_) => {
      queryClient.invalidateQueries({ queryKey: ProjectsQueries._def });
      queryClient.invalidateQueries({ queryKey: InternalProjectsQueries._def });
    },
  }),
);
