import type { ReactNode } from 'react';

import { TwBaseLayout } from '@ll-platform/frontend/components/layouts/TwBaseLayout/TwBaseLayout';
import { ProposalDocumentNavBar } from '@ll-platform/frontend/hero/creativeProposal/document/components/ProposalDocumentNavBar';

export const ProposalDocumentPageLayout = ({
  children,
}: {
  children: ReactNode;
}) => {
  return (
    <TwBaseLayout
      fullscreen
      background="concepts-page-gradient"
      contentContainerProps={{ className: 'overflow-x-auto' }}
      navigationBar={<ProposalDocumentNavBar />}
    >
      {children}
    </TwBaseLayout>
  );
};
