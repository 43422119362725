import type { CSSProperties, HTMLAttributes, ReactNode } from 'react';

import { cn } from '@ll-platform/frontend/components/shadcn/lib/utils';
import { SidebarProvider } from '@ll-platform/frontend/components/shadcn/ui/sidebar';
import { Toaster } from '@ll-platform/frontend/components/shadcn/ui/sonner';
import { NavigationBar } from '@ll-platform/frontend/components/tw/TwNavigationBar/TwNavigationBar';

interface BaseLayoutProps {
  children: ReactNode;
  navigationBar?: ReactNode;
  fullscreen?: boolean;
  background?: string;
  contentContainerProps?: HTMLAttributes<HTMLElement>;
}

export const TwBaseLayout = ({
  children,
  navigationBar,
  contentContainerProps,
  fullscreen,
  background = 'background-page',
}: BaseLayoutProps) => {
  return (
    <div className="flex flex-col">
      <div className={cn('min-h-screen h-auto w-full', background)}>
        {navigationBar ?? <NavigationBar />}
        <main
          {...contentContainerProps}
          className={cn(
            fullscreen ? 'flex flex-row' : 'mx-auto max-w-7xl px-4',
            contentContainerProps?.className,
          )}
        >
          <SidebarProvider
            className="min-h-0"
            style={
              {
                '--sidebar-width': '384px',
              } as CSSProperties
            }
          >
            {children}
          </SidebarProvider>

          <Toaster richColors toastOptions={{}} expand />
        </main>
      </div>
    </div>
  );
};
