import { useCallback, useEffect } from 'react';

import {
  useDocumentBuilder,
  useDocumentData,
} from '@ll-platform/frontend/hero/documentBuilder/core/builder/DocumentBuilderContext';
import type { DocumentData } from '@ll-platform/frontend/hero/documentBuilder/core/builder/documentBuilderTypes';
import { assertDefined } from '@ll-platform/frontend/utils/types/types';

export function useDocumentBuilderInitialData(
  getInitialData: (() => Promise<DocumentData>) | null,
) {
  const { updateDocument, isInitialized } = useDocumentBuilder();
  const document = useDocumentData();

  const isEmpty =
    document.slides.length === 0 && Object.keys(document.metadata).length === 0;

  const getAndSetInitialData = useCallback(async () => {
    assertDefined(getInitialData, 'getInitialData');
    const initialData = await getInitialData();
    updateDocument(() => initialData);
  }, [getInitialData, updateDocument]);

  useEffect(() => {
    if (isInitialized && isEmpty && getInitialData) {
      getAndSetInitialData();
    }
  }, [isInitialized, isEmpty, getAndSetInitialData, getInitialData]);
}
