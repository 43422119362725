import { useMemo } from 'react';

import { useParams, useSearchParams } from 'react-router-dom';

import type { ProjectWithDeliverables } from '@ll-platform/frontend/features/projects/types';
import { useFindProposalProjects } from '@ll-platform/frontend/features/proposals/async/useProposalsQueries';
import { assertDefined } from '@ll-platform/frontend/utils/types/types';

export const useActiveProposalProjects = (): {
  activeProjects: ProjectWithDeliverables[];
  isPending: boolean;
} => {
  const proposalId = useActiveProposalId();
  const query = useFindProposalProjects({ proposalId });

  return useMemo(() => {
    return {
      activeProjects: query.data ?? [],
      isPending: query.isPending,
    };
  }, [query.data, query.isPending]);
};

export const useActiveProposalId = (): string => {
  const pathParams = useParams();
  const [queryParams] = useSearchParams();

  const proposalId = pathParams.proposalId ?? queryParams.get('proposalId');
  assertDefined(proposalId, 'Missing active project id in path or query');

  return proposalId;
};
