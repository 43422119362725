import * as yup from 'yup';

import { truthy } from '@ll-platform/frontend/utils/types/types';

export const signature = (senderName: string | null) => {
  return `${
    senderName
      ? `,
${senderName}`
      : ''
  }`;
};

export const emailGreeting = (
  salutationWord?: string,
  senderName?: string | null,
  punctuationCharacter: string = ',',
) => {
  if (!senderName && !salutationWord) {
    return '';
  }

  return `${[salutationWord, senderName].filter(truthy).join(' ')}${punctuationCharacter}
`;
};

export const emailSignature = (
  salutationWord?: string,
  senderName?: string | null,
  punctuationCharacter: string = ',<br>',
) => {
  if (!senderName && !salutationWord) {
    return '';
  }

  return `${[salutationWord, senderName].filter(truthy).join(`${punctuationCharacter}`)}`;
};

export const htmlEmailBody = (content: string) =>
  `<p>${content}</p>`.replaceAll('\n', '');

export function isEmail(email: string) {
  return yup.string().required().email().isValidSync(email);
}
