import type { QueryClient } from '@tanstack/query-core';

import { documentBuilderService } from '@ll-platform/frontend/hero/documentBuilder/async/DocumentBuilderService';
import { DocumentBuilderQueries } from '@ll-platform/frontend/hero/documentBuilder/async/useDocumentBuilderQueries';
import { createMutationHook } from '@ll-platform/frontend/utils/factories/createMutationHook';

export const useUpdateDocument = createMutationHook(
  documentBuilderService.update.bind(documentBuilderService),
  (queryClient: QueryClient) => ({
    onSuccess: (data, variables) => {
      queryClient.setQueryData(
        DocumentBuilderQueries.getById({ id: variables.id }).queryKey,
        {
          slides: data.slides,
          metadata: data.metadata,
        },
      );
    },
  }),
);
