import type { BasicInfoInput } from '@ll-platform/frontend/features/internalProjects/projectForm/basicInfo/basicInfoDto';
import type { BasicInfoFormValues } from '@ll-platform/frontend/features/internalProjects/projectForm/basicInfo/basicInfoSchema';

export const mapBasicInfoFormToDto = ({
  dontUseForMarketing,
  msa,
  proposal,
  style,
  timeline,
  title,
  deliverables,
  rawFootageType,
  footageType,
}: BasicInfoFormValues) => {
  return {
    isMarketingCampaign: !dontUseForMarketing,
    msa,
    proposal,
    style,
    timeline,
    title,
    deliverables,
    rawFootageType,
    footageType,
  };
};

export const mapBasicInfoDtoToForm = ({
  isMarketingCampaign,
  msa,
  proposal,
  style,
  timeline,
  title,
  deliverables,
  rawFootageType,
  footageType,
}: BasicInfoInput): BasicInfoFormValues => {
  return {
    dontUseForMarketing: !isMarketingCampaign,
    msa,
    proposal,
    style,
    timeline,
    title,
    deliverables,
    rawFootageType,
    footageType,
  };
};
