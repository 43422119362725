import { type QueryClient } from '@tanstack/react-query';

import { createMutationHook } from '@ll-platform/frontend/utils/factories/createMutationHook';

import { projectCommentsService } from './ProjectCommentsService';
import { ProjectCommentsQueries } from './useProjectCommentsQueries';

const invalidateCommentsQueries = ({
  queryClient,
  params,
}: {
  queryClient: QueryClient;
  params: {
    id?: string; // comment id
    projectId: string;
    threadId?: string;
    outputSubcollection?: string;
    field?: string;
  };
}) => {
  if (params.id) {
    queryClient.invalidateQueries({
      queryKey: ProjectCommentsQueries.getCommentById({
        projectId: params.projectId,
        id: params.id,
      }).queryKey,
    });
  }
  if (params.threadId) {
    queryClient.invalidateQueries({
      queryKey: ProjectCommentsQueries.getCommentsByThreadId({
        projectId: params.projectId,
        threadId: params.threadId,
      }).queryKey,
    });
  }

  if (!params.field) {
    queryClient.invalidateQueries({
      queryKey:
        ProjectCommentsQueries.getCommentsByProjectIdAndProjectFields._def,
    });
  }

  if (!params.outputSubcollection) {
    queryClient.invalidateQueries({
      queryKey:
        ProjectCommentsQueries.getCommentsByProjectIdAndOutputSubcollections
          ._def,
    });
    queryClient.invalidateQueries({
      queryKey: ProjectCommentsQueries.getVideoDeliverableComments._def,
    });
  }

  if (!params.outputSubcollection && !params.field) {
    return;
  }

  queryClient.invalidateQueries({
    predicate: (query) => {
      return query.queryKey.every((key) => {
        if (typeof key === 'string') {
          const getQueryKeys: string[] =
            ProjectCommentsQueries.getCommentsByProjectIdAndOutputSubcollections._def.slice();

          return getQueryKeys.includes(key);
        }

        if (typeof key === 'object' && key !== null) {
          let relevant = true;

          if ('projectId' in key && typeof key.projectId === 'string') {
            relevant = key.projectId === params.projectId;
          }

          if (params.outputSubcollection) {
            if (
              'outputSubcollections' in key &&
              Array.isArray(key.outputSubcollections)
            ) {
              relevant = key.outputSubcollections.some((value) => {
                return value === params.outputSubcollection;
              });
            }
          } else if (params.field) {
            if ('fields' in key && Array.isArray(key.fields)) {
              relevant = key.fields.some((value) => {
                return value === params.field;
              });
            }
          }

          return relevant;
        }

        return false;
      });
    },
  });
};

export const useAddCommentMutation = createMutationHook(
  projectCommentsService.addComment.bind(projectCommentsService),
  (queryClient: QueryClient) => ({
    onSuccess: (data, params) => {
      invalidateCommentsQueries({
        queryClient,
        params: {
          ...data,
          ...params,
        },
      });
    },
  }),
);

export const useUpdateCommentMutation = createMutationHook(
  projectCommentsService.updateComment.bind(projectCommentsService),
  (queryClient: QueryClient) => ({
    onSuccess: (data, params) => {
      invalidateCommentsQueries({
        queryClient,
        params: {
          ...data,
          ...params,
        },
      });
    },
  }),
);

export const useDeleteCommentMutation = createMutationHook(
  projectCommentsService.deleteComment.bind(projectCommentsService),
  (queryClient: QueryClient) => ({
    onSuccess: (_, params) => {
      invalidateCommentsQueries({
        queryClient,
        params,
      });
    },
  }),
);

export const useResolveCommentMutation = createMutationHook(
  projectCommentsService.resolveComment.bind(projectCommentsService),
  (queryClient: QueryClient) => ({
    onSuccess: (_, params) => {
      invalidateCommentsQueries({
        queryClient,
        params,
      });
    },
  }),
);

export const useReopenCommentMutation = createMutationHook(
  projectCommentsService.reopenComment.bind(projectCommentsService),
  (queryClient: QueryClient) => ({
    onSuccess: (_, params) => {
      invalidateCommentsQueries({
        queryClient,
        params,
      });
    },
  }),
);
