import 'keen-slider/keen-slider.min.css';
import 'react-toastify/dist/ReactToastify.css';

import { type PropsWithChildren } from 'react';

import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { LicenseInfo } from '@mui/x-license';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { RouterProvider } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { GlobalErrorBoundary } from '@ll-platform/frontend/components/statusPages/components/GlobalErrorBoundary';
import { APP_CONFIG } from '@ll-platform/frontend/config/app.config';
import { useAmplitude } from '@ll-platform/frontend/container/analytics/useAmplitude';
import { useKlaviyo } from '@ll-platform/frontend/container/analytics/useKlaviyo';
import { useSentrySyncContext } from '@ll-platform/frontend/container/analytics/useSentry';
import { useSmartlook } from '@ll-platform/frontend/container/analytics/useSmartlook';
import { mainRouter } from '@ll-platform/frontend/container/router/mainRouter';
import { queryClient } from '@ll-platform/frontend/core/api/reactQuerySetup';
import { GlobalThemeProvider } from '@ll-platform/frontend/core/theme/GlobalThemeProvider';
import { useCreator } from '@ll-platform/frontend/creator/auth/hooks/useCreator';

LicenseInfo.setLicenseKey(APP_CONFIG.REACT_APP_MUI_LICENSE_KEY);

function CreatorApp() {
  return (
    <GlobalErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <ExternalToolsProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en">
            <GlobalThemeProvider>
              <RouterProvider router={mainRouter} />
              <ToastContainer />
            </GlobalThemeProvider>
          </LocalizationProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </ExternalToolsProvider>
      </QueryClientProvider>
    </GlobalErrorBoundary>
  );
}

const ExternalToolsProvider = ({ children }: PropsWithChildren) => {
  const creator = useCreator();

  const creatorData = creator.isAuthorized
    ? {
        id: creator.activeCreator.id,
        email: creator.activeCreator.email,
        accountType: creator.activeCreator.accountType,
        isActive: creator.activeCreator.isActive,
        firstName: creator.activeCreator.firstName,
        lastName: creator.activeCreator.lastName,
      }
    : undefined;

  useSentrySyncContext(creatorData);
  useAmplitude(creatorData);
  useSmartlook(creatorData);
  useKlaviyo(creatorData);

  return children;
};

export default CreatorApp;
