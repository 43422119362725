import { useCallback, useMemo } from 'react';

import { LoadingButton } from '@mui/lab';
import { Tooltip, type ButtonProps } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { FeatureFlagName } from '@ll-platform/frontend/config/featureFlags/featureFlags';
import { ActivityType } from '@ll-platform/frontend/core/analytics/events';
import { useActivityTracker } from '@ll-platform/frontend/core/analytics/useActivityTracker';
import { useAppFlags } from '@ll-platform/frontend/core/featureFlags/useAppFlags';
import { useActiveUser } from '@ll-platform/frontend/features/auth/hooks/useActiveUser';
import { isUserInternal } from '@ll-platform/frontend/features/auth/utils/isInternal';
import {
  useWizardNavigationContext,
  WizardFlowType,
} from '@ll-platform/frontend/features/projectWizard/contexts/WizardNavigationContext';
import { useActiveProject } from '@ll-platform/frontend/features/projectWizard/hooks/useActiveProject';
import { useFinalizeWizard } from '@ll-platform/frontend/features/projectWizard/hooks/useFinalizeWizard';
import {
  useHomepagePagePath,
  useProjectHomepagePath,
} from '@ll-platform/frontend/features/router/hooks';

type FinalizeWizardButtonProps = ButtonProps & {
  onBlockedFinalizeClick?: () => void;
};

export const FinalizeWizardButton = ({
  children,
  onBlockedFinalizeClick,
  ...props
}: FinalizeWizardButtonProps) => {
  const { flowType, reasonForBlockedFinalize, isLastStep } =
    useWizardNavigationContext();
  const { activeProject } = useActiveProject();
  const { activeUser } = useActiveUser();
  const isInternalUser = isUserInternal(activeUser);
  const flags = useAppFlags();
  const homepagePath = useHomepagePagePath();
  const projectHomepagePath = useProjectHomepagePath();
  const { trackEvent } = useActivityTracker();

  const targetPath = flags[FeatureFlagName.ProjectSpace]
    ? projectHomepagePath(activeProject.id)
    : homepagePath;
  const navigate = useNavigate();
  const navigateAfterFinalize = useCallback(() => {
    navigate(targetPath);
  }, [targetPath, navigate]);

  const {
    onClickFinalize,
    finalizeRequestState,
    sentimentDialog,
    notifyForReviewDialog,
  } = useFinalizeWizard({
    onFinalize: navigateAfterFinalize,
  });

  const label = useMemo(() => {
    if (children) {
      return children;
    }

    if (
      flowType === WizardFlowType.CreativeBrief ||
      flowType === WizardFlowType.EditCreativeDeck ||
      flowType === WizardFlowType.EditCallSheet
    ) {
      return 'Submit';
    }

    if (isInternalUser) {
      return 'Share for Review';
    }

    return 'Submit for Review';
  }, [children, flowType, isInternalUser]);

  const handleFinalize = useCallback(async () => {
    if (!reasonForBlockedFinalize) {
      trackEvent(ActivityType.CreativeBriefInputClickedShareForReview, {
        projectId: activeProject.id,
        projectName: activeProject.title,
        senderRole: activeUser.accountType,
        videoStyle: activeProject.style,
      });

      return await onClickFinalize();
    }

    onBlockedFinalizeClick?.();
    toast.error(reasonForBlockedFinalize);
  }, [
    onClickFinalize,
    onBlockedFinalizeClick,
    reasonForBlockedFinalize,
    activeProject,
    activeUser.accountType,
    trackEvent,
  ]);

  return (
    <>
      <Tooltip title={reasonForBlockedFinalize} placement="left" arrow={true}>
        <div>
          <LoadingButton
            variant="contained"
            disabled={!isLastStep}
            loading={finalizeRequestState?.status === 'pending'}
            {...props}
            onClick={handleFinalize}
            sx={{ width: 'max-content', ...props.sx }}
          >
            {label}
          </LoadingButton>
        </div>
      </Tooltip>
      {notifyForReviewDialog.notifyForReviewDialogNode}
      {sentimentDialog.node}
    </>
  );
};
