import { APP_CONFIG } from '@ll-platform/frontend/config/app.config';
import { defined } from '@ll-platform/frontend/utils/types/types';

import type { ActivityType } from './events';
import {
  EventChannel,
  type ActivityTypesWithoutMetadata,
  type TrackingEvent,
  type TrackingEventInput,
  type TypedTrackingEvent,
} from './types';

type Handler = (event: TrackingEvent) => void;

let handlers: Handler[] = [];

export function analyticsDebugLog(message: string, metadata?: unknown) {
  if (APP_CONFIG.REACT_APP_ANALYTICS_DEBUG) {
    console.debug(
      ...[`[activityTracker] ${message}`, metadata].filter(defined),
    );
  }
}

handlers.push((event) =>
  analyticsDebugLog(`#${event.channel} ${event.type}`, event.metadata),
);

export const activityTracker = {
  log(input: ActivityTypesWithoutMetadata | TypedTrackingEvent) {
    if (typeof input === 'string') {
      return fromString(input);
    }

    return fromEvent(input);
  },

  subscribe(name: string, handler: Handler) {
    analyticsDebugLog(`${name} Subscribed`);
    handlers.push(handler);

    const unsubscribe = () => {
      analyticsDebugLog(`${name} Unsubscribed`);
      handlers = handlers.filter((listHandler) => listHandler !== handler);
    };

    return unsubscribe;
  },

  clearHandlers() {
    handlers = [];
  },
};

const fromString = (type: ActivityType) => {
  fromEvent({ type });
};

const fromEvent = (eventInput: TrackingEventInput) => {
  const event = buildEvent(eventInput);

  for (const handler of handlers) {
    handler(event);
  }
};

const buildEvent = (eventInput: TrackingEventInput): TrackingEvent => {
  return {
    type: eventInput.type,
    channel: eventInput.channel || EventChannel.All,
    metadata: eventInput.metadata || {},
  };
};
