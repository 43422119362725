import { CHARS } from '@ll-platform/frontend/utils/helpers/specialCharacters';

// Put this at the start and end of an inline component to work around this Chromium bug:
// https://bugs.chromium.org/p/chromium/issues/detail?id=1249405
// Allowing to distinguish between two cursor positions just before and just after start of an inline
export const InlineChromiumBugfix = () => (
  <span
    contentEditable={false}
    style={{
      fontSize: 0,
    }}
  >
    {CHARS.nbsp}
  </span>
);
