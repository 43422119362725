import { makeNode } from '@ll-platform/frontend/hero/documentBuilder/core/nodes/nodeHelpers';
import type { NodeStyleProps } from '@ll-platform/frontend/hero/documentBuilder/core/nodes/nodeTypes';
import { DOCUMENT_SLIDE_MARGIN } from '@ll-platform/frontend/hero/documentBuilder/core/slides/slideConsts';

import { BudgetNodeName, type BudgetNodeType } from './budgetNodeTypes';

const defaultBudgetNodeStyle: NodeStyleProps = {
  top: DOCUMENT_SLIDE_MARGIN,
  left: DOCUMENT_SLIDE_MARGIN,
  right: DOCUMENT_SLIDE_MARGIN,
  bottom: DOCUMENT_SLIDE_MARGIN,
};

export function makeBudgetNode({ content }: Pick<BudgetNodeType, 'content'>) {
  return makeNode({
    type: BudgetNodeName,
    content,
    style: defaultBudgetNodeStyle,
  });
}
