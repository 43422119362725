import { createQueryKeys } from '@lukemorales/query-key-factory';

import { creatorAuthenticationService } from '@ll-platform/frontend/creator/auth/async/CreatorAuthenticationService';
import { createMutationHook } from '@ll-platform/frontend/utils/factories/createMutationHook';
import { createQueryHook } from '@ll-platform/frontend/utils/factories/createQueryHook';
import { createQueryOption } from '@ll-platform/frontend/utils/factories/createQueryOption';

const AuthQueries = createQueryKeys('auth', {
  activeCreator: createQueryOption(
    creatorAuthenticationService.getActiveCreator.bind(
      creatorAuthenticationService,
    ),
  ),
});

export const useQueryActiveCreator = createQueryHook(
  AuthQueries.activeCreator,
  {
    retry: 1,
    staleTime: 5 * 60 * 1000,
  },
);

export const useGetJwtByPassword = createMutationHook(
  creatorAuthenticationService.localAuthSignIn.bind(
    creatorAuthenticationService,
  ),
);

export const useRegister = createMutationHook(
  creatorAuthenticationService.register.bind(creatorAuthenticationService),
);

export const useRequestResetPassword = createMutationHook(
  creatorAuthenticationService.requestResetPassword.bind(
    creatorAuthenticationService,
  ),
);

export const useUpdatePassword = createMutationHook(
  creatorAuthenticationService.updatePassword.bind(
    creatorAuthenticationService,
  ),
);
