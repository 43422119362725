import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
  type PropsWithChildren,
} from 'react';

import { noop } from 'lodash-es';
import { Loader } from 'lucide-react';
import { usePrevious } from 'react-use';
import { toast } from 'sonner';

import { useUnmountOnce } from '@ll-platform/frontend/utils/hooks/useStrictModeEffect';
import { assertDefined, truthy } from '@ll-platform/frontend/utils/types/types';

type Claim = string;
type Scope = string;

type LoadingContextType = {
  loadingClaims: Map<Scope, Claim[]>;
  toasts: Map<Scope, string>;
  addLoadingClaim: (scope: Scope, claim: Claim) => void;
  removeLoadingClaim: (scope: Scope, claim: Claim) => void;
  setToast: (scope: Scope, toast: string | null) => void;
  isScopeLoading: (scope: Scope) => boolean;
  isClaimLoading: (scope: Scope, claim: Claim) => boolean;
};

const DEFAULT_CLAIM = 'default';

const LoadingContext = createContext<LoadingContextType>({
  loadingClaims: new Map(),
  addLoadingClaim: noop,
  removeLoadingClaim: noop,
  toasts: new Map(),
  setToast: noop,
  isScopeLoading: () => false,
  isClaimLoading: () => false,
});

LoadingContext.displayName = 'LoadingContext';

export const LoadingContextProvider = ({ children }: PropsWithChildren) => {
  const [loadingClaims, setLoadingClaims] = useState<Map<Scope, Claim[]>>(
    () => new Map(),
  );
  const [toasts, setToasts] = useState<Map<Scope, string>>(() => new Map());

  const addLoadingClaim = useCallback((scope: Scope, claim: Claim) => {
    setLoadingClaims((prev) => {
      const newClaims = new Map(prev);
      const claims = newClaims.get(scope) ?? ([] as Claim[]);
      claims.push(claim ?? DEFAULT_CLAIM);
      newClaims.set(scope, claims);

      return newClaims;
    });
  }, []);

  const removeLoadingClaim = useCallback((scope: Scope, claim: Claim) => {
    setLoadingClaims((prev) => {
      const newClaims = new Map(prev);
      const claims = newClaims.get(scope) ?? ([] as Claim[]);
      newClaims.set(
        scope,
        claims.filter((c) => c !== claim),
      );

      return newClaims;
    });
  }, []);

  const setToast = useCallback((scope: Scope, toast: string | null) => {
    setToasts((prev) => {
      const newToasts = new Map(prev);
      if (toast) {
        newToasts.set(scope, toast);
      } else {
        newToasts.delete(scope);
      }

      return newToasts;
    });
  }, []);

  const isScopeLoading = useCallback(
    (scope: Scope) => {
      const claims = loadingClaims.get(scope) ?? [];

      return claims.length > 0;
    },
    [loadingClaims],
  );

  const isClaimLoading = useCallback(
    (scope: Scope, claim: Claim) => {
      const claims = loadingClaims.get(scope) ?? [];

      return claims.includes(claim);
    },
    [loadingClaims],
  );

  const contextValue = useMemo(
    () => ({
      loadingClaims,
      addLoadingClaim,
      removeLoadingClaim,
      toasts,
      setToast,
      isScopeLoading,
      isClaimLoading,
    }),
    [
      loadingClaims,
      addLoadingClaim,
      removeLoadingClaim,
      toasts,
      setToast,
      isScopeLoading,
      isClaimLoading,
    ],
  );

  const activeToasts = useMemo(() => {
    const scopes = Array.from(loadingClaims.keys());

    const activeToasts = scopes
      .map((scope) => {
        if (isScopeLoading(scope)) {
          return { scope, toast: toasts.get(scope) };
        }

        return null;
      })
      .filter(truthy);

    return activeToasts;
  }, [toasts, isScopeLoading, loadingClaims]);
  const previousToasts = usePrevious(activeToasts);
  const toastsToCleanup = useRef<string[]>([]);

  useEffect(() => {
    const removed =
      previousToasts?.filter(
        (prev) => !activeToasts.find((a) => a.scope === prev.scope),
      ) ?? [];

    removed.forEach((a) => {
      toast.dismiss(a.scope);
    });

    // This also updates messages of existing toasts
    activeToasts.forEach((a) => {
      toast(a.toast, {
        duration: Infinity,
        icon: <Loader className="w-4 h-4 animate-spin" />,
        id: a.scope,
      });
      toastsToCleanup.current.push(a.scope);
    });
  }, [activeToasts, previousToasts]);

  useUnmountOnce(() => {
    toastsToCleanup.current.forEach((scope) => {
      toast.dismiss(scope);
    });
  });

  return (
    <LoadingContext.Provider value={contextValue}>
      {children}
    </LoadingContext.Provider>
  );
};

export function useLoadingContext(scope: Scope, trackedClaim?: Claim) {
  const ctx = useContext(LoadingContext);
  assertDefined(ctx, 'LoadingContext');

  const startLoadingClaim = useCallback(
    (claim = trackedClaim ?? DEFAULT_CLAIM) => {
      ctx.addLoadingClaim(scope, claim);
    },
    [scope, ctx, trackedClaim],
  );

  const stopLoadingClaim = useCallback(
    (claim = trackedClaim ?? DEFAULT_CLAIM) => {
      ctx.removeLoadingClaim(scope, claim);
    },
    [scope, ctx, trackedClaim],
  );

  const isLoading = trackedClaim
    ? ctx.isClaimLoading(scope, trackedClaim)
    : ctx.isScopeLoading(scope);

  return useMemo(
    () => ({
      isLoading,
      startLoadingClaim,
      stopLoadingClaim,
    }),
    [isLoading, startLoadingClaim, stopLoadingClaim],
  );
}

export function useLoadingContextToast(scope: Scope, toast: string | null) {
  const ctx = useContext(LoadingContext);
  assertDefined(ctx, 'LoadingContext');

  useEffect(() => {
    ctx.setToast(scope, toast);
    // Ignore ctx changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scope, toast]);

  return ctx.toasts.get(scope);
}
