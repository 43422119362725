import { merge } from 'lodash-es';

import type { SlideTemplateConfig } from '@ll-platform/frontend/hero/documentBuilder/core/slideTemplates/slideTemplateTypes';
import { makeDefaultImageObject } from '@ll-platform/frontend/hero/documentBuilder/shared/nodes/MediaNode/mediaNodeHelpers';
import { text2ImagesConfig } from '@ll-platform/frontend/hero/documentBuilder/shared/slideTemplates/text2ImagesSlideTemplate';

export const postProductionSlideTemplateDefaults: Partial<
  typeof text2ImagesConfig.templateDefaults
> = {
  text: `<h3>Post-Production Process</h3><p>Explain in one or two concise sentences how your solution transforms users' challenges into positive outcomes. Focus on the end benefits that matter most to your target audience. Keep it clear and compelling.Explain in one or two concise sentences how your solution transforms users' challenges into positive outcomes. Focus on the end benefits ...</p>`.trim(),
  media: [
    makeDefaultImageObject(
      'https://ucarecdn.com/d7366679-d6fe-4a6e-9448-e878775048b8/-/preview/1000x562/',
    ),
    makeDefaultImageObject(
      'https://ucarecdn.com/0703b27c-8ce9-4c9e-9d46-82b14f720d09/',
    ),
  ],
};

const postProductionDefaults = merge(
  text2ImagesConfig.templateDefaults,
  postProductionSlideTemplateDefaults,
);

export const postProductionSlideTemplate: SlideTemplateConfig<
  typeof text2ImagesConfig.templateDefaults
> = {
  creator: {
    name: 'Post-Production Process',
    create: async () => {
      return {
        slides: [text2ImagesConfig.makeDocumentSlide(postProductionDefaults)],
      };
    },
  },
  templateDefaults: postProductionDefaults,
  makeDocumentSlide: text2ImagesConfig.makeDocumentSlide,
};
