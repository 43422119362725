import { memo, useRef } from 'react';

import { Color } from '@tiptap/extension-color';
import { TextAlign } from '@tiptap/extension-text-align';
import { TextStyle } from '@tiptap/extension-text-style';
import { EditorContent, useEditor } from '@tiptap/react';
import { StarterKit } from '@tiptap/starter-kit';

import type {
  DocumentNodeConfig,
  NodeComponentProps,
} from '@ll-platform/frontend/hero/documentBuilder/core/nodes/nodeTypes';

import {
  RichTextNodeName,
  type RichTextNodeContent,
  type RichTextNodeType,
} from './richTextNodeTypes';

import './text.css';

import { Link } from '@shadcn/custom/tiptap/extensions/Link';
import { LinkMenu, TextMenu } from '@shadcn/custom/tiptap/menus';
import { BulletList } from '@tiptap/extension-bullet-list';
import { FontFamily } from '@tiptap/extension-font-family';
import { Highlight } from '@tiptap/extension-highlight';
import { Paragraph } from '@tiptap/extension-paragraph';
import { Subscript } from '@tiptap/extension-subscript';
import { Superscript } from '@tiptap/extension-superscript';
import { Typography } from '@tiptap/extension-typography';
import { Underline } from '@tiptap/extension-underline';
import { useUpdateEffect } from 'react-use';

export const RichTextNode = memo(
  ({ content, onContentChange }: NodeComponentProps<RichTextNodeType>) => {
    const menuContainerRef = useRef(null);
    const editor = useEditor({
      extensions: [
        StarterKit.configure({
          heading: {
            levels: [1, 2, 3],
          },
        }),
        TextAlign.configure({
          types: ['heading', 'paragraph'],
        }),
        TextStyle.configure({ mergeNestedSpanStyles: true }),
        Color,
        Highlight.configure({
          multicolor: true,
        }),
        Underline,
        FontFamily,
        Typography,
        Subscript,
        Superscript,
        Paragraph,
        BulletList,
        Link.configure({
          openOnClick: false,
        }),
      ],
      content: content.text,
      onUpdate: ({ editor }) => {
        onContentChange({
          ...content,
          text: editor.getHTML(),
        });
      },
    });

    useUpdateEffect(() => {
      if (editor && !editor.isFocused) {
        editor.commands.setContent(content.text);
      }
    }, [content.text]);

    return (
      <div className="flex w-full" ref={menuContainerRef}>
        <EditorContent
          editor={editor}
          defaultValue={content.text}
          className="bg-transparent text-black w-full h-full resize-none"
        />
        {editor && <LinkMenu editor={editor} appendTo={menuContainerRef} />}
        {editor && <TextMenu editor={editor} />}
      </div>
    );
  },
);
RichTextNode.displayName = 'RichTextNode';

export const RichTextNodeConfig: DocumentNodeConfig<
  typeof RichTextNodeName,
  RichTextNodeContent
> = {
  type: RichTextNodeName,
  render: (props) => <RichTextNode {...props} />,
};
