import { memo } from 'react';

import { TwSpinner } from '@ll-platform/frontend/components/tw/TwSpinner/TwSpinner';
import {
  useDocumentBuilder,
  useDocumentData,
} from '@ll-platform/frontend/hero/documentBuilder/core/builder/DocumentBuilderContext';
import { DocumentSlideContainer } from '@ll-platform/frontend/hero/documentBuilder/core/slides/DocumentSlideContainer';
import { DocumentSlideProvider } from '@ll-platform/frontend/hero/documentBuilder/core/slides/DocumentSlideContext';

export const DocumentBuilderContent = memo(() => {
  const document = useDocumentData();
  const { isLoading } = useDocumentBuilder();

  return (
    <div className="mx-auto flex flex-col gap-2 items-center px-6 pt-6 pb-20">
      {isLoading ? (
        <TwSpinner className="w-50 h-50" />
      ) : (
        document.slides.map((slide, index) => (
          <DocumentSlideProvider
            key={slide.id}
            slide={slide}
            slideIndex={index}
          >
            <DocumentSlideContainer />
          </DocumentSlideProvider>
        ))
      )}
    </div>
  );
});
DocumentBuilderContent.displayName = 'DocumentBuilderContent';
