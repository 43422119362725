import { useMemo, useRef } from 'react';

import { debounce, type DebounceSettings } from 'lodash-es';

export function useDebounceFn(
  fn: () => unknown,
  options: DebounceSettings & { delay: number },
) {
  const { delay, maxWait, leading = false, trailing = true } = options;
  const fnRef = useRef(fn);
  fnRef.current = fn;

  return useMemo(
    () =>
      debounce(() => fnRef.current(), delay, {
        maxWait,
        leading,
        trailing,
      }),
    [delay, maxWait, leading, trailing],
  );
}
