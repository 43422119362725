import type { User } from '@ll-platform/frontend/core/auth/types';
import { isUserInternal } from '@ll-platform/frontend/features/auth/utils/isInternal';
import { ProjectStyleCreativeBriefEnabledMap } from '@ll-platform/frontend/features/projects/consts/projectStyleCreativeBriefEnabledMap';
import type { ProjectData } from '@ll-platform/frontend/features/projects/types';

export function isProjectAccessible(
  project: Pick<
    ProjectData,
    'externalStatus' | 'style' | 'isCreativeBriefInputFilled'
  >,
  user: User,
) {
  if (isUserInternal(user)) {
    return true;
  }

  if (project.externalStatus) {
    return true;
  }

  if (
    ProjectStyleCreativeBriefEnabledMap[project.style] &&
    project.isCreativeBriefInputFilled
  ) {
    return true;
  }

  return false;
}
