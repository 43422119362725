import { commentRegex } from '@ll-platform/frontend/features/projectComments/consts';
import type { ProjectComment } from '@ll-platform/frontend/features/projectComments/types';
import {
  commentsAnchorsSyncedWithProjectComments,
  parsedCommentAnchorsMap,
} from '@ll-platform/frontend/features/projectComments/utils/commentTextAnchors';
import { makeMarkdownLink } from '@ll-platform/frontend/features/textEditor/serializers/markdown';
import { replaceMarkdownLinks } from '@ll-platform/frontend/features/textEditor/serializers/plainText';
import { TextEditorElementEnum } from '@ll-platform/frontend/features/textEditor/types';
import { defined, typedEntries } from '@ll-platform/frontend/utils/types/types';

export const stripHiddenOutputContent = <T>(output: T): T => {
  if (!defined(output)) {
    return output;
  }

  return removeMarkdownLinks(output) as T;
};

const removeMarkdownLinks = <T>(output: T): T => {
  if (typeof output === 'string') {
    return replaceMarkdownLinks(output) as T;
  }

  if (Array.isArray(output)) {
    return output.map((item) => removeMarkdownLinks(item)) as T;
  }

  if (typeof output === 'object' && output !== null) {
    return Object.fromEntries(
      typedEntries(output).map(([key, value]) => [
        key,
        removeMarkdownLinks(value),
      ]),
    );
  }

  return output;
};

export const getFirstCommentId = (text: string): string | null => {
  const match = commentRegex.exec(text);

  if (match?.[2]) {
    return match[2];
  }

  return null;
};

export const reapplyAgendaTimeComment = (
  text: string,
  commentId: string | null,
): string => {
  if (!commentId) {
    return text;
  }

  return makeMarkdownLink({
    children: [{ text }],
    comment: {
      id: commentId,
    },
    type: TextEditorElementEnum.Comment,
  });
};

export const syncCommentAnchorsWithProjectComments = <T>(
  output: T,
  projectComments: ProjectComment[],
): T => {
  if (!defined(output)) {
    return output;
  }

  const syncedComments = commentsAnchorsSyncedWithProjectComments({
    textCommentsMap: parsedCommentAnchorsMap(output),
    projectComments,
  });

  let stringifiedOutput = JSON.stringify(output);

  Object.values(syncedComments).forEach((comment) => {
    if (!defined(comment.updatedFullCommentString)) {
      return;
    }

    stringifiedOutput = stringifiedOutput.replace(
      comment.fullCommentString.replaceAll('"', '\\"'),
      comment.updatedFullCommentString,
    );
  });

  return JSON.parse(stringifiedOutput);
};
