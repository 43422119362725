import { useState } from 'react';

import { useActiveUser } from '@ll-platform/frontend/features/auth/hooks/useActiveUser';
import { isUserInternal } from '@ll-platform/frontend/features/auth/utils/isInternal';
import {
  useWizardNavigationContext,
  WizardFlowType,
} from '@ll-platform/frontend/features/projectWizard/contexts/WizardNavigationContext';
import { useActiveProject } from '@ll-platform/frontend/features/projectWizard/hooks/useActiveProject';

export const useIsSentimentDialogAvailable = () => {
  const { activeProject } = useActiveProject();
  const { activeUser } = useActiveUser();
  const [projectHasBeenFinishedBefore] = useState(() => {
    return activeProject.clientFirstFinishedPreproductionAt;
  });
  const { flowType } = useWizardNavigationContext();

  return (
    !isUserInternal(activeUser) &&
    flowType === WizardFlowType.Generate &&
    !projectHasBeenFinishedBefore
  );
};
