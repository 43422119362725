import type { PropsWithChildren, ReactNode } from 'react';

import { Stack } from '@mui/material';

import { Sidebar } from '@ll-platform/frontend/components/Sidebar/Sidebar';
import type { ProjectFormMode } from '@ll-platform/frontend/features/internalProjects/pages/ProjectForm/types';

type ProjectFormLayoutProps = PropsWithChildren<{
  stepperComponent: ReactNode;
  mode: ProjectFormMode;
}>;

export const ProjectFormLayout = ({
  children,
  stepperComponent,
  mode,
}: ProjectFormLayoutProps) => {
  const title = `${mode === 'edit' ? 'Edit' : 'New'} Project`;
  const subtitle = `${
    mode === 'edit' ? 'Editing' : 'Adding'
  } a new project is simple and fast.`;

  return (
    <>
      <Stack
        sx={{
          alignItems: 'center',
          width: '100%',
          maxWidth: '100%',
          flexGrow: 1,
          overflowY: 'auto',
        }}
        id="1"
      >
        <Stack
          direction="row"
          width="100%"
          height="100%"
          overflow="hidden"
          flexGrow={1}
          sx={{
            background: (theme) => theme.palette.greyBackground,
          }}
        >
          <Sidebar title={title} subtitle={subtitle} variant="light">
            {stepperComponent}
          </Sidebar>
          {children}
        </Stack>
      </Stack>
    </>
  );
};
