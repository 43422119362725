import type {
  ProjectUserDto,
  ProjectUserInviteDto,
  ProjectUserOrInvite,
} from '@ll-platform/frontend/features/projects/types';

export function isProjectUserInvite(
  anyProjectUser: ProjectUserOrInvite,
): anyProjectUser is ProjectUserInviteDto {
  return 'email' in anyProjectUser && !!anyProjectUser.email;
}

export function isProjectUserRegistered(
  anyProjectUser: ProjectUserOrInvite,
): anyProjectUser is ProjectUserDto {
  return 'user' in anyProjectUser && !!anyProjectUser.user;
}
