import type { AccountType, User } from '@ll-platform/frontend/core/auth/types';

export function filterUsersByType({
  users,
  type,
}: {
  users: User[] | null | undefined;
  type: AccountType;
}): User[] | null {
  return users?.filter((user) => user.accountType === type) ?? null;
}
