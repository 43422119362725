import { useEffect } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, useFormContext } from 'react-hook-form';
import { z } from 'zod';

import { useGetCreativeProposalByProposalId } from '@ll-platform/frontend/hero/creativeProposal/async/useCreativeProposalsQueries';
import { useConceptPageParams } from '@ll-platform/frontend/hero/creativeProposal/concept/hooks/useConceptPageParams';
import type { CreativeProposalInput } from '@ll-platform/frontend/hero/creativeProposal/types';
import { isUrl } from '@ll-platform/frontend/utils/helpers/testUrl';

export const CONCEPT_INPUT_MAX_LENGTH = 10000;
export const WEBSITE_INPUT_MAX_LENGTH = 256;

const ConceptFormSchema = z.object({
  userPrompt: z
    .string({
      // eslint-disable-next-line camelcase
      required_error: 'Prompt is required',
    })
    .max(
      CONCEPT_INPUT_MAX_LENGTH,
      `Prompt cannot exceed ${CONCEPT_INPUT_MAX_LENGTH} characters`,
    ),
  websiteUrls: z
    .array(z.string())
    .default([''])
    .refine((urls) => urls.every((url) => !url || isUrl(url)), {
      message: 'Input should be a valid URL address.',
    }),
});

export type ConceptFormValues = z.infer<typeof ConceptFormSchema>;

export function useConceptFormRoot() {
  const { id } = useConceptPageParams();
  const { data, isFetched } = useGetCreativeProposalByProposalId({
    id,
  });

  const methods = useForm<ConceptFormValues>({
    mode: 'onBlur',
    resolver: zodResolver(ConceptFormSchema),
    defaultValues: {
      userPrompt: '',
      websiteUrls: [''],
    },
  });

  useEffect(() => {
    if (isFetched && data) {
      methods.reset({
        ...data.input,
        websiteUrls: data.input.websiteUrls?.length
          ? data.input.websiteUrls
          : [''],
      });
    }
    // Only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetched]);

  return methods;
}

export function useConceptFormContext() {
  const methods = useFormContext<ConceptFormValues>();

  return methods;
}

export const mapConceptFormToDto = (
  values: ConceptFormValues,
): CreativeProposalInput => {
  return {
    ...values,
    websiteUrls: values.websiteUrls.filter((url) => url),
  };
};
