import { useMemo } from 'react';

import type { StepperStep } from '@ll-platform/frontend/components/CustomStepper/CustomStepper';
import {
  ProjectFormStepLabelMap,
  type ProjectFormSteps,
} from '@ll-platform/frontend/features/internalProjects/pages/ProjectForm/consts';

import { useProjectFormSteps } from './useProjectFormSteps';

type UseProjectFormNavigationProps = {
  goToStep: (step: ProjectFormSteps) => void;
  currentStep?: ProjectFormSteps;
};

export const useProjectFormNavigation = ({
  goToStep,
  currentStep,
}: UseProjectFormNavigationProps) => {
  const steps = useProjectFormSteps();

  const navigationValues = useMemo(() => {
    const currentStepIndex = steps.findIndex((step) => step === currentStep);
    const previousStep = steps[currentStepIndex - 1];
    const nextStep = steps[currentStepIndex + 1];
    const goPrevious = previousStep && (() => goToStep(previousStep));
    const goNext = nextStep && (() => goToStep(nextStep));
    const stepperSteps: StepperStep<ProjectFormSteps>[] = steps.map((step) => ({
      key: step,
      label: ProjectFormStepLabelMap[step],
    }));

    return {
      steps,
      currentStepIndex,
      currentStep,
      previousStep,
      nextStep,
      goPrevious,
      goNext,
      goToStep,
      stepperSteps,
    };
  }, [steps, currentStep, goToStep]);

  return navigationValues;
};
