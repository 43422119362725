import { AxiosError } from 'axios';

import { assertDefined } from '@ll-platform/frontend/utils/types/types';

export function extractErrorMessage({
  error,
  fallbackMessage = 'Unknown error',
}: {
  error: unknown;
  fallbackMessage?: string;
}): string {
  try {
    if (error instanceof AxiosError) {
      if (error?.response?.data) {
        return extractErrorMessage({
          error: error.response.data,
          fallbackMessage,
        });
      }

      return error.message;
    }

    if (error instanceof Error && typeof error.message === 'string') {
      return error.message;
    }

    if (typeof error === 'string') {
      return error;
    }

    if (typeof error === 'object') {
      assertDefined(error, 'error');

      if (Array.isArray(error) && error[0]) {
        return error[0];
      }

      if ('message' in error) {
        if (typeof error.message === 'string') {
          return error.message;
        } else if (typeof error.message === 'object') {
          return extractErrorMessage({ error: error.message, fallbackMessage });
        }
      } else if ('data' in error) {
        return extractErrorMessage({ error: error.data, fallbackMessage });
      }
    }

    return fallbackMessage;
  } catch (e) {
    console.warn('Failed to extract error message', e);

    return fallbackMessage;
  }
}
