import { EmailOutlined } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import { IconButton, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import { Link } from 'react-router-dom';

import { HeroLogo } from '@ll-platform/frontend/components/HeroLogo/HeroLogo';
import { LemonlightLogo } from '@ll-platform/frontend/components/LemonlightLogo/LemonlightLogo';
import { ActiveUserWithMenu } from '@ll-platform/frontend/components/User/ActiveUser/ActiveUserWithMenu';
import { HERO_SUPPORT_EMAIL } from '@ll-platform/frontend/consts/hero';
import { useSelectedTheme } from '@ll-platform/frontend/core/theme/GlobalThemeProvider';

type NavigationBarProps = {
  onHamburgerClick?: () => void;
};

export function NavigationBar({ onHamburgerClick }: NavigationBarProps) {
  const selectedTheme = useSelectedTheme();

  return (
    <Box
      sx={{
        backgroundColor:
          selectedTheme.variant === 'light' ? 'white' : undefined,
        height: '64px',
        flexShrink: 0,
        px: 1,
        borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        zIndex: 1,
      }}
      component="nav"
    >
      <Toolbar
        sx={{
          gap: 2,
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          padding: '0 !important',
        }}
      >
        <Stack direction="row" alignItems="baseline" gap={1}>
          {onHamburgerClick && (
            <IconButton onClick={onHamburgerClick}>
              <MenuIcon />
            </IconButton>
          )}
          <Link to="/">
            {selectedTheme.variant === 'dark' ? (
              <Box pl={2}>
                <HeroLogo />
              </Box>
            ) : (
              <LemonlightLogo />
            )}
          </Link>
        </Stack>
        <Box sx={{ flexGrow: 1 }} />
        <Button
          href={`mailto:${HERO_SUPPORT_EMAIL}`}
          variant="outlined"
          color={'inherit'}
          startIcon={<EmailOutlined />}
          sx={{
            fontWeight: '600',
            textTransform: 'capitalize',
          }}
        >
          Get Assistance
        </Button>
        <ActiveUserWithMenu avatarProps={{ size: 40 }} />
      </Toolbar>
    </Box>
  );
}
