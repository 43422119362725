import * as yup from 'yup';

import { isEmail } from '@ll-platform/frontend/utils/helpers/emails';

// Ensures value is NOT defined
// Useful for conditional schemas for extra safety
export const yupTestNotDefined = {
  name: 'notDefined',
  message: `\${path} must not be defined`,
  test: (value) => value === null || value === undefined,
} satisfies yup.TestConfig;

export const yupTestPasswordStrength = {
  name: 'passwordStrength',
  message: 'Min. 8 characters with uppercase, lowercase and a digit.',
  test: (value) => {
    if (!value || typeof value !== 'string') {
      return false;
    }

    const hasMinLength = value.length >= 8;
    const hasLowerCase = /[a-z]/.test(value);
    const hasUpperCase = /[A-Z]/.test(value);
    const hasDigit = /\d/.test(value);

    return hasMinLength && hasLowerCase && hasUpperCase && hasDigit;
  },
} satisfies yup.TestConfig;

export function yupExtractOptionsFromSchemaDescription<T>(
  schemaDescription: yup.SchemaFieldDescription,
): null | T[] {
  if (!schemaDescription) {
    return null;
  }

  if (schemaDescription.type === 'array') {
    if (!('innerType' in schemaDescription) || !schemaDescription.innerType) {
      return null;
    }

    const innerType = schemaDescription.innerType;

    if (!innerType || !('oneOf' in innerType)) {
      return null;
    }

    return innerType.oneOf as T[];
  } else if (schemaDescription.type === 'string') {
    if (!('oneOf' in schemaDescription) || !schemaDescription.oneOf) {
      return null;
    }

    return schemaDescription.oneOf as T[];
  }

  return null;
}

export const yupMakeFieldsDeepOptional = (
  schema: yup.AnySchema,
): yup.AnySchema => {
  if (schema instanceof yup.ObjectSchema) {
    const shape: Record<string, yup.AnySchema> = {};
    Object.entries(schema.fields).forEach(([key, fieldSchema]) => {
      if (fieldSchema instanceof yup.Schema) {
        shape[key] = yupMakeFieldsDeepOptional(fieldSchema);
      }
    });

    return yup.object().shape(shape);
  }

  if (schema instanceof yup.ArraySchema) {
    return yup
      .array()
      .of(yupMakeFieldsDeepOptional(schema.innerType as yup.AnySchema))
      .optional();
  }

  return schema.notRequired().optional();
};
export const yupUserIdOrEmail = yup
  .string()
  .required()
  .test(
    'email-or-user-id',
    'Enter an email or select existing user ',
    (value) => {
      if (!value) {
        return false;
      }

      return isEmail(value) || yup.string().length(24).isValidSync(value);
    },
  );
