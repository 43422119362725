import { AccountType, type User } from '@ll-platform/frontend/core/auth/types';
import {
  type ReviewEmailTemplateArgs,
  type ReviewEmailTemplateUsers,
} from '@ll-platform/frontend/features/reviews/types';
import { filterUsersByType } from '@ll-platform/frontend/features/users/utils/filterUsers';
import {
  defined,
  typedEntries,
  typedFromEntries,
} from '@ll-platform/frontend/utils/types/types';

const teamUserTypes: (keyof ReviewEmailTemplateUsers['teamUsers'])[] = [
  AccountType.User,
  AccountType.Producer,
  AccountType.Creative,
  AccountType.AccountExecutive,
  AccountType.Editor,
];

export const getReviewEmailUsers = ({
  users,
  recipientsTypes,
}: {
  users: User[];
  recipientsTypes: typeof teamUserTypes;
}): ReviewEmailTemplateArgs => {
  const teamUsers = typedFromEntries(
    teamUserTypes.map((userType) => [
      userType,
      filterUsersByType({
        users,
        type: userType,
      })!,
    ]),
  ) as ReviewEmailTemplateUsers['teamUsers'];

  const recipients = typedFromEntries(
    typedEntries(teamUsers).filter(([key]) => recipientsTypes.includes(key)),
  );

  return {
    reviewEmailTemplateUsers: {
      teamUsers,
      recipientUserIds: Object.values(recipients)
        .flatMap((group) => group.map((user) => user._id))
        .filter(defined),
      // TODO: verify this is correct default recipient
      // We assume brand editors are not included in the recipients list
      defaultRecipient: Object.values(recipients)[0]?.[0] ?? null,
    },
  };
};
