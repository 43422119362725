import { APP_CONFIG } from '@ll-platform/frontend/config/app.config';
import type { FeatureFlags } from '@ll-platform/frontend/config/featureFlags/featureFlags';

export const FEATURE_FLAGS_QUERY_KEY = 'feature-flags';

export enum FeatureFlagsProvider {
  Firebase = 'firebase',
  LaunchDarkly = 'launchDarkly',
}

export const FEATURE_FLAG_OVERRIDES: Partial<FeatureFlags> =
  APP_CONFIG.REACT_APP_FEATURE_FLAG_OVERRIDES ?? {};

// keep in sync with src/features/projects/enums.ts
export enum ScriptedStoryboardVisualStyleEnum {
  Photorealistic = 'photorealistic',
  SketchStyle = 'sketchStyle',
  Illustrated = 'illustrated',
}

// keep in sync with src/features/projects/enums.ts
export enum AnimationStyleEnum {
  Whiteboard = 'whiteboard',
  TypographyIcon = 'typography_icon',
  IconHeavy = 'icon_heavy',
  Cartoon = 'cartoon',
  FullIllustration = 'full_illustration',
}

// keep in sync with src/features/projects/enums.ts
export enum ProjectStyleEnum {
  DocStyle = 'Doc-Style',
  Curated = 'Curated',
  Animated = 'Animated',
  Scripted = 'Scripted',
}
