import { memo, useCallback, useMemo } from 'react';

import { Box, Typography } from '@mui/material';
import { useMediaStore } from '@vidstack/react';
import * as yup from 'yup';

import type { ProjectComment } from '@ll-platform/frontend/features/projectComments/types';
import {
  avatarSize,
  CommentAvatarButton,
} from '@ll-platform/frontend/features/videoPlayer/comments/components/CommentAvatarButton';
import { useGetOnVideoThreads } from '@ll-platform/frontend/features/videoPlayer/comments/hooks/useGetOnVideoThreads';
import type { CommentsPluginConfig } from '@ll-platform/frontend/features/videoPlayer/comments/types';
import { useTypedSearchParams } from '@ll-platform/frontend/utils/hooks/useTypedSearchParams';

type CommentsTrackbarProps = {
  pluginConfig: CommentsPluginConfig;
};

export const CommentsTrackbar = memo(
  ({ pluginConfig }: CommentsTrackbarProps) => {
    const { updateParams } = useTypedSearchParams(
      yup.object({
        commentId: yup.string().optional(),
        t: yup.number().optional(),
      }),
      undefined,
      { stripUnknown: false },
    );

    const { duration } = useMediaStore();

    const { threads } = useGetOnVideoThreads({
      projectId: pluginConfig.projectId,
      deliverableId: pluginConfig.deliverableId,
    });

    const handleClickComment = useCallback(
      (comment: ProjectComment) => {
        updateParams({ commentId: comment.id, t: comment.target.timestamp });
      },
      [updateParams],
    );

    const positionedThreads = useMemo(() => {
      if (!threads) {
        return [];
      }
      const timestampCountMap = new Map<number, number>();

      return [
        ...threads.map((thread) => {
          const roundedTimestamp = Math.round(thread.target.timestamp! * 2) / 2;
          const count = timestampCountMap.get(roundedTimestamp) ?? 0;

          const left = `calc(${(roundedTimestamp / duration) * 100}% + ${5 * count}px)`;

          timestampCountMap.set(roundedTimestamp, count + 1);

          return {
            ...thread,
            left,
          };
        }),
      ].reverse(); // for proper z-order
    }, [duration, threads]);

    return (
      <Box
        sx={{
          my: 0.5,
          height: avatarSize,
          width: '100%',
          position: 'relative',
        }}
        className="comments-trackbar"
      >
        {positionedThreads.map(({ left, ...thread }) => (
          <CommentAvatarButton
            key={thread.id}
            comment={thread}
            sx={{
              position: 'absolute',
              left,
              top: 0,
              transform: 'translateX(-50%)',
              zIndex: 1,
            }}
            onClick={() => handleClickComment(thread)}
          />
        ))}

        {/* Caption to fill in blank space */}
        {!positionedThreads.length &&
          pluginConfig.canAddNewDeliverableComments && (
            <Typography variant="caption" color="white">
              Click on the video to add a comment
            </Typography>
          )}
      </Box>
    );
  },
);

CommentsTrackbar.displayName = 'CommentsTrackbar';
