import { useEffect, type PropsWithChildren } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import Status404 from '@ll-platform/frontend/components/statusPages/Status404';
import SuspenseLoader from '@ll-platform/frontend/components/SuspenseLoader';
import { AnalyticsProvider } from '@ll-platform/frontend/core/analytics/AnalyticsContext';
import { Pages } from '@ll-platform/frontend/core/router/pages';
import { mapProjectToAnalytics } from '@ll-platform/frontend/features/analytics/eventUtils';
import { useActiveUser } from '@ll-platform/frontend/features/auth/hooks/useActiveUser';
import { isUserInternal } from '@ll-platform/frontend/features/auth/utils/isInternal';
import { useGetProjectById } from '@ll-platform/frontend/features/projects/async/useProjectsQueries';
import { isProjectAccessible } from '@ll-platform/frontend/features/projects/utils/isProjectAccessible';
import { useUpdateClientFirstViewedAtMutation } from '@ll-platform/frontend/features/projectWizard/async/useProjectWizardMutations';
import { ActiveProjectContext } from '@ll-platform/frontend/features/projectWizard/contexts/ActiveProjectContext';
import { useIsProposalWizardFlow } from '@ll-platform/frontend/features/proposals/hooks/useIsProposalWizardFlow';

const ActiveProjectContainer = ({ children }: PropsWithChildren) => {
  const { id } = useParams();
  const { activeUser } = useActiveUser();
  const isProposalFlow = useIsProposalWizardFlow();
  const { data: activeProject, isPending } = useGetProjectById({ id });
  const { mutateAsync: updateClientFirstViewedAtMutateAsync } =
    useUpdateClientFirstViewedAtMutation();
  const navigate = useNavigate();

  const isAccessible =
    activeProject && isProjectAccessible(activeProject, activeUser);

  // analytics
  useEffect(() => {
    if (!activeProject || isProposalFlow) {
      return;
    }

    if (!isAccessible) {
      toast.info(
        `Project ${activeProject.title} is not ready for your review yet. We will notify you once it becomes available.`,
      );

      navigate(Pages.DashboardRedirect);

      return;
    }

    if (!activeProject.clientFirstViewedAt && !isUserInternal(activeUser)) {
      updateClientFirstViewedAtMutateAsync({
        projectId: activeProject.id,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeUser, activeProject, isAccessible, isProposalFlow]);

  if (isPending) {
    return <SuspenseLoader />;
  }

  if (!activeProject) {
    return <Status404 />;
  }

  if (!isAccessible) {
    return null;
  }

  return (
    <AnalyticsProvider metadata={mapProjectToAnalytics(activeProject)}>
      <ActiveProjectContext.Provider value={activeProject}>
        {children}
      </ActiveProjectContext.Provider>
    </AnalyticsProvider>
  );
};

export default ActiveProjectContainer;
