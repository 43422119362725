import type { RefObject } from 'react';

import type { MediaPlayerInstance, MediaRemoteControl } from '@vidstack/react';

import { nextTick } from '@ll-platform/frontend/utils/helpers/helpers';
import { assertDefined } from '@ll-platform/frontend/utils/types/types';

export function getPlayer(playerRef: RefObject<MediaPlayerInstance>) {
  assertDefined(playerRef.current, 'playerRef.current');

  return playerRef.current;
}

// When calling pause and seek in the same tick,
// the player state sometimes gets out of sync
export function pauseAndSeek(mediaRemote: MediaRemoteControl, time: number) {
  mediaRemote.pause();
  nextTick(() => mediaRemote.seek(time));
}
