import type { ElementType, ReactNode } from 'react';

import { Stack, type BoxProps } from '@mui/material';

import { NavigationBar } from '@ll-platform/frontend/creator/dashboard/components/NavigationBar';
import { useResponsiveView } from '@ll-platform/frontend/utils/hooks/useResponsiveView';

const DashboardLayoutContentWrapper = ({ children, ...props }: BoxProps) => {
  const { isMobile } = useResponsiveView();

  return (
    <Stack
      flexDirection="column"
      alignItems="center"
      {...props}
      sx={{
        width: '100%',
        maxWidth: '100%',
        flexGrow: 1,
        px: isMobile ? '1rem' : '5vw',
        overflowY: 'auto',
        backgroundColor: (theme) => {
          if (isMobile) {
            return theme.palette.background.paper;
          }

          return 'inherit';
        },
        ...props.sx,
      }}
      component={'main' as ElementType}
    >
      {children}
    </Stack>
  );
};

type DashboardLayoutProps = BoxProps & {
  children: ReactNode;
};

export const DashboardLayout = ({
  children,
  ...props
}: DashboardLayoutProps) => {
  return (
    <>
      <NavigationBar />
      <DashboardLayoutContentWrapper {...props}>
        {children}
      </DashboardLayoutContentWrapper>
    </>
  );
};
