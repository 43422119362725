import { useCallback, useEffect, useMemo, useRef } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { cloneDeep, omit } from 'lodash-es';
import { useForm } from 'react-hook-form';
import type { PartialDeep } from 'type-fest';

import {
  fieldsSpecificToDocAndScriptedStyle,
  fieldsSpecificToDocStyle,
  fieldsSpecificToVideoStyle,
  fieldsSpecificToWithRawFootage,
  fieldsSpecificToWithRawFootageOnly,
  PROJECT_SCHEMA,
  type ContextSpecificField,
  type ProjectFormValues,
} from '@ll-platform/frontend/features/internalProjects/projectForm/projectFormSchema';
import { getDefaultFormValues } from '@ll-platform/frontend/features/internalProjects/projectForm/utils/yupHelpers';
import type {
  ProjectValidationContextType,
  ProjectValidationExternalContextType,
} from '@ll-platform/frontend/features/internalProjects/projectForm/validationContext';
import {
  ProjectDeliverablesEnum,
  ProjectStyleEnum,
} from '@ll-platform/frontend/features/projects/enums';

export const useProjectForm = ({
  initialValue,
  contexts,
}: {
  initialValue: PartialDeep<ProjectFormValues>;
  contexts: ProjectValidationExternalContextType;
}) => {
  const mergedInitialValue = useMemo(() => {
    const defaultValues = getDefaultFormValues(initialValue, contexts);

    return defaultValues;
  }, [initialValue, contexts]);

  const projectDeliverablesRef = useRef<ProjectDeliverablesEnum>(
    mergedInitialValue.basicInfo?.deliverables,
  );

  const projectStyleRef = useRef<ProjectStyleEnum>(
    mergedInitialValue.basicInfo?.style,
  );

  const methods = useForm({
    defaultValues: mergedInitialValue,
    mode: 'onChange',
    resolver: yupResolver<ProjectFormValues>(PROJECT_SCHEMA),
    context: {
      projectDeliverables: projectDeliverablesRef.current,
      projectStyle: projectStyleRef.current,
      ...contexts,
    } satisfies ProjectValidationContextType,
  });

  const getFieldsChangedByContext = ({
    data,
    previousDeliverables,
  }: {
    data: ProjectFormValues;
    previousDeliverables: ProjectDeliverablesEnum;
  }): ContextSpecificField[] | null => {
    const isProjectStyleChanged =
      data.basicInfo?.style !== projectStyleRef.current;
    const isDeliverablesChanged =
      data.basicInfo?.deliverables !== projectDeliverablesRef.current;

    if (isProjectStyleChanged) {
      const changedFields = [
        ...fieldsSpecificToVideoStyle,
        ...([projectStyleRef.current, data.basicInfo?.style].includes(
          ProjectStyleEnum.DocStyle,
        )
          ? fieldsSpecificToDocStyle
          : []),
        ...(![projectStyleRef.current, data.basicInfo?.style].every((style) =>
          [ProjectStyleEnum.DocStyle, ProjectStyleEnum.Scripted].includes(
            style,
          ),
        )
          ? fieldsSpecificToDocAndScriptedStyle
          : []),
      ];

      // WARN: this can return here because change to video style changes the whole form below
      // it should be revisited if the logic changes
      return changedFields;
    }

    if (isDeliverablesChanged) {
      const projectDeliverables = data.basicInfo?.deliverables;

      const changedFields = [
        ...(projectDeliverables &&
        ![
          ProjectDeliverablesEnum.RawFootageAndHeroVideos,
          ProjectDeliverablesEnum.RawFootageOnly,
        ].includes(projectDeliverables)
          ? fieldsSpecificToWithRawFootage
          : []),
        // we need to clear video when switching to or from raw footage only because it gets the fake Raw Footage Video type
        ...([projectDeliverables, previousDeliverables].includes(
          ProjectDeliverablesEnum.RawFootageOnly,
        )
          ? fieldsSpecificToWithRawFootageOnly
          : []),
      ];

      return changedFields;
    }

    return null;
  };

  const resetDefaultValues = useCallback(
    (data: ProjectFormValues) => {
      const projectDeliverables = data.basicInfo?.deliverables;
      const projectStyle = data.basicInfo?.style;
      if (
        projectDeliverables === projectDeliverablesRef.current &&
        projectStyle === projectStyleRef.current
      ) {
        return;
      }

      const changedFields =
        getFieldsChangedByContext({
          data,
          previousDeliverables: projectDeliverablesRef.current,
        }) ?? [];
      const unchangedData = omit(data, changedFields);

      projectDeliverablesRef.current = projectDeliverables;
      projectStyleRef.current = projectStyle;

      const defaultValues = getDefaultFormValues(unchangedData, contexts);

      changedFields.forEach((field) => {
        methods.resetField(field, {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          defaultValue: null,
          keepDirty: false,
          keepTouched: false,
          keepError: false,
        });
      });

      // resetField doesn't work with default values for field array
      // We use .reset to set default values instead, keeping all other state untouched
      methods.reset(cloneDeep(defaultValues), {
        keepDirty: true,
        keepTouched: true,
        keepErrors: true,
      });
    },
    [methods, contexts],
  );

  useEffect(() => {
    const subscription = methods.watch(resetDefaultValues) as unknown;

    return () =>
      typeof subscription === 'object' &&
      subscription &&
      'unsubscribe' in subscription &&
      typeof subscription.unsubscribe === 'function' &&
      subscription.unsubscribe();
  }, [methods, methods.watch, resetDefaultValues]);

  return {
    methods,
  };
};
