import type { DocumentNodeConfig } from '@ll-platform/frontend/hero/documentBuilder/core/nodes/nodeTypes';
import type { DocumentSlide } from '@ll-platform/frontend/hero/documentBuilder/core/slides/slideTypes';
import type { SlideTemplateCreator } from '@ll-platform/frontend/hero/documentBuilder/core/slideTemplates/slideTemplateTypes';

export enum DocumentBuilderViewModeEnum {
  Edit = 'edit',
  View = 'view',
}

export type DocumentData = {
  slides: DocumentSlide[];
  metadata: Record<string, unknown>;
};

export type DocumentDto = DocumentData & {
  id: string;
  createdBy: string;
  createdAt: Date;
  updatedAt: Date;
};

export type DocumentBuilderConfig = {
  documentId: string;
  mode: DocumentBuilderViewModeEnum;
  nodes: DocumentNodeConfig[];
  slideTemplates: SlideTemplateCreator[];
  onSave?: (data: DocumentData) => void;
  onUpdate?: (data: DocumentData) => void;
};

export type DocumentBuilderContextType = {
  config: DocumentBuilderConfig;
  documentId: string;
  mode: DocumentBuilderViewModeEnum;
  availableNodes: DocumentNodeConfig[];
  slideTemplates: SlideTemplateCreator[];
  isInitialized: boolean;
  isLoading: boolean;
  isSaving: boolean;
  isDirty: boolean;
  triggerSave: () => void;
  updateDocument: (updater: (current: DocumentData) => DocumentData) => void;
  setConfig: (config: DocumentBuilderConfig) => void;
};
