import {
  createContext,
  useEffect,
  useMemo,
  type PropsWithChildren,
} from 'react';

import SuspenseLoader from '@ll-platform/frontend/components/SuspenseLoader';
import { FEATURE_FLAG_OVERRIDES } from '@ll-platform/frontend/config/featureFlags/consts';
import {
  defaultFeatureFlags,
  type FeatureFlags,
} from '@ll-platform/frontend/config/featureFlags/featureFlags';
import {
  LaunchDarklyProvider,
  useLaunchDarklyFeatureFlags,
} from '@ll-platform/frontend/core/featureFlags/useLaunchDarklyFeatureFlags';

// For use outside react context. Not reactive!
export let StaticFeatureFlags: FeatureFlags = {
  ...defaultFeatureFlags,
  ...FEATURE_FLAG_OVERRIDES,
};

export const FeatureFlagProvider = (props: PropsWithChildren) => {
  return (
    <LaunchDarklyProvider>
      <AppFlagsProvider {...props} />
    </LaunchDarklyProvider>
  );
};

export const AppFlagsContext = createContext<FeatureFlags>({
  ...defaultFeatureFlags,
  ...FEATURE_FLAG_OVERRIDES,
});
AppFlagsContext.displayName = 'AppFlagsContext';

const AppFlagsProvider = ({ children }: PropsWithChildren) => {
  const launchDarklyFlags = useLaunchDarklyFeatureFlags();

  const mergedFlags = useMemo(
    () => ({
      ...defaultFeatureFlags,
      ...launchDarklyFlags.data,
      ...FEATURE_FLAG_OVERRIDES,
    }),
    [launchDarklyFlags.data],
  );

  StaticFeatureFlags = mergedFlags;

  useEffect(() => {
    if (launchDarklyFlags.isError) {
      console.warn(
        'LaunchDarkly Feature Flags failed to load. Fallback to defaults.',
        launchDarklyFlags.error,
      );
    }
  }, [launchDarklyFlags.error, launchDarklyFlags.isError]);

  if (launchDarklyFlags.isPending) {
    return <SuspenseLoader />;
  }

  return (
    <AppFlagsContext.Provider value={mergedFlags}>
      {children}
    </AppFlagsContext.Provider>
  );
};
