import React, { CSSProperties, forwardRef, HTMLProps } from "react"

import { cn } from "@shadcn/lib/utils"
import { Toggle } from "@shadcn/ui/toggle"

import { Surface } from "./Surface"
import Tooltip from "./Tooltip"

export type ToolbarWrapperProps = {
  shouldShowContent?: boolean
  isVertical?: boolean
} & HTMLProps<HTMLDivElement>

const ToolbarWrapper = forwardRef<HTMLDivElement, ToolbarWrapperProps>(
  (
    {
      shouldShowContent = true,
      children,
      isVertical = false,
      className,
      ...rest
    },
    ref
  ) => {
    const toolbarClassName = cn(
      "text-black dark:text-white inline-flex h-full leading-none gap-0.5",
      isVertical ? "flex-col p-2" : "flex-row p-1 items-center",
      className
    )

    return (
      shouldShowContent && (
        <Surface className={toolbarClassName} {...rest} ref={ref}>
          {children}
        </Surface>
      )
    )
  }
)

ToolbarWrapper.displayName = "Toolbar"

export type ToolbarDividerProps = {
  horizontal?: boolean
} & HTMLProps<HTMLDivElement>

const ToolbarDivider = forwardRef<HTMLDivElement, ToolbarDividerProps>(
  ({ horizontal, className, ...rest }, ref) => {
    const dividerClassName = cn(
      "bg-neutral-200 dark:bg-neutral-800",
      horizontal
        ? "w-full min-w-[1.5rem] h-[1px] my-1 first:mt-0 last:mt-0"
        : "h-full min-h-[1.5rem] w-[1px] mx-1 first:ml-0 last:mr-0",
      className
    )

    return <div className={dividerClassName} ref={ref} {...rest} />
  }
)

ToolbarDivider.displayName = "Toolbar.Divider"

export type ToolbarButtonProps = React.ComponentProps<typeof Toggle> & {
  active?: boolean
  activeStyle?: CSSProperties
  tooltip?: string
  tooltipShortcut?: string[]
}

const ToolbarButton = forwardRef<HTMLButtonElement, ToolbarButtonProps>(
  (
    {
      children,
      size = "default",
      variant = "default",
      className,
      tooltip,
      tooltipShortcut,
      activeStyle,
      active,
      ...rest
    },
    ref
  ) => {
    const buttonClass = cn(["gap-1 min-w-[2.5rem] px-2 w-auto", className])

    const content = (
      <Toggle
        className={buttonClass}
        pressed={active}
        variant={variant}
        size={size}
        style={active ? activeStyle : undefined}
        ref={ref}
        {...rest}
      >
        {children}
      </Toggle>
    )

    if (tooltip) {
      return (
        // shadcn/ui/Tooltip doesn't work well in nested popups
        <Tooltip title={tooltip} shortcut={tooltipShortcut}>
          {content}
        </Tooltip>
      )
    }

    return content
  }
)

ToolbarButton.displayName = "ToolbarButton"

export const Toolbar = {
  Wrapper: ToolbarWrapper,
  Divider: ToolbarDivider,
  Button: ToolbarButton,
}
