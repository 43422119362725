import type { PropsWithChildren } from 'react';

import { WizardTopBar } from '@ll-platform/frontend/features/projectWizard/components/topBar/WizardTopBar';

type WizardLayoutProps = PropsWithChildren;

export const WizardLayout = ({ children }: WizardLayoutProps) => {
  return (
    <>
      <WizardTopBar />
      {children}
    </>
  );
};
