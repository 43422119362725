import { memo } from 'react';

import { AddSlideButton } from '@ll-platform/frontend/hero/documentBuilder/core/slides/actionBars/AddSlideButton';

export const BottomSlideActionsBar = memo(() => {
  return (
    <div className="flex justify-center py-2 px-4">
      <AddSlideButton />
    </div>
  );
});
BottomSlideActionsBar.displayName = 'BottomSlideActionsBar';
