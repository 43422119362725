import { LoaderCircle } from 'lucide-react';

import { cn } from '@ll-platform/frontend/components/shadcn/lib/utils';

export const TwSpinner = ({
  wrapperClassName,
  className,
}: {
  wrapperClassName?: string;
  className?: string;
}) => {
  return (
    <div
      className={cn(
        'flex h-screen items-center justify-center',
        wrapperClassName,
      )}
    >
      <LoaderCircle className={cn('h-4 w-4 animate-spin', className)} />
    </div>
  );
};
