// Returns seconds
import dayjs from 'dayjs';
import type { Duration } from 'dayjs/plugin/duration';

export function parseDurationWithColon(durationString: string): number | null {
  try {
    const parts = durationString
      .split(':')
      .map((part) => part.trim())
      .reverse();
    let seconds = 0;

    if (parts.length > 4) {
      throw new Error('Invalid duration format');
    }

    for (let i = 0; i < parts.length; i++) {
      const value = parseInt(parts[i], 10);
      if (Number.isNaN(value)) {
        throw new Error('Invalid number in duration');
      }

      seconds += value * Math.pow(60, i);
    }

    return seconds;
  } catch (e) {
    console.warn(e);

    return null;
  }
}

export function formatSecondsDurationWithColon(
  durationInSeconds: number | null | undefined,
): string {
  if (
    durationInSeconds === undefined ||
    durationInSeconds === null ||
    Number.isNaN(Number(durationInSeconds))
  ) {
    return `--:--`;
  }

  return formatDurationWithColon(dayjs.duration(durationInSeconds, 'seconds'));
}

export function formatDurationWithColon(duration: Duration): string {
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();

  const parts: string[] = [];

  if (hours > 0) {
    parts.push(`${hours}`.padStart(2, '0'));
  }

  parts.push(`${minutes || 0}`.padStart(2, '0'));
  parts.push(`${seconds || 0}`.padStart(2, '0'));

  return parts.join(':');
}
