import { Element, Range, Transforms, type Editor } from 'slate';

import {
  TextEditorElementEnum,
  type LinkElement,
} from '@ll-platform/frontend/features/textEditor/types';

import {
  getCurrentLinkNode,
  getCurrentLinkPath,
  isLinkActive,
} from './linkQueries';

export const updateLink = (editor: Editor, url: string) => {
  const isCollapsed = !!editor.selection && Range.isCollapsed(editor.selection);

  if (isCollapsed) {
    return;
  }

  const currentLinkNode = getCurrentLinkNode(editor);
  const currentLinkLocation = getCurrentLinkPath(editor);

  if (!currentLinkNode) {
    return;
  }

  const updatedLinkNode: LinkElement = {
    ...currentLinkNode,
    url,
  };

  Transforms.setNodes(editor, updatedLinkNode, {
    at: currentLinkLocation,
  });
};

export const unwrapLink = (editor: Editor) => {
  Transforms.unwrapNodes(editor, {
    match: (n) => Element.isElement(n) && n.type === TextEditorElementEnum.Link,
  });
};

export const wrapLink = (editor: Editor, url: string) => {
  const isCollapsed = !!editor.selection && Range.isCollapsed(editor.selection);
  const node: LinkElement = {
    type: TextEditorElementEnum.Link,
    url,
    children: isCollapsed ? [{ text: url }] : [],
  };

  if (isLinkActive(editor)) {
    unwrapLink(editor);
  }

  if (isCollapsed) {
    Transforms.insertNodes(editor, node);
  } else {
    Transforms.wrapNodes(editor, node, { split: true });
    Transforms.collapse(editor, { edge: 'end' });
  }
};

export const insertLink = (editor: Editor, url: string) => {
  if (editor.selection) {
    wrapLink(editor, url);
  }
};
