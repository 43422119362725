import { useMemo } from 'react';

import {
  useQueries,
  type UseQueryOptions,
  type UseQueryResult,
} from '@tanstack/react-query';

import type { $TSFixMe } from '@ll-platform/frontend/utils/types/types';

export function useBatchQuery<T>(
  queries: UseQueryOptions<T, unknown, unknown, $TSFixMe>[],
  queryOptions?: Omit<
    UseQueryOptions<T, unknown, unknown, $TSFixMe>,
    'queryKey' | 'queryFn'
  >,
): {
  isPending: boolean;
  isLoading: boolean;
  isFetching: boolean;
  isFetched: boolean;
  isSuccess: boolean;
  isError: boolean;
  data: (T | undefined)[] | undefined;
  queriesResults: UseQueryResult<T>[];
  queries: UseQueryOptions<T, unknown, unknown, $TSFixMe>[];
  refetch: () => Promise<UseQueryResult<T>[]>;
} {
  const queriesResults = useQueries({
    queries: queries.map((query) => ({
      ...query,
      ...queryOptions,
    })),
  });

  const results = useMemo(() => {
    let isPending = false;
    let isLoading = false;
    let isFetching = false;
    let isFetched = true;
    let isSuccess = true;
    let isError = false;

    queriesResults.forEach((queryResult) => {
      if (queryResult.isPending) {
        isPending = true;
      }
      if (queryResult.isLoading) {
        isLoading = true;
      }
      if (queryResult.isFetching) {
        isFetching = true;
      }
      if (!queryResult.isFetched) {
        isFetched = false;
      }
      if (!queryResult.isSuccess) {
        isSuccess = false;
      }
      if (queryResult.isError) {
        isError = true;
      }
    });

    return {
      isPending,
      isLoading,
      isFetching,
      isFetched,
      isSuccess,
      isError,
      data: isLoading
        ? undefined
        : queriesResults.map((queryResult) =>
            queryResult.status === 'success' ||
            (queryResult.isPlaceholderData && queryResult.data)
              ? queryResult.data
              : undefined,
          ),
      queries,
      queriesResults,
      refetch: () =>
        Promise.all(queriesResults.map((queryResult) => queryResult.refetch())),
    };
  }, [queriesResults, queries]);

  return results;
}
