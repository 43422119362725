import { useMemo } from 'react';

import { useGetDeliverableVideoReviewComments } from '@ll-platform/frontend/features/projectComments/async/useProjectCommentsQueries';
import { useFilterCommentThreads } from '@ll-platform/frontend/features/projectComments/hooks/useFilterCommentThreads';
import { videoCommentsComparator } from '@ll-platform/frontend/features/videoPlayer/comments/utils/videoCommentsComparator';
import { defined } from '@ll-platform/frontend/utils/types/types';

export function useGetVideoThreadIndexes({
  projectId,
  deliverableId,
}: {
  projectId: string;
  deliverableId: string;
}) {
  // this query should already be cached
  const commentsQuery = useGetDeliverableVideoReviewComments({
    projectId,
    deliverableId,
  });

  const threads = useFilterCommentThreads({
    comments: commentsQuery.data,
  });

  const indexes = useMemo(() => {
    const unresolvedPositionedThreads = threads?.filter(
      (thread) => !thread.isResolved && defined(thread.target.position),
    );

    unresolvedPositionedThreads?.sort(videoCommentsComparator);

    return Object.fromEntries(
      unresolvedPositionedThreads?.map((thread, i) => [thread.id, i]) ?? [],
    );
  }, [threads]);

  return { indexes };
}
