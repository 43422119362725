import { useCallback } from 'react';

import { useMutationState } from '@tanstack/react-query';

import { FeatureFlagName } from '@ll-platform/frontend/config/featureFlags/featureFlags';
import { activityTracker } from '@ll-platform/frontend/core/analytics/activityTracker';
import { ActivityType } from '@ll-platform/frontend/core/analytics/events';
import { useAppFlags } from '@ll-platform/frontend/core/featureFlags/useAppFlags';
import { mapProjectToAnalytics } from '@ll-platform/frontend/features/analytics/eventUtils';
import { useActiveUser } from '@ll-platform/frontend/features/auth/hooks/useActiveUser';
import { isUserInternal } from '@ll-platform/frontend/features/auth/utils/isInternal';
import { useGetBrandByProjectId } from '@ll-platform/frontend/features/brands/async/useBrandsQueries';
import {
  useNotifyProjectFinalizationMutation,
  useNotifyProjectFinalizationMutationKey,
} from '@ll-platform/frontend/features/productionDocs/async/useProductionDocsMutations';
import { useUpdateProjectFields } from '@ll-platform/frontend/features/projects/async/useProjectsMutations';
import { ProjectStyleCreativeBriefEnabledMap } from '@ll-platform/frontend/features/projects/consts/projectStyleCreativeBriefEnabledMap';
import {
  ExternalProjectStatus,
  ProjectStatus,
  ProjectStyleEnum,
} from '@ll-platform/frontend/features/projects/enums';
import {
  useUpdateClientFirstFinishedPreproductionAtMutation,
  useUpdateSuccessfullyFirstFinalizedPreproductionAtMutation,
} from '@ll-platform/frontend/features/projectWizard/async/useProjectWizardMutations';
import { WizardStepLabelMap } from '@ll-platform/frontend/features/projectWizard/consts/wizardSteps';
import {
  useWizardNavigationContext,
  WizardFlowType,
} from '@ll-platform/frontend/features/projectWizard/contexts/WizardNavigationContext';
import { useActiveProject } from '@ll-platform/frontend/features/projectWizard/hooks/useActiveProject';
import { useSentimentDialog } from '@ll-platform/frontend/features/projectWizard/pages/steps/finalize/sentimentDialog/useSentimentDialog';
import { useFinalizeEdit } from '@ll-platform/frontend/features/projectWizard/pages/steps/finalizeEdit/useFinalizeEdit';
import {
  makeReviewWizardFlowKey,
  reviewWizardFlowKey,
} from '@ll-platform/frontend/features/projectWizard/reviews/reviewWizardFlowKeys';
import { useReviewWizardFlow } from '@ll-platform/frontend/features/projectWizard/reviews/useReviewWizardFlow';
import { useRequestReview } from '@ll-platform/frontend/features/reviews/async/useReviewsMutations';
import type {
  CreateReviewDto,
  ReviewEmailData,
} from '@ll-platform/frontend/features/reviews/types';
import { mapUnknownToDate } from '@ll-platform/frontend/utils/helpers/date';
import { assertDefined } from '@ll-platform/frontend/utils/types/types';

type UseFinalizeWizardArgs = {
  onFinalize?: () => void;
};

export const useFinalizeWizard = ({
  onFinalize,
}: UseFinalizeWizardArgs = {}) => {
  const { flowType, currentStep } = useWizardNavigationContext();
  const { activeUser } = useActiveUser();
  const { activeProject } = useActiveProject();
  const isInternalUser = isUserInternal(activeUser);
  const flags = useAppFlags();

  const notificationType = reviewWizardFlowKey({
    flowType,
    user: activeUser,
    isNewFlowEnabled: !!flags[FeatureFlagName.NewWizardFlow],
  });

  const getBrandByProjectIdQuery = useGetBrandByProjectId({
    projectId: activeProject.id,
  });
  const brandId = getBrandByProjectIdQuery.data?._id;

  const { mutateAsync: mutateUpdateProjectFieldsAsync } =
    useUpdateProjectFields();
  const updateClientFirstFinishedPreproductionAtMutation =
    useUpdateClientFirstFinishedPreproductionAtMutation();
  const { mutateAsync: updateSuccessfullyFirstFinalizedPreproductionAt } =
    useUpdateSuccessfullyFirstFinalizedPreproductionAtMutation();
  const {
    mutateAsync: mutateRequestReviewAsync,
    isSuccess: isRequestReviewSuccess,
  } = useRequestReview();
  const {
    mutateAsync: mutateNotifyScriptedFinalizationMutationAsync,
    reset: resetNotifyScriptedFinalization,
    isSuccess: isNotifyScriptedFinalizationSuccess,
  } = useNotifyProjectFinalizationMutation({
    meta: {
      supressErrorToast: true,
    },
  });
  // subscribe to mutation state to make the state available in all components using the hook even if the mutation was called by a different component
  const finalizeNonScriptedRequestState = useMutationState({
    filters: { mutationKey: useNotifyProjectFinalizationMutationKey },
  }).at(-1);
  const finalizeWithoutCreativeBriefRequestState = useMutationState({
    filters: { mutationKey: useNotifyProjectFinalizationMutationKey },
  }).at(-1);
  const finalizeRequestState =
    activeProject.style === ProjectStyleEnum.Scripted
      ? finalizeWithoutCreativeBriefRequestState
      : finalizeNonScriptedRequestState;

  const { finalizeEdit } = useFinalizeEdit();
  const sentimentDialog = useSentimentDialog({
    modalTriggerFlag:
      activeProject.style === ProjectStyleEnum.Scripted
        ? isNotifyScriptedFinalizationSuccess
        : isRequestReviewSuccess,
    onFinalize,
  });

  const handleFinishedAnalytics = useCallback(() => {
    if (
      !isInternalUser &&
      activeProject.clientFirstViewedAt &&
      !activeProject.clientFirstFinishedPreproductionAt
    ) {
      updateClientFirstFinishedPreproductionAtMutation.mutateAsync({
        projectId: activeProject.id,
      });

      const firstViewedAt = mapUnknownToDate(activeProject.clientFirstViewedAt);
      const duration = (Date.now() - firstViewedAt!.getTime()) / 1000;

      activityTracker.log({
        type: ActivityType.WizardFinishedPreproSteps,
        metadata: {
          duration,
          ...mapProjectToAnalytics(activeProject),
        },
      });
    }
  }, [
    activeProject,
    updateClientFirstFinishedPreproductionAtMutation,
    isInternalUser,
  ]);

  const approveAndSendScriptedNotification = useCallback(async () => {
    await mutateNotifyScriptedFinalizationMutationAsync({
      projectId: activeProject.id,
    });
  }, [mutateNotifyScriptedFinalizationMutationAsync, activeProject.id]);

  const finalizeCreativeBriefFlow = useCallback(
    async (reviewEmailData: ReviewEmailData) => {
      assertDefined(brandId, 'brandId');
      const projectId = activeProject.id;
      const requestPayload = {
        brandId,
        isPingOnly: true,
        email: reviewEmailData,
        reviewKey: makeReviewWizardFlowKey({
          projectId,
          reviewNotificationType: notificationType,
        }),
      } satisfies CreateReviewDto;

      switch (flowType) {
        case WizardFlowType.EditCreativeDeck:
        case WizardFlowType.EditCallSheet: {
          const finalizeCallback = async () => {
            await mutateRequestReviewAsync(requestPayload);
          };

          return await finalizeEdit(finalizeCallback);
        }

        case WizardFlowType.CreativeBrief: {
          await mutateRequestReviewAsync(requestPayload);

          await mutateUpdateProjectFieldsAsync({
            id: projectId,
            data: {
              isCreativeBriefInputFilled: true,
              status: ProjectStatus.VideoSummary,
            },
          });

          break;
        }

        case WizardFlowType.Generate: {
          await mutateRequestReviewAsync(requestPayload);

          if (
            !activeProject.successfullyFirstFinalizedPreproductionAt &&
            !isInternalUser
          ) {
            updateSuccessfullyFirstFinalizedPreproductionAt({
              projectId,
            });
          }

          if (!activeProject.externalStatus) {
            await mutateUpdateProjectFieldsAsync({
              id: projectId,
              data: {
                externalStatus: ExternalProjectStatus.VideoSummary,
              },
            });
          } else {
            // for legacy projects started before Creative Brief flow
            await mutateUpdateProjectFieldsAsync({
              id: projectId,
              data: {
                externalStatus: ExternalProjectStatus.VideoSummary,
                isCreativeBriefInputFilled: true, // get legacy projects on track with the new flow
              },
            });
          }

          break;
        }
      }

      if (!sentimentDialog.isEnabled) {
        onFinalize?.();
      } // otherwise onFinalize is called by sentimentDialog
    },
    [
      isInternalUser,
      activeProject,
      flowType,
      finalizeEdit,
      updateSuccessfullyFirstFinalizedPreproductionAt,
      mutateUpdateProjectFieldsAsync,
      onFinalize,
      sentimentDialog.isEnabled,
      brandId,
      notificationType,
      mutateRequestReviewAsync,
    ],
  );

  const finalizeWithoutCreativeBrief = useCallback(async () => {
    const projectId = activeProject.id;

    switch (flowType) {
      case WizardFlowType.EditCreativeDeck:
      case WizardFlowType.EditCallSheet: {
        return await finalizeEdit();
      }

      case WizardFlowType.CreativeBrief: {
        throw new Error('Creative Brief input for Scripted is not supported');
      }

      case WizardFlowType.Generate: {
        await approveAndSendScriptedNotification();

        if (
          !activeProject.successfullyFirstFinalizedPreproductionAt &&
          !isInternalUser
        ) {
          updateSuccessfullyFirstFinalizedPreproductionAt({
            projectId,
          });
        }

        if (!activeProject.externalStatus) {
          await mutateUpdateProjectFieldsAsync({
            id: projectId,
            data: {
              externalStatus: ExternalProjectStatus.ScriptedVideoSummary,
            },
          });
        }

        break;
      }
    }

    if (!sentimentDialog.isEnabled) {
      onFinalize?.();
    } // otherwise onFinalize is called by sentimentDialog
  }, [
    isInternalUser,
    activeProject,
    flowType,
    finalizeEdit,
    updateSuccessfullyFirstFinalizedPreproductionAt,
    mutateUpdateProjectFieldsAsync,
    approveAndSendScriptedNotification,
    onFinalize,
    sentimentDialog.isEnabled,
  ]);

  const notifyForReviewDialog = useReviewWizardFlow({
    onSubmit: finalizeCreativeBriefFlow,
    notificationType,
    page: currentStep ? WizardStepLabelMap[currentStep] : '',
  });

  const handleClickFinalize = useCallback(async () => {
    if (flowType === WizardFlowType.Generate) {
      activityTracker.log({
        type: ActivityType.WizardFinalizeClickFinalize,
        metadata: mapProjectToAnalytics(activeProject),
      });

      handleFinishedAnalytics();
    }

    if (ProjectStyleCreativeBriefEnabledMap[activeProject.style]) {
      notifyForReviewDialog.openNotifyModal();
    } else {
      await finalizeWithoutCreativeBrief();
    }
  }, [
    notifyForReviewDialog,
    activeProject,
    flowType,
    handleFinishedAnalytics,
    finalizeWithoutCreativeBrief,
  ]);

  return {
    onClickFinalize: handleClickFinalize,
    sentimentDialog,
    notifyForReviewDialog,
    resetNotifyScriptedFinalization,
    finalizeRequestState,
  };
};
