import { ActivityType } from '@ll-platform/frontend/core/analytics/events';
import { AccountType, type User } from '@ll-platform/frontend/core/auth/types';
import { type BrandWithOrganization } from '@ll-platform/frontend/features/brands/types';
import type { ProjectWithDeliverables } from '@ll-platform/frontend/features/projects/types';
import { creativeBriefInternalEmail } from '@ll-platform/frontend/features/projectWizard/reviews/emailTemplates/creativeBriefInternalEmail';
import { ReviewModalTitle } from '@ll-platform/frontend/features/reviews/consts';
import type { ReviewModalTemplate } from '@ll-platform/frontend/features/reviews/types';
import { getReviewEmailUsers } from '@ll-platform/frontend/features/reviews/utils/getReviewEmailUsers';
import type { Nullable } from '@ll-platform/frontend/utils/types/types';

import { reviewWizardAnalyticsMetadata } from './common/reviewWizardAnalyticsMetadata';
import { reviewWizardToastParams } from './common/reviewWizardTexts';

type CreativeBriefInternalModalTemplateArgs = {
  users: User[];
  activeUser: User;
  brand: Nullable<BrandWithOrganization>;
  project?: ProjectWithDeliverables;
  page: string;
};

export const creativeBriefInternalModalTemplate = ({
  brand,
  users,
  project,
  activeUser,
  page,
}: CreativeBriefInternalModalTemplateArgs): ReviewModalTemplate => {
  return {
    reviewEmailData: creativeBriefInternalEmail({
      ...getReviewEmailUsers({
        users,
        recipientsTypes: [
          AccountType.Producer,
          AccountType.Creative,
          AccountType.AccountExecutive,
        ],
      }),
      project,
      brand,
    }),
    titleContent: ReviewModalTitle.notifyProducerForReview,
    warningContent:
      "You are about to submit the creative input for the producer's review. Before submitting, please review all details with the client during your Account Setup Call.",
    headingContent: '',
    toastParams: reviewWizardToastParams,
    analyticsMetadata: reviewWizardAnalyticsMetadata({
      submitType: ActivityType.CreativeBriefInputClickedSubmitForReview,
      cancelType: ActivityType.CreativeBriefInputCanceledSubmittingForReview,
      activeUser,
      page,
      project,
    }),
  };
};
