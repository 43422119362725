import { useQueryClient } from '@tanstack/react-query';

import { activityTracker } from '@ll-platform/frontend/core/analytics/activityTracker';
import { ActivityType } from '@ll-platform/frontend/core/analytics/events';
import { creatorAuthenticationService } from '@ll-platform/frontend/creator/auth/async/CreatorAuthenticationService';
import type { Creator } from '@ll-platform/frontend/creator/auth/types';
import { CreatorPages } from '@ll-platform/frontend/creator/pages';

export function useCreatorLogOut({
  activeCreator,
}: {
  activeCreator?: Creator;
} = {}) {
  const queryClient = useQueryClient();

  return ({
    redirect = CreatorPages.Login,
  }: { redirect?: string | false } = {}) => {
    if (activeCreator) {
      activityTracker.log(ActivityType.LogoutUser);
    }

    sessionStorage.clear();
    creatorAuthenticationService.clearUserTokens();

    if (redirect) {
      // Load the page from scratch
      window.location.href = redirect;
    } else {
      queryClient.resetQueries();
    }
  };
}
