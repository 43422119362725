import { CommentsTrackbar } from '@ll-platform/frontend/features/videoPlayer/comments/components/CommentsTrackbar';
import { CommentsVideoOverlay } from '@ll-platform/frontend/features/videoPlayer/comments/components/CommentsVideoOverlay';
import { commentsStyleOverrides } from '@ll-platform/frontend/features/videoPlayer/comments/styles';
import type { CommentsPluginConfig } from '@ll-platform/frontend/features/videoPlayer/comments/types';
import type { PluginConfigFactory } from '@ll-platform/frontend/features/videoPlayer/types';

export const withVideoCommentsPlugin: PluginConfigFactory<
  CommentsPluginConfig
> = (config, pluginConfig) => {
  config.layoutConfig.children.push(
    <CommentsVideoOverlay pluginConfig={pluginConfig} />,
  );
  config.layoutConfig.slots ??= {};
  config.layoutConfig.slots.beforeTimeSlider = (
    <>
      {config.layoutConfig.slots.beforeTimeSlider}
      <CommentsTrackbar pluginConfig={pluginConfig} />
    </>
  );

  config.styleOverrides.push(commentsStyleOverrides);

  return config;
};
