import { useState, type PropsWithChildren } from 'react';

import { Stack } from '@mui/material';
import { useUpdateEffect } from 'react-use';

import { DashboardLayoutContentWrapper } from '@ll-platform/frontend/components/layouts/DashboardLayout/DashboardLayout';
import { NavigationBar } from '@ll-platform/frontend/components/NavigationBar';
import { activityTracker } from '@ll-platform/frontend/core/analytics/activityTracker';
import { ActivityType } from '@ll-platform/frontend/core/analytics/events';
import { useUpdateActiveUserSettings } from '@ll-platform/frontend/features/auth/userSettings/async/useUserSettingsMutations';
import { useGetActiveUserSettings } from '@ll-platform/frontend/features/auth/userSettings/async/useUserSettingsQueries';
import { SidebarNavigation } from '@ll-platform/frontend/features/internalDashboard/components/SidebarNavigation/SidebarNavigation';

export const InternalDashboardLayout = ({ children }: PropsWithChildren) => {
  const { data: userSettings } = useGetActiveUserSettings();
  const userSettingsMutation = useUpdateActiveUserSettings({
    meta: { supressErrorToast: true },
  });
  const [isSidebarOpen, setIsSidebarOpen] = useState(
    !!userSettings.isInternalDashboardSidebarOpen,
  );

  useUpdateEffect(() => {
    userSettingsMutation.mutateAsync({
      isInternalDashboardSidebarOpen: isSidebarOpen,
    });
  }, [isSidebarOpen]);

  return (
    <>
      <NavigationBar
        onHamburgerClick={() => {
          setIsSidebarOpen((current) => {
            activityTracker.log({
              type: ActivityType.DashboardClickedHamburger,
              metadata: {
                action: current ? 'close' : 'open',
              },
            });

            return !current;
          });
        }}
      />
      <Stack
        direction="row"
        sx={{ height: '100%', minHeight: 0, position: 'relative', zIndex: 0 }}
      >
        <SidebarNavigation isOpen={isSidebarOpen} />
        <DashboardLayoutContentWrapper sx={{ py: 2 }}>
          {children}
        </DashboardLayoutContentWrapper>
      </Stack>
    </>
  );
};
