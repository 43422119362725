import type { ReactNode } from 'react';

import type { SxProps, Theme } from '@mui/material';
import type { MediaPlayerProps, VideoSrc } from '@vidstack/react';
import type { DefaultVideoLayoutProps } from '@vidstack/react/types/vidstack-react.js';
import type { EmptyObject, SetOptional, SetRequired } from 'type-fest';

import type { CommentsPluginConfig } from '@ll-platform/frontend/features/videoPlayer/comments/types';

export enum VideoPlayerPlugins {
  Comments = 'comments',
  ControlsBelowPlayer = 'controlsBelowPlayer',
}

type PluginsConfig = {
  [VideoPlayerPlugins.Comments]: CommentsPluginConfig;
  [VideoPlayerPlugins.ControlsBelowPlayer]: EmptyObject;
};

// https://www.vidstack.io/docs/player/components/core/player
// We don't need rest of the props for now
export type BaseVideoPlayerConfig = {
  playerConfig: Pick<
    MediaPlayerProps,
    | 'autoFocus'
    | 'autoplay'
    | 'controlsDelay'
    | 'hideControlsOnMouseLeave'
    | 'keyDisabled'
    | 'keyShortcuts'
    | 'keyTarget'
    | 'load'
    | 'logLevel'
    | 'muted'
    | 'playsInline'
    | 'crossOrigin'
    | 'poster'
    | 'preload'
    | 'title'
    | 'artist'
    | 'aspectRatio'
    | 'loop'
    | 'onContextMenu'
  > & {
    // Please specify the width and height of the source when possible
    // it helps with performance of auto-quality selection
    src?: VideoSrc[];
  };
} & {
  layoutConfig: Pick<
    DefaultVideoLayoutProps,
    | 'colorScheme'
    | 'download'
    | 'icons'
    | 'showTooltipDelay'
    | 'showMenuDelay'
    | 'smallLayoutWhen'
    | 'hideQualityBitrate'
    | 'playbackRates'
    | 'thumbnails'
    | 'menuGroup'
    | 'noAudioGain'
    | 'noModal'
    | 'noScrubGesture'
    | 'noKeyboardAnimations'
    | 'seekStep'
    | 'slots'
  > & {
    children: ReactNode[];
  };
  styleOverrides: SxProps<Theme>[];
  pluginsConfig?: {
    [K in VideoPlayerPlugins]?: PluginsConfig[K] | false;
  };
};

export type VideoPlayerConfig = {
  playerConfig: SetRequired<BaseVideoPlayerConfig['playerConfig'], 'src'>;
  layoutConfig?: SetOptional<
    Omit<BaseVideoPlayerConfig['layoutConfig'], 'smallLayoutWhen'>,
    'icons' | 'children'
  >;
  styleOverrides?: BaseVideoPlayerConfig['styleOverrides'];
  pluginsConfig?: BaseVideoPlayerConfig['pluginsConfig'];
};

export type PluginConfigFactory<
  T extends Record<string, unknown> = Record<string, unknown>,
> = (config: BaseVideoPlayerConfig, pluginConfig: T) => BaseVideoPlayerConfig;
