import { useState } from 'react';

import { Box, Popover, type AvatarProps } from '@mui/material';

import { UserAvatar } from '@ll-platform/frontend/components/User/UserAvatar';
import { UserContactInfo } from '@ll-platform/frontend/components/User/UserContactInfo';
import type { User } from '@ll-platform/frontend/core/auth/types';

type UserAvatarWithPopoverProps = AvatarProps & {
  user: User;
  brandName?: string;
  size?: number;
};

export const UserAvatarWithPopover = ({
  user,
  brandName,
  size,
  ...props
}: UserAvatarWithPopoverProps) => {
  const [anchor, setAnchor] = useState<HTMLElement | null>(null);

  return (
    <>
      <UserAvatar
        user={user}
        onClick={(event) => {
          event.stopPropagation();
          setAnchor((current) => (current ? null : event.currentTarget));
        }}
        size={size}
        {...props}
      />
      <Popover
        anchorEl={anchor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={!!anchor}
        onClose={() => setAnchor(null)}
      >
        <Box sx={{ p: 2 }}>
          <UserContactInfo user={user} brandName={brandName} />
        </Box>
      </Popover>
    </>
  );
};
