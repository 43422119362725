import { useEffect, type ReactNode } from 'react';

import { Navigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Pages } from '@ll-platform/frontend/core/router/pages';
import { useActiveUser } from '@ll-platform/frontend/features/auth/hooks/useActiveUser';
import { isUserInternal } from '@ll-platform/frontend/features/auth/utils/isInternal';
import { useOptionalActiveOrganization } from '@ll-platform/frontend/features/organizations/hooks/useActiveOrganization';

type OrganizationGuardProps = {
  children: ReactNode;
  // Some pages are used by both internal and external
  ignoreExternalUsers?: boolean;
};

export const OrganizationGuard = ({
  children,
  ignoreExternalUsers = false,
}: OrganizationGuardProps) => {
  const { activeUser } = useActiveUser();
  const { activeOrganization } = useOptionalActiveOrganization();

  useEffect(() => {
    if (
      activeOrganization ||
      (ignoreExternalUsers && !isUserInternal(activeUser))
    ) {
      return;
    }

    toast.error(
      "We are sorry, but you're not assigned to any organization yet. Please contact support.",
    );
  }, [activeUser, activeOrganization, ignoreExternalUsers]);

  if (ignoreExternalUsers && !isUserInternal(activeUser)) {
    return children;
  }

  if (!activeOrganization) {
    return <Navigate to={Pages.SettingsMyAccount} replace />;
  }

  return children;
};
