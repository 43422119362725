import type {
  ProjectDeliverablesEnum,
  ProjectStyleEnum,
} from '@ll-platform/frontend/features/projects/enums';

const projectValidationContextFields = {
  projectDeliverables: 'projectDeliverables',
  projectStyle: 'projectStyle',
  isCreativeProposalFlow: 'isCreativeProposalFlow',
} as const;

export type ProjectValidationExternalContextType = {
  [projectValidationContextFields.isCreativeProposalFlow]: boolean;
};

export type ProjectValidationContextType =
  ProjectValidationExternalContextType & {
    [projectValidationContextFields.projectDeliverables]:
      | ProjectDeliverablesEnum
      | null
      | undefined;
    [projectValidationContextFields.projectStyle]:
      | ProjectStyleEnum
      | null
      | undefined;
  };

// Used to access the projectDeliverables value from the yup context
export const contextProjectDeliverables = `$${projectValidationContextFields.projectDeliverables}`;

// Used to access the projectStyle value from the yup context
export const contextProjectStyle = `$${projectValidationContextFields.projectStyle}`;

// Used to access the isCreativeProposalFlow value from the yup context
export const contextIsCreativeProposalFlow = `$${projectValidationContextFields.isCreativeProposalFlow}`;
