import { linkToCallSheet } from '@ll-platform/frontend/features/projectSpace/pages/CallSheets/utils/linkToCallSheet';
import { linkToCreativeDeck } from '@ll-platform/frontend/features/projectSpace/pages/CreativeDeck/utils/linkToCreativeDeck';
import {
  ReviewCallSheetNotificationType,
  ReviewCreativeDeckNotificationType,
  ReviewEditGeneratedDocsKey,
} from '@ll-platform/frontend/features/projectSpace/reviews/reviewGeneratedDocsKeys';

export const generatedDocData = ({
  notificationType,
  projectId,
  productionDayId,
}: {
  notificationType:
    | ReviewCreativeDeckNotificationType
    | ReviewCallSheetNotificationType
    | ReviewEditGeneratedDocsKey;
  projectId: string;
  productionDayId?: string;
}) => {
  const generatedDocName = (() => {
    switch (notificationType) {
      case ReviewCreativeDeckNotificationType.ApproveExternal:
      case ReviewCreativeDeckNotificationType.ApproveInternal:
      case ReviewCreativeDeckNotificationType.SubmitForReviewExternal:
      case ReviewCreativeDeckNotificationType.SubmitForReviewInternal:
      case ReviewEditGeneratedDocsKey.CreativeDeckEditExternal:
      case ReviewEditGeneratedDocsKey.CreativeDeckEditInternal:
        return 'Creative Deck';
      case ReviewCallSheetNotificationType.ApproveExternal:
      case ReviewCallSheetNotificationType.ApproveInternal:
      case ReviewCallSheetNotificationType.SubmitForReviewExternal:
      case ReviewCallSheetNotificationType.SubmitForReviewInternal:
      case ReviewEditGeneratedDocsKey.CallSheetEditExternal:
      case ReviewEditGeneratedDocsKey.CallSheetEditInternal:
        return 'Call Sheet';
      default:
        return null;
    }
  })();

  const generatedDocUrl = (() => {
    switch (generatedDocName) {
      case 'Creative Deck': {
        return linkToCreativeDeck(projectId);
      }
      case 'Call Sheet': {
        return linkToCallSheet(projectId, productionDayId);
      }
      default:
        return '';
    }
  })();

  const generatedDocLinkText = (() => {
    switch (generatedDocName) {
      case 'Creative Deck': {
        return generatedDocUrl
          ? `
Outlines all of the creative details for the videos.
`
          : '';
      }
      case 'Call Sheet': {
        return generatedDocUrl
          ? `
Outlines the logistical details of the production day.
`
          : '';
      }
      default:
        return '';
    }
  })();

  return { generatedDocName, generatedDocUrl, generatedDocLinkText };
};
