import { FALLBACK_ORGANIZATION_NAME } from '@ll-platform/frontend/consts/organization';
import { AccountType } from '@ll-platform/frontend/core/auth/types';
import type { BrandWithOrganization } from '@ll-platform/frontend/features/brands/types';
import type { ProjectWithDeliverables } from '@ll-platform/frontend/features/projects/types';
import { linkToWizard } from '@ll-platform/frontend/features/projectWizard/utils/linkToWizard';
import type { ReviewEmailTemplateArgs } from '@ll-platform/frontend/features/reviews/types';
import {
  emailGreeting,
  emailSignature,
  htmlEmailBody,
} from '@ll-platform/frontend/utils/helpers/emails';
import {
  propertiesDefined,
  type Nullable,
} from '@ll-platform/frontend/utils/types/types';

export const creativeBriefInternalEmail = ({
  brand,
  project,
  reviewEmailTemplateUsers,
}: ReviewEmailTemplateArgs<{
  brand: Nullable<BrandWithOrganization>;
  project?: ProjectWithDeliverables;
}>) => {
  const requiredBodyParams = {
    organizationName: brand?.organization.name ?? FALLBACK_ORGANIZATION_NAME,
    projectMsa: project?.msa,
    projectName: project?.title,
    projectId: project?.id,
    brandName: brand?.name,
  };

  return {
    recipientUserIds: reviewEmailTemplateUsers.recipientUserIds,
    title: `A new project ${brand?.name ? `for ${brand.name}` : ''} is ready for your review`,
    body: emailBody({
      requiredBodyParams,
      reviewEmailTemplateUsers,
    }),
  };
};

const emailBody = ({
  requiredBodyParams,
  reviewEmailTemplateUsers: { defaultRecipient, teamUsers },
}: ReviewEmailTemplateArgs<{
  requiredBodyParams: {
    organizationName: string;
    projectMsa: Nullable<string>;
    projectName: Nullable<string>;
    projectId: Nullable<string>;
    brandName: Nullable<string>;
  };
}>) => {
  if (!propertiesDefined(requiredBodyParams)) {
    return '';
  }

  const accountExecutiveFirstName =
    teamUsers[AccountType.AccountExecutive][0]?.firstName;
  const accountExecutiveFirstNameText = accountExecutiveFirstName
    ? ` to the AE, ${accountExecutiveFirstName}`
    : '.';
  const wizardLink = linkToWizard(requiredBodyParams.projectId);

  return htmlEmailBody(`${emailGreeting('', defaultRecipient?.firstName)}
<br><br>The project details have been submitted for:
<br><br>${requiredBodyParams.brandName}
<br>${requiredBodyParams.projectMsa}, ${requiredBodyParams.projectName}.
<br>Review it <a href="${wizardLink}">here.</a>
<br><br>Please review the output and prepare the documents for the client prior to the Creative Review call.
<br><br>If you have any questions or need more information, feel free to reach out${accountExecutiveFirstNameText}.
<br><br>${emailSignature('Best', requiredBodyParams.organizationName)}`);
};
