import type { Editor } from 'slate';

import type { TextEditorConfig } from '@ll-platform/frontend/features/textEditor/types';

import { unwrapAllComments } from './commentTransforms';

type CleanupAfterCommentsPluginArgs = {
  editor: Editor;
  config?: TextEditorConfig;
  previousConfig?: TextEditorConfig;
};

export const cleanupAfterCommentsPlugin = ({
  editor,
  config,
  previousConfig,
}: CleanupAfterCommentsPluginArgs) => {
  if (previousConfig?.plugins?.comments && !config?.plugins?.comments) {
    unwrapAllComments(editor);
  }
};
