import { Suspense, type ComponentType } from 'react';

import type { CircularProgressProps } from '@mui/material';
import { lazily } from 'react-lazily';

import { Spinner } from '@ll-platform/frontend/components/Spinner/Spinner';
import SuspenseLoader from '@ll-platform/frontend/components/SuspenseLoader';
import { handleLoadError } from '@ll-platform/frontend/config/setupLoadErrorHandler';

export const withLoader =
  <P extends object>(Component: ComponentType<P>) =>
  // eslint-disable-next-line react/display-name
  (props: P) => (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

export const withSpinner =
  <P extends object>(
    Component: ComponentType<P>,
    spinnerProps?: CircularProgressProps,
  ) =>
  // eslint-disable-next-line react/display-name
  (props: P) => (
    <Suspense fallback={<Spinner {...spinnerProps} />}>
      <Component {...props} />
    </Suspense>
  );

export const lazyLoad = <T extends object>(
  loader: (x?: string | undefined) => Promise<T>,
) => {
  return lazily(async () => {
    try {
      return (await loader()) as T;
    } catch (error) {
      if (
        error &&
        error instanceof Error &&
        error.message.includes('Failed to fetch dynamically imported module')
      ) {
        handleLoadError();
      }

      throw error;
    }
  });
};
