import { Editor, Element, type Node, type Path } from 'slate';

import {
  TextEditorElementEnum,
  type LinkElement,
} from '@ll-platform/frontend/features/textEditor/types';

const getCurrentLinkEntries = (editor: Editor) => {
  return Array.from(
    Editor.nodes(editor, {
      match: (n: Node): n is LinkElement =>
        Element.isElement(n) && n.type === TextEditorElementEnum.Link,
    }),
  );
};

export const getCurrentLinkNode = (editor: Editor): LinkElement | undefined => {
  const linkEntries = getCurrentLinkEntries(editor);

  if (linkEntries.length === 0) {
    return undefined;
  }

  const [node] = linkEntries[0];

  return node;
};

export const getCurrentLinkPath = (editor: Editor): Path | undefined => {
  const linkEntries = getCurrentLinkEntries(editor);
  if (linkEntries.length === 0) {
    return undefined;
  }

  return linkEntries[0][1];
};

export const isLinkActive = (editor: Editor): boolean => {
  return getCurrentLinkEntries(editor).length > 0;
};
