import { clsx, type ClassValue } from "clsx"
import { FieldError, Merge } from "react-hook-form"
import { twMerge } from "tailwind-merge"

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function getInputClasses(
  error?: Merge<FieldError, (FieldError | undefined)[]> | undefined
) {
  return cn(
    "background-input no-scrollbar focus-visible:ring-0 focus-visible:ring-offset-0",
    error && "border-red-500"
  )
}
