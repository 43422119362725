import { useCallback } from 'react';

import { Stack, Typography } from '@mui/material';

import { BudgetDiscountBadge } from '@ll-platform/frontend/features/budget/components/BudgetDiscount/BudgetDiscountBadge';
import { BudgetDiscountButton } from '@ll-platform/frontend/features/budget/components/BudgetDiscount/BudgetDiscountButton';
import type { UseBudgetDiscountDialogArgs } from '@ll-platform/frontend/features/budget/components/BudgetDiscount/useBudgetDiscountDialog';
import { useManageExistingProjectDiscount } from '@ll-platform/frontend/features/budget/hooks/useManageExistingProjectDiscount';
import type { PriceAdjustment } from '@ll-platform/frontend/features/budget/types';
import { useManageProjectFormDiscount } from '@ll-platform/frontend/features/internalProjects/pages/ProjectForm/hooks/useManageProjectFormDiscount';
import { formatNumberToDollars } from '@ll-platform/frontend/utils/helpers/helpers';

type BudgetSummaryDiscountItemInnerProps = UseBudgetDiscountDialogArgs & {
  discountData?: PriceAdjustment;
};

const BudgetSummaryDiscountItemInner = ({
  discountData,
  ...budgetDiscountButtonProps
}: BudgetSummaryDiscountItemInnerProps) => {
  return (
    <Stack direction="row" justifyContent="space-between">
      <BudgetDiscountButton {...budgetDiscountButtonProps} />
      <Stack direction="row" alignItems="center" gap={1}>
        <BudgetDiscountBadge discountData={discountData} size="small" />
        <Typography variant="body1">
          -{formatNumberToDollars((discountData?.valueInCents ?? 0) / 100)}
        </Typography>
      </Stack>
    </Stack>
  );
};

type BudgetSummaryDiscountItemWrapperBaseProps = Omit<
  BudgetSummaryDiscountItemInnerProps,
  'onSubmit' | 'onRemove'
> & {
  projectId?: string;
};

const BudgetSummaryExistingProjectSummary = ({
  projectId,
  ...props
}: BudgetSummaryDiscountItemWrapperBaseProps) => {
  const discountActionHandlers = useManageExistingProjectDiscount({
    projectId,
  });

  return (
    <BudgetSummaryDiscountItemInner {...props} {...discountActionHandlers} />
  );
};

const BudgetSummaryProjectFormSummary = ({
  projectId,
  ...props
}: BudgetSummaryDiscountItemWrapperBaseProps) => {
  const databaseActionHandlers = useManageExistingProjectDiscount({
    projectId,
  });
  const projectFormActionHandlers = useManageProjectFormDiscount();

  const onSubmit: (typeof projectFormActionHandlers)['onSubmit'] = useCallback(
    async (args) => {
      if (projectId) {
        await databaseActionHandlers.onSubmit(args);
      }
      await projectFormActionHandlers.onSubmit(args);
    },
    [databaseActionHandlers, projectFormActionHandlers, projectId],
  );

  const onRemove: (typeof projectFormActionHandlers)['onRemove'] =
    useCallback(async () => {
      if (projectId) {
        await databaseActionHandlers.onRemove();
      }
      await projectFormActionHandlers.onRemove();
    }, [databaseActionHandlers, projectFormActionHandlers, projectId]);

  return (
    <BudgetSummaryDiscountItemInner
      {...props}
      onRemove={onRemove}
      onSubmit={onSubmit}
    />
  );
};

type BudgetSummaryDiscountItemProps =
  BudgetSummaryDiscountItemWrapperBaseProps & {
    isInProjectForm?: boolean;
  };

export const BudgetSummaryDiscountItem = ({
  isInProjectForm,
  ...props
}: BudgetSummaryDiscountItemProps) => {
  if (isInProjectForm) {
    return <BudgetSummaryProjectFormSummary {...props} />;
  }

  return <BudgetSummaryExistingProjectSummary {...props} />;
};
