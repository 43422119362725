import dayjs, { type Dayjs } from 'dayjs';
import { isArray, isObject } from 'lodash-es';
import type { JsonValue } from 'type-fest';

// Dates are converted to strings
export type SerializedJson<T> = T extends Dayjs | Date
  ? string
  : T extends object
    ? {
        [K in keyof T]: SerializedJson<T[K]>;
      }
    : T;

function convertIsoStringToDayjs(text: string): string | Dayjs {
  if (text.length === 24 && text.endsWith('Z')) {
    const date = new Date(text);
    if (date.toISOString() === text) {
      return dayjs(date);
    }
  }

  return text;
}

function isJsonObject(obj: unknown): obj is Record<string, JsonValue> {
  return isObject(obj) && !isArray(obj);
}

// WARN: This mutates the object in place
// We map all ISO strings to DayJS objects
export function automapDates<T>(data: SerializedJson<T>): T {
  if (!data) {
    return data as T;
  }

  if (typeof data === 'string') {
    return convertIsoStringToDayjs(data) as T;
  }

  if (isArray(data)) {
    for (let i = 0; i < data.length; i++) {
      data[i] = automapDates(data[i]);
    }

    return data as T;
  }

  if (isJsonObject(data)) {
    for (const key in data) {
      if (Object.hasOwn(data, key)) {
        // We map the type
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        data[key] = automapDates(data[key]);
      }
    }
  }

  return data as T;
}
