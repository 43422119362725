import {
  createContext,
  useCallback,
  useContext,
  useState,
  type ReactNode,
} from 'react';

import { isDev } from '@ll-platform/frontend/config/isDev';

type ActiveAudioProvider = {
  id?: string;
  audio: HTMLAudioElement;
  onStop?: (audio: HTMLAudioElement) => void | Promise<void>;
};

const ActiveAudioContext = createContext<{
  activeAudio: ActiveAudioProvider | null;
  setActiveAudio: (activeAudio: ActiveAudioProvider | null) => void;
}>({
  activeAudio: null,
  setActiveAudio: () => {},
});
ActiveAudioContext.displayName = 'ActiveAudioContext';

export const ActiveAudioProvider = ({ children }: { children: ReactNode }) => {
  const [activeAudio, setActiveAudio] = useState<ActiveAudioProvider | null>(
    null,
  );

  // When setting a new active audio, the previous audio will be paused
  const setActiveAudioCallback = useCallback(
    async (newActiveAudio: ActiveAudioProvider | null) => {
      if (activeAudio && 'audio' in activeAudio) {
        if (activeAudio.audio === newActiveAudio?.audio) {
          return;
        }

        const logPrefix = `[ActiveAudio] ${
          activeAudio.id ? `${activeAudio.id} ` : ''
        }`;

        if (isDev()) {
          console.debug(`${logPrefix}Stopping active audio`);
        }

        try {
          if (!activeAudio.audio.paused) {
            activeAudio.audio.pause();
          }
        } catch (e) {
          console.error(`${logPrefix}Unable to stop active audio`);
        }
        if (activeAudio.onStop) {
          try {
            await activeAudio.onStop(activeAudio.audio);
          } catch (error) {
            console.error(`${logPrefix}onStop callback error`, error);
          }
        }
      }
      setActiveAudio(newActiveAudio);
    },
    [activeAudio],
  );

  return (
    <ActiveAudioContext.Provider
      value={{ activeAudio, setActiveAudio: setActiveAudioCallback }}
    >
      {children}
    </ActiveAudioContext.Provider>
  );
};

export function useActiveAudio() {
  return useContext(ActiveAudioContext);
}
