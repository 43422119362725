import { useState } from 'react';

import { File, Trash2 } from 'lucide-react';
import { useParams } from 'react-router-dom';
import { toast } from 'sonner';

import { cn } from '@ll-platform/frontend/components/shadcn/lib/utils';
import { Button } from '@ll-platform/frontend/components/shadcn/ui/button';
import { TwConfirmDialog } from '@ll-platform/frontend/components/tw/TwConfirmDialog/TwConfirmDialog';
import { useTwConfirmDialog } from '@ll-platform/frontend/components/tw/TwConfirmDialog/useTwConfirmDialog';
import { TwTypography } from '@ll-platform/frontend/components/tw/TwTypography/TwTypography';
import { useDeleteProposalFileById } from '@ll-platform/frontend/hero/creativeProposal/async/useCreativeProposalsMutations';
import type { ContextFile } from '@ll-platform/frontend/hero/creativeProposal/types';
import { assertDefined } from '@ll-platform/frontend/utils/types/types';

type ConceptAssetsUploaderFileProps = {
  file: ContextFile;
  isLoading?: boolean;
};

const VIEWABLE_FILE_TYPES = [
  'image/png',
  'image/jpeg',
  'image/gif',
  'image/webp',
  'image/svg+xml',
];

export const ConceptAssetsUploaderFile = ({
  file,
  isLoading,
}: ConceptAssetsUploaderFileProps) => {
  const { fileType, fileName, url, id } = file;

  const { id: proposalId } = useParams<{ id: string }>();
  const { isOpen, onClose, onOpen } = useTwConfirmDialog();
  const [isRemoving, setIsRemoving] = useState(false);

  const { mutateAsync: mutateRemoveProposalFile } = useDeleteProposalFileById();

  const actionDisabled = isRemoving || isLoading;

  const onRemoveButtonClick = async () => {
    onOpen();
  };

  const onRemovingCancel = () => {
    onClose();
  };

  const handleFileRemove = async () => {
    assertDefined(proposalId);
    setIsRemoving(true);
    onClose();

    toast.promise(
      () => mutateRemoveProposalFile({ fileId: id, id: proposalId }),
      {
        error: () => {
          setIsRemoving(false);

          return 'Failed to remove file';
        },
        success: 'File removed!',
      },
    );
  };

  return (
    <div
      className={cn(
        'shadow-md w-full p-2 pr-0 bg-popover border border-border rounded-md flex gap-4 items-center max-h-11',
        {
          'pointer-events-none opacity-30 blur-[0.25px]': actionDisabled,
        },
      )}
    >
      <div>
        {VIEWABLE_FILE_TYPES.includes(fileType) ? (
          <img src={url} className="h-8 w-8 object-fill rounded-md" />
        ) : (
          <File size={16} />
        )}
      </div>
      <TwTypography
        variant="small"
        className="truncate overflow-hidden whitespace-nowrap font-medium"
      >
        {fileName}
      </TwTypography>
      <Button
        variant="ghost"
        disabled={actionDisabled}
        onClick={onRemoveButtonClick}
        className="ml-auto"
        type="button"
      >
        <Trash2 size={16} />
      </Button>
      <TwConfirmDialog
        open={isOpen}
        onCancel={onRemovingCancel}
        onConfirm={handleFileRemove}
        confirmButtonVariant="destructive"
        confirmLabel="Remove file"
        title="Remove file?"
        body={
          <div>
            This file will be removed from the context. This action cannot be
            undone.
          </div>
        }
      />
    </div>
  );
};
