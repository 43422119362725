import type { DocumentNode } from '@ll-platform/frontend/hero/documentBuilder/core/nodes/nodeTypes';

export const BudgetNodeName = 'BudgetWidget';

export type BudgetNodeContent = {
  totalBudget: number;
};

export type BudgetNodeType = DocumentNode<
  typeof BudgetNodeName,
  BudgetNodeContent
>;
