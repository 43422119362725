import * as yup from 'yup';

export const cutDownFields = {
  id: 'id',
  videoLength: 'videoLength',
  aspectRatio: 'aspectRatio',
  alternateVersions: 'alternateVersions',
  isInEditMode: 'isInEditMode',
} as const;

export const cutDownAlternateVersionSchema = yup.object().shape({
  [cutDownFields.aspectRatio]: yup.number().notRequired().default(null),
});

export const cutDownSchema = yup.object().shape({
  [cutDownFields.id]: yup.string().optional().default(undefined),
  // In seconds
  [cutDownFields.videoLength]: yup.number().required().default(null),
  [cutDownFields.aspectRatio]: yup.number().notRequired().default(1),
  [cutDownFields.alternateVersions]: yup
    .array()
    .of(cutDownAlternateVersionSchema)
    .optional()
    .default([]),
  [cutDownFields.isInEditMode]: yup.boolean().default(true),
});

export type CutDownSubFormValues = yup.InferType<typeof cutDownSchema>;
