import type { ProjectNotesFormValues } from '@ll-platform/frontend/features/internalProjects/projectForm/notes/projectNotesSchema';
import type { ProjectFormInput } from '@ll-platform/frontend/features/internalProjects/types';

export const mapNotesFormToDto = ({
  projectNotes,
  videoReferences,
}: Partial<ProjectNotesFormValues>) => {
  return { notes: projectNotes ?? '', videoReferences: videoReferences ?? '' };
};

export const mapNotesDtoToForm = ({
  notes,
  videoReferences,
}: Pick<
  ProjectFormInput,
  'notes' | 'videoReferences'
>): ProjectNotesFormValues => {
  return {
    projectNotes: notes ?? '',
    videoReferences: videoReferences ?? '',
  };
};
