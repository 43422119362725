import {
  createBrowserRouter,
  Navigate,
  Outlet,
  type RouteObject,
} from 'react-router-dom';

import { BaseLayout } from '@ll-platform/frontend/components/layouts/BaseLayout';
import { ErrorPage } from '@ll-platform/frontend/components/statusPages/ErrorPage';
import {
  APP_CONFIG,
  AppEnvEnum,
} from '@ll-platform/frontend/config/app.config';
import {
  lazyLoad,
  withLoader,
} from '@ll-platform/frontend/core/router/helpers';
import { creatorRoutes } from '@ll-platform/frontend/creator/router';
import { AuthRedirectController } from '@ll-platform/frontend/features/auth/pages/AuthRedirectController';
import { appRoutes } from '@ll-platform/frontend/features/router';
import { creativeProposalRoutes } from '@ll-platform/frontend/hero/creativeProposal/router';

const Status404 = withLoader(
  lazyLoad(
    () => import('@ll-platform/frontend/components/statusPages/Status404'),
  ).default,
);

const GoogleOAuthCallbackController = withLoader(
  lazyLoad(
    () =>
      import(
        '@ll-platform/frontend/container/auth/pages/callbacks/google/GoogleOAuthCallbackController'
      ),
  ).GoogleOAuthCallbackController,
);

const routes: RouteObject[] = [
  {
    path: '',
    element: <Outlet />,
    ErrorBoundary: [AppEnvEnum.Production, AppEnvEnum.Staging].includes(
      APP_CONFIG.REACT_APP_ENV,
    )
      ? ErrorPage
      : undefined,
    children: [
      {
        path: '',
        element: <AuthRedirectController />,
      },
      ...appRoutes,
      ...creatorRoutes,
      ...creativeProposalRoutes,
      {
        path: 'auth',
        children: [
          {
            path: 'google',
            children: [
              {
                path: 'callback',
                element: <GoogleOAuthCallbackController />,
              },
            ],
          },
        ],
      },
      {
        path: 'status',
        children: [
          {
            path: '',
            element: <Navigate to="404" replace />,
          },
          {
            path: '404',
            element: (
              <BaseLayout>
                <Status404 />
              </BaseLayout>
            ),
          },
        ],
      },
      {
        path: '*',
        element: (
          <BaseLayout>
            <Status404 />
          </BaseLayout>
        ),
      },
    ],
  },
];

export const mainRouter = createBrowserRouter(routes);
