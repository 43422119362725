// Letest Figma UI Kit v5.16.0: https://www.figma.com/design/Ou7RmFBoNfHYu6LP06dW6h/Lemonlight-Material-UI-5.16.0?node-id=11611-166760&m=dev
// Legacy Figma UI Kit v5.11.1: https://www.figma.com/file/ocAvHH2idVWLTctW3PeFOL/Lemonlight-MUI-(v5.11.1)?type=design&node-id=4662-14&mode=dev
// Theme Playground: http://localhost:3000/internal/theme-playground
// MUI Theming Docs: https://mui.com/material-ui/customization/theming/
// MUI Default Theme: https://mui.com/material-ui/customization/default-theme/
import { alpha, createTheme, type ThemeOptions } from '@mui/material';
import { merge } from 'lodash-es';

import {
  lemonlightMuiLightThemeOptions,
  customPalette as lightCustomPalette,
  otherColors as lightOtherColors,
} from '@ll-platform/frontend/core/theme/themes/LemonlightMui/LemonlightMuiLightTheme';
import type {
  CustomElevation,
  MuiPalette,
  OtherColors,
} from '@ll-platform/frontend/core/theme/themes/LemonlightMui/types';

const white = '#FFFFFF';
const black = '#000000';
const primaryTextColor = '#FFFFFF';

const customPalette = lightCustomPalette;

// Miscellaneous, non-standard values
const otherColors: OtherColors = {
  ...lightOtherColors,
  avatarFill: '#4d5bff',
  greyBackground: customPalette.grey[900],
  backgroundPrimary: '#1D1D1F',
  backgroundSecondary: '#161618',
  actionIconBackground: 'rgba(29, 29, 31, 0.06)',
  customIconColors: {
    default: { bgColor: alpha(primaryTextColor, 0.06), iconColor: undefined },
    primary: {
      bgColor: customPalette.blue[200],
      iconColor: customPalette.grey[900],
      hoverBgColor: customPalette.blue[300],
    },
    secondary: { bgColor: undefined, iconColor: undefined },
    error: { bgColor: undefined, iconColor: undefined },
    warning: { bgColor: undefined, iconColor: undefined },
    success: { bgColor: undefined, iconColor: undefined },
    info: { bgColor: undefined, iconColor: undefined },
    inherit: { bgColor: alpha(primaryTextColor, 0.06), iconColor: undefined },
    inheritWhite: { bgColor: white, iconColor: undefined },
  },
};

const muiPalette = {
  common: {
    black,
    white,
  },
  text: {
    primary: primaryTextColor,
    secondary: alpha(primaryTextColor, 0.6),
    disabled: alpha(primaryTextColor, 0.38),
  },
  primary: {
    main: customPalette.blue[200],
    dark: customPalette.blue[400],
    light: customPalette.blue[50],
    contrastText: customPalette.grey[900],
  },
  secondary: {
    main: customPalette.yellow[200],
    dark: customPalette.yellow[400],
    light: customPalette.yellow[50],
    contrastText: customPalette.grey[900],
  },
  background: {
    paper: otherColors.backgroundSecondary,
    default: otherColors.backgroundPrimary,
  },
  error: {
    main: customPalette.red[500],
    dark: customPalette.red[700],
    light: customPalette.red[300],
    contrastText: white,
  },
  warning: {
    main: customPalette.orange[400],
    dark: customPalette.orange[700],
    light: customPalette.orange[200],
    contrastText: customPalette.grey[900],
  },
  info: {
    main: customPalette.lightBlue[400],
    dark: customPalette.lightBlue[700],
    light: customPalette.lightBlue[200],
    contrastText: customPalette.grey[900],
  },
  success: {
    main: customPalette.green[400],
    dark: customPalette.green[700],
    light: customPalette.green[200],
    contrastText: customPalette.grey[900],
  },
  action: {
    activatedOpacity: 0.56,
    active: alpha(primaryTextColor, 0.56),
    hoverOpacity: 0.08,
    hover: alpha(primaryTextColor, 0.08),
    selectedOpacity: 0.08,
    selected: alpha(primaryTextColor, 0.16),
    focusOpacity: 0.12,
    focus: alpha(primaryTextColor, 0.12),
    disabledOpacity: 0.38,
    disabled: alpha(primaryTextColor, 0.38),
    disabledBackground: alpha(primaryTextColor, 0.12),
  },
  divider: alpha(primaryTextColor, 0.12),
} satisfies MuiPalette;

// NOTE: this values may be wrong but it's too complex to sort this out right know
const elevation: CustomElevation = {
  xs: `0px 1px 2px 0px ${alpha(primaryTextColor, 0.12)}`,
  sm: `0px 1px 3px 0px ${alpha(
    primaryTextColor,
    0.1,
  )}, 0px 1px 2px 0px ${alpha(primaryTextColor, 0.06)}`,
  md: `0px 4px 8px -2px ${alpha(
    primaryTextColor,
    0.1,
  )}, 0px 2px 4px -2px ${alpha(otherColors.shadow, 0.06)}`,
  lg: `0px 12px 16px -4px ${alpha(
    otherColors.shadow,
    0.08,
  )}, 0px 4px 6px -2px ${alpha(otherColors.shadow, 0.03)}`,
  xl: `0px 20px 24px -4px ${alpha(
    otherColors.shadow,
    0.08,
  )}, 0px 8px 8px -4px ${alpha(otherColors.shadow, 0.03)}`,
  '2xl': `0px 24px 48px -12px ${alpha(otherColors.shadow, 0.18)}`,
  '3xl': `0px 32px 64px -12px ${alpha(otherColors.shadow, 0.14)}`,
};

const components = {
  MuiCssBaseline: {
    styleOverrides: {
      body: {
        background: otherColors.backgroundSecondary,
      },
    },
  },
} satisfies ThemeOptions['components'];

const lemonlightMuiDarkThemeOptions: Partial<ThemeOptions> = {
  palette: {
    ...muiPalette,
    ...customPalette,
    ...otherColors,
  },
  components,
  elevation,
};

export const lemonlightMuiDarkTheme = createTheme(
  merge({}, lemonlightMuiLightThemeOptions, lemonlightMuiDarkThemeOptions),
);
