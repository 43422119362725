import { useMemo } from 'react';

import { useActiveUser } from '@ll-platform/frontend/features/auth/hooks/useActiveUser';
import {
  useGetBrandById,
  useGetBrandByProjectId,
} from '@ll-platform/frontend/features/brands/async/useBrandsQueries';
import { useGetProjectUsers } from '@ll-platform/frontend/features/projects/async/useProjectsQueries';
import { editGeneratedDocExternalModalTemplate } from '@ll-platform/frontend/features/projectSpace/reviews/modalTemplates/editGeneratedDocExternalModalTemplate';
import { editGeneratedDocInternalModalTemplate } from '@ll-platform/frontend/features/projectSpace/reviews/modalTemplates/editGeneratedDocInternalModalTemplate';
import { ReviewEditGeneratedDocsKey } from '@ll-platform/frontend/features/projectSpace/reviews/reviewGeneratedDocsKeys';
import { useActiveProject } from '@ll-platform/frontend/features/projectWizard/hooks/useActiveProject';
import { useNotifyForReviewDialog } from '@ll-platform/frontend/features/reviews/hooks/useNotifyForReviewModal';
import type { ReviewEmailData } from '@ll-platform/frontend/features/reviews/types';

import { creativeBriefInternalModalTemplate } from './modalTemplates/creativeBriefInternalModalTemplate';
import { wizardGenerateExternalModalTemplate } from './modalTemplates/wizardGenerateExternalModalTemplate';
import { wizardGenerateInternalModalTemplate } from './modalTemplates/wizardGenerateInternalModalTemplate';
import { ReviewWizardFlowKey } from './reviewWizardFlowKeys';

type UseReviewWizardFlowArgs = {
  onSubmit: (payload: ReviewEmailData) => Promise<void>;
  notificationType: ReviewWizardFlowKey | ReviewEditGeneratedDocsKey;
  page: string;
};

export const useReviewWizardFlow = ({
  onSubmit,
  notificationType,
  page,
}: UseReviewWizardFlowArgs) => {
  const { activeUser } = useActiveUser();
  const { activeProject } = useActiveProject();

  const brandByProjectQuery = useGetBrandByProjectId({
    projectId: activeProject.id,
  });
  const brandId = brandByProjectQuery.data?._id;

  const brandByIdQuery = useGetBrandById(
    {
      brandId: brandId!,
    },
    { enabled: !!brandId },
  );

  const projectUsersQuery = useGetProjectUsers(
    {
      projectId: activeProject?.id ?? '',
    },
    {
      enabled: !!activeProject?.id,
    },
  );

  const projectTeam = useMemo(() => {
    return projectUsersQuery.data?.items.map((user) => user.user) ?? [];
  }, [projectUsersQuery.data]);

  const reviewModalTemplate = useMemo(() => {
    const sharedArgs = {
      activeUser,
      page,
      project: activeProject,
      users: projectTeam,
    };

    switch (notificationType) {
      case ReviewWizardFlowKey.CreativeBriefInternal:
        return creativeBriefInternalModalTemplate({
          ...sharedArgs,
          brand: brandByIdQuery?.data,
        });
      case ReviewWizardFlowKey.WizardGenerateExternal:
        return wizardGenerateExternalModalTemplate(sharedArgs);
      case ReviewWizardFlowKey.WizardGenerateExternalLegacy:
        return wizardGenerateExternalModalTemplate({
          ...sharedArgs,
          isLegacyFlow: true,
        });
      case ReviewWizardFlowKey.WizardGenerateInternal:
        return wizardGenerateInternalModalTemplate({
          ...sharedArgs,
          brandName: brandByIdQuery?.data?.name,
        });
      case ReviewEditGeneratedDocsKey.CreativeDeckEditInternal:
      case ReviewEditGeneratedDocsKey.CallSheetEditInternal:
        return editGeneratedDocInternalModalTemplate({
          brand: brandByIdQuery?.data,
          reviewKey: notificationType,
          ...sharedArgs,
        });
      case ReviewEditGeneratedDocsKey.CreativeDeckEditExternal:
      case ReviewEditGeneratedDocsKey.CallSheetEditExternal:
        return editGeneratedDocExternalModalTemplate({
          reviewKey: notificationType,
          ...sharedArgs,
        });
      default:
        throw new Error(
          `Wizard flow for ${notificationType} has not been defined`,
        );
    }
  }, [
    activeUser,
    brandByIdQuery?.data,
    activeProject,
    projectTeam,
    notificationType,
    page,
  ]);

  return useNotifyForReviewDialog({
    onSubmit,
    reviewModalTemplate,
    projectId: activeProject.id,
    isLoading: brandByIdQuery.isPending || projectUsersQuery.isPending,
  });
};
