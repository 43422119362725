import { useCallback, useEffect, useRef } from 'react';

import { toast } from 'sonner';

import { TwSpinner } from '@ll-platform/frontend/components/tw/TwSpinner/TwSpinner';
import { TwTypography } from '@ll-platform/frontend/components/tw/TwTypography/TwTypography';
import {
  useGenerateProposalSummary,
  useUpdateCreativeProposal,
} from '@ll-platform/frontend/hero/creativeProposal/async/useCreativeProposalsMutations';
import { useGetCreativeProposalByProposalId } from '@ll-platform/frontend/hero/creativeProposal/async/useCreativeProposalsQueries';
import { GenerateConceptsButton } from '@ll-platform/frontend/hero/creativeProposal/concept/components/GenerateConceptsButton';
import {
  GeneratingLoadingScopeClaims,
  LOADING_SCOPE_GENERATING,
  NEW_CONCEPTS_COUNT,
} from '@ll-platform/frontend/hero/creativeProposal/concept/consts';
import { useConceptPageParams } from '@ll-platform/frontend/hero/creativeProposal/concept/hooks/useConceptPageParams';
import { useLoadingContext } from '@ll-platform/frontend/utils/contexts/LoadingContext';
import { wait } from '@ll-platform/frontend/utils/helpers/helpers';

import { ConceptCard } from './ConceptCard';
import { EmptyConcepts } from './EmptyConcepts';

export const ConceptsList = () => {
  const { id } = useConceptPageParams();
  const { data, isPending } = useGetCreativeProposalByProposalId({
    id,
  });
  const { mutateAsync: mutateUpdateProposal } = useUpdateCreativeProposal();
  const loadingGeneratingCtx = useLoadingContext(LOADING_SCOPE_GENERATING);
  const { mutateAsync: mutateGenerateProposalSummary } =
    useGenerateProposalSummary();

  const handleGenerateConcepts = useCallback(async () => {
    try {
      loadingGeneratingCtx.startLoadingClaim(
        GeneratingLoadingScopeClaims.CreateConcepts,
      );
      await mutateGenerateProposalSummary({ id });
      await mutateUpdateProposal({
        id,
        conceptsToCreate: NEW_CONCEPTS_COUNT,
      });
      await wait(1000); // Prevent toast flicker
      loadingGeneratingCtx.stopLoadingClaim(
        GeneratingLoadingScopeClaims.CreateConcepts,
      );
    } catch (error) {
      console.error(error);
      toast.error('Failed to generate concepts.');
      loadingGeneratingCtx.stopLoadingClaim(
        GeneratingLoadingScopeClaims.CreateConcepts,
      );
    }
  }, [
    mutateGenerateProposalSummary,
    id,
    loadingGeneratingCtx,
    mutateUpdateProposal,
  ]);

  const listRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (listRef.current) {
      listRef.current.children[
        listRef.current.children.length - 1
      ].scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, [data?.concepts.length]);

  if (isPending) {
    return (
      <div className="w-full grid place-items-center">
        <TwSpinner />
      </div>
    );
  }

  if (!data?.concepts.length) {
    return <EmptyConcepts />;
  }

  return (
    <div className="max-w-[816px] px-6 py-8 mx-auto">
      <TwTypography variant="large" className="mb-2 text-foreground">
        Edit and refine your concepts
      </TwTypography>
      <TwTypography variant="muted" className="text-muted-foreground">
        Review your concepts, make any edits, and delete the ones you don’t
        need. Click Generate proposal to continue with the concepts you’ve kept.
      </TwTypography>
      <div className="flex flex-col gap-4 mt-6 mb-10" ref={listRef}>
        {data?.concepts.map((concept) => (
          <ConceptCard key={concept.id} proposalId={id} concept={concept} />
        ))}
      </div>
      <GenerateConceptsButton
        concepts={data?.concepts ?? []}
        onClick={handleGenerateConcepts}
        disabled={false}
      />
    </div>
  );
};
