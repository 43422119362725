import type { BaseEditor, BaseRange, BaseText } from 'slate';
import type { HistoryEditor } from 'slate-history';
import type { ReactEditor } from 'slate-react';

import type {
  CommentElement,
  TextEditorCommentsPluginConfig,
  TextEditorEditCommentMode,
} from './comments/types';
import type { TextEditorEditLinkMode } from './richtext/types';

export type TextEditorEditMode =
  | 'hover'
  | TextEditorEditCommentMode
  | TextEditorEditLinkMode;

type DecoratorProps = {
  keepSelected?: boolean;
};

export enum TextEditorPluginsEnum {
  History = 'history',
  Comments = 'comments',
  RichText = 'richText',
}

export enum TextEditorElementEnum {
  Paragraph = 'paragraph',
  Comment = 'comment',
  Link = 'link',
  LineBreak = 'lineBreak',
}

type CustomEditor = BaseEditor & ReactEditor & HistoryEditor;

type CustomText = BaseText & DecoratorProps;

type CustomRange = BaseRange & DecoratorProps;

export type ParagraphElement = {
  type: TextEditorElementEnum.Paragraph;
  children: CustomText[];
};

export type LinkElement = {
  type: TextEditorElementEnum.Link;
  url: string;
  children: CustomText[];
};

export type TextEditorConfig = {
  plugins: Partial<Record<TextEditorPluginsEnum, boolean>>;
};

export type TextEditorPluginsConfig = {
  [TextEditorPluginsEnum.Comments]: TextEditorCommentsPluginConfig;
};

export type CustomElement = ParagraphElement | CommentElement | LinkElement;

declare module 'slate' {
  interface CustomTypes {
    Editor: CustomEditor;
    Element: CustomElement;
    Text: CustomText;
    Range: CustomRange;
  }
}

export type TextEditorInputType = 'plaintext' | 'pseudoMarkdown' | 'html';
