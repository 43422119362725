import { Node, type Descendant } from 'slate';

export const slateToPlainText = (nodes: Descendant[]) => {
  return replaceMarkdownLinks(nodes.map((n) => Node.string(n)).join('\n'));
};

export const replaceMarkdownLinks = (text: string): string => {
  const markdownLinkRegex = /(?:__|[*#])|\[([^\[\]]+)\]\(([^()\n]+?)\)/g;
  const plainText = text.replace(markdownLinkRegex, '$1');

  return plainText;
};
