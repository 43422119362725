import { type PropsWithChildren } from 'react';

import { useParams } from 'react-router-dom';

import { ErrorMessage } from '@ll-platform/frontend/components/errors/ErrorMessage';
import SuspenseLoader from '@ll-platform/frontend/components/SuspenseLoader';
import { useEditProjectFormInitialValues } from '@ll-platform/frontend/features/internalProjects/pages/ProjectForm/hooks/useEditProjectFormInitialValues';
import { assertDefined } from '@ll-platform/frontend/utils/types/types';

import { ProjectFormRouteProvider } from './ProjectFormRouteProvider';

export const EditProjectFormProvider = ({ children }: PropsWithChildren) => {
  const { id } = useParams<{
    id: string;
  }>();

  assertDefined(id);

  const { initialValues, isPending, error } =
    useEditProjectFormInitialValues(id);

  if (isPending) {
    return <SuspenseLoader />;
  }

  if (error || !initialValues) {
    return <ErrorMessage error={error} />;
  }

  assertDefined(initialValues);

  return (
    <ProjectFormRouteProvider passedInitialValues={initialValues}>
      {children}
    </ProjectFormRouteProvider>
  );
};
