import { useMutation, useQueryClient } from '@tanstack/react-query';
import { merge } from 'lodash-es';

import type { UniversalQueryOptions } from '@ll-platform/frontend/core/api/reactQuerySetup';
import { useActiveUser } from '@ll-platform/frontend/features/auth/hooks/useActiveUser';
import { userSettingsService } from '@ll-platform/frontend/features/auth/userSettings/async/UserSettingsService';
import { UserSettingsQueries } from '@ll-platform/frontend/features/auth/userSettings/async/useUserSettingsQueries';
import type {
  UpdateUserSettingsArgs,
  UserSettings,
} from '@ll-platform/frontend/features/auth/userSettings/types';
import { usersService } from '@ll-platform/frontend/features/users/async/UsersService';
import { UsersQueries } from '@ll-platform/frontend/features/users/async/useUsersQueries';
import { createMutationHook } from '@ll-platform/frontend/utils/factories/createMutationHook';

const UserSettingsMutations = {
  updateUserSettings: (userId: UpdateUserSettingsArgs['userId']) => ({
    mutationFn: (settingsData: UpdateUserSettingsArgs['settingsData']) => {
      return userSettingsService.updateUserSettingsDocument({
        userId,
        settingsData,
      });
    },
  }),
};

export function useUpdateActiveUserSettings(options?: UniversalQueryOptions) {
  const { activeUser } = useActiveUser();
  const queryClient = useQueryClient();

  return useMutation({
    ...UserSettingsMutations.updateUserSettings(activeUser._id),
    ...options,
    onSuccess(_, updatedData) {
      // update cache as fast as possible
      queryClient.setQueriesData<UserSettings>(
        UserSettingsQueries.getActiveUserSettings({ userId: activeUser._id }),
        (oldData) => {
          return merge({}, oldData ?? {}, updatedData);
        },
      );
      queryClient.invalidateQueries({ queryKey: UserSettingsQueries._def });
    },
  });
}

export const useCreateUser = createMutationHook(
  usersService.create.bind(usersService),
  (queryClient) => ({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: UsersQueries._def,
      });
    },
  }),
);
