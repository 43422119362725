import type { FunctionComponent, ReactNode } from 'react';

import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';

interface BaseLayoutProps {
  children?: ReactNode;
}

export const BaseLayout: FunctionComponent<BaseLayoutProps> = ({
  children,
}) => {
  return (
    <Box
      sx={{
        flex: 1,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {children || <Outlet />}
    </Box>
  );
};
