import {
  useLayoutEffect,
  useRef,
  useState,
  type PropsWithChildren,
} from 'react';

import { Box, Stack, type BoxProps, type StackProps } from '@mui/material';
import { debounce } from 'lodash-es';

type EllipsisProps = {
  visible: boolean;
  ellipsisWidth: number;
} & BoxProps;

const Ellipsis = ({ visible, ellipsisWidth, sx, ...props }: EllipsisProps) => {
  const ellipsisToggledStyles = visible
    ? {
        transition: '0.35s',
        width: `${ellipsisWidth}px`,
      }
    : {
        transition: 'none',
        width: 0,
      };

  return (
    <Box
      {...props}
      sx={{
        background: 'white',
        height: '100%',
        top: 0,
        display: 'flex',
        justifyContent: 'center',
        ...sx,
        zIndex: 1,
        position: 'absolute',
        overflow: 'hidden',
        ...ellipsisToggledStyles,
      }}
    >
      ...
    </Box>
  );
};

type ContainerWithElipsisProps = {
  onResize?: () => void;
  enableLeadingEllipsis?: boolean;
  enableTrailingEllipsis?: boolean;
  leadingEllipsisProps?: Omit<EllipsisProps, 'visible' | 'ellipsisWidth'>;
  trailingEllipsisProps?: Omit<EllipsisProps, 'visible' | 'ellipsisWidth'>;
  ellipsisWidth?: number;
} & StackProps;

export const ContainerWithEllipsis = ({
  children,
  onResize,
  enableLeadingEllipsis = false,
  enableTrailingEllipsis = true,
  leadingEllipsisProps = {},
  trailingEllipsisProps = {},
  ellipsisWidth = 10,
  ...props
}: PropsWithChildren<ContainerWithElipsisProps>) => {
  const [isLeadingEllipsisVisible, setIsLeadingEllipsisVisible] =
    useState(false);
  const [isTrailingEllipsisVisible, setIsTrailingEllipsisVisible] =
    useState(false);
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);

  useLayoutEffect(() => {
    const container = scrollContainerRef.current;

    if (!container) {
      return;
    }

    const setComputedElipses = () => {
      if (!container) {
        return;
      }

      if (enableLeadingEllipsis) {
        if (container.scrollLeft >= ellipsisWidth) {
          setIsLeadingEllipsisVisible(true);
        } else {
          setIsLeadingEllipsisVisible(false);
        }
      }

      if (enableTrailingEllipsis) {
        if (
          container.scrollWidth -
            container.scrollLeft -
            container.clientWidth >=
          ellipsisWidth
        ) {
          setIsTrailingEllipsisVisible(true);
        } else {
          setIsTrailingEllipsisVisible(false);
        }
      }
    };

    const handleScroll = () => {
      setComputedElipses();
    };

    const handleResize = debounce(() => {
      setComputedElipses();
      onResize?.();
    }, 50);

    setComputedElipses();
    container.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);

    return () => {
      container.removeEventListener('scroll', handleScroll);
      handleResize.cancel();
      window.removeEventListener('resize', handleResize);
    };
  }, [enableLeadingEllipsis, enableTrailingEllipsis, onResize, ellipsisWidth]);

  return (
    <Stack position="relative" overflow="hidden" width="100%">
      {!!enableLeadingEllipsis && (
        <Ellipsis
          {...leadingEllipsisProps}
          ellipsisWidth={ellipsisWidth}
          visible={isLeadingEllipsisVisible}
          sx={{
            ...(leadingEllipsisProps?.sx ?? {}),
            left: 0,
          }}
        />
      )}

      <Stack
        {...props}
        direction="row"
        sx={{
          ...props.sx,
          position: 'relative',
          overflowY: 'hidden',
          scrollbarWidth: 'none', // hiding scrollbar in firefox
          '&::-webkit-scrollbar': {
            // hiding scrollbar in chrome, safari, edge
            width: 0,
            height: 0,
          },
        }}
        ref={scrollContainerRef}
      >
        {children}
      </Stack>

      {!!enableTrailingEllipsis && (
        <Ellipsis
          {...trailingEllipsisProps}
          ellipsisWidth={ellipsisWidth}
          visible={isTrailingEllipsisVisible}
          sx={{
            ...(trailingEllipsisProps?.sx ?? {}),
            right: 0,
          }}
        />
      )}
    </Stack>
  );
};
