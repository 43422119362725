import { ActivityType } from '@ll-platform/frontend/core/analytics/events';
import { AccountType, type User } from '@ll-platform/frontend/core/auth/types';
import type { ProjectWithDeliverables } from '@ll-platform/frontend/features/projects/types';
import { wizardGenerateInternalEmail } from '@ll-platform/frontend/features/projectWizard/reviews/emailTemplates/wizardGenerateInternalEmail';
import {
  reviewDefaultHeading,
  ReviewModalTitle,
} from '@ll-platform/frontend/features/reviews/consts';
import type { ReviewModalTemplate } from '@ll-platform/frontend/features/reviews/types';
import { getReviewEmailUsers } from '@ll-platform/frontend/features/reviews/utils/getReviewEmailUsers';

import { reviewWizardAnalyticsMetadata } from './common/reviewWizardAnalyticsMetadata';
import { reviewWizardToastParams } from './common/reviewWizardTexts';

type wizardGenerateInternalModalTemplateArgs = {
  users: User[];
  activeUser: User;
  brandName?: string;
  project?: ProjectWithDeliverables;
  page: string;
};

export const wizardGenerateInternalModalTemplate = ({
  brandName,
  users,
  project,
  activeUser,
  page,
}: wizardGenerateInternalModalTemplateArgs): ReviewModalTemplate => {
  return {
    reviewEmailData: wizardGenerateInternalEmail({
      ...getReviewEmailUsers({
        users,
        recipientsTypes: [
          AccountType.User,
          AccountType.Creative,
          AccountType.Producer,
        ],
      }),
      activeUser,
      project,
      brandName,
    }),
    titleContent: ReviewModalTitle.notifyClientForReview,
    headingContent: reviewDefaultHeading,
    toastParams: reviewWizardToastParams,
    analyticsMetadata: reviewWizardAnalyticsMetadata({
      submitType: ActivityType.CreativeBriefClickedSubmitForReview,
      cancelType: ActivityType.CreativeBriefCanceledSubmittingForReview,
      activeUser,
      page,
      project,
    }),
  };
};
