import { useParams } from 'react-router-dom';

import { assertDefined } from '@ll-platform/frontend/utils/types/types';

export function useCreativeProposalPageParams() {
  const { id } = useParams<{ id: string }>();
  assertDefined(id, 'creativeProposalId path param');

  return { id };
}
