import type { AvatarProps } from '@mui/material';

import { PersonAvatar } from '@ll-platform/frontend/components/PersonAvatar/PersonAvatar';
import type { User } from '@ll-platform/frontend/core/auth/types';
import { getPersonInitials } from '@ll-platform/frontend/utils/helpers/helpers';

export type UserAvatarProps = AvatarProps & {
  user: User | null | undefined;
  size?: number;
};

export const UserAvatar = ({ user, size = 32, ...props }: UserAvatarProps) => {
  return (
    <PersonAvatar src={user?.avatar?.url} size={size} {...props}>
      {props.children ?? getPersonInitials(user)}
    </PersonAvatar>
  );
};
