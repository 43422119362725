/* eslint-disable no-invalid-this */
import dayjs from 'dayjs';
import * as yup from 'yup';

import { FORM_ERRORS } from '@ll-platform/frontend/consts/formErrors';

yup.setLocale(FORM_ERRORS);

yup.addMethod(yup.mixed, 'dayjs', function (message = 'Invalid date') {
  return this.test('dayjs', message, (value) => {
    // treat empty values as valid, this should be handled by required() if needed
    if (!value) {
      return true;
    }

    return dayjs.isDayjs(value) && dayjs(value).isValid();
  });
});

declare module 'yup' {
  interface MixedSchema {
    dayjs(message?: string): MixedSchema<dayjs.Dayjs>;
  }
}
