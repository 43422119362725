import { FormHelperText, type FormHelperTextProps } from '@mui/material';

type CharacterCounterProps = FormHelperTextProps & {
  value: string;
  maxLength: number;
};

export const CharacterCounter = ({
  value,
  maxLength,
  ...props
}: CharacterCounterProps) => {
  return (
    <FormHelperText
      {...props}
      sx={{
        color: (theme) => `${theme.palette.text.secondary} !important`,
        ...props.sx,
      }}
    >
      {value.length}/{maxLength}
    </FormHelperText>
  );
};
