import { TextSelect } from 'lucide-react';

import {
  Card,
  CardContent,
} from '@ll-platform/frontend/components/shadcn/ui/card';
import { TwTypography } from '@ll-platform/frontend/components/tw/TwTypography/TwTypography';

export const EmptyConcepts = () => {
  return (
    <div className="w-full h-[calc(100vh_-_113px)]">
      <div className="flex flex-col items-center justify-center h-full m-6 border-dashed border border-grayscale-700">
        <Card className="rounded-xl overflow-hidden mb-6 w-auto h-auto min-w-0">
          <CardContent className="p-4">
            <TextSelect className="text-foreground" />
          </CardContent>
        </Card>
        <TwTypography variant="large" className="mb-2 text-foreground">
          No concepts yet
        </TwTypography>
        <TwTypography variant="muted" className="text-muted-foreground">
          Fill out the form to generate your concepts.
        </TwTypography>
      </div>
    </div>
  );
};
