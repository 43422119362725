import { EventSourceParserStream } from 'eventsource-parser/stream';

import { APP_CONFIG } from '@ll-platform/frontend/config/app.config';
import { MAX_BACKEND_STREAM_TIMEOUT } from '@ll-platform/frontend/core/api/consts';
import { heroHttpClient } from '@ll-platform/frontend/core/api/HeroHttpClient';
import { AbortError } from '@ll-platform/frontend/features/llm/async/errors';
import type {
  AnyJsonOutput,
  GenericLlmGeneratorResponseDto,
} from '@ll-platform/frontend/features/llmGenerators/types';
import {
  assertDefined,
  type DeepPartialObject,
} from '@ll-platform/frontend/utils/types/types';

const STREAM_COMPLETE_EVENT_DATA = '[DONE]';

export type StreamJsonOptions = {
  abortSignal?: AbortSignal;
};

export async function* streamJson<Output extends AnyJsonOutput>(
  { method, endpoint }: { method: 'POST' | 'PATCH'; endpoint: string },
  data: unknown,
  { abortSignal }: StreamJsonOptions = {},
): AsyncGenerator<
  GenericLlmGeneratorResponseDto<DeepPartialObject<Output>>,
  GenericLlmGeneratorResponseDto<Output>
> {
  const abortController = new AbortController();
  abortSignal?.addEventListener('abort', (event) =>
    abortController.abort((event.target as AbortSignal).reason),
  );
  const timeoutId = setTimeout(
    () => abortController.abort('timeout'),
    MAX_BACKEND_STREAM_TIMEOUT,
  );

  try {
    const response = await fetch(`${APP_CONFIG.REACT_APP_API_URL}${endpoint}`, {
      method,
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        ...(await heroHttpClient.getAuthHeaders()),
      },
    });

    if (!response.ok) {
      if (response.status === 401) {
        await heroHttpClient.defaultHandleUnauthorized();
      }
      throw new Error('Network error');
    }

    assertDefined(response.body, 'response.body');

    const reader = response.body
      .pipeThrough(new TextDecoderStream())
      .pipeThrough(new EventSourceParserStream())
      .getReader();

    let chunk: DeepPartialObject<Output> | null = null;
    while (true) {
      if (abortController.signal?.aborted) {
        throw new AbortError(abortController.signal.reason);
      }
      const { done, value } = await reader.read();

      if (value?.data === STREAM_COMPLETE_EVENT_DATA) {
        break;
      }

      if (done) {
        throw new Error('Stream ended without confirmation');
      }

      // chunks are already aggregated into complete json on the backend
      chunk = JSON.parse(value.data) as DeepPartialObject<Output>;
      if (!chunk) {
        continue;
      }

      yield {
        output: chunk,
      };
    }

    return {
      output: chunk as Output,
    };
  } finally {
    clearTimeout(timeoutId);
  }
}
