import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import {
  Box,
  Button,
  ClickAwayListener,
  Stack,
  Typography,
} from '@mui/material';
import { ErrorBoundary } from 'react-error-boundary';

import {
  useGetCommentById,
  useGetCommentsByThreadId,
} from '@ll-platform/frontend/features/projectComments/async/useProjectCommentsQueries';
import { CommentEditMessage } from '@ll-platform/frontend/features/projectComments/components/CommentEditMessage';
import { CommentMessage } from '@ll-platform/frontend/features/projectComments/components/CommentMessage';
import type { CommentMessageType } from '@ll-platform/frontend/features/projectComments/types';
import { useActiveProjectIdFromProposalOrParams } from '@ll-platform/frontend/features/projectWizard/hooks/useActiveProject';
import type { TextEditorCommentsPluginConfig } from '@ll-platform/frontend/features/textEditor/comments/types';
import type { TextEditorEditMode } from '@ll-platform/frontend/features/textEditor/types';
import { defined } from '@ll-platform/frontend/utils/types/types';

const ThrowUnavailableThreadError = () => {
  throw new Error('Accessed unavailable comment thread');
};

type CommentThreadProps = {
  threadId: string;
  commentsConfig?: TextEditorCommentsPluginConfig;
  onAddReply: ({ message }: { message: string }) => Promise<unknown>;
  onRemoveComment: ({ id }: { id: string }) => Promise<unknown>;
  onResolve?: () => Promise<unknown>;
  onReopen?: () => Promise<unknown>;
  isTogglingResolve?: boolean;
  onUpdate: (
    data: { id: string } & Partial<CommentMessageType>,
  ) => Promise<unknown>;
  onCancel: () => void;
  onEditStart?: () => void;
  onEditFinish?: () => void;
  onInputChange?: (value: string) => void;
  onLoad?: (threadId: string) => void;
  editMode: TextEditorEditMode;
  isInSidebar?: boolean;
  isReadOnly?: boolean;
  index?: number;
};

export const CommentThread = ({
  editMode,
  threadId,
  isReadOnly,
  isInSidebar,
  commentsConfig,
  onUpdate,
  onInputChange,
  onRemoveComment,
  onResolve,
  onReopen,
  isTogglingResolve,
  onEditStart,
  onEditFinish,
  onAddReply,
  onCancel,
  onLoad,
  index,
}: CommentThreadProps) => {
  const threadContainerRef = useRef<HTMLDivElement>(null);
  const [showReply, setShowReply] = useState(false);

  const projectId = useActiveProjectIdFromProposalOrParams();
  const isQueryEnabled = !!threadId && !!projectId;

  const { data: foundingComment, isPending: isFoundingCommentPending } =
    useGetCommentById(
      {
        projectId,
        id: threadId,
      },
      {
        enabled: isQueryEnabled,
      },
    );

  const { data: commentsThreadData } = useGetCommentsByThreadId(
    {
      projectId,
      threadId,
    },
    {
      enabled: isQueryEnabled,
    },
  );

  const comments = useMemo(() => {
    return [
      commentsThreadData?.filter(
        (comment) => !comment.isDeleted && comment.id !== threadId,
      ),
    ]
      .flat()
      .filter(defined);
  }, [commentsThreadData, threadId]);

  useEffect(() => {
    if (!isInSidebar && commentsThreadData?.length) {
      const scrollToBottom = () => {
        if (threadContainerRef.current) {
          threadContainerRef.current.scrollIntoView({
            block: 'end',
          });
        }
      };

      scrollToBottom();
    }
  }, [commentsThreadData?.length, isInSidebar]);

  useEffect(() => {
    if (commentsThreadData) {
      onLoad?.(threadId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commentsThreadData, onLoad]);
  const handleSubmitNewComment = useCallback(
    async ({ message }: { message: string }) => {
      await onAddReply({ message });
      setShowReply(false);
    },
    [onAddReply],
  );

  const replyEnabled = !isReadOnly && (!isInSidebar || showReply);

  return (
    <Stack p={2} pb={isReadOnly ? 1 : undefined} ref={threadContainerRef}>
      {isQueryEnabled && !isFoundingCommentPending && !foundingComment ? (
        <ErrorBoundary
          fallback={<Typography>Failed to load the comment thread</Typography>}
        >
          <ThrowUnavailableThreadError />
        </ErrorBoundary>
      ) : (
        <>
          {/* showing thread founding comment seperately as it should be shown even if deleted if user found a link to it in output */}
          <CommentMessage
            isFounding
            index={index}
            comment={foundingComment}
            commentsConfig={commentsConfig}
            isReadOnly={isReadOnly}
            editMode={editMode}
            onSubmit={onUpdate}
            onRemove={onRemoveComment}
            onToggleResolve={foundingComment?.isResolved ? onReopen : onResolve}
            isTogglingResolve={isTogglingResolve}
            onEditStart={onEditStart}
            onEditFinish={onEditFinish}
            onInputChange={onInputChange}
            showThreadLine={comments.length > 0}
            showQuote={isInSidebar}
            alwaysShowMenu={isInSidebar}
          />
          {comments.map((comment, i) => {
            return (
              <CommentMessage
                key={comment.id || 'thread-loading'}
                comment={comment}
                commentsConfig={commentsConfig}
                isReadOnly={isReadOnly}
                editMode={editMode}
                onSubmit={onUpdate}
                onRemove={onRemoveComment}
                onEditStart={onEditStart}
                onEditFinish={onEditFinish}
                onInputChange={onInputChange}
                showThreadLine={i !== comments.length - 1}
                alwaysShowMenu={isInSidebar}
              />
            );
          })}
          {!isReadOnly && isInSidebar && !showReply && (
            <Box>
              <Button
                sx={{ ml: 3.5, color: (theme) => theme.palette.text.primary }}
                size="small"
                onClick={() => setShowReply(true)}
              >
                Reply
              </Button>
            </Box>
          )}
          {replyEnabled && (
            <ClickAwayListener onClickAway={() => setShowReply(false)}>
              <Box>
                <CommentEditMessage
                  editMode="newComment"
                  onSubmit={handleSubmitNewComment}
                  onCancel={onCancel}
                  onInputChange={onInputChange}
                  isReply
                />
              </Box>
            </ClickAwayListener>
          )}
        </>
      )}
    </Stack>
  );
};
