import { useEffect } from 'react';

import Smartlook from 'smartlook-client';

import { APP_CONFIG } from '@ll-platform/frontend/config/app.config';
import { analyticsDebugLog } from '@ll-platform/frontend/core/analytics/activityTracker';
import type { AnyUser } from '@ll-platform/frontend/core/hooks/useAnyUser';
import { useOptionalActiveOrganization } from '@ll-platform/frontend/features/organizations/hooks/useActiveOrganization';

export const useSmartlook = (activeUser?: AnyUser) => {
  const { activeOrganization } = useOptionalActiveOrganization();

  useEffect(() => {
    const key = APP_CONFIG.REACT_APP_SMARTLOOK_KEY;
    if (!key) {
      return;
    }
    analyticsDebugLog(`Initializing Smartlook`);
    Smartlook.init(key);
  }, []);

  useEffect(() => {
    if (!Smartlook.initialized()) {
      return;
    }

    if (activeUser) {
      const { id: _id, email, accountType, isActive } = activeUser;
      analyticsDebugLog(`Identifying user ${_id} ${email} in Smartlook`);
      if (activeOrganization) {
        analyticsDebugLog(
          `Identifying organization ${activeOrganization.id} ${activeOrganization.name} in Smartlook`,
        );
      }
      Smartlook.identify(_id, {
        email,
        accountType,
        isActive,
        ...(activeOrganization && {
          organizationId: activeOrganization.id,
          organizationName: activeOrganization.name,
          organizationDomain: activeOrganization.domain ?? '',
        }),
      });
    } else {
      analyticsDebugLog(`Deidentifying user in Smartlook`);
      Smartlook.anonymize();
    }
  }, [activeUser, activeOrganization]);
};
