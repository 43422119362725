import type { ReactNode } from 'react';

import { CloseOutlined } from '@mui/icons-material';
import {
  Checkbox,
  FormControlLabel,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';

type CommentsSidebarHeaderProps = {
  title: ReactNode;
  close?: () => void;
  isShowingResolved: boolean;
  onToggleResolved: () => void;
};

export const CommentsSidebarHeader = ({
  title,
  close,
  isShowingResolved,
  onToggleResolved,
}: CommentsSidebarHeaderProps) => {
  return (
    <Stack>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        gap={2}
        sx={{ px: 2, py: 1.5 }}
      >
        <Typography variant="h6">{title}</Typography>
        {close && (
          <IconButton size="medium" onClick={close}>
            <CloseOutlined />
          </IconButton>
        )}
      </Stack>

      <FormControlLabel
        label="Show resolved comments"
        control={
          <Checkbox checked={isShowingResolved} onChange={onToggleResolved} />
        }
        sx={{
          ml: 1,
          '& .MuiFormControlLabel-label': {
            fontSize: '12px',
            lineHeight: '166%',
            letterSpacing: '0.4px',
          },
        }}
      />
    </Stack>
  );
};
