import { useQueryActiveUser } from '@ll-platform/frontend/features/auth/async/hooks';

export function useActiveUser() {
  const {
    data: activeUser,
    error,
    isPending,
  } = useQueryActiveUser(
    {},
    {
      enabled: false,
    },
  );

  if (!activeUser) {
    // if isLoading is true, route is not secured by IsLoggedInGuard
    throw new Error(`User is not authorized, pending: ${isPending}`, {
      cause: error,
    });
  }

  return {
    activeUser,
  };
}
