import { type PropsWithChildren } from 'react';

import { useParams } from 'react-router-dom';

import Status404 from '@ll-platform/frontend/components/statusPages/Status404';
import SuspenseLoader from '@ll-platform/frontend/components/SuspenseLoader';
import { AnalyticsProvider } from '@ll-platform/frontend/core/analytics/AnalyticsContext';
import { mapProjectToAnalytics } from '@ll-platform/frontend/features/analytics/eventUtils';
import { useGetProjectBaseDataById } from '@ll-platform/frontend/features/projects/async/useProjectsQueries';

export const ActiveProjectBaseDataGuard = ({ children }: PropsWithChildren) => {
  const { id } = useParams();
  const { data: activeProject, isPending } = useGetProjectBaseDataById({ id });

  if (isPending) {
    return <SuspenseLoader />;
  }

  if (!activeProject) {
    return <Status404 />;
  }

  return (
    <AnalyticsProvider metadata={mapProjectToAnalytics(activeProject)}>
      {children}
    </AnalyticsProvider>
  );
};
