import type { AccountType } from '@ll-platform/frontend/core/auth/types';
import { isUserInternal } from '@ll-platform/frontend/features/auth/utils/isInternal';
import { ReviewEditGeneratedDocsKey } from '@ll-platform/frontend/features/projectSpace/reviews/reviewGeneratedDocsKeys';
import { WizardFlowType } from '@ll-platform/frontend/features/projectWizard/contexts/WizardNavigationContext';
import { makeReviewNotificationKey } from '@ll-platform/frontend/features/reviews/utils/makeReviewNotificationKey';

export enum ReviewWizardFlowKey {
  CreativeBriefInternal = 'creativeBrief:internal',
  // Creative Brief is dedicated to Internals (Account Executives) and not available for externals
  WizardGenerateInternal = 'wizardGenerate:internal',
  WizardGenerateExternal = 'wizardGenerate:external',
  WizardGenerateExternalLegacy = 'wizardGenerate:external:legacy',
}

type ReviewWizardFlowKeyProps = {
  flowType: WizardFlowType;
  user: { accountType?: AccountType };
  isNewFlowEnabled: boolean;
};

export const reviewWizardFlowKey = ({
  flowType,
  user,
  isNewFlowEnabled,
}: ReviewWizardFlowKeyProps):
  | ReviewWizardFlowKey
  | ReviewEditGeneratedDocsKey => {
  switch (flowType) {
    case WizardFlowType.CreativeBrief:
      return ReviewWizardFlowKey.CreativeBriefInternal;
    case WizardFlowType.Generate:
      if (!isNewFlowEnabled && !isUserInternal(user)) {
        return ReviewWizardFlowKey.WizardGenerateExternalLegacy;
      }

      return isUserInternal(user)
        ? ReviewWizardFlowKey.WizardGenerateInternal
        : ReviewWizardFlowKey.WizardGenerateExternal;
    case WizardFlowType.EditCreativeDeck:
      return isUserInternal(user)
        ? ReviewEditGeneratedDocsKey.CreativeDeckEditInternal
        : ReviewEditGeneratedDocsKey.CreativeDeckEditExternal;
    case WizardFlowType.EditCallSheet:
      return isUserInternal(user)
        ? ReviewEditGeneratedDocsKey.CallSheetEditInternal
        : ReviewEditGeneratedDocsKey.CallSheetEditExternal;
    default:
      throw new Error('This flow is not supported');
  }
};

type MakeReviewWizardFlowKeyArgs = {
  projectId: string;
  reviewNotificationType: ReviewWizardFlowKey | ReviewEditGeneratedDocsKey;
  resourceId?: string;
};

export const makeReviewWizardFlowKey = ({
  projectId,
  reviewNotificationType,
  resourceId,
}: MakeReviewWizardFlowKeyArgs): string => {
  return makeReviewNotificationKey([
    'project',
    projectId,
    reviewNotificationType,
    resourceId,
  ]);
};
