import type { ElementType } from 'react';

import { cn } from '@ll-platform/frontend/components/shadcn/lib/utils';

import type { TypographyVariant } from './types';

type VariantOptions = Record<TypographyVariant, string>;
type ElementOptions = Partial<Record<TypographyVariant, ElementType>>;

export const typographyVariants: VariantOptions = {
  h1: 'text-4xl/10 tracking-tight font-semibold lg:text-5xl/[100%] lg:tracking-tight lg:font-semibold',
  h2: 'text-3xl/9 tracking-tight font-semibold',
  h3: 'text-2xl/8 tracking-tight font-semibold',
  h4: 'text-xl/7 tracking-tight font-semibold',
  body: 'text-base/7 tracking-normal font-normal',
  blockquote:
    'text-base/7 tracking-normal font-normal italic pl-6 border-l-2 border-l-grayscale-2',
  list: 'text-base/7 tracking-normal font-normal',
  'inline-code':
    'text-sm/5 tracking-normal font-bold bg-muted inline-block text-foreground rounded-sm',
  lead: 'text-xl/7 font-normal tracking-normal text-muted-foreground',
  large: 'text-lg/7 font-semibold tracking-normal',
  small: 'text-sm/5 font-medium tracking-normal',
  muted: 'text-sm/5 font-medium tracking-normal text-muted-foreground',
};

const typographyDefaultComponents: ElementOptions = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  blockquote: 'blockquote',
  list: 'li',
  'inline-code': 'code',
};

export const getTypographyVariantStyles = (variant?: TypographyVariant) => {
  if (typeof variant === 'string') {
    return cn(typographyVariants[variant]);
  }

  return typographyVariants.body;
};

export const getTypographyDefaultComponent = (variant?: TypographyVariant) => {
  if (typeof variant === 'string') {
    return typographyDefaultComponents[variant];
  }

  return null;
};
