import { Skeleton } from '@ll-platform/frontend/components/shadcn/ui/skeleton';

export const ConceptsSkeletonTitle = () => (
  <div className="flex flex-row justify-between">
    <Skeleton className="h-4 w-1/2" />
    <Skeleton className="h-4 w-[54px]" />
  </div>
);

export const ConceptsSkeletonContent = () => (
  <div className="space-y-2 p-3 min-h-[160px] border border-popover rounded-md">
    <Skeleton className="h-4 w-full" />
    <Skeleton className="h-4 w-10/12" />
    <Skeleton className="h-4 w-[95%]" />
    <Skeleton className="h-4 w-9/12" />
  </div>
);

export const ConceptsSkeletonMoodboardImage = () => (
  <Skeleton className="h-[125px] w-full" />
);

export const ConceptsSkeletonMoodboardImages = () => (
  <div className="w-full space-x-4 flex flex-row">
    <ConceptsSkeletonMoodboardImage />
    <ConceptsSkeletonMoodboardImage />
    <ConceptsSkeletonMoodboardImage />
    <ConceptsSkeletonMoodboardImage />
  </div>
);

export const ConceptsSkeleton = () => {
  return (
    <div className="w-full space-y-7 rounded-lg border border-border p-6 bg-card shadow-sm">
      <ConceptsSkeletonTitle />
      <ConceptsSkeletonContent />
      <ConceptsSkeletonMoodboardImages />
    </div>
  );
};
