import { Navigate, Outlet, type RouteObject } from 'react-router-dom';

import { BaseLayout } from '@ll-platform/frontend/components/layouts/BaseLayout';
import {
  DashboardLayout,
  ExternalDashboardLayout,
} from '@ll-platform/frontend/components/layouts/DashboardLayout/DashboardLayout';
import {
  lazyLoad,
  withLoader,
} from '@ll-platform/frontend/core/router/helpers';
import { Pages } from '@ll-platform/frontend/core/router/pages';
import {
  IsExternalLoggedInGuard,
  IsInternalLoggedInGuard,
  IsLoggedInGuard,
} from '@ll-platform/frontend/features/auth/guards/IsLoggedInGuard';
import { AuthRedirectController } from '@ll-platform/frontend/features/auth/pages/AuthRedirectController';
import { InternalDashboardLayout } from '@ll-platform/frontend/features/internalDashboard/components/layout/InternalDashboardLayout';
import { NavigationBarLayout } from '@ll-platform/frontend/features/internalDashboard/components/layout/NavigationBarLayout';
import { InternalDashboardRedirectController } from '@ll-platform/frontend/features/internalDashboard/pages/InternalDashboardRedirectController';
import { EditProjectFormProvider } from '@ll-platform/frontend/features/internalProjects/pages/ProjectForm/routeStepper/EditProjectFormProvider';
import { ProjectFormRouteLayout } from '@ll-platform/frontend/features/internalProjects/pages/ProjectForm/routeStepper/ProjectFormRouteLayout/ProjectFormRouteLayout';
import { ProjectFormRouteProvider as ProjectFormProvider } from '@ll-platform/frontend/features/internalProjects/pages/ProjectForm/routeStepper/ProjectFormRouteProvider';
import { OrganizationGuard } from '@ll-platform/frontend/features/organizations/guards/OrganizationGuard';
import { ActiveProjectBaseDataGuard } from '@ll-platform/frontend/features/projects/guards/ActiveProjectBaseDataGuard';
import { ProjectSpaceFeatureFlagGuard } from '@ll-platform/frontend/features/projectSpace/pages/ProjectSpaceFeatureFlagGuard';
import { WizardLayout } from '@ll-platform/frontend/features/projectWizard/components/layout/WizardLayout';
import ActiveProjectContainer from '@ll-platform/frontend/features/projectWizard/containers/ActiveProjectContainer';
import { WizardNavigationContextProvider } from '@ll-platform/frontend/features/projectWizard/contexts/WizardNavigationContext';
import ActiveProposalContainer from '@ll-platform/frontend/features/proposals/containers/ActiveProposalContainer';
import ActiveProposalProjectsContainer from '@ll-platform/frontend/features/proposals/containers/ActiveProposalProjectsContainer';
import { CreativeProposalFeatureFlagGuard } from '@ll-platform/frontend/features/proposals/pages/CreativeProposalFeatureFlagGuard';
import { RedirectInternalProjectSpace } from '@ll-platform/frontend/features/RedirectInternalProjectSpace';
import { VideoPlayerPlayground } from '@ll-platform/frontend/features/videoPlayer/VideoPlayerPlayground';

const ProjectsListPage = withLoader(
  lazyLoad(
    () =>
      import(
        '@ll-platform/frontend/features/projects/pages/ProjectsList/ProjectsListPage'
      ),
  ).default,
);
const InternalBrandPage = withLoader(
  lazyLoad(
    () =>
      import(
        '@ll-platform/frontend/features/brands/pages/internal/InternalBrandPage/InternalBrandPage'
      ),
  ).default,
);

const ProjectFormController = withLoader(
  lazyLoad(
    () =>
      import(
        '@ll-platform/frontend/features/internalProjects/pages/ProjectForm/routeStepper/ProjectFormRouteController'
      ),
  ).ProjectFormRouteController,
);

const ProjectFormDefaultStepController = withLoader(
  lazyLoad(
    () =>
      import(
        '@ll-platform/frontend/features/internalProjects/pages/ProjectForm/routeStepper/ProjectFormDefaultStepRouteController'
      ),
  ).ProjectFormDefaultStepRouteController,
);

const InternalProjectListPage = withLoader(
  lazyLoad(
    () =>
      import(
        '@ll-platform/frontend/features/internalProjects/pages/ProjectsListPage/ProjectsListPage'
      ),
  ).default,
);
const ContractsPage = withLoader(
  lazyLoad(
    () =>
      import(
        '@ll-platform/frontend/features/contracts/pages/ContractsPage/ContractsPage'
      ),
  ).ContractsPage,
);
const NewContractPage = withLoader(
  lazyLoad(
    () =>
      import(
        '@ll-platform/frontend/features/contracts/pages/NewContractPage/NewContractPage'
      ),
  ).NewContractPage,
);
const EditContractPage = withLoader(
  lazyLoad(
    () =>
      import(
        '@ll-platform/frontend/features/contracts/pages/EditContractPage/EditContractPage'
      ),
  ).EditContractPage,
);
const ProposalPage = withLoader(
  lazyLoad(
    () =>
      import(
        '@ll-platform/frontend/features/proposals/pages/ProposalPage/ProposalPage'
      ),
  ).ProposalPage,
);
const ProposalDoc = withLoader(
  lazyLoad(
    () =>
      import(
        '@ll-platform/frontend/features/proposals/pages/ProposalPage/ProposalDoc'
      ),
  ).ProposalDoc,
);
const NewProposalPage = withLoader(
  lazyLoad(
    () =>
      import(
        '@ll-platform/frontend/features/proposals/pages/NewProposalPage/NewProposalPage'
      ),
  ).NewProposalPage,
);
const EditProposalPage = withLoader(
  lazyLoad(
    () =>
      import(
        '@ll-platform/frontend/features/proposals/pages/EditProposalPage/EditProposalPage'
      ),
  ).EditProposalPage,
);

const ProjectSpaceDefaultRouteController = withLoader(
  lazyLoad(
    () =>
      import(
        '@ll-platform/frontend/features/projectSpace/pages/ProjectSpaceDefaultRouteController'
      ),
  ).ProjectSpaceDefaultRouteController,
);

const ProjectSpaceController = withLoader(
  lazyLoad(
    () =>
      import(
        '@ll-platform/frontend/features/projectSpace/pages/ProjectSpaceController'
      ),
  ).ProjectSpaceController,
);

const LoginPage = withLoader(
  lazyLoad(
    () =>
      import('@ll-platform/frontend/features/auth/pages/login/pages/LoginPage'),
  ).LoginPage,
);

const ResetPasswordController = withLoader(
  lazyLoad(
    () =>
      import(
        '@ll-platform/frontend/features/auth/pages/resetPassword/ResetPasswordController'
      ),
  ).ResetPasswordController,
);

const RegisterPage = withLoader(
  lazyLoad(
    () =>
      import('@ll-platform/frontend/features/auth/pages/register/RegisterPage'),
  ).RegisterPage,
);

const PlaygroundsIndex = withLoader(
  lazyLoad(
    () =>
      import(
        '@ll-platform/frontend/features/internalDashboard/pages/PlaygroundsIndex'
      ),
  ).PlaygroundsIndex,
);

const PromptPlayground = withLoader(
  lazyLoad(
    () =>
      import(
        '@ll-platform/frontend/features/llm/pages/PromptPlayground/PromptPlayground'
      ),
  ).PromptPlayground,
);

const WardrobePlayground = withLoader(
  lazyLoad(
    () =>
      import(
        '@ll-platform/frontend/features/projectWizard/pages/WardrobePlayground/WardrobePlayground'
      ),
  ).WardrobePlayground,
);

const ThemePlayground = withLoader(
  lazyLoad(
    () => import('@ll-platform/frontend/core/theme/playground/ThemePlayground'),
  ).ThemePlayground,
);

const TextEditorPlayground = withLoader(
  lazyLoad(
    () =>
      import('@ll-platform/frontend/features/textEditor/TextEditorPlayground'),
  ).TextEditorPlayground,
);

const ProjectSpacePlayground = withLoader(
  lazyLoad(
    () =>
      import(
        '@ll-platform/frontend/features/projectSpace/ProjectSpacePlayground'
      ),
  ).ProjectSpacePlayground,
);

const ProjectWizardController = withLoader(
  lazyLoad(
    () =>
      import(
        '@ll-platform/frontend/features/projectWizard/pages/ProjectWizardController'
      ),
  ).ProjectWizardController,
);
const ProjectWizardDefaultStepController = withLoader(
  lazyLoad(
    () =>
      import(
        '@ll-platform/frontend/features/projectWizard/pages/ProjectWizardDefaultStepController'
      ),
  ).ProjectWizardDefaultStepController,
);

const ProposalDefaultProjectWizardController = withLoader(
  lazyLoad(
    () =>
      import(
        '@ll-platform/frontend/features/proposals/pages/ProposalDefaultProjectWizardController/ProposalDefaultProjectWizardController'
      ),
  ).ProposalDefaultProjectWizardController,
);

const MyAccountPage = withLoader(
  lazyLoad(
    () =>
      import(
        '@ll-platform/frontend/features/auth/pages/myAccount/MyAccountPage'
      ),
  ).MyAccountPage,
);

const VideoReviewPage = withLoader(
  lazyLoad(
    () =>
      import(
        '@ll-platform/frontend/features/postProductionMedia/pages/VideoReview/VideoReviewPage'
      ),
  ).VideoReviewPage,
);

const VideoPublicPage = withLoader(
  lazyLoad(
    () =>
      import(
        '@ll-platform/frontend/features/postProductionMedia/pages/VideoPublic/VideoPublicPage'
      ),
  ).VideoReviewPage,
);

export const appRoutes: RouteObject[] = [
  {
    path: '',
    element: (
      <BaseLayout>
        <AuthRedirectController />
      </BaseLayout>
    ),
  },
  {
    path: 'internal',
    children: [
      { path: '', element: <Navigate to="/" replace /> },
      {
        path: 'project-space',
        element: <RedirectInternalProjectSpace />,
        children: [
          {
            path: ':id',
            element: <RedirectInternalProjectSpace />,
            children: [
              {
                path: '',
                element: <RedirectInternalProjectSpace />,
              },
              {
                path: ':page',
                element: <RedirectInternalProjectSpace />,
              },
            ],
          },
        ],
      },
      {
        path: 'register',
        element: <Navigate to="/register" replace />,
      },
      {
        path: 'login',
        element: <Navigate to="/login" replace />,
      },

      {
        path: 'brand/:id',
        element: (
          <IsInternalLoggedInGuard>
            <OrganizationGuard>
              <InternalDashboardLayout>
                <Outlet />
              </InternalDashboardLayout>
            </OrganizationGuard>
          </IsInternalLoggedInGuard>
        ),
        children: [
          {
            path: '',
            element: <Navigate to="projects" relative="path" replace />,
          },
          {
            path: ':tab',
            element: <InternalBrandPage />,
          },
        ],
      },
      {
        path: 'dashboard',
        element: (
          <IsInternalLoggedInGuard>
            <OrganizationGuard>
              <Outlet />
            </OrganizationGuard>
          </IsInternalLoggedInGuard>
        ),
        children: [
          { path: '', element: <InternalDashboardRedirectController /> },
          {
            path: 'projects',
            element: (
              <InternalDashboardLayout>
                <InternalProjectListPage />
              </InternalDashboardLayout>
            ),
          },
          {
            path: 'contracts',
            children: [
              {
                path: '',
                element: (
                  <InternalDashboardLayout>
                    <ContractsPage />
                  </InternalDashboardLayout>
                ),
              },
              {
                path: 'new',
                element: (
                  <NavigationBarLayout>
                    <NewContractPage />
                  </NavigationBarLayout>
                ),
              },
              {
                path: 'edit',
                children: [
                  {
                    path: ':id',
                    element: (
                      <NavigationBarLayout>
                        <EditContractPage />
                      </NavigationBarLayout>
                    ),
                  },
                ],
              },
            ],
          },
          {
            path: 'proposals',
            children: [
              {
                path: '',
                element: (
                  <CreativeProposalFeatureFlagGuard>
                    <InternalDashboardLayout>
                      <ProposalPage />
                    </InternalDashboardLayout>
                  </CreativeProposalFeatureFlagGuard>
                ),
              },
              {
                path: 'new',
                element: (
                  <CreativeProposalFeatureFlagGuard>
                    <NavigationBarLayout>
                      <NewProposalPage />
                    </NavigationBarLayout>
                  </CreativeProposalFeatureFlagGuard>
                ),
              },
              {
                path: 'edit',
                children: [
                  {
                    path: ':id',
                    element: (
                      <CreativeProposalFeatureFlagGuard>
                        <NavigationBarLayout>
                          <EditProposalPage />
                        </NavigationBarLayout>
                      </CreativeProposalFeatureFlagGuard>
                    ),
                  },
                ],
              },
            ],
          },
          {
            path: 'proposal',
            children: [
              {
                path: ':proposalId',
                children: [
                  {
                    path: '',
                    element: <ProposalDefaultProjectWizardController />,
                  },
                  {
                    path: 'project',
                    element: (
                      <IsInternalLoggedInGuard>
                        <OrganizationGuard>
                          <Outlet />
                        </OrganizationGuard>
                      </IsInternalLoggedInGuard>
                    ),
                    children: [
                      {
                        path: ':id',
                        children: [
                          {
                            path: ':flowType',
                            element: (
                              <ActiveProjectContainer>
                                <WizardNavigationContextProvider>
                                  <WizardLayout>
                                    <Outlet />
                                  </WizardLayout>
                                </WizardNavigationContextProvider>
                              </ActiveProjectContainer>
                            ),
                            children: [
                              {
                                path: '',
                                element: <ProjectWizardDefaultStepController />,
                              },
                              {
                                path: ':step',
                                element: <ProjectWizardController />,
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: 'project',
        children: [
          {
            path: 'new',
            element: (
              <IsInternalLoggedInGuard>
                <OrganizationGuard>
                  <ProjectFormProvider>
                    <ProjectFormRouteLayout>
                      <Outlet />
                    </ProjectFormRouteLayout>
                  </ProjectFormProvider>
                </OrganizationGuard>
              </IsInternalLoggedInGuard>
            ),
            children: [
              {
                path: '',
                element: <ProjectFormDefaultStepController />,
              },
              {
                path: ':step',
                element: <ProjectFormController />,
              },
            ],
          },
          {
            path: 'edit/:id',
            element: (
              <IsInternalLoggedInGuard>
                <OrganizationGuard>
                  <EditProjectFormProvider>
                    <ProjectFormRouteLayout>
                      <Outlet />
                    </ProjectFormRouteLayout>
                  </EditProjectFormProvider>
                </OrganizationGuard>
              </IsInternalLoggedInGuard>
            ),
            children: [
              {
                path: '',
                element: <ProjectFormDefaultStepController />,
              },
              {
                path: ':step',
                element: <ProjectFormController />,
              },
            ],
          },
        ],
      },
      {
        path: 'playgrounds',
        element: (
          <IsInternalLoggedInGuard>
            <NavigationBarLayout>
              <Outlet />
            </NavigationBarLayout>
          </IsInternalLoggedInGuard>
        ),
        children: [
          {
            path: '',
            element: <PlaygroundsIndex />,
          },
          {
            path: 'prompt',
            element: <PromptPlayground />,
          },
          {
            path: 'wardrobe',
            element: <WardrobePlayground />,
          },
          {
            path: 'theme',
            element: <ThemePlayground />,
          },
          {
            path: 'text-editor',
            element: <TextEditorPlayground />,
          },
          {
            path: 'project-space',
            element: <ProjectSpacePlayground />,
          },
          {
            path: 'video-player',
            element: <VideoPlayerPlayground />,
          },
        ],
      },
      {
        path: 'prompt-playground',
        element: <Navigate to={Pages.InternalPromptPlayground} replace />,
      },
      {
        path: 'theme-playground',
        element: <Navigate to={Pages.InternalThemePlayground} replace />,
      },
    ],
  },
  {
    path: 'dashboard',
    children: [
      {
        path: '',
        element: <Navigate to="projects" replace />,
      },
      {
        path: 'projects',
        element: (
          <IsExternalLoggedInGuard>
            <ExternalDashboardLayout>
              <ProjectsListPage />
            </ExternalDashboardLayout>
          </IsExternalLoggedInGuard>
        ),
      },
      {
        path: 'project',
        element: (
          <IsLoggedInGuard>
            <OrganizationGuard ignoreExternalUsers>
              <Outlet />
            </OrganizationGuard>
          </IsLoggedInGuard>
        ),
        children: [
          {
            path: ':id',
            children: [
              {
                path: ':flowType',
                element: (
                  <ActiveProjectContainer>
                    <WizardNavigationContextProvider>
                      <WizardLayout>
                        <Outlet />
                      </WizardLayout>
                    </WizardNavigationContextProvider>
                  </ActiveProjectContainer>
                ),
                children: [
                  {
                    path: '',
                    element: <ProjectWizardDefaultStepController />,
                  },
                  {
                    path: ':step',
                    element: <ProjectWizardController />,
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: 'project-space',
    element: (
      <IsLoggedInGuard>
        <OrganizationGuard ignoreExternalUsers>
          <Outlet />
        </OrganizationGuard>
      </IsLoggedInGuard>
    ),
    children: [
      {
        path: ':id',
        element: (
          <ActiveProjectContainer>
            <ProjectSpaceFeatureFlagGuard>
              <Outlet />
            </ProjectSpaceFeatureFlagGuard>
          </ActiveProjectContainer>
        ),
        children: [
          {
            path: '',
            element: <ProjectSpaceDefaultRouteController />,
          },
          {
            path: ':page/*',
            element: <ProjectSpaceController />,
          },
        ],
      },
    ],
  },
  {
    path: 'post-production',
    children: [
      {
        path: ':id',
        children: [
          {
            path: 'review',
            element: (
              <IsLoggedInGuard>
                <OrganizationGuard ignoreExternalUsers>
                  <ActiveProjectBaseDataGuard>
                    <Outlet />
                  </ActiveProjectBaseDataGuard>
                </OrganizationGuard>
              </IsLoggedInGuard>
            ),
            children: [
              {
                path: ':deliverableKey',
                children: [
                  {
                    path: '',
                    element: <VideoReviewPage />,
                  },
                  {
                    path: ':versionName',
                    element: <VideoReviewPage />,
                  },
                ],
              },
            ],
          },
          {
            path: 'public',
            element: (
              <ActiveProjectBaseDataGuard>
                <Outlet />
              </ActiveProjectBaseDataGuard>
            ),
            children: [
              {
                path: ':deliverableKey',
                children: [
                  {
                    path: '',
                    element: <VideoPublicPage />,
                  },
                  {
                    path: ':versionName',
                    element: <VideoPublicPage />,
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: 'register',
    element: <RegisterPage />,
  },
  {
    path: 'proposal/:proposalId',
    element: (
      <CreativeProposalFeatureFlagGuard>
        <ActiveProposalContainer>
          <ActiveProposalProjectsContainer>
            <ProposalDoc />
          </ActiveProposalProjectsContainer>
        </ActiveProposalContainer>
      </CreativeProposalFeatureFlagGuard>
    ),
  },
  {
    path: 'login',
    element: <LoginPage />,
  },
  {
    path: 'reset-password',
    element: <ResetPasswordController />,
  },
  {
    path: 'settings',
    element: (
      <IsLoggedInGuard>
        <DashboardLayout>
          <Outlet />
        </DashboardLayout>
      </IsLoggedInGuard>
    ),
    children: [
      {
        path: 'my-account',
        element: <MyAccountPage />,
      },
    ],
  },
];
