import { Rating, Stack, Typography } from '@mui/material';
import { Controller } from 'react-hook-form';

import type { SentimentFormValues } from './sentiment-dialog-schema';

type SentimentQuestionProps = {
  name: keyof Omit<SentimentFormValues, 'opinion'>;
  text: string;
  question: string;
};

export const SentimentQuestion = ({
  name,
  text,
  question,
}: SentimentQuestionProps) => {
  return (
    <Controller
      name={name}
      render={({ field: { ref: _, onChange, ...field }, fieldState }) => (
        <Stack
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexDirection: 'row',
          }}
        >
          <Stack>
            <Typography
              variant="body1"
              fontWeight={600}
              sx={
                fieldState.error
                  ? {
                      color: (theme) => theme.palette.error.main,
                    }
                  : {}
              }
            >
              {text}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                color: (theme) => theme.palette.text.secondary,
              }}
            >
              {question}
            </Typography>
          </Stack>
          <Rating
            {...field}
            precision={0.5}
            name={name}
            onChange={(_, value) => {
              onChange(value);
            }}
          />
        </Stack>
      )}
    />
  );
};
