import { TwSpinner } from '@ll-platform/frontend/components/tw/TwSpinner/TwSpinner';
import { EnsureDarkTheme } from '@ll-platform/frontend/core/theme/GlobalThemeProvider';
import { useGetCreativeProposalByProposalId } from '@ll-platform/frontend/hero/creativeProposal/async/useCreativeProposalsQueries';
import { ProposalDocumentPageLayout } from '@ll-platform/frontend/hero/creativeProposal/document/components/ProposalDocumentPageLayout';
import { CreativeProposalDocumentContent } from '@ll-platform/frontend/hero/creativeProposal/document/CreativeProposalDocumentContent';
import { useCreativeProposalPageParams } from '@ll-platform/frontend/hero/creativeProposal/hooks/useCreativeProposalPageParams';
import type { CreativeProposal } from '@ll-platform/frontend/hero/creativeProposal/types';
import { DocumentBuilderProvider } from '@ll-platform/frontend/hero/documentBuilder/core/builder/DocumentBuilderContext';

import { useCreativeProposalDocumentBuilderConfig } from './useCreativeProposalDocumentBuilderConfig';

const CreativeProposalDocumentInner = ({
  creativeProposal,
}: {
  creativeProposal: CreativeProposal;
}) => {
  useCreativeProposalDocumentBuilderConfig({
    creativeProposal,
  });

  return <CreativeProposalDocumentContent />;
};

export const CreativeProposalDocumentPage = () => {
  const { id } = useCreativeProposalPageParams();

  const { data } = useGetCreativeProposalByProposalId({
    id,
  });
  const config = useCreativeProposalDocumentBuilderConfig({
    creativeProposal: data,
  });

  return (
    <EnsureDarkTheme>
      <DocumentBuilderProvider config={config}>
        <ProposalDocumentPageLayout>
          {data ? (
            <CreativeProposalDocumentInner creativeProposal={data} />
          ) : (
            <div className="w-full grid place-items-center">
              <TwSpinner className="w-50 h-50" />
            </div>
          )}
        </ProposalDocumentPageLayout>
      </DocumentBuilderProvider>
    </EnsureDarkTheme>
  );
};
