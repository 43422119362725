import { useQueryActiveCreator } from '@ll-platform/frontend/creator/auth/async/hooks/hooks';

export function useActiveCreator() {
  const {
    data: activeCreator,
    error,
    isPending,
  } = useQueryActiveCreator(
    {},
    {
      enabled: false,
    },
  );
  if (!activeCreator) {
    // if isLoading is true, route is not secured by IsLoggedInGuard
    throw new Error(`Creator is not authorized, pending: ${isPending}`, {
      cause: error,
    });
  }

  return {
    activeCreator,
  };
}
