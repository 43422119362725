import { useEffect } from 'react';

import { useActiveUser } from '@ll-platform/frontend/features/auth/hooks/useActiveUser';
import { isUserInternal } from '@ll-platform/frontend/features/auth/utils/isInternal';
import { useUpdateProjectFields } from '@ll-platform/frontend/features/projects/async/useProjectsMutations';
import {
  ExternalProjectStatus,
  ProjectStatus,
} from '@ll-platform/frontend/features/projects/enums';
import {
  findProjectStatusByWizardStep,
  ProjectStatusIgnoredSteps,
} from '@ll-platform/frontend/features/projectWizard/consts/statusStepMap';
import type { WizardSteps } from '@ll-platform/frontend/features/projectWizard/consts/wizardSteps';

import { useActiveProject } from './useActiveProject';

export function useUpdateProjectStatus(currentStep?: WizardSteps) {
  const updateProjectFieldsMutation = useUpdateProjectFields();
  const { activeProject } = useActiveProject();
  const { activeUser } = useActiveUser();

  useEffect(() => {
    if (!currentStep) {
      return;
    }
    const status = findProjectStatusByWizardStep(currentStep);
    if (!status) {
      return;
    }

    if (ProjectStatusIgnoredSteps.includes(currentStep)) {
      return;
    }

    const shouldUpdateExternalStatus =
      !isUserInternal(activeUser) &&
      Object.values(ExternalProjectStatus).includes(
        status as ExternalProjectStatus,
      );
    const shouldUpdateStatus =
      isUserInternal(activeUser) &&
      Object.values(ProjectStatus).includes(status as ProjectStatus);

    updateProjectFieldsMutation.mutateAsync({
      id: activeProject.id,
      data: {
        ...(shouldUpdateExternalStatus && {
          externalStatus: status as ExternalProjectStatus,
        }),
        ...(shouldUpdateStatus && {
          status: status as ProjectStatus,
        }),
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeProject.id, currentStep, activeUser]);
}
