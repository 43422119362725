import { useMemo } from 'react';

import { Stack } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import {
  CustomStepper,
  type StepperStep,
} from '@ll-platform/frontend/components/CustomStepper/CustomStepper';
import { FeatureFlagName } from '@ll-platform/frontend/config/featureFlags/featureFlags';
import { useAppFlags } from '@ll-platform/frontend/core/featureFlags/useAppFlags';
import { BudgetSummary } from '@ll-platform/frontend/features/budget/components/BudgetSummary/BudgetSummary';
import { ProjectFormSteps } from '@ll-platform/frontend/features/internalProjects/pages/ProjectForm/consts';
import { useProjectEmpty } from '@ll-platform/frontend/features/internalProjects/pages/ProjectForm/hooks/useProjectFormEmpty';
import type { ProjectFormValues } from '@ll-platform/frontend/features/internalProjects/projectForm/projectFormSchema';
import { mapProjectFormForBudget } from '@ll-platform/frontend/features/internalProjects/projectForm/utils/mapFormForBudget';

type ProjectFormStepper = {
  steps: ProjectFormSteps[];
  currentStepIndex: number;
  goToStep: (step: ProjectFormSteps) => void;
  stepperSteps: StepperStep<ProjectFormSteps>[];
};

export const ProjectFormStepper = ({
  steps,
  currentStepIndex,
  goToStep,
  stepperSteps,
}: ProjectFormStepper) => {
  const flags = useAppFlags();
  const isEmpty = useProjectEmpty();
  const stepIndex = isEmpty ? 0 : currentStepIndex;
  const activeStep = steps[stepIndex];

  const { watch } = useFormContext<ProjectFormValues>();
  const proposalId = watch('basicInfo.proposal.proposalId');
  const formValues = watch();
  const project = useMemo(() => {
    return {
      id: formValues.id,
      ...mapProjectFormForBudget({
        project: formValues,
        includeCharacters:
          !!formValues.id ||
          stepIndex >=
            Object.values(steps).findIndex(
              (step) => step === ProjectFormSteps.Talents,
            ),
      }),
    };
  }, [formValues, stepIndex, steps]);

  return (
    <Stack
      sx={
        proposalId
          ? {
              flexGrow: 1,
              justifyContent: 'space-between',
            }
          : {}
      }
    >
      <CustomStepper
        steps={stepperSteps}
        activeStep={activeStep}
        onClick={(step) => goToStep(step.key)}
        variant="light"
      />
      {flags[FeatureFlagName.CreativeProposalBudget] && proposalId && (
        <BudgetSummary project={project} isInProjectForm />
      )}
    </Stack>
  );
};
