import { useState } from 'react';

import {
  ActiveUserAvatar,
  type ActiveUserAvatarProps,
} from '@ll-platform/frontend/components/User/ActiveUser/ActiveUserAvatar';
import {
  ActiveUserMenu,
  type ActiveUserMenuProps,
} from '@ll-platform/frontend/components/User/ActiveUser/ActiveUserMenu';

type ActiveUserWithMenuProps = {
  avatarProps?: ActiveUserAvatarProps;
  menuProps?: ActiveUserMenuProps;
};

export const ActiveUserWithMenu = ({
  avatarProps,
  menuProps,
}: ActiveUserWithMenuProps) => {
  const [anchor, setAnchor] = useState<HTMLElement | null>(null);

  return (
    <>
      <ActiveUserAvatar
        size={40}
        {...avatarProps}
        onClick={(event) =>
          setAnchor((current) => (current ? null : event.currentTarget))
        }
      />
      <ActiveUserMenu
        {...menuProps}
        open={!!anchor}
        anchorEl={anchor}
        onClose={() => setAnchor(null)}
      />
    </>
  );
};
