import { useMemo } from 'react';

import { merge } from 'lodash-es';

import { activityTracker } from '@ll-platform/frontend/core/analytics/activityTracker';
import { useAnalyticsContext } from '@ll-platform/frontend/core/analytics/AnalyticsContext';
import type {
  ActivityEventsMetadata,
  ActivityType,
} from '@ll-platform/frontend/core/analytics/events';
import type {
  ActivityTypesWithMetadata,
  TrackingEventInput,
  TrackingEventMetadata,
} from '@ll-platform/frontend/core/analytics/types';
import { useDeepCompareCallback } from '@ll-platform/frontend/utils/hooks/useDeepCompare';

export function useActivityTracker(hookMetadata: TrackingEventMetadata = {}) {
  const { metadata: contextMetadata } = useAnalyticsContext();

  const trackEvent = useDeepCompareCallback(
    <T extends ActivityType = ActivityType>(
      type: T,
      ...args: T extends ActivityTypesWithMetadata
        ? [
            eventMetadata: ActivityEventsMetadata[T],
            channel?: TrackingEventInput['channel'],
          ]
        : [
            eventMetadata?: TrackingEventMetadata,
            channel?: TrackingEventInput['channel'],
          ]
    ) => {
      const [eventMetadata, channel] = args;
      const mergedMetadata = merge(
        {},
        contextMetadata,
        hookMetadata,
        eventMetadata,
      );
      // No way to ensure type safety with recursive contexts
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      activityTracker.log({
        type,
        metadata: mergedMetadata,
        channel,
      });
    },
    [hookMetadata, contextMetadata],
  );

  return useMemo(() => ({ trackEvent }), [trackEvent]);
}
