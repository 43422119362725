import type { Entries } from 'type-fest';

type Falsey = null | undefined | false | '' | 0 | 0n;
type Truthy<T> = T extends Falsey ? never : T;

export type Dictionary<T> = {
  [key: string]: T;
};
// Use it to mark a type as deprecated
export type $Deprecated<T> = T;

export type KeysWithValsOfType<T, V> = keyof {
  [P in keyof T as T[P] extends V ? P : never]: P;
};

export type PropertiesWithValsOfType<T, U> = Pick<
  T,
  KeysWithValsOfType<T, U> & keyof T
>;

export type XOR<T1, T2> =
  | (T1 & { [k in Exclude<keyof T2, keyof T1>]?: never })
  | (T2 & { [k in Exclude<keyof T1, keyof T2>]?: never });

export type ByIdParams = {
  id: string;
};

export type UnknownDate = Date | string | object;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type $TSFixMe = any;

export type $TSFixMeFunction = (...args: $TSFixMe[]) => $TSFixMe;

export type Nullable<T> = T | undefined | null;

export type DeepPartialObject<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartialObject<T[P]>;
    }
  : T;

export function assertDefined<T>(
  value: T,
  name: string = 'Value',
): asserts value {
  if (value === null) {
    throw new TypeError(`${name} should not be null.`);
  }
  if (value === undefined) {
    throw new TypeError(`${name} should not be undefined.`);
  }
}

export function typedEntries<T extends object>(obj: T): Entries<T> {
  return Object.entries(obj) as Entries<T>;
}

export function typedKeys<T extends object>(obj: T): (keyof T)[] {
  return Object.keys(obj) as (keyof T)[];
}

export function typedFromEntries<T extends Record<string, unknown>>(
  entries: Entries<T>,
): T {
  return Object.fromEntries(entries) as T;
}

export function defined<T>(value: T): value is NonNullable<T> {
  return value !== null && value !== undefined;
}

export function propertiesDefined<T extends Record<string, unknown>>(
  obj: T,
): obj is { [K in keyof T]: NonNullable<T[K]> } {
  return Object.values(obj).every(defined);
}

export function truthy<T>(value: T): value is Truthy<T> {
  return !!value;
}

export type EmailOrUserId = string;
