export function isUploadcareUrl<T extends string | null | undefined>(
  url: T,
): boolean {
  return url?.startsWith('https://ucarecdn.com/') || false;
}

export function isUploadcareGifUrl<T extends string | null | undefined>(
  url: T,
): boolean {
  return url?.includes('/gif2video/') ?? false;
}

// dimensions param format: https://uploadcare.com/docs/transformations/image/resize-crop/#operation-preview
export function scaleUploadcareUrl<T extends string | null | undefined>(
  url: T,
  dimensions?: string | number,
): T | string {
  if (typeof url !== 'string') {
    return url;
  }

  if (!isUploadcareUrl(url)) {
    console.warn(
      '[scaleUploadcareUrl] Blocked attempt to apply transformations to non-Uploadcare url ',
    );

    return url;
  }

  let newUrl = url.replace(/\/+-\/(preview|resize)\/.*?\//, '/');
  if (!newUrl.endsWith('/')) {
    newUrl += '/';
  }

  if (!dimensions) {
    return `${newUrl}-/preview/`;
  }

  const formattedDimensions =
    typeof dimensions === 'number' ? `${dimensions}x${dimensions}` : dimensions;

  return `${newUrl}-/resize/${formattedDimensions}/`;
}

export function createUploadcareThumbnailUrl<
  T extends string | null | undefined,
>(url: T, dimensions?: string | number): T | string {
  if (!isUploadcareGifUrl(url)) {
    return scaleUploadcareUrl(url, dimensions);
  }

  const [baseUrl] = (url as string).split('/gif2video');

  return scaleUploadcareUrl(baseUrl, dimensions);
}

export function convertUploadcareGifToVideoUrl<
  T extends string | null | undefined,
>(url: T): T | string {
  if (!isUploadcareUrl(url)) {
    console.warn(
      '[convertGifToVideoUrl] Blocked attempt to convert non-Uploadcare url ',
    );

    return url;
  }

  return `${url}gif2video/-/format/mp4/-/quality/better/`;
}
