import type { ActivityType } from '@ll-platform/frontend/core/analytics/events';
import type { TypedTrackingEvent } from '@ll-platform/frontend/core/analytics/types';
import type { User } from '@ll-platform/frontend/core/auth/types';
import { mapProjectToAnalytics } from '@ll-platform/frontend/features/analytics/eventUtils';
import type { ProjectWithDeliverables } from '@ll-platform/frontend/features/projects/types';

export const reviewWizardAnalyticsMetadata = ({
  submitType,
  cancelType,
  activeUser,
  project,
  page,
}: {
  submitType: ActivityType;
  cancelType: ActivityType;
  activeUser: User;
  project?: ProjectWithDeliverables;
  page: string;
}) => {
  const metadata = {
    senderRole: activeUser.accountType,
    ...(project ? mapProjectToAnalytics(project) : {}),
    page,
  };

  return {
    confirm: {
      type: submitType,
      metadata,
    } as TypedTrackingEvent,
    cancel: {
      type: cancelType,
      metadata,
    } as TypedTrackingEvent,
  };
};
