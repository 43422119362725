import { useEffect } from 'react';

import { activityTracker } from '@ll-platform/frontend/core/analytics/activityTracker';
import {
  EventChannel,
  type TrackingEvent,
} from '@ll-platform/frontend/core/analytics/types';
import type { AnyUser } from '@ll-platform/frontend/core/hooks/useAnyUser';
import { klaviyoService } from '@ll-platform/frontend/core/klaviyo/async/KlaviyoService';
import {
  buildEventName,
  isEventOnChannel,
} from '@ll-platform/frontend/features/analytics/eventUtils';

const EVENT_NAME_SEPARATOR = ' ' as const;

export const useKlaviyo = (activeUser?: AnyUser) => {
  useEffect(() => {
    // Klaviyo BE endpoint requires authorization
    if (!activeUser) {
      return;
    }

    return activityTracker.subscribe('Klaviyo', (event: TrackingEvent) => {
      // klaviyo processes events on engagement channel
      if (!isEventOnChannel(event, EventChannel.EngagementTriggers)) {
        return;
      }
      klaviyoService.makeCreateEvent({
        eventName: buildEventName(event.type, EVENT_NAME_SEPARATOR),
        eventService: 'frontend',
        metadata: event.metadata,
      });
    });
  }, [activeUser]);
};
