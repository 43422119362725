import { createMutationHook } from '@ll-platform/frontend/utils/factories/createMutationHook';

import { productionDocsService } from './ProductionDocsService';

export const useNotifyProjectFinalizationMutationKey = [
  'notifyProjectFinalization',
];

export const useNotifyProjectFinalizationMutation = createMutationHook(
  productionDocsService.notifyProjectFinalization.bind(productionDocsService),
  () => ({
    retry: 0,
    mutationKey: useNotifyProjectFinalizationMutationKey,
  }),
);

export const useGetProjectCallSheetsPdfMutation = createMutationHook(
  productionDocsService.getProjectCallSheetsPdf.bind(productionDocsService),
);

export const useGetProjectCreativeDeckPdfMutation = createMutationHook(
  productionDocsService.getProjectCreativeDeckPdf.bind(productionDocsService),
);

export const useGetProjectDeliverableSummaryPdfMutation = createMutationHook(
  productionDocsService.getProjectDeliverableSummaryPdf.bind(
    productionDocsService,
  ),
);
