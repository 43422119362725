import type {
  ComponentPropsWithoutRef,
  MouseEventHandler,
  ReactNode,
} from 'react';

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@ll-platform/frontend/components/shadcn/ui/alert-dialog';
import {
  buttonVariants,
  type ButtonProps,
} from '@ll-platform/frontend/components/shadcn/ui/button';

export type TwConfirmDialogProps = ComponentPropsWithoutRef<
  typeof AlertDialog
> & {
  onConfirm: MouseEventHandler;
  onCancel: MouseEventHandler;
  body?: ReactNode;
  isLoading?: boolean;
  title?: ReactNode;
  cancelButtonVariant?: ButtonProps['variant'];
  confirmButtonVariant?: ButtonProps['variant'];
  cancelLabel?: string;
  confirmLabel?: string;
};

export const TwConfirmDialog = ({
  onCancel,
  onConfirm,
  body,
  isLoading,
  title = 'Are you sure?',
  cancelButtonVariant = 'outline',
  confirmButtonVariant = 'default',
  cancelLabel = 'Cancel',
  confirmLabel = 'Confirm',
  ...dialogProps
}: TwConfirmDialogProps) => {
  return (
    <AlertDialog {...dialogProps}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>{title}</AlertDialogTitle>
          <AlertDialogDescription>{body}</AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel
            className={buttonVariants({ variant: cancelButtonVariant })}
            disabled={isLoading}
            onClick={onCancel}
          >
            {cancelLabel}
          </AlertDialogCancel>
          <AlertDialogAction
            className={buttonVariants({ variant: confirmButtonVariant })}
            disabled={isLoading}
            onClick={onConfirm}
          >
            {confirmLabel}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
