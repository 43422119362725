import { type User } from '@ll-platform/frontend/core/auth/types';
import type { ProjectWithDeliverables } from '@ll-platform/frontend/features/projects/types';
import { linkToWizard } from '@ll-platform/frontend/features/projectWizard/utils/linkToWizard';
import { REVIEW_FORM_BLOCKING_PHRASES } from '@ll-platform/frontend/features/reviews/consts';
import type { ReviewEmailTemplateArgs } from '@ll-platform/frontend/features/reviews/types';
import {
  emailGreeting,
  emailSignature,
  htmlEmailBody,
} from '@ll-platform/frontend/utils/helpers/emails';
import { getPersonFullName } from '@ll-platform/frontend/utils/helpers/helpers';
import {
  propertiesDefined,
  type Nullable,
} from '@ll-platform/frontend/utils/types/types';

export const wizardGenerateInternalEmail = ({
  project,
  activeUser,
  brandName,
  reviewEmailTemplateUsers,
}: ReviewEmailTemplateArgs<{
  project?: ProjectWithDeliverables;
  brandName?: string;
  activeUser: User;
}>) => {
  const requiredBodyParams = {
    brandName,
    projectName: project?.title,
    projectId: project?.id,
  };

  return {
    recipientUserIds: reviewEmailTemplateUsers.recipientUserIds,
    title: `Review Your Video Project Details for ${requiredBodyParams.projectName ? `${requiredBodyParams.projectName}` : 'Your project'}`,
    body: emailBody({
      requiredBodyParams,
      activeUser,
      reviewEmailTemplateUsers,
    }),
  };
};

const emailBody = ({
  requiredBodyParams,
  reviewEmailTemplateUsers: { defaultRecipient },
  activeUser,
}: ReviewEmailTemplateArgs<
  {
    requiredBodyParams: {
      brandName: Nullable<string>;
      projectName: Nullable<string>;
      projectId: Nullable<string>;
    };
  } & {
    activeUser: User;
  }
>) => {
  if (!propertiesDefined(requiredBodyParams)) {
    return '';
  }

  const wizardLink = linkToWizard(requiredBodyParams.projectId);

  return htmlEmailBody(`${emailGreeting('Hi', defaultRecipient?.firstName)}
<br><br>The pre-production documents for ${requiredBodyParams.brandName} are now ready for review.
<br><br>Step 1: Review the project details for project <a href="${wizardLink}">${requiredBodyParams.projectName} here</a>. You can make changes or leave feedback directly on our Hero platform.
<br><br>Step 2: Schedule your Creative Review call ${REVIEW_FORM_BLOCKING_PHRASES.hubspotLink.text} to review the documents with your Producer. On this call, we will fine-tune all production details and ensure everything aligns perfectly with your vision.
<br><br>If you have any questions or need assistance, don't hesitate to reach out.
<br><br>${emailSignature('Best', getPersonFullName(activeUser))}`);
};
