import {
  useCallback,
  useEffect,
  useMemo,
  type ChangeEvent,
  type FormEvent,
  type KeyboardEvent,
  type ReactNode,
} from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  ArrowForwardOutlined,
  ArrowUpwardOutlined,
  CheckOutlined,
  CloseOutlined,
} from '@mui/icons-material';
import { Stack, Tooltip, type StackProps } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { CustomIconButton } from '@ll-platform/frontend/components/CustomIconButton/CustomIconButton';
import { SmallWhiteTextField } from '@ll-platform/frontend/components/form/SmallWhiteTextField/SmallWhiteTextField';
import { Spinner } from '@ll-platform/frontend/components/Spinner/Spinner';
import { UserAvatar } from '@ll-platform/frontend/components/User/UserAvatar';
import { useActiveUser } from '@ll-platform/frontend/features/auth/hooks/useActiveUser';
import { INLINE_EDIT_MAX_CHAR_LIMITS } from '@ll-platform/frontend/features/projectWizard/consts/inlineEditMaxCharLimits';
import type { TextEditorEditMode } from '@ll-platform/frontend/features/textEditor/types';

import { CommentErrorInfo } from './CommentErrorInfo';

const commentMessageSchema = yup.object().shape({
  message: yup
    .string()
    .max(INLINE_EDIT_MAX_CHAR_LIMITS.comment)
    .transform((value) => (value ? value.trim() : value))
    .required("Comment can't be empty"),
});

export type CommentMessageValues = yup.InferType<typeof commentMessageSchema>;

type CommentEditMessageProps = Omit<StackProps, 'onSubmit' | 'onChange'> & {
  message?: string;
  editMode: TextEditorEditMode;
  onCancel?: () => void;
  onSubmit: (values: CommentMessageValues) => Promise<unknown>;
  onInputChange?: (value: string) => void;
  isReply?: boolean;
  withAvatar?: boolean;
  avatarSize?: number;
  additionalCommentForm?: ReactNode;
};

export const CommentEditMessage = ({
  message,
  editMode,
  onSubmit,
  onCancel,
  onInputChange,
  withAvatar = true,
  isReply = false,
  avatarSize = 32,
  additionalCommentForm,
  ...props
}: CommentEditMessageProps) => {
  const { activeUser } = useActiveUser();
  const isNew = editMode === 'newComment';

  const methods = useForm({
    defaultValues: {
      message: message ?? '',
    },
    resolver: yupResolver(commentMessageSchema),
    mode: 'onChange',
  });

  const submit = async ({ message }: CommentMessageValues) => {
    try {
      await onSubmit({ message });
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    return methods.handleSubmit(submit)(event);
  };

  const handleInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (onInputChange && typeof onInputChange === 'function') {
        onInputChange(e.target.value);
      }
    },
    [onInputChange],
  );

  const submitIcon = useMemo(() => {
    if (isReply) {
      return <ArrowUpwardOutlined />;
    }

    if (isNew) {
      return <ArrowForwardOutlined />;
    }

    return <CheckOutlined />;
  }, [isNew, isReply]);

  useEffect(() => {
    if (methods.formState.isSubmitSuccessful) {
      methods.reset();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [methods.formState.isSubmitSuccessful]);

  return (
    <FormProvider {...methods}>
      <Stack
        component="form"
        gap={0.5}
        {...props}
        sx={{
          pb: 2,
          position: 'relative',
          ...props.sx,
        }}
        onSubmit={handleSubmit}
        onKeyDown={(event: KeyboardEvent) => {
          if (
            event.key === 'Enter' &&
            !(event.shiftKey || event.metaKey || event.ctrlKey) &&
            !methods.formState.isSubmitting
          ) {
            methods.handleSubmit(submit)(event);
            event.preventDefault();
            event.stopPropagation();
          }
        }}
      >
        <Stack direction="row" alignItems="center" gap={1}>
          {withAvatar ? (
            <UserAvatar
              user={activeUser}
              size={avatarSize}
              sx={{
                alignSelf: 'flex-start',
                marginTop: 1,
              }}
            />
          ) : null}
          <Stack width="100%">
            {additionalCommentForm}
            <SmallWhiteTextField
              {...methods.register('message')}
              onChange={(ev: ChangeEvent<HTMLInputElement>) => {
                handleInputChange(ev);
                methods.register('message').onChange(ev);
              }}
              placeholder={isReply ? 'Reply...' : 'Add comment'}
              fullWidth
              autoFocus
              multiline
              helperText=""
              inputProps={{
                // We want to display an information for user why he can't write more characters
                maxLength: INLINE_EDIT_MAX_CHAR_LIMITS.comment + 1,
              }}
              InputProps={{
                endAdornment: (
                  <Stack
                    direction="row"
                    gap={1}
                    sx={{
                      alignSelf: 'flex-end',
                    }}
                  >
                    {!isNew && onCancel && (
                      <Tooltip title="Reject changes" arrow placement="top">
                        <CustomIconButton
                          size="small"
                          color="default"
                          onClick={onCancel}
                        >
                          <CloseOutlined />
                        </CustomIconButton>
                      </Tooltip>
                    )}
                    {methods.formState.isSubmitting ? (
                      <Stack sx={{ p: 0.5 }}>
                        <Spinner size={24} />
                      </Stack>
                    ) : (
                      <Tooltip
                        title="Accept changes"
                        arrow
                        placement="top"
                        disableHoverListener={isNew}
                      >
                        <div>
                          <CustomIconButton
                            size="small"
                            type="submit"
                            color="primary"
                            disabled={!methods.formState.isValid}
                          >
                            {submitIcon}
                          </CustomIconButton>
                        </div>
                      </Tooltip>
                    )}
                  </Stack>
                ),
                notched: false,
                sx: {
                  position: 'relative',
                  gap: 1,
                  px: 1,
                },
              }}
            />
          </Stack>
        </Stack>
        <CommentErrorInfo sx={editMode === 'newComment' ? { ml: 5 } : {}} />
      </Stack>
    </FormProvider>
  );
};
