import type { NodeStyleProps } from '@ll-platform/frontend/hero/documentBuilder/core/nodes/nodeTypes';
import {
  DOCUMENT_SLIDE_HEIGHT,
  DOCUMENT_SLIDE_WIDTH,
} from '@ll-platform/frontend/hero/documentBuilder/core/slides/slideConsts';
import { makeSlide } from '@ll-platform/frontend/hero/documentBuilder/core/slides/slideHelpers';
import type { DocumentSlide } from '@ll-platform/frontend/hero/documentBuilder/core/slides/slideTypes';
import type { SlideTemplateConfig } from '@ll-platform/frontend/hero/documentBuilder/core/slideTemplates/slideTemplateTypes';
import {
  makeDefaultImageObject,
  makeMediaNode,
} from '@ll-platform/frontend/hero/documentBuilder/shared/nodes/MediaNode/mediaNodeHelpers';
import { type MediaObject } from '@ll-platform/frontend/hero/documentBuilder/shared/nodes/MediaNode/mediaNodeTypes';
import { makeRichTextNode } from '@ll-platform/frontend/hero/documentBuilder/shared/nodes/RichTextNode/richTextNodeHelpers';

const makeDocumentCoverSlide = ({
  title,
  media,
}: CoverSlideTemplateParams): DocumentSlide => {
  return makeSlide({
    nodes: [
      makeMediaNode({
        content: {
          media,
        },
        style: backgroundMediaStyle,
      }),
      makeRichTextNode({
        content: {
          text: `<h1 style="text-align: left"><span style="color: #ffffff">${title}</span></h1>`,
        },
        style: titleTextStyle,
      }),
    ],
  });
};

const titleTextStyle: NodeStyleProps = {
  top: '60%',
  left: '10%',
  right: '10%',
};

const backgroundMediaStyle: NodeStyleProps = {
  top: 0,
  left: 0,
  width: DOCUMENT_SLIDE_WIDTH,
  height: DOCUMENT_SLIDE_HEIGHT,
};

type CoverSlideTemplateParams = {
  title: string;
  media: MediaObject;
};

const coverSlideTemplateDefaults: CoverSlideTemplateParams = {
  title: 'Cover',
  media: makeDefaultImageObject('https://placehold.co/1280x720'),
};

export const coverSlideTemplate: SlideTemplateConfig<CoverSlideTemplateParams> =
  {
    creator: {
      name: 'Cover',
      create: async () => ({
        slides: [makeDocumentCoverSlide(coverSlideTemplateDefaults)],
      }),
    },
    makeDocumentSlide: makeDocumentCoverSlide,
    templateDefaults: coverSlideTemplateDefaults,
  };
