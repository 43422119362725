import {
  mapBasicInfoDtoToForm,
  mapBasicInfoFormToDto,
} from '@ll-platform/frontend/features/internalProjects/projectForm/basicInfo/mapBasicInfoFormToDto';
import {
  mapDroneProductionDtoToForm,
  mapDroneProductionFormToDto,
} from '@ll-platform/frontend/features/internalProjects/projectForm/droneProduction/mapDroneProductionFormToDto';
import {
  mapHeroVideoDtoToForm,
  mapHeroVideoFormToDto,
} from '@ll-platform/frontend/features/internalProjects/projectForm/heroVideos/mapHeroVideoFormToDto';
import {
  mapNotesDtoToForm,
  mapNotesFormToDto,
} from '@ll-platform/frontend/features/internalProjects/projectForm/notes/mapNotesDtoToForm';
import {
  mapProductionDayFormToDto,
  mapProductionDaysDtoToForm,
} from '@ll-platform/frontend/features/internalProjects/projectForm/productionDays/mapProductionDayFormToDto';
import type { ProjectFormValues } from '@ll-platform/frontend/features/internalProjects/projectForm/projectFormSchema';
import {
  mapProjectUsersDtoToForm,
  mapProjectUsersFormToDto,
} from '@ll-platform/frontend/features/internalProjects/projectForm/projectUsers/projectUsersSchema';
import {
  mapCharacterDtoToForm,
  mapTalentsFormValuesToCharacters,
} from '@ll-platform/frontend/features/internalProjects/projectForm/talents/mapTalentsInfoFormToDto';
import type {
  ProjectFormInput,
  ProjectFormOutput,
} from '@ll-platform/frontend/features/internalProjects/types';
import type { ProjectCharacter } from '@ll-platform/frontend/features/projects/types';

export const mapProjectFormToDto = ({
  droneProduction,
  heroVideos,
  notes,
  productionDays,
  basicInfo,
  characters,
  projectUsers,
}: ProjectFormValues): ProjectFormOutput => {
  return {
    ...mapBasicInfoFormToDto(basicInfo),
    projectUsers: projectUsers
      ? mapProjectUsersFormToDto({ ...projectUsers })
      : { userIds: [], emails: [] },
    heroes: heroVideos?.map(mapHeroVideoFormToDto),
    productions: productionDays?.map(mapProductionDayFormToDto),
    drones: droneProduction?.map(mapDroneProductionFormToDto),
    ...(characters && {
      characters: mapTalentsFormValuesToCharacters(characters).map(
        (character) => ({ ...character }),
      ),
    }),
    ...mapNotesFormToDto(notes ?? {}),
  };
};

export const mapProjectDtoToForm = ({
  id,
  heroes,
  productions,
  drones,
  notes,
  videoReferences,
  characters,
  projectUsers,
  ...basicInfo
}: Omit<ProjectFormInput, 'characters'> & {
  characters?: ProjectCharacter[];
}): ProjectFormValues => {
  return {
    id,
    basicInfo: mapBasicInfoDtoToForm({ ...basicInfo }),
    projectUsers: mapProjectUsersDtoToForm(projectUsers),
    heroVideos: heroes?.map(mapHeroVideoDtoToForm),
    productionDays: productions?.map(mapProductionDaysDtoToForm),
    droneProduction: drones?.map(mapDroneProductionDtoToForm),
    ...(characters ? { characters: mapCharacterDtoToForm(characters) } : {}),
    notes: mapNotesDtoToForm({ notes, videoReferences }),
  };
};
