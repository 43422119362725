import { createQueryKeys } from '@lukemorales/query-key-factory';

import { projectTimelineService } from '@ll-platform/frontend/features/projectTimeline/async/ProjectTimelineService';
import { createQueryHook } from '@ll-platform/frontend/utils/factories/createQueryHook';
import { createQueryOption } from '@ll-platform/frontend/utils/factories/createQueryOption';

export const ProjectTimelineQueries = createQueryKeys('projectTimeline', {
  getTimeline: createQueryOption(
    projectTimelineService.getTimeline.bind(projectTimelineService),
  ),
});

export const useGetProjectTimeline = createQueryHook(
  ProjectTimelineQueries.getTimeline,
);
