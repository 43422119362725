import 'keen-slider/keen-slider.min.css';
import 'react-toastify/dist/ReactToastify.css';

import { type PropsWithChildren } from 'react';

import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { LicenseInfo } from '@mui/x-license';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { RouterProvider } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { ShadcnProviders } from '@ll-platform/frontend/components/shadcn/ShadcnProviders';
import { GlobalErrorBoundary } from '@ll-platform/frontend/components/statusPages/components/GlobalErrorBoundary';
import { APP_CONFIG } from '@ll-platform/frontend/config/app.config';
import { useAmplitude } from '@ll-platform/frontend/container/analytics/useAmplitude';
import { useKlaviyo } from '@ll-platform/frontend/container/analytics/useKlaviyo';
import { useSentrySyncContext } from '@ll-platform/frontend/container/analytics/useSentry';
import { useSmartlook } from '@ll-platform/frontend/container/analytics/useSmartlook';
import { mainRouter } from '@ll-platform/frontend/container/router/mainRouter';
import { queryClient } from '@ll-platform/frontend/core/api/reactQuerySetup';
import { ActiveAudioProvider } from '@ll-platform/frontend/core/audio/ActiveAudioProvider';
import { FeatureFlagProvider } from '@ll-platform/frontend/core/featureFlags/FeatureFlagProvider';
import { GlobalThemeProvider } from '@ll-platform/frontend/core/theme/GlobalThemeProvider';
import { useUser } from '@ll-platform/frontend/features/auth/hooks/useUser';

LicenseInfo.setLicenseKey(APP_CONFIG.REACT_APP_MUI_LICENSE_KEY);

function App() {
  return (
    <GlobalErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <FeatureFlagProvider>
          <ExternalToolsProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en">
              <GlobalThemeProvider>
                <ShadcnProviders>
                  <ActiveAudioProvider>
                    <RouterProvider router={mainRouter} />
                  </ActiveAudioProvider>
                  <ToastContainer />
                </ShadcnProviders>
              </GlobalThemeProvider>
            </LocalizationProvider>
            <ReactQueryDevtools initialIsOpen={false} />
          </ExternalToolsProvider>
        </FeatureFlagProvider>
      </QueryClientProvider>
    </GlobalErrorBoundary>
  );
}

const ExternalToolsProvider = ({ children }: PropsWithChildren) => {
  const user = useUser();

  const userData = user.isAuthorized
    ? {
        id: user.activeUser._id,
        email: user.activeUser.email,
        accountType: user.activeUser.accountType,
        isActive: user.activeUser.isActive,
        firstName: user.activeUser.firstName,
        lastName: user.activeUser.lastName,
      }
    : undefined;

  useSentrySyncContext(userData);
  useAmplitude(userData);
  useSmartlook(userData);
  useKlaviyo(userData);

  return children;
};

export default App;
