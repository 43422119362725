import { useCallback, useMemo } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { FeatureFlagName } from '@ll-platform/frontend/config/featureFlags/featureFlags';
import { useAppFlags } from '@ll-platform/frontend/core/featureFlags/useAppFlags';
import { Pages } from '@ll-platform/frontend/core/router/pages';
import type { WizardSteps } from '@ll-platform/frontend/features/projectWizard/consts/wizardSteps';
import { WizardFlowType } from '@ll-platform/frontend/features/projectWizard/contexts/WizardNavigationContext';
import { makeWizardPath } from '@ll-platform/frontend/features/projectWizard/utils/navigation';
import { useIsProposalWizardFlow } from '@ll-platform/frontend/features/proposals/hooks/useIsProposalWizardFlow';
import { makeProposalPath } from '@ll-platform/frontend/features/proposals/utils/navigation';
import {
  useHomepagePagePath,
  useProjectHomepagePath,
} from '@ll-platform/frontend/features/router/hooks';
import {
  useFromState,
  useMakeFromState,
} from '@ll-platform/frontend/utils/hooks/useNavigation';
import { assertDefined } from '@ll-platform/frontend/utils/types/types';

import { useActiveProject } from './useActiveProject';
import {
  useActiveProposalId,
  useActiveProposalProjects,
} from './useActiveProposalProjects';

export function useNavigateBackFromWizard() {
  const navigate = useNavigate();

  const { flowType, proposalId } = useParams<{
    proposalId?: string;
    flowType: WizardFlowType;
  }>();

  const { activeProject } = useActiveProject();
  const isProposalFlow = useIsProposalWizardFlow();
  const homepagePath = useHomepagePagePath();
  const projectHomepagePath = useProjectHomepagePath();
  const flags = useAppFlags();
  const fromState = useFromState();

  const backPath = useMemo(() => {
    if (isProposalFlow) {
      assertDefined(flowType, 'Flow Type must be provided');

      if ([WizardFlowType.GenerateProposal].includes(flowType)) {
        return Pages.InternalProposals;
      } else if ([WizardFlowType.EditProposal].includes(flowType)) {
        assertDefined(proposalId, 'Proposal ID must be provided');
        makeProposalPath(proposalId ?? '');
      }
    }

    return (
      fromState ??
      (flags[FeatureFlagName.ProjectSpace] &&
      activeProject.isCreativeBriefInputFilled !== false
        ? projectHomepagePath(activeProject.id)
        : homepagePath)
    );
  }, [
    activeProject.id,
    activeProject.isCreativeBriefInputFilled,
    flags,
    flowType,
    fromState,
    homepagePath,
    isProposalFlow,
    projectHomepagePath,
    proposalId,
  ]);

  const isFromState = !!fromState;

  const navigateBack = useCallback(
    () =>
      navigate(backPath, {
        replace: isFromState,
      }),
    [backPath, navigate, isFromState],
  );

  return useMemo(
    () => ({ navigateBack, isFromState }),
    [navigateBack, isFromState],
  );
}

function useNavigateToWizardFlow(
  flowType: WizardFlowType,
  projectId: string,
  proposalId?: string,
) {
  const navigate = useNavigate();
  const makeFromState = useMakeFromState();

  return useCallback(
    (
      step: WizardSteps,
      {
        backAnchor,
        queryParams,
      }: { backAnchor?: string; queryParams?: Record<string, string> } = {},
    ) => {
      navigate(
        {
          pathname: makeWizardPath(projectId, flowType, step, proposalId),
          search: queryParams
            ? new URLSearchParams(queryParams).toString()
            : undefined,
        },
        { state: { ...makeFromState(backAnchor) } },
      );
    },
    [projectId, navigate, makeFromState, flowType, proposalId],
  );
}

export function useNavigateToEditWizardForCreativeDeck() {
  const { activeProject } = useActiveProject();

  return useNavigateToWizardFlow(
    WizardFlowType.EditCreativeDeck,
    activeProject.id,
  );
}

export function useNavigateToEditWizardForCallSheet() {
  const { activeProject } = useActiveProject();

  return useNavigateToWizardFlow(
    WizardFlowType.EditCallSheet,
    activeProject.id,
  );
}

export function useNavigateToEditWizardForProposal() {
  const proposalId = useActiveProposalId();
  const { activeProjects } = useActiveProposalProjects();

  // TODO: Remove getting first index after ready to get multiple projects
  const activeProject = activeProjects[0];

  return useNavigateToWizardFlow(
    WizardFlowType.EditProposal,
    activeProject.id,
    proposalId,
  );
}
