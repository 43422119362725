import { memo } from 'react';

import { Button } from '@ll-platform/frontend/components/shadcn/ui/button';
import {
  BudgetNodeName,
  type BudgetNodeContent,
  type BudgetNodeType,
} from '@ll-platform/frontend/hero/creativeProposal/document/nodes/BudgetNode/budgetNodeTypes';
import type {
  DocumentNodeConfig,
  NodeComponentProps,
} from '@ll-platform/frontend/hero/documentBuilder/core/nodes/nodeTypes';

export const BudgetNode = memo(
  ({ content, onContentChange }: NodeComponentProps<BudgetNodeType>) => {
    return (
      <div className="flex gap-4 justify-end items-end">
        <h2 className="text-2xl font-bold">
          Total Budget: {content.totalBudget}$
        </h2>
        <Button
          onClick={() => {
            onContentChange({
              ...content,
              totalBudget: content.totalBudget + 1000,
            });
          }}
        >
          More Budget
        </Button>
      </div>
    );
  },
);
BudgetNode.displayName = 'BudgetNode';

export const BudgetNodeConfig: DocumentNodeConfig<
  typeof BudgetNodeName,
  BudgetNodeContent
> = {
  type: BudgetNodeName,
  render: (props) => <BudgetNode {...props} />,
};
