// keep in sync with src/config/featureFlags/consts.ts
export enum ProjectStyleEnum {
  DocStyle = 'Doc-Style',
  Curated = 'Curated',
  Animated = 'Animated',
  Scripted = 'Scripted',
}
export enum HeroVideoTypeEnum {
  VideoAd = 'Video Ad',
  ProductVideo = 'Product Video',
  AboutUsVideo = 'About Us Video',
  CrowdfundingVideo = 'Crowdfunding Video',
  TestimonialVideo = 'Testimonial Video',
  EventVideo = 'Event Video',
  TeamVideo = 'Team Video',
  TutorialVideo = 'Tutorial Video',
  ExplainerVideo = 'Explainer Video',
  RawFootage = 'Raw Footage',
}

export enum ProjectStatus {
  VideoSummary = 'ProjectObjectives_v2',
  Interviews = 'Interviews_v2',
  Voiceover = 'Voiceover_v2',
  VoiceoverTts = 'VoiceoverTts_v2',
  Visuals = 'Visuals_v2',
  Schedule = 'FilmingAgenda_v2',
  Finalize = 'Finalize_v2',
  ScriptedVideoSummary = 'ScriptedVideoSummary_v2',
  Characters = 'Characters_v2',
  RoleCasting = 'RoleCasting_v2',
  ScriptedScript = 'ScriptedScript_v2',
  ScriptedTts = 'ScriptedTts_v2',
  Storyboard = 'Storyboard_v2',
  SubmitForReview = 'SubmitForReview_v2',
}
export enum ExternalProjectStatus {
  VideoSummary = 'ProjectObjectives_v2',
  Interviews = 'Interviews_v2',
  Voiceover = 'Voiceover_v2',
  VoiceoverTts = 'VoiceoverTts_v2',
  Visuals = 'Visuals_v2',
  Schedule = 'FilmingAgenda_v2',
  Finalize = 'Finalize_v2',
  ScriptedVideoSummary = 'ScriptedVideoSummary_v2',
  Characters = 'Characters_v2',
  RoleCasting = 'RoleCasting_v2',
  ScriptedScript = 'ScriptedScript_v2',
  ScriptedTts = 'ScriptedTts_v2',
  Storyboard = 'Storyboard_v2',
}

export enum CrewMemberEnum {
  SpecialtyDirector = 'Specialty Director',
  Director = 'Director',
  RemoteDirector = 'Remote Director',
  AssistantDirector = 'Assistant Director (AD)',
  SpecialtyDirectorOfPhotography = 'Specialty Director of Photography (DP)',
  DirectorOfPhotography = 'Director of Photography (DP)',
  SecondCameraOperator = '2nd Camera Operator',
  AssistantCameraOperator = 'Assistant Camera Operator (AC)',
  DroneOperator = 'Drone Operator',
  SpecialtyDroneOperator = 'Specialty Drone Operator',
  Photographer = 'Photographer',
  SoundOperator = 'Sound Operator',
  SpecialtyGaffer = 'Specialty Gaffer',
  Gaffer = 'Gaffer',
  Grip = 'Grip',
  JibOperator = 'Jib Operator',
  ProductionAssistant = 'Production Assistant (PA)',
  ProductionDesigner = 'Production Designer (PD)',
  HairAndMakeUpStylist = 'Hair & Makeup Stylist (HMU)',
  WardrobeStylist = 'Wardrobe Stylist',
  FoodStylist = 'Food Stylist',
  DepartmentAssistant = 'Department Assistant',
  StudioTeacher = 'Studio Teacher',
  OnSiteEditor = 'On-Site Editor',
  DigitalImagingTechnician = 'Digital Imaging Technician (DIT)',
}

export enum ProjectDeliverablesEnum {
  HeroVideosOnly = 'Hero Videos Only',
  RawFootageOnly = 'Raw Footage Only',
  RawFootageAndHeroVideos = 'Raw Footage and Hero Videos',
}

export enum RawFootageTypeEnum {
  Standard = 'Standard',
  Speciality = 'Speciality',
}

export enum FootageTypeEnum {
  ARoll = 'A-Roll',
  BRoll = 'B-Roll',
}

export enum ProjectTimelineEnum {
  STANDARD = 'Standard',
  PRIORITY = 'Priority',
  RUSH = 'Rush',
}

export enum LocationTypeEnum {
  STUDIO = 'Studio',
  SPECIALTY = 'Specialty',
}

export enum ProductionScheduleStatusEnum {
  SubmittedForExternalReview = 'submittedForExternalReview',
  SubmittedForInternalApproval = 'submittedForInternalApproval',
  Scheduled = 'scheduled',
}

export enum DroneOperatorTypeEnum {
  STANDARD = 'STANDARD',
  SPECIALITY = 'SPECIALITY',
}

export enum AspectRatioEnum {
  Horizontal = 'horizontal-16:9',
  Vertical = 'vertical-9:16',
  Square = 'square-1:1',
  Unknown = 'unknown',
}

export enum FootageSourceEnum {
  ClientProvided = 'client_provided',
  StockFootage = 'stock_footage',
  PreviouslyShot = 'previously_shot',
  ScreenRecording = 'screen_recording',
}

export enum HeroVideoAudioEnum {
  INTERVIEWS = 'INTERVIEWS',
  VOICEOVER = 'VOICEOVER',
  DIALOGUE = 'DIALOGUE',
  MIXED = 'MIXED',
  MUSIC = 'MUSIC',
}

export enum HeroVideoGraphicsEnum {
  BASIC = 'BASIC',
  SPECIALITY = 'SPECIALITY',
  PREMIUM = 'PREMIUM',
}

export enum ProjectTalentTypeEnum {
  FEATURE = 'Feature',
  BACKGROUND = 'Background',
  SPEAKING = 'Speaking',
  SPECIALTY = 'Specialty',
}

export enum ProjectProductTypeEnum {
  PRODUCT = 'Product',
  SERVICE = 'Service',
  SOFTWARE = 'Software',
}

export enum WardrobeTypeEnum {
  Basic = 'Basic',
  Specialty = 'Specialty',
  Custom = 'Custom',
}

export enum PropsTypeEnum {
  Basic = 'Basic',
  Specialty = 'Specialty',
  Custom = 'Custom',
}

// keep in sync with src/config/featureFlags/consts.ts
export enum AnimationStyleEnum {
  Whiteboard = 'whiteboard',
  TypographyIcon = 'typography_icon',
  IconHeavy = 'icon_heavy',
  Cartoon = 'cartoon',
  FullIllustration = 'full_illustration',
}

// keep in sync with src/config/featureFlags/consts.ts
export enum ScriptedStoryboardVisualStyleEnum {
  Photorealistic = 'photorealistic',
  SketchStyle = 'sketchStyle',
  Illustrated = 'illustrated',
}

export enum ProjectSubCollections {
  HeroVideos = 'heroes',
  DroneProductionDays = 'drones',
  ProductionDays = 'productions',
  Characters = 'characters',
  ProjectDocuments = 'projectDocuments',
  InterviewsInputs = 'interviewsInputs',
  VoiceoverInputs = 'voiceoverInputs',
  VisualsInputs = 'visualsInputs',
  StoryboardFrames = 'storyboardFrames',
  LookAndFeelImages = 'lookAndFeelImages',
  WardrobeImages = 'wardrobeImages',
  TimelineAdjustments = 'timelineAdjustments',
  Comments = 'comments',
  ProjectImages = 'projectImages',
  BudgetLineItems = 'budgetLineItems',
}

export enum ProjectUserRole {
  Internal = 'internal',
  Client = 'client',
}
