import { useCallback } from 'react';

import { FeatureFlagName } from '@ll-platform/frontend/config/featureFlags/featureFlags';
import { useAppFlags } from '@ll-platform/frontend/core/featureFlags/useAppFlags';
import {
  DashboardUserGroupMap,
  Pages,
} from '@ll-platform/frontend/core/router/pages';
import { useActiveUser } from '@ll-platform/frontend/features/auth/hooks/useActiveUser';
import { userGroupSwitch } from '@ll-platform/frontend/features/auth/utils/userGroupSwitch';
import { WizardFlowType } from '@ll-platform/frontend/features/projectWizard/contexts/WizardNavigationContext';
import { makeWizardPath } from '@ll-platform/frontend/features/projectWizard/utils/navigation';

export const useHomepagePagePath = () => {
  const { activeUser } = useActiveUser();
  const projectsPagePath = userGroupSwitch({
    user: activeUser,
    map: DashboardUserGroupMap,
  });

  return projectsPagePath;
};

export const useProjectHomepagePath = () => {
  const projectSpacePagePath = useCallback((id: string) => {
    return Pages.ProjectSpace.replace(':id', id);
  }, []);

  return projectSpacePagePath;
};

export const useProjectDetailsPath = () => {
  const flags = useAppFlags();

  const projectDetailsPath = useCallback(
    (id: string, isCreativeBriefInputFilled?: boolean) => {
      const path = (() => {
        if (
          isCreativeBriefInputFilled === false &&
          flags[FeatureFlagName.NewWizardFlow]
        ) {
          return makeWizardPath(id, WizardFlowType.CreativeBrief);
        }

        if (!flags[FeatureFlagName.ProjectSpace]) {
          return makeWizardPath(id);
        }

        return Pages.ProjectSpace;
      })();

      return path.replace(':id', id);
    },
    [flags],
  );

  return projectDetailsPath;
};
