import {
  createContext,
  useContext,
  useMemo,
  type PropsWithChildren,
} from 'react';

import type { DocumentSlide } from '@ll-platform/frontend/hero/documentBuilder/core/slides/slideTypes';
import { assertDefined } from '@ll-platform/frontend/utils/types/types';

type DocumentSlideContextType = {
  slide: DocumentSlide;
  slideIndex: number;
};

const DocumentSlideContext = createContext<DocumentSlideContextType | null>(
  null,
);
DocumentSlideContext.displayName = 'DocumentSlideContext';

type DocumentSlideProviderProps = DocumentSlideContextType & PropsWithChildren;

export const DocumentSlideProvider = ({
  children,
  slide,
  slideIndex,
}: DocumentSlideProviderProps) => {
  const value = useMemo(
    () => ({
      slide,
      slideIndex,
    }),
    [slide, slideIndex],
  );

  return (
    <DocumentSlideContext.Provider value={value}>
      {children}
    </DocumentSlideContext.Provider>
  );
};

export const useDocumentSlideContext = () => {
  const context = useContext(DocumentSlideContext);
  assertDefined(context, 'DocumentSlideContext in useDocumentSlideContext');

  return context;
};
