import type { NodeStyleProps } from '@ll-platform/frontend/hero/documentBuilder/core/nodes/nodeTypes';
import { DOCUMENT_SLIDE_MARGIN } from '@ll-platform/frontend/hero/documentBuilder/core/slides/slideConsts';
import { makeSlide } from '@ll-platform/frontend/hero/documentBuilder/core/slides/slideHelpers';
import type { DocumentSlide } from '@ll-platform/frontend/hero/documentBuilder/core/slides/slideTypes';
import type { SlideTemplateConfig } from '@ll-platform/frontend/hero/documentBuilder/core/slideTemplates/slideTemplateTypes';
import {
  makeDefaultImageObject,
  makeMediaNode,
} from '@ll-platform/frontend/hero/documentBuilder/shared/nodes/MediaNode/mediaNodeHelpers';
import { type MediaObject } from '@ll-platform/frontend/hero/documentBuilder/shared/nodes/MediaNode/mediaNodeTypes';
import { makeRichTextNode } from '@ll-platform/frontend/hero/documentBuilder/shared/nodes/RichTextNode/richTextNodeHelpers';

const makeDocumentSlide = ({
  text,
  media,
}: SlideTemplateParams): DocumentSlide => {
  return makeSlide({
    style: {
      padding: DOCUMENT_SLIDE_MARGIN,
    },
    nodes: [
      makeMediaNode({
        content: {
          media: media[0],
        },
        style: mediaStyle1,
      }),
      makeMediaNode({
        content: {
          media: media[1],
        },
        style: mediaStyle2,
      }),
      makeRichTextNode({
        content: {
          text,
        },
        style: textStyle,
      }),
    ],
  });
};

const textStyle: NodeStyleProps = {
  top: '50%',
  bottom: 0,
  left: '52%',
  right: 0,
};

const mediaStyle1: NodeStyleProps = {
  top: 0,
  bottom: 0,
  left: 0,
  height: '100%',
  width: '48%',
};

const mediaStyle2: NodeStyleProps = {
  top: 0,
  height: '45%',
  left: '52%',
  right: 0,
};

type SlideTemplateParams = {
  text: string;
  media: [MediaObject, MediaObject];
};

const templateDefaults: SlideTemplateParams = {
  text: 'Lorem Ipsum',
  media: [
    makeDefaultImageObject('https://placehold.co/588x624'),
    makeDefaultImageObject('https://placehold.co/588x331'),
  ],
};

const slideTemplate: SlideTemplateConfig<SlideTemplateParams> = {
  creator: {
    name: 'Image + (Text + Images)',
    create: async () => ({
      slides: [makeDocumentSlide(templateDefaults)],
    }),
  },
  templateDefaults,
  makeDocumentSlide,
};

export const text2ImagesConfig = {
  templateDefaults,
  slideTemplate,
  makeDocumentSlide,
};
