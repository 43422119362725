import { ArrowLeft } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

import { Button } from '@ll-platform/frontend/components/shadcn/ui/button';
import { NavigationBar } from '@ll-platform/frontend/components/tw/TwNavigationBar/TwNavigationBar';
import { Pages } from '@ll-platform/frontend/core/router/pages';
import { ProposalTitle } from '@ll-platform/frontend/hero/creativeProposal/components/ProposalTitle';
import { DocumentBuilderStatusIndicator } from '@ll-platform/frontend/hero/documentBuilder/shared/components/DocumentBuilderStatusIndicator';

export const ProposalDocumentNavBar = () => {
  const navigate = useNavigate();

  return (
    <NavigationBar>
      <div className="flex items-center">
        <Button
          variant="ghost"
          size="icon"
          onClick={() => navigate(Pages.CreativeProposalDashboard)}
          className="px-4 text-foreground"
        >
          <ArrowLeft />
        </Button>

        <ProposalTitle />

        <div className="flex items-center gap-2 translate-y-0.4">
          <DocumentBuilderStatusIndicator />
        </div>
      </div>
    </NavigationBar>
  );
};
