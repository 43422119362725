import { useMemo } from 'react';

import { PriceAdjustmentTypeEnum } from '@ll-platform/frontend/features/budget/enums';
import type { ProjectBudget } from '@ll-platform/frontend/features/budget/types';

type UsePriceAdjustmentsArgs = {
  data?: ProjectBudget;
};

export const usePriceAdjustments = ({ data }: UsePriceAdjustmentsArgs) => {
  return useMemo(() => {
    return {
      discountData: data?.priceAdjustments.find((adjustment) =>
        [
          PriceAdjustmentTypeEnum.FixedDiscount,
          PriceAdjustmentTypeEnum.PercentageDiscount,
        ].includes(adjustment.type),
      ),
      rushOrderData: data?.priceAdjustments.find((adjustment) =>
        [PriceAdjustmentTypeEnum.Markup].includes(adjustment.type),
      ),
    };
  }, [data?.priceAdjustments]);
};
