import type { Editor, Element } from 'slate';
import { withHistory } from 'slate-history';

import { withComments } from './comments/withCommentsPlugin';
import { withRichtext } from './richtext/withRichtextPlugin';
import {
  TextEditorElementEnum,
  TextEditorPluginsEnum,
  type TextEditorConfig,
  type TextEditorEditMode,
} from './types';

export const defaultEditMode: TextEditorEditMode = 'hover';

export const textEditorPluginMap: Record<
  TextEditorPluginsEnum,
  (editor: Editor) => Editor
> = {
  [TextEditorPluginsEnum.History]: withHistory,
  [TextEditorPluginsEnum.Comments]: withComments,
  [TextEditorPluginsEnum.RichText]: withRichtext,
};

export const selectionToolbarPlugins: TextEditorPluginsEnum[] = [
  TextEditorPluginsEnum.Comments,
];

export const toolbarPlugins: TextEditorPluginsEnum[] = [
  TextEditorPluginsEnum.RichText,
];

export const textEditorDefaultConfig: TextEditorConfig = {
  plugins: {
    comments: true,
    history: true,
  },
};

export const plainTextEditorDefaultConfig: TextEditorConfig = {
  plugins: {
    comments: false,
    history: false,
  },
};

export const textEditorDefaultInitialValue: Element[] = [
  {
    type: TextEditorElementEnum.Paragraph,
    children: [{ text: '' }],
  },
];
