import { difference, isEqual, omitBy } from 'lodash-es';

import {
  automapDates,
  type SerializedJson,
} from '@ll-platform/frontend/core/api/helpers/dateMapper';
import { heroHttpClient } from '@ll-platform/frontend/core/api/HeroHttpClient';
import {
  BrandRoleEnum,
  type AddProjectToBrand,
  type Brand,
  type BrandByIdQuery,
  type BrandByIdUsersArgs,
  type BrandProjectData,
  type BrandUsersResponse,
  type BrandWithTeamAndOrganization,
  type CreateBrand,
  type CreateBrandPayload,
  type FindAllBrandsDto,
  type GetBrandsByProjectId,
  type UpdateBrandArg,
  type UpdateBrandPayload,
} from '@ll-platform/frontend/features/brands/types';
import {
  defined,
  type ByIdParams,
} from '@ll-platform/frontend/utils/types/types';

class BrandsService {
  async create(createBrand: CreateBrand): Promise<Brand> {
    const {
      name,
      website,
      clientMainContactId,
      producerId,
      companyType,
      accountExecutiveId,
      creativeProducerId,
      editorId,
    } = createBrand;

    const brand = await this.makeCreate({
      name,
      website,
      companyType,
      users: [
        clientMainContactId,
        producerId,
        accountExecutiveId,
        creativeProducerId,
        editorId,
      ].filter(defined),
    });

    return brand;
  }

  private async makeCreate(
    createBrandPayload: CreateBrandPayload,
  ): Promise<Brand> {
    return await heroHttpClient.unwrappedHttpRequest<Brand>({
      config: {
        method: 'POST',
        url: '/v1/brands',
        data: createBrandPayload,
      },
    });
  }

  async update({
    id,
    keepExistingEditors = true,
    ...payload
  }: UpdateBrandArg): Promise<Brand> {
    const existingBrand = await this.getBrand({
      brandId: id,
    });

    const patch = omitBy(payload, (value, key) =>
      isEqual(value, existingBrand[key as keyof typeof existingBrand]),
    ) as UpdateBrandPayload;

    if (patch.users) {
      const existingOwner = existingBrand.team.find(
        (brandUser) => brandUser.role === BrandRoleEnum.Owner,
      )?.userId;
      const existingInternalUsers = existingBrand.team
        .filter((brandUser) => brandUser.role === BrandRoleEnum.Internal)
        .map((brandUser) => brandUser.userId);

      const existingUsersToCompare = [
        existingOwner,
        ...existingInternalUsers,
      ].filter(defined);
      const usersDiff = difference(patch.users, existingUsersToCompare);

      if (
        patch.users.length === existingUsersToCompare.length &&
        usersDiff.length === 0
      ) {
        delete patch.users;
      }

      if (patch.users && keepExistingEditors) {
        const existingEditors = existingBrand.team
          .filter((brandUser) => brandUser.role === BrandRoleEnum.Editor)
          .map((brandUser) => brandUser.userId);

        patch.users = [
          ...patch.users,
          ...existingEditors.filter((userId) => !patch.users!.includes(userId)),
        ];
      }
    }

    if (!Object.keys(patch).length) {
      return existingBrand;
    }

    const updatedBrand = await this.makeUpdate({
      id,
      ...patch,
    });

    return updatedBrand;
  }

  private async makeUpdate({
    id,
    ...payload
  }: UpdateBrandPayload & ByIdParams): Promise<Brand> {
    return await heroHttpClient.unwrappedHttpRequest<Brand>({
      config: {
        method: 'PATCH',
        url: `/v1/brands/${id}`,
        data: payload,
      },
    });
  }

  // This method is idempotent
  async addProjectToBrand({
    brandId,
    projectId,
    ...rest
  }: AddProjectToBrand): Promise<BrandProjectData> {
    return await heroHttpClient.unwrappedHttpRequest<BrandProjectData>({
      config: {
        method: 'PUT',
        url: `/v1/brands/${brandId}/projects/${projectId}`,
        data: {
          ...rest,
        },
      },
    });
  }

  async getBrandByProjectId({
    projectId,
  }: GetBrandsByProjectId): Promise<Brand> {
    const brands = await this.findAll({ projectId });

    return brands[0];
  }

  async getBrand(args: BrandByIdQuery): Promise<BrandWithTeamAndOrganization> {
    const brandData = await heroHttpClient.unwrappedHttpRequest<
      SerializedJson<BrandWithTeamAndOrganization>
    >({
      config: {
        method: 'GET',
        url: `/v1/brands/${args.brandId}`,
      },
    });

    return automapDates<BrandWithTeamAndOrganization>(brandData);
  }

  async findAll(args: FindAllBrandsDto): Promise<Brand[]> {
    const brands = await heroHttpClient.unwrappedHttpRequest<Brand[]>({
      config: {
        method: 'GET',
        url: '/v1/brands',
        params: args,
      },
    });

    return brands;
  }

  async getBrandUsers(args: BrandByIdUsersArgs): Promise<BrandUsersResponse> {
    return await heroHttpClient.unwrappedHttpRequest<BrandUsersResponse>({
      config: {
        method: 'GET',
        url: `/v1/brands/${args.brandId}/users`,
      },
    });
  }
}

export const brandsService = new BrandsService();
