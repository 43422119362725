import { EditOutlined } from '@mui/icons-material';
import { Button, Typography } from '@mui/material';

import { useUser } from '@ll-platform/frontend/features/auth/hooks/useUser';
import { isUserInternal } from '@ll-platform/frontend/features/auth/utils/isInternal';

import {
  useBudgetDiscountDialog,
  type UseBudgetDiscountDialogArgs,
} from './useBudgetDiscountDialog';

type BudgetDiscountButtonProps = UseBudgetDiscountDialogArgs;

export const BudgetDiscountButton = (props: BudgetDiscountButtonProps) => {
  const { activeUser } = useUser();
  const isInternalUser = isUserInternal(activeUser);

  const mode = props.discount ? 'Edit' : 'Add';

  const budgetDiscountDialog = useBudgetDiscountDialog(props);

  if (!isInternalUser) {
    return <Typography variant="body1">Discount</Typography>;
  }

  return (
    <>
      <Button
        variant="text"
        endIcon={mode === 'Edit' && <EditOutlined />}
        onClick={budgetDiscountDialog.openDialog}
        sx={{ p: 0 }}
      >
        <Typography variant="body1">{mode} Discount</Typography>
      </Button>
      {budgetDiscountDialog.dialogNode}
    </>
  );
};
