import {
  createContext,
  useContext,
  useMemo,
  useRef,
  useState,
  type PropsWithChildren,
} from 'react';

import { useEvent } from 'react-use';

type HoveredCommentContextType = {
  id: string | null;
  setId: (id: string | null) => void;
};

const HoveredCommentContext = createContext<HoveredCommentContextType>({
  id: null,
  setId: () => {},
});

HoveredCommentContext.displayName = 'HoveredCommentContext';

export function HoveredCommentContextProvider({ children }: PropsWithChildren) {
  const [id, setId] = useState<string | null>(null);

  const value = useMemo(() => {
    return {
      id,
      setId,
    };
  }, [id, setId]);

  return (
    <HoveredCommentContext.Provider value={value}>
      {children}
    </HoveredCommentContext.Provider>
  );
}

type UseHoveredCommentFromContextArgs = {
  threadId: string | null;
};

export const useHoveredCommentContext = ({
  threadId,
}: UseHoveredCommentFromContextArgs) => {
  const threadElementRef = useRef<HTMLDivElement>(null);
  const hoveredCommentContext = useContext(HoveredCommentContext);

  const isHovered = useMemo(() => {
    return hoveredCommentContext.id === threadId;
  }, [threadId, hoveredCommentContext.id]);

  useEvent(
    'mouseover',
    () => {
      hoveredCommentContext.setId(threadId);
    },
    threadElementRef?.current,
  );

  useEvent(
    'mouseleave',
    () => {
      hoveredCommentContext.setId(null);
    },
    threadElementRef?.current,
  );

  return useMemo(() => {
    return {
      isHovered,
      threadElementRef,
    };
  }, [isHovered, threadElementRef]);
};
