import { useMemo } from 'react';

import { useParams } from 'react-router-dom';

import { BudgetNodeConfig } from '@ll-platform/frontend/hero/creativeProposal/document/nodes/BudgetNode/BudgetNode';
import { budgetSlideTemplate } from '@ll-platform/frontend/hero/creativeProposal/document/slideTemplates/BudgetSlideTemplate/BudgetSlideTemplate';
import { overviewSlideTemplate } from '@ll-platform/frontend/hero/creativeProposal/document/slideTemplates/OverviewSlideTemplate/OverviewSlideTemplate';
import { postProductionSlideTemplate } from '@ll-platform/frontend/hero/creativeProposal/document/slideTemplates/postProductionSlideTemplate';
import type { CreativeProposal } from '@ll-platform/frontend/hero/creativeProposal/types';
import {
  type DocumentBuilderConfig,
  type DocumentBuilderViewModeEnum,
} from '@ll-platform/frontend/hero/documentBuilder/core/builder/documentBuilderTypes';
import type { DocumentNodeConfig } from '@ll-platform/frontend/hero/documentBuilder/core/nodes/nodeTypes';
import { defaultDocumentBuilderNodes } from '@ll-platform/frontend/hero/documentBuilder/shared/nodes/consts';
import { coverSlideTemplate } from '@ll-platform/frontend/hero/documentBuilder/shared/slideTemplates/coverSlideTemplate';
import { titleSlideTemplate } from '@ll-platform/frontend/hero/documentBuilder/shared/slideTemplates/titleSlideTemplate';
import { assertDefined } from '@ll-platform/frontend/utils/types/types';

export const useCreativeProposalDocumentBuilderConfig = ({
  creativeProposal,
}: {
  creativeProposal: CreativeProposal | undefined;
}): DocumentBuilderConfig | null => {
  const { mode } = useParams<{ mode: DocumentBuilderViewModeEnum }>();
  assertDefined(mode, 'mode path param');

  const config = useMemo((): DocumentBuilderConfig | null => {
    if (!creativeProposal) {
      return null;
    }

    return {
      documentId: creativeProposal.documentBuilderId,
      mode,
      nodes: [
        ...defaultDocumentBuilderNodes,
        BudgetNodeConfig,
      ] as DocumentNodeConfig[],
      slideTemplates: [
        titleSlideTemplate.creator,
        coverSlideTemplate.creator,
        overviewSlideTemplate.creator,
        postProductionSlideTemplate.creator,
        budgetSlideTemplate.creator,
      ],
    };
  }, [creativeProposal, mode]);

  return config;
};
