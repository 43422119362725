import type { JsonObject } from 'type-fest';

import type { DocumentNode } from '@ll-platform/frontend/hero/documentBuilder/core/nodes/nodeTypes';
import { generateId } from '@ll-platform/frontend/utils/helpers/id';

export const makeNodeId = () => `node-${generateId()}`;

export function makeNode<Type extends string, Content extends JsonObject>({
  type,
  content,
  style,
}: Pick<DocumentNode<Type, Content>, 'type' | 'content' | 'style'>) {
  return {
    id: makeNodeId(),
    type,
    content,
    style,
  };
}
