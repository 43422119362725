import { type ReactNode } from 'react';

import { Close } from '@mui/icons-material';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  type DialogProps,
} from '@mui/material';

export type CustomDialogProps = Omit<DialogProps, 'title' | 'onClose'> & {
  title?: ReactNode;
  body?: ReactNode;
  showCloseIcon?: boolean;
  onDialogClose: () => unknown;
  customActions?: ReactNode;
};

export const CustomDialog = ({
  title,
  body,
  showCloseIcon = true,
  onDialogClose,
  customActions,
  ...dialogProps
}: CustomDialogProps) => {
  return (
    <Dialog fullWidth maxWidth="sm" {...dialogProps} onClose={onDialogClose}>
      {title && (
        <DialogTitle
          sx={{
            py: 2,
            px: 3,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {title}
          {showCloseIcon && (
            <IconButton
              aria-label="close"
              onClick={onDialogClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <Close />
            </IconButton>
          )}
        </DialogTitle>
      )}

      {body && (
        <DialogContent
          sx={{
            '&.MuiDialogContent-root': {
              py: 2,
              px: 3,
            },
          }}
        >
          {typeof body === 'string' ? (
            <Typography variant="body1">{body}</Typography>
          ) : (
            body
          )}
        </DialogContent>
      )}

      {customActions}
    </Dialog>
  );
};
