import { useMemo } from 'react';

import { isEqual, omit } from 'lodash-es';
import { useFormContext } from 'react-hook-form';

import { getDefaultFormValues } from '@ll-platform/frontend/features/internalProjects/projectForm/utils/yupHelpers';

const defaultValues = getDefaultFormValues(
  {},
  {
    isCreativeProposalFlow: false, // FIXME: This is probably wrong
  },
);

export const useProjectEmpty = () => {
  const { getValues } = useFormContext();
  const project = getValues();

  const isProjectEmpty = useMemo(() => {
    if (!project) {
      return null;
    }

    // FIXME: This is a hack to avoid breaking something
    return isEqual(
      omit(project, 'projectUsers'),
      omit(defaultValues, 'projectUsers'),
    );
  }, [project]);

  return isProjectEmpty;
};
