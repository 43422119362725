import type { To } from 'react-router-dom';

import { UserGroup } from '@ll-platform/frontend/core/auth/types';

export enum Pages {
  AuthRedirect = '/',
  InternalBase = '/internal',
  InternalBrandRedirect = '/internal/brand/:id',
  InternalBrandPage = '/internal/brand/:id/:tab',
  InternalDashboardRedirect = '/internal/dashboard',
  InternalProjects = '/internal/dashboard/projects',
  InternalNewProject = '/internal/project/new',
  InternalEditProject = '/internal/project/edit/:id',
  InternalContracts = '/internal/dashboard/contracts',
  InternalNewContract = '/internal/dashboard/contracts/new',
  InternalEditContract = '/internal/dashboard/contracts/edit/:id',
  InternalProposals = '/internal/dashboard/proposals',
  ProposalDoc = '/proposal/:id',
  InternalNewProposal = '/internal/dashboard/proposals/new',
  InternalEditProposal = '/internal/dashboard/proposals/edit/:id',
  InternalPromptPlayground = '/internal/playgrounds/prompt',
  InternalWardrobePlayground = '/internal/playgrounds/wardrobe',
  ProjectSpacePlayground = '/internal/playgrounds/project-space',
  InternalThemePlayground = '/internal/playgrounds/theme',
  InternalTextEditorPlayground = '/internal/playgrounds/text-editor',
  InternalVideoPlayerPlayground = '/internal/playgrounds/video-player',
  DashboardRedirect = '/dashboard',
  ExternalProjects = '/dashboard/projects',
  ProjectSpace = '/project-space/:id',
  ProjectSpacePage = '/project-space/:id/:page',
  ProjectWizard = '/dashboard/project/:id/:flowType/:step',
  ProposalDefaultProjectWizard = '/internal/dashboard/proposal/:proposalId',
  ProposalProjectWizard = '/internal/dashboard/proposal/:proposalId/project/:id/:flowType/:step',
  Register = '/register',
  Login = '/login',
  ResetPassword = '/reset-password',
  AuthGoogleCallback = '/auth/google/callback',
  SettingsMyAccount = '/settings/my-account',
  PostProductionVideoReviewLatest = '/post-production/:id/review/:deliverableKey',
  PostProductionVideoPublicLatest = '/post-production/:id/public/:deliverableKey',
  PostProductionVideoReviewVersion = '/post-production/:id/review/:deliverableKey/:versionName',
  PostProductionVideoPublicVersion = '/post-production/:id/public/:deliverableKey/:versionName',
  CreativeProposalDashboard = '/creative-proposal/dashboard',
  CreativeProposal = '/creative-proposal/:id',
  Creator = '/creator',
  Status404 = '/status/404',
}

export const DashboardUserGroupMap: Record<UserGroup, To> = {
  [UserGroup.Internal]: Pages.InternalDashboardRedirect,
  [UserGroup.External]: Pages.DashboardRedirect,
  [UserGroup.Creator]: Pages.Creator,
};
