import { useMemo } from 'react';

import {
  defaultSteps,
  ProjectFormSteps,
} from '@ll-platform/frontend/features/internalProjects/pages/ProjectForm/consts';
import { useProjectDeliverables } from '@ll-platform/frontend/features/internalProjects/projectForm/utils/useProjectDeliverables';
import { useProjectStyle } from '@ll-platform/frontend/features/internalProjects/projectForm/utils/useProjectStyle';
import {
  ProjectDeliverablesEnum,
  ProjectStyleEnum,
} from '@ll-platform/frontend/features/projects/enums';

export const useProjectFormSteps = () => {
  const projectStyle = useProjectStyle();
  const projectDeliverables = useProjectDeliverables();

  const steps = useMemo(() => {
    if (
      [ProjectStyleEnum.Curated, ProjectStyleEnum.Animated].includes(
        projectStyle,
      )
    ) {
      return defaultSteps;
    }

    if (
      [ProjectStyleEnum.DocStyle, ProjectStyleEnum.Scripted].includes(
        projectStyle,
      )
    ) {
      const steps = [
        ProjectFormSteps.ProjectDetails,
        ProjectFormSteps.ProductionDays,
      ];

      if (
        projectStyle === ProjectStyleEnum.Scripted ||
        projectDeliverables !== ProjectDeliverablesEnum.RawFootageOnly
      ) {
        steps.push(ProjectFormSteps.HeroVideos);
      }

      if (projectStyle === ProjectStyleEnum.Scripted) {
        steps.push(ProjectFormSteps.Talents);
      }

      steps.push(ProjectFormSteps.ReviewAndComplete);

      return steps;
    }

    return defaultSteps;
  }, [projectStyle, projectDeliverables]);

  return steps;
};
