export enum ProjectFormSteps {
  ProjectDetails = 'project-details',
  ProductionDays = 'production-days',
  HeroVideos = 'hero-videos',
  Talents = 'talents',
  ReviewAndComplete = 'review-and-complete',
}

export const ProjectFormStepLabelMap: Record<ProjectFormSteps, string> = {
  [ProjectFormSteps.ProjectDetails]: 'Project Details',
  [ProjectFormSteps.ProductionDays]: 'Production Days',
  [ProjectFormSteps.HeroVideos]: 'Hero Videos',
  [ProjectFormSteps.Talents]: 'Total Talent',
  [ProjectFormSteps.ReviewAndComplete]: 'Review and Complete',
};

export const defaultSteps = [
  ProjectFormSteps.ProjectDetails,
  ProjectFormSteps.HeroVideos,
  ProjectFormSteps.ReviewAndComplete,
];

export const defaultStep = ProjectFormSteps.ProjectDetails;
