import React, { useEffect, useImperativeHandle, useRef } from 'react';

import { usePrevious } from 'react-use';

import { Textarea } from '@ll-platform/frontend/components/shadcn/ui/textarea';

const TwTextareaAutoresize = React.forwardRef<
  HTMLTextAreaElement,
  React.ComponentProps<'textarea'>
>(({ ...props }, ref) => {
  const textareaLocalRef = useRef<HTMLTextAreaElement | null>(null);

  const prevValue = usePrevious(props.value);

  useImperativeHandle(
    ref,
    () => textareaLocalRef.current as HTMLTextAreaElement,
  );

  const resizeTextArea = () => {
    if (textareaLocalRef.current) {
      textareaLocalRef.current.style.height = 'auto';
      textareaLocalRef.current.style.height =
        textareaLocalRef.current.scrollHeight + 'px';
    }
  };
  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    // In case of value is passed in the props this function will be called by use effect
    !props.value && resizeTextArea();
    props.onChange?.(e);
  };

  useEffect(() => {
    if (props.value !== prevValue) {
      resizeTextArea();
    }
  }, [props.value, prevValue]);

  return <Textarea ref={textareaLocalRef} {...props} onChange={onChange} />;
});

TwTextareaAutoresize.displayName = 'TwTextareaAutoresize';

export default TwTextareaAutoresize;
