import { ValidationError, type AnyObjectSchema, type InferType } from 'yup';

export function makeConfig<T extends AnyObjectSchema>({
  schema,
  name,
}: {
  schema: T;
  name: string;
}): InferType<T> {
  try {
    const validatedConfig = schema.validateSync(import.meta.env, {
      abortEarly: false,
      stripUnknown: true,
    });

    return validatedConfig;
  } catch (error) {
    if (import.meta.env.PROD || !(error instanceof ValidationError)) {
      throw error;
    }
    console.error(`Validation errors for ${name} config:`);
    error.errors.forEach((err: string) => {
      console.error('- ', err);
    });

    const missingEnvVars = error.inner.filter(
      (err) => err.type === 'optionality',
    );

    const invalidEnvVars = error.inner.filter(
      (err) => err.type !== 'optionality',
    );

    console.log('Copy missing variables below');
    console.log(
      missingEnvVars.map((v) => `${v.path}=${v.value ?? ''}`).join('\n'),
    );

    window.alert(
      `Missing environment variables:\n${missingEnvVars
        .map((v) => `${v.path}=${v.value ?? ''}`)
        .join('\n')}\n\nInvalid environment variables:\n${invalidEnvVars
        .map((v) => `${v.path}=${v.value ?? ''}`)
        .join('\n')}\n\nCheck console for more informations and template`,
    );

    throw new Error('Abort');
  }
}
