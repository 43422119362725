import { authenticationService } from '@ll-platform/frontend/core/auth/async/AuthenticationService';
import {
  AccountType,
  type ActiveUserProfile,
} from '@ll-platform/frontend/core/auth/types';
import { useQueryActiveUser } from '@ll-platform/frontend/features/auth/async/hooks';

type UseUserReturn =
  | {
      isLoading: boolean;
      isAuthorized: true;
      activeUser: ActiveUserProfile;
    }
  | {
      isLoading: boolean;
      isAuthorized: false;
      activeUser: undefined;
    };

export function useUser(): UseUserReturn {
  const token = authenticationService.getUserJwt();

  const activeUserQuery = useQueryActiveUser(
    {},
    {
      enabled: !!token && token.accountType !== AccountType.Creator,
      meta: {
        supressErrorToast: true,
      },
    },
  );
  const activeUser = activeUserQuery.data;

  if (activeUser) {
    return {
      isLoading: false,
      isAuthorized: true,
      activeUser,
    };
  } else {
    return {
      isLoading: activeUserQuery.isLoading,
      isAuthorized: false,
      activeUser: undefined,
    };
  }
}
