import { useCallback } from 'react';

import { AddCommentOutlined } from '@mui/icons-material';
import { Button, type ButtonProps } from '@mui/material';
import { useSlateStatic } from 'slate-react';

import { activityTracker } from '@ll-platform/frontend/core/analytics/activityTracker';
import { ActivityType } from '@ll-platform/frontend/core/analytics/events';
import type { ProjectCommentMetadata } from '@ll-platform/frontend/features/projectComments/types';
import { useToolbarEditModeContext } from '@ll-platform/frontend/features/textEditor/contexts/ToolbarEditModeContext';

type CommentButtonProps = ButtonProps & {
  metadata: ProjectCommentMetadata;
  disablePartialCommenting?: boolean;
};

export const CommentButton = ({
  metadata,
  disablePartialCommenting,
  ...props
}: CommentButtonProps) => {
  const { setEditMode } = useToolbarEditModeContext();
  const editor = useSlateStatic();

  const handleClickCommentButton = useCallback(() => {
    setEditMode('newComment');
    if (disablePartialCommenting) {
      editor.select({
        anchor: editor.start([]),
        focus: editor.end([]),
      });
    }

    activityTracker.log({
      type: ActivityType.WizardOutputClickedToLeaveComment,
      metadata,
    });
  }, [disablePartialCommenting, editor, metadata, setEditMode]);

  return (
    <Button
      color="inherit"
      data-disable-entering-edit-mode={true}
      startIcon={<AddCommentOutlined />}
      onClick={handleClickCommentButton}
      {...props}
      sx={{
        '& .MuiButton-icon': {
          marginLeft: 0,
        },
      }}
    >
      Add comment
    </Button>
  );
};
