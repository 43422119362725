import { Node, type Descendant, type Text } from 'slate';

import type { CommentElement } from '@ll-platform/frontend/features/textEditor/comments/types';
import { TextEditorElementEnum } from '@ll-platform/frontend/features/textEditor/types';

export const slateToPseudoMarkdown = (nodes: Descendant[]) => {
  return nodes.map((n) => convertNodeToPseudoMarkdown(n)).join('\n');
};

const convertNodeToPseudoMarkdown = (node: Descendant): string => {
  if ('text' in node) {
    return makeMarkdownText(node);
  }

  if ('children' in node) {
    switch (node.type) {
      case TextEditorElementEnum.Paragraph:
        return node.children
          .map((n) => convertNodeToPseudoMarkdown(n))
          .join('');
      case TextEditorElementEnum.Comment:
        return makeMarkdownLink(node);
    }
  }

  return convertNodeToPseudoMarkdown(node);
};

export const makeMarkdownLink = (node: CommentElement) => {
  const displayText = Node.string(node);
  if (!displayText) {
    return '';
  }
  const linkTitle = JSON.stringify(node.comment);
  const link = TextEditorElementEnum.Comment;

  return `[${displayText}](${link} '${linkTitle}')`;
};

const makeMarkdownText = (node: Text) => {
  return Node.string(node);
};
