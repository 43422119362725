import { useCallback } from 'react';

import { useGetCreativeProposalByProposalId } from '@ll-platform/frontend/hero/creativeProposal/async/useCreativeProposalsQueries';
import { createProposalDocument } from '@ll-platform/frontend/hero/creativeProposal/document/createProposalDocument';
import { useCreativeProposalPageParams } from '@ll-platform/frontend/hero/creativeProposal/hooks/useCreativeProposalPageParams';
import { DocumentBuilderContent } from '@ll-platform/frontend/hero/documentBuilder/core/builder/DocumentBuilderContent';
import { useDocumentBuilderInitialData } from '@ll-platform/frontend/hero/documentBuilder/core/builder/useDocumentBuilderInitialData';
import { assertDefined } from '@ll-platform/frontend/utils/types/types';

export const CreativeProposalDocumentContent = () => {
  const { id } = useCreativeProposalPageParams();
  const { data: creativeProposal } = useGetCreativeProposalByProposalId({
    id,
  });

  const getInitialData = useCallback(async () => {
    assertDefined(creativeProposal, 'creativeProposal');

    return await createProposalDocument({ creativeProposal });
  }, [creativeProposal]);
  useDocumentBuilderInitialData(creativeProposal ? getInitialData : null);

  return <DocumentBuilderContent />;
};
