import {
  MutationCache,
  QueryCache,
  QueryClient,
  type keepPreviousData,
  type Mutation,
  type Query,
  type QueryKey,
  type UseQueryOptions,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';

import { APP_CONFIG } from '@ll-platform/frontend/config/app.config';
import { isDev } from '@ll-platform/frontend/config/isDev';
import { extractErrorMessage } from '@ll-platform/frontend/utils/helpers/errors';

interface MetaOptions {
  supressErrorToast?: boolean;
}

const queryRequestErrorHandler = (
  error: Error,
  query: Query<unknown, unknown, unknown, QueryKey>,
) => {
  console.error(error);
  const message = extractErrorMessage({
    error,
    fallbackMessage: 'Request failed',
  });

  if (!query.meta?.supressErrorToast) {
    toast.error(message);
  }
};

const mutationRequestErrorHandler = (
  error: unknown,
  _variables: unknown,
  _context: unknown,
  mutation: Mutation<unknown, unknown, unknown, unknown>,
  // eslint-disable-next-line max-params
) => {
  console.error(error);
  const message = extractErrorMessage({
    error,
    fallbackMessage: 'Request failed',
  });

  if (!mutation.options.meta?.supressErrorToast) {
    toast.error(message);
  }
};

const serverDownToastId = 'hero-http-client-server-down';
function retryStrategy(failureCount: number, error: unknown) {
  if (APP_CONFIG.REACT_APP_SHOULD_RETRY_ON_NETWORK_ERROR) {
    if (error instanceof AxiosError && error.code === 'ERR_NETWORK') {
      if (isDev()) {
        console.log('API Server is down, retrying...');
        toast.warn('API Server is down, retrying...', {
          autoClose: false,
          toastId: serverDownToastId,
        });
      }

      return true;
    }
  }

  return failureCount < 3;
}
function retryDelayStrategy(failureCount: number, error: unknown) {
  if (error instanceof AxiosError && error.code === 'ERR_NETWORK') {
    return 3000;
  }

  return Math.min(1000 * Math.pow(2, failureCount), 10000);
}

export const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: queryRequestErrorHandler,
    onSuccess: () => {
      toast.dismiss(serverDownToastId);
    },
  }),
  mutationCache: new MutationCache({
    onError: mutationRequestErrorHandler,
  }),
  defaultOptions: {
    queries: {
      retry: retryStrategy,
      retryDelay: retryDelayStrategy,
    },
  },
});

declare module '@tanstack/react-query' {
  interface QueryMeta extends MetaOptions {}
  interface MutationMeta extends MetaOptions {}
}

export type UniversalQueryOptions = Pick<
  UseQueryOptions,
  'enabled' | 'staleTime' | 'meta'
> & { refetchInterval?: number; placeholderData?: typeof keepPreviousData };
