import { Editor, Element, type Path } from 'slate';

import { TextEditorElementEnum } from '@ll-platform/frontend/features/textEditor/types';

import type { CommentElement } from './types';

const getCurrentCommentEntries = (editor: Editor) => {
  const commentEntries = Array.from(
    Editor.nodes<CommentElement>(editor, {
      match: (n) =>
        Element.isElement(n) && n.type === TextEditorElementEnum.Comment,
    }),
  );

  return commentEntries;
};

export const getCurrentCommentNode = (
  editor: Editor,
): CommentElement | undefined => {
  const commentEntries = getCurrentCommentEntries(editor);

  if (commentEntries.length === 0) {
    return undefined;
  }

  const node = commentEntries[0][0];

  return Element.isElement(node) ? node : undefined;
};

export const getCurrentCommentPath = (editor: Editor): Path | undefined => {
  const commentEntries = getCurrentCommentEntries(editor);

  if (commentEntries.length === 0) {
    return undefined;
  }

  const path = commentEntries[0][1];

  return path;
};

export const isCommentActive = (editor: Editor) => {
  return getCurrentCommentEntries(editor).length > 0;
};
