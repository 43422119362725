import type { User } from '@ll-platform/frontend/core/auth/types';
import { isUserInternal } from '@ll-platform/frontend/features/auth/utils/isInternal';
import {
  FootageTypeEnum,
  HeroVideoAudioEnum,
  ProjectDeliverablesEnum,
  ProjectStyleEnum,
} from '@ll-platform/frontend/features/projects/enums';
import type {
  ProjectCharacter,
  ProjectWithDeliverables,
} from '@ll-platform/frontend/features/projects/types';
import { WizardFlowType } from '@ll-platform/frontend/features/projectWizard/contexts/WizardNavigationContext';

export enum WizardSteps {
  VideoSummary = 'video-summary',
  ScriptedVideoSummary = 'scripted-video-summary',
  Interviews = 'interviews',
  Schedule = 'schedule',
  Voiceover = 'voiceover',
  VoiceoverTts = 'voiceover-tts',
  Visuals = 'visuals',
  ScriptedScript = 'scripted-script',
  ScriptedTts = 'scripted-tts',
  Storyboard = 'storyboard',
  Characters = 'characters',
  RoleCasting = 'role-casting',
  Finalize = 'finalize',
  SubmitForReview = 'submit-for-review',
  FinalizeEdit = 'finalize-edit',
}

export const WizardStepLabelMap: Record<WizardSteps, string> = {
  [WizardSteps.VideoSummary]: 'Video Objectives',
  [WizardSteps.ScriptedVideoSummary]: 'Video Objectives',
  [WizardSteps.Interviews]: 'Interviewee Messaging',
  [WizardSteps.Schedule]: 'Filming Agenda',
  [WizardSteps.Voiceover]: 'Scripts',
  [WizardSteps.VoiceoverTts]: 'Voiceovers',
  [WizardSteps.Visuals]: 'Scene Descriptions',
  [WizardSteps.ScriptedScript]: 'Scripts',
  [WizardSteps.ScriptedTts]: 'Voiceovers',
  [WizardSteps.Storyboard]: 'Storyboards',
  [WizardSteps.Characters]: 'Characters',
  [WizardSteps.RoleCasting]: 'Role Casting',
  [WizardSteps.Finalize]: 'Finish',
  [WizardSteps.SubmitForReview]: 'Finish',
  [WizardSteps.FinalizeEdit]: 'Finish',
};

type GetWizardStepsForProjectArgs = {
  project: ProjectWithDeliverables;
  user: User;
  characters?: ProjectCharacter[];
  flowType?: WizardFlowType;
  isProposalFlow?: boolean;
};

export function getWizardStepsForProject({
  project,
  user,
  characters = [],
  flowType = WizardFlowType.Generate,
  isProposalFlow = false,
}: GetWizardStepsForProjectArgs) {
  if (!project) {
    return null;
  }

  const steps: WizardSteps[] = [];

  switch (project.style) {
    case ProjectStyleEnum.DocStyle: {
      const doesProjectHaveInterviews = project.heroes?.some(
        (video) => video.audio === HeroVideoAudioEnum.INTERVIEWS,
      );

      steps.push(WizardSteps.VideoSummary);

      if (isProposalFlow) {
        break;
      }

      if (
        doesProjectHaveInterviews ||
        project.deliverables === ProjectDeliverablesEnum.RawFootageOnly
      ) {
        steps.push(WizardSteps.Interviews);
      }
      steps.push(WizardSteps.Schedule);

      break;
    }
    case ProjectStyleEnum.Animated:
    case ProjectStyleEnum.Curated: {
      const doesProjectHaveVoiceovers = project.heroes?.some(
        (video) => video.audio === HeroVideoAudioEnum.VOICEOVER,
      );

      steps.push(WizardSteps.VideoSummary);

      if (isProposalFlow) {
        break;
      }

      if (doesProjectHaveVoiceovers) {
        steps.push(WizardSteps.Voiceover);
        if (flowType !== WizardFlowType.CreativeBrief) {
          steps.push(WizardSteps.VoiceoverTts);
        }
      }
      steps.push(WizardSteps.Visuals);

      break;
    }
    case ProjectStyleEnum.Scripted: {
      steps.push(WizardSteps.ScriptedVideoSummary);

      if (isProposalFlow) {
        break;
      }

      const doesProjectHaveCharacters = !!characters?.length;
      if (doesProjectHaveCharacters) {
        steps.push(WizardSteps.Characters);
      }

      steps.push(WizardSteps.ScriptedScript);

      const doesProjectHaveSpeech = project.heroes.some(
        (video) =>
          // Only VOICEOVER with a single voice is supported for now
          video.audio && [HeroVideoAudioEnum.VOICEOVER].includes(video.audio),
      );
      const isARollType = project.footageType === FootageTypeEnum.ARoll;

      if (doesProjectHaveSpeech && !isARollType) {
        steps.push(WizardSteps.ScriptedTts);
      }
      steps.push(WizardSteps.Storyboard);

      if (doesProjectHaveCharacters) {
        steps.push(WizardSteps.RoleCasting);
      }

      if (
        [
          WizardFlowType.EditCreativeDeck,
          WizardFlowType.EditCallSheet,
        ].includes(flowType)
      ) {
        steps.push(WizardSteps.FinalizeEdit);
      } else {
        if (isUserInternal(user)) {
          steps.push(WizardSteps.SubmitForReview);
        } else {
          steps.push(WizardSteps.Finalize);
        }
      }

      break;
    }
    default: {
      console.warn(`Project style "${project.style}" is not supported`);
    }
  }

  return steps;
}
