import * as yup from 'yup';

export const projectNotesFields = {
  projectNotes: 'projectNotes',
  videoReferences: 'videoReferences',
} as const;

export const projectNotesSchema = yup.object().shape({
  [projectNotesFields.projectNotes]: yup.string().required().default(''),
  [projectNotesFields.videoReferences]: yup.string().optional().default(''),
});

export type ProjectNotesFormValues = yup.InferType<typeof projectNotesSchema>;
