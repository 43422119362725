import { useCallback, useMemo, useState } from 'react';

export const useTwConfirmDialog = () => {
  const [isOpen, setIsOpen] = useState(false);

  const onToggle = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, []);

  const onOpen = useCallback(() => {
    setIsOpen(true);
  }, []);

  const onClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  return useMemo(
    () => ({
      isOpen,
      onOpen,
      onClose,
      onToggle,
    }),
    [isOpen, onOpen, onClose, onToggle],
  );
};
