import '@ll-platform/frontend/config/setupSentry';
import '@ll-platform/frontend/config/setupLoadErrorHandler';
import '@ll-platform/frontend/config/setupYup';
import '@ll-platform/frontend/config/setupDayjs';
import '@ll-platform/frontend/config/tailwindStyles.css';

import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

import { HelmetProvider } from 'react-helmet-async';

import App from './container/App';
import CreatorApp from './container/CreatorApp';

const root = createRoot(document.getElementById('root')!);

root.render(
  <StrictMode>
    <HelmetProvider>
      {location.pathname.startsWith('/creator') ? <CreatorApp /> : <App />}
    </HelmetProvider>
  </StrictMode>,
);
