import * as yup from 'yup';

export const sentimentSchema = yup
  .object()
  .shape({
    easyToUse: yup.number().required().min(0.5),
    accuracyOfQuestions: yup.number().required().min(0.5),
    recommendationLikehood: yup.number().required().min(0.5),
    opinion: yup.string(),
  })
  .required();

export type SentimentFormValues = yup.InferType<typeof sentimentSchema>;
