import { type ReactNode } from 'react';

import { LoadingButton } from '@mui/lab';
import {
  Button,
  DialogActions,
  type ButtonProps,
  type DialogProps,
} from '@mui/material';

import { CustomDialog } from '@ll-platform/frontend/components/CustomDialog/CustomDialog';

export type ConfirmDialogProps = Omit<DialogProps, 'title'> & {
  title?: ReactNode;
  body?: ReactNode;
  showCloseIcon?: boolean;
  confirmLabel?: string;
  cancelLabel?: string;
  confirmButtonProps?: Partial<ButtonProps>;
  cancelButtonProps?: Partial<ButtonProps>;
  isLoading?: boolean;
  onConfirm: () => Promise<unknown> | unknown;
  onCancel: () => unknown;
};

export const ConfirmDialog = ({
  title,
  body,
  confirmLabel = 'Confirm',
  cancelLabel = 'Cancel',
  confirmButtonProps = {},
  cancelButtonProps = {},
  isLoading = false,
  showCloseIcon = true,
  onConfirm,
  onCancel,
  ...dialogProps
}: ConfirmDialogProps) => {
  const {
    color: confirmButtonColor = 'primary',
    variant: confirmButtonVariant = 'contained',
    ...otherConfirmButtonProps
  } = confirmButtonProps;

  const {
    color: cancelButtonColor = 'inherit',
    variant: cancelButtonVariant = 'outlined',
    ...otherCancelButtonProps
  } = cancelButtonProps;

  return (
    <CustomDialog
      title={title}
      body={body}
      showCloseIcon={showCloseIcon}
      onDialogClose={onCancel}
      customActions={
        <DialogActions sx={{ p: 3, gap: 1 }}>
          <Button
            onClick={onCancel}
            color={cancelButtonColor}
            variant={cancelButtonVariant}
            disabled={isLoading}
            {...otherCancelButtonProps}
          >
            {cancelLabel}
          </Button>
          <LoadingButton
            onClick={onConfirm}
            color={confirmButtonColor}
            variant={confirmButtonVariant}
            loading={isLoading}
            {...otherConfirmButtonProps}
          >
            {confirmLabel}
          </LoadingButton>
        </DialogActions>
      }
      {...dialogProps}
    />
  );
};
