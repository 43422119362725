import { groupBy, mapValues } from 'lodash-es';
import * as yup from 'yup';

import { ProjectStyleEnum } from '@ll-platform/frontend/config/featureFlags/consts';
import { AccountType } from '@ll-platform/frontend/core/auth/types';
import { isLemonlightEmployee } from '@ll-platform/frontend/features/auth/utils/isInternal';
import { contextProjectStyle } from '@ll-platform/frontend/features/internalProjects/projectForm/validationContext';
import { type ProjectFormOutput } from '@ll-platform/frontend/features/internalProjects/types';
import { type ProjectUserOrInvite } from '@ll-platform/frontend/features/projects/types';
import { isProjectUserRegistered } from '@ll-platform/frontend/features/projects/utils/projectUsers';
import { isEmail } from '@ll-platform/frontend/utils/helpers/emails';
import { yupUserIdOrEmail } from '@ll-platform/frontend/utils/helpers/yup';
import { type EmailOrUserId } from '@ll-platform/frontend/utils/types/types';

export const projectUsersFields = {
  [AccountType.User]: AccountType.User,
  [AccountType.AccountExecutive]: AccountType.AccountExecutive,
  [AccountType.Producer]: AccountType.Producer,
  [AccountType.Creative]: AccountType.Creative,
  [AccountType.Editor]: AccountType.Editor,
} as const;

export type PrefixedProjectUsersFields =
  `projectUsers.${keyof typeof projectUsersFields}`;

export const projectUsersSchema = yup.object().shape({
  [AccountType.User]: yup
    .array()
    .of(yup.string().required())
    .min(1)
    .max(50)
    .required()
    .default([]),
  [AccountType.AccountExecutive]: yup
    .array()
    .of(yupUserIdOrEmail)
    .min(1)
    .max(50)
    .required()
    .default([]),
  [AccountType.Producer]: yup
    .array()
    .of(yupUserIdOrEmail)
    .min(1)
    .max(50)
    .required()
    .default([]),
  [AccountType.Creative]: yup
    .array()
    .of(yupUserIdOrEmail)
    .when(contextProjectStyle, {
      is: ProjectStyleEnum.Scripted,
      then: (schema) => schema.min(1),
      otherwise: (schema) => schema,
    })
    .max(50)
    .required()
    .default([]),
  [AccountType.Editor]: yup
    .array()
    .of(yupUserIdOrEmail)
    .required()
    .max(50)
    .default([]),
});

export type ProjectUsersFormValues = yup.InferType<typeof projectUsersSchema>;

export type ProjectUsersInput = ProjectUserOrInvite[];

export const mapProjectUsersFormToDto = (
  formValues: ProjectUsersFormValues,
): ProjectFormOutput['projectUsers'] => {
  const emailsOrUserIds = Object.values(formValues).flat();
  const { userIds, emails } = groupBy(emailsOrUserIds, (emailOrUserId) =>
    isEmail(emailOrUserId) ? 'emails' : 'userIds',
  );

  return { userIds, emails };
};

export const mapProjectUsersDtoToForm = (
  dto: ProjectUsersInput,
): ProjectUsersFormValues => {
  return mapValues(
    groupBy(dto, (projectUser) => {
      if (isProjectUserRegistered(projectUser)) {
        return projectUser.user.accountType;
      }

      if (isLemonlightEmployee(projectUser)) {
        return AccountType.AccountExecutive; // We don't know exact account type;
      }

      return AccountType.User;
    }),
    (users) =>
      users.map((user) => {
        if (isProjectUserRegistered(user)) {
          return user.user._id;
        }

        return user.email;
      }),
  ) as Record<AccountType, EmailOrUserId[]>;
};
