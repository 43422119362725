import * as yup from 'yup';

import { PriceAdjustmentTypeEnum } from '@ll-platform/frontend/features/budget/enums';

export const budgetDiscountSchema = (maxValue?: number) =>
  yup
    .object()
    .shape({
      type: yup
        .string()
        .oneOf(Object.values(PriceAdjustmentTypeEnum))
        .default(null)
        .required(),
      value: yup
        .number()
        .transform((value, originalValue) =>
          originalValue === '' ? null : value,
        )
        .positive()
        .required()
        .test(
          'max-fixed-value',
          'Discount value cannot be greater than estimated budget',
          (value, context) => {
            const type = context.parent.type;
            if (!maxValue || type !== PriceAdjustmentTypeEnum.FixedDiscount) {
              return true;
            }

            return value !== undefined && value <= maxValue / 100;
          },
        )
        .test(
          'max-percentage-value',
          'Discount % cannot be higher than 100',
          (value, context) => {
            const type = context.parent.type;
            if (type !== PriceAdjustmentTypeEnum.PercentageDiscount) {
              return true;
            }

            return !!value && value <= 100;
          },
        ),
    })
    .required();

export type DiscountSchemaType = yup.InferType<
  ReturnType<typeof budgetDiscountSchema>
>;
