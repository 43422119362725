import { createQueryKeys } from '@lukemorales/query-key-factory';
import { useQuery } from '@tanstack/react-query';

import type { UniversalQueryOptions } from '@ll-platform/frontend/core/api/reactQuerySetup';
import type { GetBudgetArgs } from '@ll-platform/frontend/features/budget/types';
import { createQueryHook } from '@ll-platform/frontend/utils/factories/createQueryHook';
import { createQueryOption } from '@ll-platform/frontend/utils/factories/createQueryOption';
import useDebouncedValue from '@ll-platform/frontend/utils/hooks/useDebouncedValue';

import { budgetService } from './BudgetService';

export const BudgetQueries = createQueryKeys('contracts', {
  getBudgetByProject: createQueryOption(
    budgetService.getBudgetByProject.bind(budgetService),
  ),
  getBudget: createQueryOption(budgetService.getBudget.bind(budgetService)),
});

export const useGetBudgetByProject = createQueryHook(
  BudgetQueries.getBudgetByProject,
);
export const useGetBudget = createQueryHook(BudgetQueries.getBudget);

// used when project can change with each keystroke in input e.g. in project name/notes
export const useGetDebouncedBudget = (
  { project, debounceMs, ...restArgs }: GetBudgetArgs & { debounceMs?: number },
  options?: UniversalQueryOptions,
) => {
  const debouncedProjectData = useDebouncedValue(project, debounceMs ?? 250);

  return useQuery({
    ...BudgetQueries.getBudget({
      project: debouncedProjectData,
      ...restArgs,
    }),
    enabled: !!debouncedProjectData,
    placeholderData: (prevData) => prevData,
    ...options,
  });
};
