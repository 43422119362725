import type { NodeStyleProps } from '@ll-platform/frontend/hero/documentBuilder/core/nodes/nodeTypes';
import { DOCUMENT_SLIDE_MARGIN } from '@ll-platform/frontend/hero/documentBuilder/core/slides/slideConsts';
import { makeSlide } from '@ll-platform/frontend/hero/documentBuilder/core/slides/slideHelpers';
import type { DocumentSlide } from '@ll-platform/frontend/hero/documentBuilder/core/slides/slideTypes';
import type { SlideTemplateConfig } from '@ll-platform/frontend/hero/documentBuilder/core/slideTemplates/slideTemplateTypes';
import {
  makeDefaultImageObject,
  makeMediaNode,
} from '@ll-platform/frontend/hero/documentBuilder/shared/nodes/MediaNode/mediaNodeHelpers';
import { type MediaObject } from '@ll-platform/frontend/hero/documentBuilder/shared/nodes/MediaNode/mediaNodeTypes';
import { makeRichTextNode } from '@ll-platform/frontend/hero/documentBuilder/shared/nodes/RichTextNode/richTextNodeHelpers';

const makeDocumentTextImageSlide = ({
  text,
  media,
}: TextImageSlideTemplateParams): DocumentSlide => {
  return makeSlide({
    nodes: [
      makeRichTextNode({
        content: {
          text,
        },
        style: textStyle,
      }),
      makeMediaNode({
        content: {
          media,
        },
        style: mediaStyle,
      }),
    ],
  });
};

const textStyle: NodeStyleProps = {
  top: DOCUMENT_SLIDE_MARGIN,
  bottom: DOCUMENT_SLIDE_MARGIN,
  left: DOCUMENT_SLIDE_MARGIN,
  width: '36%',
};

const mediaStyle: NodeStyleProps = {
  top: DOCUMENT_SLIDE_MARGIN,
  bottom: DOCUMENT_SLIDE_MARGIN,
  right: DOCUMENT_SLIDE_MARGIN,
  width: 660,
};

type TextImageSlideTemplateParams = {
  text: string;
  media: MediaObject;
};

const textImageSlideTemplateDefaults: TextImageSlideTemplateParams = {
  text: 'Lorem Ipsum',
  media: makeDefaultImageObject('https://placehold.co/660x704'),
};

export const textImageSlideTemplate: SlideTemplateConfig<TextImageSlideTemplateParams> =
  {
    creator: {
      name: 'Text + Image',
      create: async () => ({
        slides: [makeDocumentTextImageSlide(textImageSlideTemplateDefaults)],
      }),
    },
    templateDefaults: textImageSlideTemplateDefaults,
    makeDocumentSlide: makeDocumentTextImageSlide,
  };
