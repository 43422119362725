import { useQueryClient } from '@tanstack/react-query';

import { activityTracker } from '@ll-platform/frontend/core/analytics/activityTracker';
import { ActivityType } from '@ll-platform/frontend/core/analytics/events';
import { authenticationService } from '@ll-platform/frontend/core/auth/async/AuthenticationService';
import type { User } from '@ll-platform/frontend/core/auth/types';
import { Pages } from '@ll-platform/frontend/core/router/pages';

export function useLogOut({ activeUser }: { activeUser?: User } = {}) {
  const queryClient = useQueryClient();

  return async ({
    redirect = Pages.Login,
  }: { redirect?: string | false } = {}) => {
    if (activeUser) {
      activityTracker.log(ActivityType.LogoutUser);
    }

    sessionStorage.clear();
    authenticationService.clearUserTokens();

    if (redirect) {
      // Load the page from scratch
      window.location.href = redirect;
    } else {
      await queryClient.resetQueries();
    }
  };
}
