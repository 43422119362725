import { useCallback } from 'react';

import { toast } from 'sonner';

import { getInputClasses } from '@ll-platform/frontend/components/shadcn/lib/utils';
import {
  Card,
  CardContent,
} from '@ll-platform/frontend/components/shadcn/ui/card';
import {
  Form,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@ll-platform/frontend/components/shadcn/ui/form';
import { Input } from '@ll-platform/frontend/components/shadcn/ui/input';
import { Separator } from '@ll-platform/frontend/components/shadcn/ui/separator';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@ll-platform/frontend/components/shadcn/ui/tooltip';
import { TwSpinner } from '@ll-platform/frontend/components/tw/TwSpinner/TwSpinner';
import TwTextareaAutoresize from '@ll-platform/frontend/components/tw/TwTextareaAutoresize/TwTextareaAutoresize';
import {
  useGenerateProposalSummary,
  useUpdateCreativeProposal,
} from '@ll-platform/frontend/hero/creativeProposal/async/useCreativeProposalsMutations';
import { useGetCreativeProposalByProposalId } from '@ll-platform/frontend/hero/creativeProposal/async/useCreativeProposalsQueries';
import { ConceptUploadAssets } from '@ll-platform/frontend/hero/creativeProposal/concept/components/ConceptUploadAssets';
import { GenerateConceptsButton } from '@ll-platform/frontend/hero/creativeProposal/concept/components/GenerateConceptsButton';
import {
  GeneratingLoadingScopeClaims,
  LOADING_SCOPE_GENERATING,
  NEW_CONCEPTS_COUNT,
} from '@ll-platform/frontend/hero/creativeProposal/concept/consts';
import {
  CONCEPT_INPUT_MAX_LENGTH,
  mapConceptFormToDto,
  useConceptFormContext,
  WEBSITE_INPUT_MAX_LENGTH,
  type ConceptFormValues,
} from '@ll-platform/frontend/hero/creativeProposal/concept/hooks/useConceptForm';
import { useConceptPageParams } from '@ll-platform/frontend/hero/creativeProposal/concept/hooks/useConceptPageParams';
import { useLoadingContext } from '@ll-platform/frontend/utils/contexts/LoadingContext';
import { wait } from '@ll-platform/frontend/utils/helpers/helpers';

type ConceptFormProps = {
  //
};

// eslint-disable-next-line no-empty-pattern
export const ConceptForm = ({}: ConceptFormProps) => {
  const { id } = useConceptPageParams();
  // TODO: Use suspense
  const { data, isPending } = useGetCreativeProposalByProposalId({
    id,
  });
  const { mutateAsync: mutateUpdateProposal } = useUpdateCreativeProposal();
  const { mutateAsync: mutateGenerateProposalSummary } =
    useGenerateProposalSummary();

  const loadingGeneratingCtx = useLoadingContext(LOADING_SCOPE_GENERATING);

  const methods = useConceptFormContext();
  const isValid = methods.formState.isValid;

  const handleSubmit = useCallback(
    async (values: ConceptFormValues) => {
      try {
        loadingGeneratingCtx.startLoadingClaim(
          GeneratingLoadingScopeClaims.SaveForm,
        );
        await mutateUpdateProposal({
          id,
          input: mapConceptFormToDto(values),
        });
        await mutateGenerateProposalSummary({ id });
        await mutateUpdateProposal({
          id,
          conceptsToCreate: NEW_CONCEPTS_COUNT,
        });

        await wait(1000); // Prevent toast flicker
        loadingGeneratingCtx.stopLoadingClaim(
          GeneratingLoadingScopeClaims.SaveForm,
        );
      } catch (error) {
        console.error(error);
        toast.error('Failed to generate concepts.');
        loadingGeneratingCtx.stopLoadingClaim(
          GeneratingLoadingScopeClaims.SaveForm,
        );
      }
    },
    [
      mutateGenerateProposalSummary,
      id,
      loadingGeneratingCtx,
      mutateUpdateProposal,
    ],
  );

  const autoSave = useCallback(async () => {
    if (!(await methods.trigger())) {
      return;
    }

    const values = methods.getValues();

    await mutateUpdateProposal({
      id,
      input: mapConceptFormToDto(values),
    });
  }, [methods, mutateUpdateProposal, id]);

  if (isPending) {
    return <TwSpinner />;
  }

  return (
    <>
      <Form {...methods}>
        <form className="mt-7 flex h-full w-full flex-col space-y-4 overflow-hidden">
          <Card className="mx-6 mb-1 overflow-auto">
            <CardContent className="p-4">
              <FormField
                control={methods.control}
                name="userPrompt"
                render={({ field }) => (
                  <FormItem className="flex flex-col">
                    <FormLabel className="line-clamp-1 mb-4">
                      Creative prompt
                    </FormLabel>
                    <FormDescription className="line-clamp-2">
                      Describe your concept, script, or raw ideas.
                    </FormDescription>
                    <TwTextareaAutoresize
                      {...field}
                      className={getInputClasses(
                        methods.formState.errors.userPrompt,
                      )}
                      maxLength={CONCEPT_INPUT_MAX_LENGTH + 1}
                      onBlur={() => {
                        field.onBlur();
                        autoSave();
                      }}
                    />
                    <FormMessage />
                  </FormItem>
                )}
              />
            </CardContent>
          </Card>

          <Card className="mx-6 mb-1">
            <CardContent className="p-4">
              <FormField
                control={methods.control}
                name="websiteUrls.0"
                render={({ field }) => (
                  <FormItem className="flex flex-col">
                    <FormLabel className="line-clamp-1 mb-4">
                      Brand Website
                    </FormLabel>
                    <FormDescription className="line-clamp-2">
                      Paste the site URL to align concepts with the brand.
                    </FormDescription>
                    <Input
                      {...field}
                      className={getInputClasses(
                        methods.formState.errors.websiteUrls,
                      )}
                      maxLength={WEBSITE_INPUT_MAX_LENGTH + 1}
                      onBlur={() => {
                        field.onBlur();
                        autoSave();
                      }}
                    />
                    <FormMessage>
                      {methods.formState.errors.websiteUrls?.root?.message}
                    </FormMessage>
                  </FormItem>
                )}
              />
            </CardContent>
          </Card>
          <Separator className="mx-6 border-dashed w-auto bg-transparent border-t" />
          <ConceptUploadAssets />
          <Tooltip>
            <TooltipTrigger
              className="!mt-auto sticky bottom-0 left-0 bg-sidebar px-6 py-5"
              asChild
            >
              <div className="w-full">
                <GenerateConceptsButton
                  concepts={data?.concepts ?? []}
                  onClick={methods.handleSubmit(handleSubmit)}
                  disabled={!isValid}
                />
              </div>
            </TooltipTrigger>
            {!isValid && (
              <TooltipContent>Add details to generate a concept</TooltipContent>
            )}
          </Tooltip>
        </form>
      </Form>
    </>
  );
};
