import { useCallback, type MouseEvent, type ReactNode } from 'react';

import { Box, Tooltip, Typography, type TypographyProps } from '@mui/material';
import { ReactEditor, useSelected, useSlateStatic } from 'slate-react';

import { InlineChromiumBugfix } from '@ll-platform/frontend/features/textEditor/components/InlineChromiumBugfix';
import { useToolbarEditModeContext } from '@ll-platform/frontend/features/textEditor/contexts/ToolbarEditModeContext';
import type { LinkElement as LinkElementType } from '@ll-platform/frontend/features/textEditor/types';

type LinkElementProps = {
  attributes: Record<string, unknown>;
  children: ReactNode;
  element: LinkElementType;
  textNodeProps?: TypographyProps;
};

export const LinkElement = ({
  attributes,
  children,
  element,
  textNodeProps,
}: LinkElementProps) => {
  const selected = useSelected();
  const editor = useSlateStatic();

  const { setEditMode } = useToolbarEditModeContext();

  const handleClick = useCallback(
    (e: MouseEvent) => {
      // Added to prevent calling mouse handler from a input element which enters edit mode when someone clicks comment
      e.stopPropagation();
      setEditMode('editLink');
      const path = ReactEditor.findPath(editor, element);
      editor.select(path);
    },
    [setEditMode, element, editor],
  );

  return (
    <Box component="span" {...attributes}>
      <Tooltip title={element.url}>
        <Typography
          component="a"
          href={element.url}
          target="_blank"
          onMouseDown={handleClick}
          rel="noopener noreferrer"
          {...textNodeProps}
          sx={{
            cursor: 'pointer',
            textDecoration: 'underline',
            color: 'primary.main',
            ...(selected && {
              backgroundColor: (theme) => theme.palette.action.selected,
            }),
            ...textNodeProps?.sx,
          }}
        >
          <InlineChromiumBugfix />
          {children}
          <InlineChromiumBugfix />
        </Typography>
      </Tooltip>
    </Box>
  );
};
