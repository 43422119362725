import { memo, useCallback } from 'react';

import { Plus } from 'lucide-react';

import { Button } from '@ll-platform/frontend/components/shadcn/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@ll-platform/frontend/components/shadcn/ui/dropdown-menu';
import { useDocumentBuilder } from '@ll-platform/frontend/hero/documentBuilder/core/builder/DocumentBuilderContext';
import { useDocumentSlideContext } from '@ll-platform/frontend/hero/documentBuilder/core/slides/DocumentSlideContext';
import { useManageSlides } from '@ll-platform/frontend/hero/documentBuilder/core/slides/useManageSlides';
import type { SlideTemplateCreator } from '@ll-platform/frontend/hero/documentBuilder/core/slideTemplates/slideTemplateTypes';

export const AddSlideButton = memo(() => {
  const { slideTemplates } = useDocumentBuilder();
  const { insertSlides } = useManageSlides();
  const { slideIndex } = useDocumentSlideContext();

  const handleCreateFromTemplate = useCallback(
    async (template: SlideTemplateCreator) => {
      const { slides } = await template.create();
      insertSlides(slideIndex + 1, slides);
    },
    [insertSlides, slideIndex],
  );

  return (
    <DropdownMenu modal={false}>
      <DropdownMenuTrigger asChild>
        <Button variant="outline">
          <Plus className="h-4 w-4 mr-2" />
          Add section
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        {slideTemplates.map((template) => (
          <DropdownMenuItem
            key={template.name}
            onClick={() => handleCreateFromTemplate(template)}
          >
            {template.name}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
});
AddSlideButton.displayName = 'AddSlideButton';
