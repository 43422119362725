import type { User } from '@ll-platform/frontend/core/auth/types';
import { isUserInternal } from '@ll-platform/frontend/features/auth/utils/isInternal';
import type { ProjectWithDeliverables } from '@ll-platform/frontend/features/projects/types';

type GetProjectStatusArgs = {
  project: ProjectWithDeliverables;
  activeUser: User;
};

export const getProjectStatus = ({
  project,
  activeUser,
}: GetProjectStatusArgs) => {
  return (
    (!isUserInternal(activeUser) && project.externalStatus) || project.status!
  );
};
