import { createQueryKeys } from '@lukemorales/query-key-factory';
import { keepPreviousData, useQuery } from '@tanstack/react-query';

import { projectsService } from '@ll-platform/frontend/features/projects/async/ProjectsService';
import { createQueryHook } from '@ll-platform/frontend/utils/factories/createQueryHook';
import { createQueryOption } from '@ll-platform/frontend/utils/factories/createQueryOption';

export const ProjectsQueries = createQueryKeys('projects', {
  findAll: createQueryOption(projectsService.findAll.bind(projectsService)),
  getById: createQueryOption(projectsService.getById.bind(projectsService)),
  getBaseDataById: createQueryOption(
    projectsService.getBaseDataById.bind(projectsService),
  ),
  getHeroVideosByProjectId: createQueryOption(
    projectsService.getHeroVideosByProjectId.bind(projectsService),
  ),
  getHeroVideoById: createQueryOption(
    projectsService.getHeroVideoById.bind(projectsService),
  ),
  getProductionDaysByProjectId: createQueryOption(
    projectsService.getProductionDaysByProjectId.bind(projectsService),
  ),
  getDroneProductionDaysByProjectId: createQueryOption(
    projectsService.getDroneProductionDaysByProjectId.bind(projectsService),
  ),
  getCharactersByProjectId: createQueryOption(
    projectsService.getCharactersByProjectId.bind(projectsService),
  ),
  getProjectUsers: createQueryOption(
    projectsService.getProjectUsers.bind(projectsService),
  ),
  getProjectUserInvites: createQueryOption(
    projectsService.getProjectUserInvites.bind(projectsService),
  ),
});

export function useGetProjectById({ id }: { id: string | undefined }) {
  return useQuery({
    ...ProjectsQueries.getById({ id: id! }),
    enabled: !!id,
    staleTime: 1000 * 60 * 5,
  });
}

export function useGetProjectBaseDataById({ id }: { id: string | undefined }) {
  return useQuery({
    ...ProjectsQueries.getBaseDataById({ id: id! }),
    enabled: !!id,
    staleTime: 1000 * 60 * 5,
  });
}

export function useClientProjects() {
  return useQuery({
    ...ProjectsQueries.findAll({}),
  });
}

export const useGetProductionDaysQuery = createQueryHook(
  ProjectsQueries.getProductionDaysByProjectId,
  {
    placeholderData: keepPreviousData,
  },
);

export const useGetDroneProductionsQuery = createQueryHook(
  ProjectsQueries.getDroneProductionDaysByProjectId,
  {
    placeholderData: keepPreviousData,
  },
);

export const useGetHeroVideoById = createQueryHook(
  ProjectsQueries.getHeroVideoById,
  {
    placeholderData: keepPreviousData,
  },
);

export const useGetCharactersByProjectId = createQueryHook(
  ProjectsQueries.getCharactersByProjectId,
);

export const useGetProjectUsers = createQueryHook(
  ProjectsQueries.getProjectUsers,
);

export const useGetProjectUserInvites = createQueryHook(
  ProjectsQueries.getProjectUserInvites,
);
