import {
  FileUploaderInline,
  FileUploaderMinimal,
  type FileValidators,
  type OutputFileEntry,
  type TProps as UploaderProps,
} from '@uploadcare/react-uploader';

import { APP_CONFIG } from '@ll-platform/frontend/config/app.config';

import '@uploadcare/react-uploader/core.css';

import { convertUploadcareGifToVideoUrl } from '@ll-platform/frontend/utils/helpers/uploadcare';

export type { OutputFileEntry } from '@uploadcare/react-uploader';

export type TwUploadcareWidgetProps = {
  onChange: (files: OutputFileEntry[] | null) => void;
  closeDialog: VoidFunction;
  onUploadStart?: VoidFunction;
  maxLocalFileSizeBytes?: number;
  imgOnly?: boolean;
  minimal?: boolean;
  multiple?: boolean;
  fileValidators?: FileValidators;
};

export const TwUploadcareWidget = ({
  onChange,
  closeDialog,
  maxLocalFileSizeBytes,
  onUploadStart,
  imgOnly = true,
  minimal = false,
  multiple = false,
  fileValidators,
}: TwUploadcareWidgetProps) => {
  // we only want to set common props between Minimal, Inline and Regular uploader,
  // so it shouldn't matter which one is in the generic
  const commonProps: UploaderProps<'Minimal'> = {
    pubkey: APP_CONFIG.REACT_APP_UPLOADCARE_PUBLIC_KEY,
    multiple,
    imgOnly,
    maxLocalFileSizeBytes,
    classNameUploader: 'uc-dark',
    sourceList: 'local, url, camera, dropbox, gdrive',
    onFileUploadStart: onUploadStart,
  };

  const handleFileChange = (files: OutputFileEntry[] | null) => {
    if (!files) {
      onChange(null);

      return;
    }

    const processedFiles = files.map((file) => {
      if (file.mimeType === 'image/gif') {
        return {
          ...file,
          cdnUrl: convertUploadcareGifToVideoUrl(file.cdnUrl),
        } as OutputFileEntry;
      }

      return file;
    });

    onChange(processedFiles);
  };

  const themeStyles =
    '[&_uc-file-uploader-minimal]:uc-primary-oklch-dark-[57.3%_0.223_265.63] bg-background';

  const inlineThemeStyles =
    '[&_uc-file-uploader-inline]:uc-primary-oklch-dark-[57.3%_0.223_265.63] bg-background';

  return minimal ? (
    <div className={`${themeStyles} rounded-md transition-colors`}>
      <FileUploaderMinimal
        {...commonProps}
        onFileUploadSuccess={(file) => handleFileChange([file])}
        onFileRemoved={() => handleFileChange(null)}
        onFileUploadFailed={() => handleFileChange(null)}
        fileValidators={fileValidators}
      />
    </div>
  ) : (
    <div
      className={`w-[500px] max-w-full ${inlineThemeStyles} rounded-md transition-colors`}
    >
      <FileUploaderInline
        {...commonProps}
        fileValidators={fileValidators}
        onDoneClick={(event) => {
          if (event.status === 'success') {
            handleFileChange(
              multiple ? event.successEntries : [event.successEntries[0]],
            );
          }
          closeDialog();
        }}
      />
    </div>
  );
};
