import { forwardRef, memo, useCallback, useMemo } from 'react';

import type { Descendant } from 'slate';

import { assertDefined } from '@ll-platform/frontend/utils/types/types';

import { deserializeEditor } from './deserializers/deserialize';
import { serializeEditor } from './serializers/serialize';
import {
  StatelessTextEditor,
  type StatelessTextEditorProps,
} from './StatelessTextEditor';
import type { TextEditorInputType } from './types';

export type TextEditorProps = Omit<
  StatelessTextEditorProps,
  'initialValue' | 'onChange' | 'onReadOnlySubmit' | 'onEditorBlur'
> & {
  value?: string;
  type: TextEditorInputType;
  isReadOnly?: boolean;
  isWholeTextHighlighted?: boolean;
  disablePartialCommenting?: boolean;
  onReadOnlySubmit?: (value: string) => void;
  onBlur?: (value: string) => Promise<void> | void | Promise<boolean>;
  onChange?: (value: string) => Promise<void> | void | Promise<boolean>;
};

export const TextEditor = memo(
  forwardRef(
    (
      {
        value,
        type,
        onChange: onChangeInput,
        onReadOnlySubmit,
        onBlur,
        ...props
      }: TextEditorProps,
      ref,
    ) => {
      const initialValue = useMemo(() => {
        if (!value) {
          return undefined;
        }

        return deserializeEditor(value, type);
      }, [value, type]);

      const handleEditorChange: NonNullable<
        StatelessTextEditorProps['onChange']
      > = useCallback(
        (nodes) => {
          const newValue = serializeEditor(nodes, type);

          onChangeInput?.(newValue);
        },
        [onChangeInput, type],
      );

      const handleReadOnlySubmit = useCallback(
        (nodes: Descendant[]) => {
          const newValue = serializeEditor(nodes, type);
          onReadOnlySubmit?.(newValue);
        },
        [onReadOnlySubmit, type],
      );

      const handleBlur = (nodes: Descendant[]) => {
        assertDefined(onBlur, 'onBlur');
        const newValue = serializeEditor(nodes, type);

        onBlur(newValue);
      };

      return (
        <StatelessTextEditor
          key={JSON.stringify(initialValue)}
          initialValue={initialValue}
          onChange={onChangeInput ? handleEditorChange : undefined}
          ref={ref}
          onReadOnlySubmit={handleReadOnlySubmit}
          onEditorBlur={onBlur ? handleBlur : undefined}
          {...props}
        />
      );
    },
  ),
);

TextEditor.displayName = 'TextEditor';
