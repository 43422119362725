import { budgetSlideTemplate } from '@ll-platform/frontend/hero/creativeProposal/document/slideTemplates/BudgetSlideTemplate/BudgetSlideTemplate';
import { overviewSlideTemplate } from '@ll-platform/frontend/hero/creativeProposal/document/slideTemplates/OverviewSlideTemplate/OverviewSlideTemplate';
import type { CreativeProposal } from '@ll-platform/frontend/hero/creativeProposal/types';
import type { DocumentData } from '@ll-platform/frontend/hero/documentBuilder/core/builder/documentBuilderTypes';
import {
  DOCUMENT_SLIDE_HEIGHT,
  DOCUMENT_SLIDE_WIDTH,
} from '@ll-platform/frontend/hero/documentBuilder/core/slides/slideConsts';
import { makeDefaultImageObject } from '@ll-platform/frontend/hero/documentBuilder/shared/nodes/MediaNode/mediaNodeHelpers';
import { titleSlideTemplate } from '@ll-platform/frontend/hero/documentBuilder/shared/slideTemplates/titleSlideTemplate';

export const createProposalDocument = async ({
  creativeProposal,
}: {
  creativeProposal: CreativeProposal;
}): Promise<DocumentData> => {
  return {
    slides: [
      titleSlideTemplate.makeDocumentSlide({
        title: creativeProposal.name,
        media: makeDefaultImageObject(
          `https://picsum.photos/seed/2/${DOCUMENT_SLIDE_WIDTH}/${DOCUMENT_SLIDE_HEIGHT}`,
        ),
      }),
      ...(await overviewSlideTemplate.creator.create()).slides,
      ...(await budgetSlideTemplate.creator.create()).slides,
      titleSlideTemplate.makeDocumentSlide({
        title: 'Thank you!',
        media: makeDefaultImageObject(
          `https://picsum.photos/seed/3/${DOCUMENT_SLIDE_WIDTH}/${DOCUMENT_SLIDE_HEIGHT}`,
        ),
      }),
    ],
    metadata: {},
  };
};
