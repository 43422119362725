import { useCallback } from 'react';

import { useDocumentBuilder } from '@ll-platform/frontend/hero/documentBuilder/core/builder/DocumentBuilderContext';

import { type DocumentSlide } from './slideTypes';

export const useManageSlides = () => {
  const { updateDocument } = useDocumentBuilder();

  const updateSlides = useCallback(
    (updater: (current: DocumentSlide[]) => DocumentSlide[]) => {
      updateDocument((doc) => {
        return { ...doc, slides: updater(doc.slides) };
      });
    },
    [updateDocument],
  );

  const updateSlide = useCallback(
    (slideId: string, updater: (current: DocumentSlide) => DocumentSlide) => {
      updateSlides((slides) => {
        return slides.map((p) => (p.id === slideId ? updater(p) : p));
      });
    },
    [updateSlides],
  );

  const insertSlides = useCallback(
    (index: number, slides: DocumentSlide[]) => {
      updateSlides((current) => {
        const newSlides = [...current];
        newSlides.splice(index, 0, ...slides);

        return newSlides;
      });
    },
    [updateSlides],
  );

  const deleteSlide = useCallback(
    (slideId: string) => {
      updateSlides((slides) => {
        return slides.filter((p) => p.id !== slideId);
      });
    },
    [updateSlides],
  );

  return {
    insertSlides,
    deleteSlide,
    updateSlides,
    updateSlide,
  };
};
