import { createQueryKeys } from '@lukemorales/query-key-factory';

import { createQueryHook } from '@ll-platform/frontend/utils/factories/createQueryHook';
import { createQueryOption } from '@ll-platform/frontend/utils/factories/createQueryOption';

import { projectCommentsService } from './ProjectCommentsService';

export const ProjectCommentsQueries = createQueryKeys('projectComments', {
  getCommentById: createQueryOption(
    projectCommentsService.getCommentById.bind(projectCommentsService),
  ),
  getCommentsByThreadId: createQueryOption(
    projectCommentsService.getCommentsByThreadId.bind(projectCommentsService),
  ),
  getCommentsByProjectIdAndOutputSubcollections: createQueryOption(
    projectCommentsService.getCommentsByProjectIdAndOutputSubcollections.bind(
      projectCommentsService,
    ),
  ),
  getCommentsByProjectIdAndProjectFields: createQueryOption(
    projectCommentsService.getCommentsByProjectIdAndProjectFields.bind(
      projectCommentsService,
    ),
  ),
  getVideoDeliverableComments: createQueryOption(
    projectCommentsService.getVideoDeliverableComments.bind(
      projectCommentsService,
    ),
  ),
});

export const useGetCommentById = createQueryHook(
  ProjectCommentsQueries.getCommentById,
);

export const useGetCommentsByThreadId = createQueryHook(
  ProjectCommentsQueries.getCommentsByThreadId,
);

export const useGetCommentsByProjectIdAndOutputSubcollections = createQueryHook(
  ProjectCommentsQueries.getCommentsByProjectIdAndOutputSubcollections,
);

export const useGetCommentsByProjectIdAndProjectFields = createQueryHook(
  ProjectCommentsQueries.getCommentsByProjectIdAndProjectFields,
);

export const useGetDeliverableVideoReviewComments = createQueryHook(
  ProjectCommentsQueries.getVideoDeliverableComments,
);
