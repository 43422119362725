import { LEMONLIGHT_WORKSPACE_DOMAIN } from '@ll-platform/frontend/consts/hero';
import {
  InternalAccountTypes,
  type AccountType,
} from '@ll-platform/frontend/core/auth/types';
import { extractDomainFromEmail } from '@ll-platform/frontend/utils/helpers/helpers';

export function isUserInternal(user?: { accountType?: AccountType }) {
  return user?.accountType && InternalAccountTypes.includes(user.accountType);
}

export function isLemonlightEmployee({ email }: { email: string }) {
  return extractDomainFromEmail(email) === LEMONLIGHT_WORKSPACE_DOMAIN;
}
