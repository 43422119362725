import { Element, Transforms, type Descendant, type Editor } from 'slate';

import {
  TextEditorElementEnum,
  type CustomElement,
  type LinkElement,
} from '@ll-platform/frontend/features/textEditor/types';
import { isUrl } from '@ll-platform/frontend/utils/helpers/testUrl';

import { wrapLink } from './linkTransforms';

export const withRichtext = (editor: Editor) => {
  const {
    isInline,
    isSelectable,
    normalizeNode,
    getFragment,
    insertText,
    insertData,
  } = editor;

  editor.isInline = (element: Element | CustomElement) => {
    return element.type === TextEditorElementEnum.Link || isInline(element);
  };

  editor.isSelectable = (element: Element | CustomElement) => {
    return element.type === TextEditorElementEnum.Link || isSelectable(element);
  };

  editor.normalizeNode = ([node, path]) => {
    if (!Element.isElement(node)) {
      return normalizeNode([node, path]);
    }

    if (node.type === TextEditorElementEnum.Link) {
      const linkNode = node as LinkElement;
      const hasValidUrl = linkNode.url && typeof linkNode.url === 'string';

      if (!hasValidUrl) {
        Transforms.unwrapNodes(editor, { at: path });

        return;
      }
    }

    normalizeNode([node, path]);
  };

  editor.getFragment = (): Descendant[] => {
    const fragment = getFragment();

    return fragment.map((node) => {
      if (!Element.isElement(node)) {
        return node;
      }

      if (node.type === TextEditorElementEnum.Link) {
        return {
          type: TextEditorElementEnum.Paragraph,
          children: (node as LinkElement).children,
        };
      }

      return node;
    });
  };

  editor.insertText = (text) => {
    if (text && isUrl(text)) {
      wrapLink(editor, text);
    } else {
      insertText(text);
    }
  };

  editor.insertData = (data) => {
    const text = data.getData('text/plain');

    if (text && isUrl(text)) {
      wrapLink(editor, text);
    } else {
      insertData(data);
    }
  };

  return editor;
};
