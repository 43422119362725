import { useMemo } from "react"
import { Icon } from "@shadcn/custom/tiptap/ui/Icon"
import { icons } from "lucide-react"

import { cn } from "@shadcn/lib/utils"
import { Button } from "@shadcn/ui/button"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@shadcn/ui/dropdown-menu"

export type ContentTypePickerOption = {
  label: string
  id: string
  type: "option"
  disabled: () => boolean
  isActive: () => boolean
  onClick: () => void
  icon: keyof typeof icons
}

export type ContentTypePickerCategory = {
  label: string
  id: string
  type: "category"
}

export type ContentPickerOptions = Array<
  ContentTypePickerOption | ContentTypePickerCategory
>

export type ContentTypePickerProps = {
  options: ContentPickerOptions
  container?: HTMLElement
}

const isOption = (
  option: ContentTypePickerOption | ContentTypePickerCategory
): option is ContentTypePickerOption => option.type === "option"
const isCategory = (
  option: ContentTypePickerOption | ContentTypePickerCategory
): option is ContentTypePickerCategory => option.type === "category"

export const ContentTypePicker = ({
  options,
  container,
}: ContentTypePickerProps) => {
  const activeItem = useMemo(
    () =>
      options.find((option) => option.type === "option" && option.isActive()),
    [options]
  )

  return (
    <DropdownMenu modal={false}>
      <DropdownMenuTrigger asChild>
        <Button
          variant="ghost"
          className={cn({
            active: activeItem?.id !== "paragraph" && !!activeItem?.type,
          })}
        >
          <>
            <Icon
              name={
                (activeItem?.type === "option" && activeItem.icon) || "Pilcrow"
              }
            />
            <Icon name="ChevronDown" className="w-2 h-2" />
          </>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56" container={container}>
        <DropdownMenuGroup>
          {options.map((option) => {
            if (isOption(option)) {
              return (
                <DropdownMenuItem
                  key={option.id}
                  onClick={option.onClick}
                  className={cn({
                    active: option.isActive(),
                  })}
                >
                  <Icon name={option.icon} className="w-4 h-4 mr-1" />
                  {option.label}
                </DropdownMenuItem>
              )
            } else if (isCategory(option)) {
              return (
                <div className="mt-2 first:mt-0" key={option.id}>
                  <DropdownCategoryTitle key={option.id}>
                    {option.label}
                  </DropdownCategoryTitle>
                </div>
              )
            } else {
              return null
            }
          })}
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

const DropdownCategoryTitle = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="text-[.65rem] font-semibold mb-1 uppercase text-neutral-500 dark:text-neutral-400 px-1.5">
      {children}
    </div>
  )
}
