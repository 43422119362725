import { createQueryKeys } from '@lukemorales/query-key-factory';
import { keepPreviousData } from '@tanstack/react-query';

import type {
  ByProjectPropertyParam,
  QueryableProjectProperty,
} from '@ll-platform/frontend/features/internalProjects/types';
import { createQueryHook } from '@ll-platform/frontend/utils/factories/createQueryHook';
import { createQueryOption } from '@ll-platform/frontend/utils/factories/createQueryOption';
import { mapUnknownToDate } from '@ll-platform/frontend/utils/helpers/date';

import { internalProjectsService } from './InternalProjectsService';

export const InternalProjectsQueries = createQueryKeys('internalProjects', {
  list: createQueryOption(
    internalProjectsService.list.bind(internalProjectsService),
  ),
  findByProperty: <T extends keyof QueryableProjectProperty>(
    args: ByProjectPropertyParam<T>,
  ) => ({
    queryKey: [args],
    queryFn: () => internalProjectsService.findByProperty<T>(args),
  }),
});

export const useFindProjectsByProperty = createQueryHook(
  InternalProjectsQueries.findByProperty.bind(internalProjectsService),
);

export const useInternalListProjects = createQueryHook(
  InternalProjectsQueries.list,
  {
    placeholderData: keepPreviousData,
    staleTime: 1000 * 60 * 1,
    select(data) {
      return {
        ...data,
        items: data.items.map((project) => {
          const productions = project.productions?.map((production) => ({
            ...production,
            dateTime: mapUnknownToDate(production.dateTime),
          }));

          return {
            ...project,
            productions,
          };
        }),
      };
    },
  },
);

export const useFindProjectsByBrandId = createQueryHook(
  InternalProjectsQueries.list,
);
