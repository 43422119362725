import type {
  ActivityType,
  ProjectMetadata,
  ProposalMetadata,
  VideoMetadata,
} from '@ll-platform/frontend/core/analytics/events';
import {
  EventChannel,
  type TrackingEvent,
} from '@ll-platform/frontend/core/analytics/types';
import type {
  ProjectDataWithId,
  ProjectHeroVideo,
  ProjectWithDeliverablesAndBrand,
} from '@ll-platform/frontend/features/projects/types';
import type { Proposal } from '@ll-platform/frontend/features/proposals/types';

const activityTypeSegmentSeparator = '.';

export const buildEventName = (type: ActivityType, separator: string): string =>
  type.split(activityTypeSegmentSeparator).join(separator);

export const isEventOnChannel = (
  event: TrackingEvent,
  channelType: EventChannel,
) => {
  if (event.channel === EventChannel.All) {
    return true;
  }

  return Array.isArray(event.channel)
    ? event.channel.includes(channelType)
    : event.channel === channelType;
};

export const mapProjectToAnalytics = (
  project: Pick<
    ProjectWithDeliverablesAndBrand | ProjectDataWithId,
    'id' | 'title' | 'style'
  >,
) => {
  return {
    projectId: project.id,
    projectName: project.title,
    videoStyle: project.style,
  } satisfies ProjectMetadata;
};

export const mapProjectsToAnalytics = (
  projects: Pick<
    ProjectWithDeliverablesAndBrand | ProjectDataWithId,
    'id' | 'title' | 'style'
  >[],
) => {
  return {
    projects: projects.map(
      (project) =>
        ({
          projectId: project.id,
          projectName: project.title,
          videoStyle: project.style,
        }) satisfies ProjectMetadata,
    ),
  };
};

export const mapVideoToAnalytics = (
  video: Pick<ProjectHeroVideo, 'id' | 'type'>,
) => {
  return {
    videoId: video.id,
    videoType: video.type,
  } satisfies VideoMetadata;
};

export const mapProposalToAnalytics = (
  proposal: Pick<Proposal, 'id' | 'name' | 'status'>,
) => {
  return {
    proposalId: proposal.id,
    proposalName: proposal.name,
    proposalStatus: proposal.status,
  } satisfies ProposalMetadata;
};
