import {
  useQuery,
  type DefaultError,
  type QueryKey,
  type UseQueryOptions,
} from '@tanstack/react-query';

export function createQueryHook<
  TQueryFnData = unknown,
  TError = DefaultError,
  TQueryKey extends QueryKey = QueryKey,
  TArgs = unknown,
>(
  queryFnConfig: (
    args: TArgs,
  ) => UseQueryOptions<TQueryFnData, TError, TQueryFnData, TQueryKey>,
  globalConfig: Omit<
    UseQueryOptions<TQueryFnData, TError, TQueryFnData, TQueryKey>,
    'queryKey' | 'queryFn'
  > = {
    enabled: true,
  },
) {
  return <TFinalData = TQueryFnData>(
    args: TArgs,
    config: Omit<
      UseQueryOptions<TQueryFnData, TError, TFinalData, TQueryKey>,
      'queryKey' | 'queryFn'
    > = {
      enabled: true,
    },
  ) => {
    return useQuery({
      ...(queryFnConfig(args) as unknown as UseQueryOptions<
        TQueryFnData,
        TError,
        TFinalData,
        TQueryKey
      >),
      ...(globalConfig as UseQueryOptions<
        TQueryFnData,
        TError,
        TFinalData,
        TQueryKey
      >),
      ...config,
    });
  };
}
