import * as React from 'react';

import { cn } from '@shadcn/lib/utils';
import { Loader2 } from 'lucide-react';

import {
  Button,
  type ButtonProps,
} from '@ll-platform/frontend/components/shadcn/ui/button';

export interface TwLoadingButtonProps extends ButtonProps {
  loading?: boolean;
  loadingPosition?: 'start' | 'end' | 'center';
  loadingIndicator?: React.ReactNode;
  withLargerGap?: boolean;
  children?: React.ReactNode;
}

export const TwLoadingButton = React.forwardRef<
  HTMLButtonElement,
  TwLoadingButtonProps
>(
  (
    {
      className,
      children,
      loading = false,
      loadingPosition = 'center',
      loadingIndicator,
      disabled,
      withLargerGap = true,
      ...props
    },
    ref,
  ) => {
    const defaultLoadingIndicator = (
      <Loader2 className="h-4 w-4 animate-spin" />
    );
    const indicator = loadingIndicator || defaultLoadingIndicator;

    const getContent = () => {
      if (!loading) {
        return children;
      }

      switch (loadingPosition) {
        case 'start':
          return (
            <>
              {indicator}
              <span className={cn({ 'ml-2': withLargerGap })}>{children}</span>
            </>
          );
        case 'end':
          return (
            <>
              <span className={cn({ 'ml-2': withLargerGap })}>{children}</span>
              {indicator}
            </>
          );
        default:
          return indicator;
      }
    };

    return (
      <Button
        ref={ref}
        className={cn('relative', loading && 'cursor-not-allowed', className)}
        disabled={loading || disabled}
        {...props}
      >
        {getContent()}
      </Button>
    );
  },
);

TwLoadingButton.displayName = 'TwLoadingButton';
