import { WarningAmberOutlined } from '@mui/icons-material';
import { Button, Stack, Typography } from '@mui/material';

import { HERO_SUPPORT_EMAIL } from '@ll-platform/frontend/consts/hero';

export function ErrorPage() {
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{ height: '100vh', px: 5, pb: 10 }}
    >
      <Stack
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{
          py: 10,
          maxWidth: 400,
        }}
        gap={2}
      >
        <WarningAmberOutlined
          color="warning"
          sx={{
            color: (theme) => theme.palette.grey[400],
            fontSize: 50,
          }}
        />
        <Typography variant="h6" textAlign="center" sx={{ my: 1 }}>
          We are sorry, but something went wrong
        </Typography>
        <Typography variant="body1" textAlign="center">
          Try reloading the page. If the problem persists, please contact
          support{' '}
          <a href={`mailto:${HERO_SUPPORT_EMAIL}`}>{HERO_SUPPORT_EMAIL}</a>.
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => window.location.reload()}
          sx={{ minWidth: 200, mt: 3 }}
        >
          Reload
        </Button>
      </Stack>
    </Stack>
  );
}
