import { useCallback, useMemo } from 'react';

import type { ProposalBudgetDiscount } from '@ll-platform/frontend/features/budget/types';
import { useUpdateProjectFields } from '@ll-platform/frontend/features/projects/async/useProjectsMutations';
import { assertDefined } from '@ll-platform/frontend/utils/types/types';

type UseManageExistingProjectDiscountArgs = {
  projectId?: string;
};

export const useManageExistingProjectDiscount = ({
  projectId,
}: UseManageExistingProjectDiscountArgs) => {
  const { mutateAsync: mutateUpdateProjectFieldsAsync } =
    useUpdateProjectFields();

  const handleSubmit = useCallback(
    async ({ data }: { data: ProposalBudgetDiscount }) => {
      assertDefined(projectId);

      return await mutateUpdateProjectFieldsAsync({
        id: projectId,
        data: {
          proposal: {
            budgetDiscount: data,
          },
        },
      });
    },
    [projectId, mutateUpdateProjectFieldsAsync],
  );

  const handleRemove = useCallback(async () => {
    assertDefined(projectId);

    return await mutateUpdateProjectFieldsAsync({
      id: projectId,
      data: {
        proposal: {
          budgetDiscount: null,
        },
      },
    });
  }, [projectId, mutateUpdateProjectFieldsAsync]);

  return useMemo(() => {
    return { onSubmit: handleSubmit, onRemove: handleRemove };
  }, [handleRemove, handleSubmit]);
};
