import { decodeToken } from 'react-jwt';

import type {
  Creator,
  LocalAuthPayloadDto,
  RegisterDto,
  RequestResetPasswordDto,
  UpdatePasswordDto,
  UserJwtDto,
  UserTokens,
} from '@ll-platform/frontend/creator/auth/types';
import { creatorHttpClient } from '@ll-platform/frontend/creator/core/api/CreatorHttpClient';
import { assertDefined } from '@ll-platform/frontend/utils/types/types';

const USER_TOKENS_LS_KEY = 'userTokens';

class CreatorAuthenticationService {
  async localAuthSignIn(args: LocalAuthPayloadDto): Promise<UserTokens> {
    const tokens = await creatorHttpClient.unwrappedHttpRequest<UserTokens>({
      config: {
        method: 'POST',
        url: '/v1/auth/login',
        data: args,
      },
      withAuth: false,
    });

    return tokens;
  }

  async getActiveCreator(): Promise<Creator> {
    const user = await creatorHttpClient.unwrappedHttpRequest<Creator>({
      config: {
        method: 'GET',
        url: '/v1/creators/me',
      },
      handleUnauthorized: () => {
        this.clearUserTokens();
        throw new Error('Unauthorized. Please log in again');
      },
    });

    return user;
  }

  async register(args: RegisterDto): Promise<UserTokens> {
    const tokens = await creatorHttpClient.unwrappedHttpRequest<UserTokens>({
      config: {
        method: 'POST',
        url: '/v1/creators/register',
        data: args,
      },
      withAuth: false,
    });

    return tokens;
  }

  async requestResetPassword(args: RequestResetPasswordDto): Promise<void> {
    await creatorHttpClient.unwrappedHttpRequest<void>({
      config: {
        method: 'POST',
        url: '/v1/auth/request-reset-password',
        data: args,
      },
      withAuth: false,
    });
  }

  async updatePassword(args: UpdatePasswordDto): Promise<void> {
    await creatorHttpClient.unwrappedHttpRequest<void>({
      config: {
        method: 'POST',
        url: '/v1/auth/update-password',
        data: args,
      },
      withAuth: false,
    });
  }

  // Used to refresh the tokens after updating account information
  async reloadTokens() {
    const existingTokens = this.getUserTokens();
    assertDefined(existingTokens, 'existingTokens');
    const tokens = await this.makeReloadTokens(existingTokens);
    await this.saveUserTokens(tokens);
  }

  private async makeReloadTokens(currentTokens: UserTokens) {
    return await creatorHttpClient.unwrappedHttpRequest<UserTokens>({
      config: {
        method: 'POST',
        url: '/v1/creators/reload-token',
        data: currentTokens,
      },
    });
  }

  async saveUserTokens(tokens: UserTokens) {
    localStorage.setItem(USER_TOKENS_LS_KEY, JSON.stringify(tokens));
  }

  getUserTokens() {
    const tokens = localStorage.getItem(USER_TOKENS_LS_KEY);

    return tokens ? (JSON.parse(tokens) as UserTokens) : null;
  }

  clearUserTokens() {
    localStorage.removeItem(USER_TOKENS_LS_KEY);
  }

  // NOTE: Don't use this method for authorization
  // Use only if you can't wait to fetch user profile
  getUserJwt(): UserJwtDto | null {
    const tokens = this.getUserTokens();
    if (!tokens?.accessToken) {
      return null;
    }

    return decodeToken(tokens.accessToken);
  }
}

export const creatorAuthenticationService = new CreatorAuthenticationService();
