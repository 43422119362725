import { useMemo } from 'react';

import { ArrowBackOutlined } from '@mui/icons-material';
import {
  Box,
  IconButton,
  Paper,
  Stack,
  Toolbar,
  Typography,
} from '@mui/material';

import {
  StepsBreadcrumb,
  type StepsBreadcrumbProps,
} from '@ll-platform/frontend/components/StepsBreadcrumb/StepsBreadcrumb';
import { TextWithEllipsis } from '@ll-platform/frontend/components/TextWithEllipsis/TextWithEllipsis';
import { activityTracker } from '@ll-platform/frontend/core/analytics/activityTracker';
import { ActivityType } from '@ll-platform/frontend/core/analytics/events';
import { mapProjectToAnalytics } from '@ll-platform/frontend/features/analytics/eventUtils';
import { useActiveUser } from '@ll-platform/frontend/features/auth/hooks/useActiveUser';
import { useGetBrandByProjectId } from '@ll-platform/frontend/features/brands/async/useBrandsQueries';
import { ProjectStyleCreativeBriefEnabledMap } from '@ll-platform/frontend/features/projects/consts/projectStyleCreativeBriefEnabledMap';
import { getProjectStatus } from '@ll-platform/frontend/features/projects/utils/getProjectStatus';
import { FinalizeWizardButton } from '@ll-platform/frontend/features/projectWizard/components/buttons/FinalizeWizardButton';
import { ProjectStatusStepMap } from '@ll-platform/frontend/features/projectWizard/consts/statusStepMap';
import { WizardStepLabelMap } from '@ll-platform/frontend/features/projectWizard/consts/wizardSteps';
import {
  useWizardNavigationContext,
  WizardFlowType,
} from '@ll-platform/frontend/features/projectWizard/contexts/WizardNavigationContext';
import { useActiveProject } from '@ll-platform/frontend/features/projectWizard/hooks/useActiveProject';
import { useNavigateBackFromWizard } from '@ll-platform/frontend/features/projectWizard/hooks/useWizardNavigationUtils';
import { useIsProposalWizardFlow } from '@ll-platform/frontend/features/proposals/hooks/useIsProposalWizardFlow';
import { defined } from '@ll-platform/frontend/utils/types/types';

const BreadcrumbTitleForFlowMap: Partial<Record<WizardFlowType, string>> = {
  [WizardFlowType.GenerateProposal]: 'Creative Proposal',
  [WizardFlowType.EditProposal]: 'Creative Proposal',
};

export const WizardTopBar = () => {
  const { activeUser } = useActiveUser();
  const { activeProject } = useActiveProject();
  const isProposalFlow = useIsProposalWizardFlow();

  const brandQuery = useGetBrandByProjectId({
    projectId: activeProject.id,
  });
  const {
    steps,
    currentStepIndex,
    goToStep,
    reasonForBlockedNextStep,
    flowType,
    isLastStep,
  } = useWizardNavigationContext();

  const displayBreadcrumbs = !isProposalFlow;

  const displayFinalize =
    !isProposalFlow &&
    (flowType !== WizardFlowType.CreativeBrief ||
      (flowType === WizardFlowType.CreativeBrief && isLastStep)) &&
    ProjectStyleCreativeBriefEnabledMap[activeProject.style];

  const { navigateBack } = useNavigateBackFromWizard();

  const breadcrumbSteps = useMemo<StepsBreadcrumbProps['steps']>(() => {
    const status = getProjectStatus({
      activeUser,
      project: activeProject,
    });
    const projectStatusStep = ProjectStatusStepMap[status];
    if (!steps || !defined(currentStepIndex)) {
      return [];
    }

    const projectStatusStepIndex = steps.indexOf(projectStatusStep);

    return steps.map((step, i) => {
      // Prevent skipping steps but allow to go to the next step if current step's state allows it
      const isClickable =
        i <= currentStepIndex + (reasonForBlockedNextStep ? 0 : 1) ||
        i <= projectStatusStepIndex;

      return {
        content: WizardStepLabelMap[step],
        onClick: isClickable ? () => goToStep(step) : undefined,
      };
    });
  }, [
    goToStep,
    steps,
    currentStepIndex,
    reasonForBlockedNextStep,
    activeUser,
    activeProject,
  ]);

  const handleBackClick = () => {
    activityTracker.log({
      type: ActivityType.ProjectHomepageClickedBackArrow,
      metadata: mapProjectToAnalytics(activeProject),
    });
    navigateBack();
  };

  return (
    <Paper
      sx={{
        height: '56px',
        flexShrink: 0,
        px: 1,
        borderRadius: 0,
        zIndex: 10,
        overflow: 'hidden',
      }}
      elevation={3}
      component="nav"
    >
      <Toolbar
        sx={{
          gap: 2,
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          padding: '0 !important',
        }}
      >
        <IconButton onClick={handleBackClick} color="inherit">
          <ArrowBackOutlined />
        </IconButton>
        <Stack direction="row" gap={0.5} alignItems="center">
          {displayBreadcrumbs && (
            <>
              <TextWithEllipsis
                variant="body1"
                fontSize="16px"
                sx={{ maxWidth: '100px' }}
              >
                {brandQuery.data?.name ?? 'Loading...'}
              </TextWithEllipsis>
              <Typography variant="body1">/</Typography>
            </>
          )}
          <TextWithEllipsis
            variant="body1"
            fontSize="16px"
            fontWeight={700}
            sx={{ maxWidth: displayBreadcrumbs ? '150px' : 'unset' }}
          >
            {displayBreadcrumbs
              ? activeProject.title
              : BreadcrumbTitleForFlowMap[flowType]}
          </TextWithEllipsis>
        </Stack>

        {displayBreadcrumbs && (
          <>
            <Box sx={{ mx: 'auto', overflow: 'auto' }}>
              <StepsBreadcrumb
                steps={breadcrumbSteps}
                currentStepIndex={currentStepIndex}
              />
            </Box>
            {/* Spacer */}
            <Box sx={{ width: '200px', minWidth: 0, flexShrink: 2 }} />
          </>
        )}

        {displayFinalize && <FinalizeWizardButton />}
      </Toolbar>
    </Paper>
  );
};
