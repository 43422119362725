import {
  forwardRef,
  type ComponentProps,
  type ElementType,
  type ReactNode,
} from 'react';

import { Root } from '@radix-ui/react-slot';

import { cn } from '@ll-platform/frontend/components/shadcn/lib/utils';

import type { TypographyVariant } from './types';
import {
  getTypographyDefaultComponent,
  getTypographyVariantStyles,
} from './utils';

export type TypographyProps = ComponentProps<'p'> & {
  children?: ReactNode;
  variant?: TypographyVariant;
  component?: ElementType;
  asChild?: boolean;
};

export const TwTypography = forwardRef<HTMLElement, TypographyProps>(
  (
    { component, children, className, variant, asChild = false, ...rest },
    ref,
  ) => {
    const typographyVariant = getTypographyVariantStyles(variant);

    const Component = asChild
      ? Root
      : component || getTypographyDefaultComponent(variant) || 'p';
    const styles = cn(typographyVariant, className);

    return (
      <Component ref={ref} className={styles} {...rest}>
        {children}
      </Component>
    );
  },
);

TwTypography.displayName = 'TwTypography';
