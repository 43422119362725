import { Navigate } from 'react-router-dom';

import { Pages } from '@ll-platform/frontend/core/router/pages';
import { useGetActiveUserSettings } from '@ll-platform/frontend/features/auth/userSettings/async/useUserSettingsQueries';

export const InternalDashboardRedirectController = () => {
  const { data: userSettings } = useGetActiveUserSettings();

  return (
    <Navigate
      to={userSettings.internalDashboardDefaultPage ?? Pages.InternalProjects}
      replace
    />
  );
};
