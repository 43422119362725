import {
  CreatorAccountTypes,
  UserGroup,
  type User,
} from '@ll-platform/frontend/core/auth/types';

import { isUserInternal } from './isInternal';

export function getUserGroup(user: Pick<User, 'accountType'>): UserGroup {
  if (isUserInternal(user)) {
    return UserGroup.Internal;
  }

  if (CreatorAccountTypes.includes(user.accountType)) {
    return UserGroup.Creator;
  }

  return UserGroup.External;
}
