import type { ReactNode } from "react"
import { Toaster } from "@ll-platform/frontend/components/shadcn/ui/sonner"

import { TooltipProvider } from "./ui/tooltip"

export const ShadcnProviders = ({ children }: { children: ReactNode }) => {
  return (
    <TooltipProvider>
      {children}
      <Toaster richColors toastOptions={{}} expand />
    </TooltipProvider>
  )
}
