import { creatorAuthenticationService } from '@ll-platform/frontend/creator/auth/async/CreatorAuthenticationService';
import { useQueryActiveCreator } from '@ll-platform/frontend/creator/auth/async/hooks/hooks';
import {
  AccountType,
  type Creator,
} from '@ll-platform/frontend/creator/auth/types';

type UseCreatorReturn =
  | {
      isLoading: boolean;
      isAuthorized: true;
      activeCreator: Creator;
    }
  | {
      isLoading: boolean;
      isAuthorized: false;
      activeCreator: undefined;
    };

export function useCreator(): UseCreatorReturn {
  const token = creatorAuthenticationService.getUserJwt();

  const activeCreatorQuery = useQueryActiveCreator(
    {},
    {
      enabled: !!token && token.accountType === AccountType.Creator,
    },
  );
  const activeCreator = activeCreatorQuery.data;

  if (activeCreator) {
    return {
      isLoading: false,
      isAuthorized: true,
      activeCreator,
    };
  } else {
    return {
      isLoading: activeCreatorQuery.isLoading,
      isAuthorized: false,
      activeCreator: undefined,
    };
  }
}
