import { useCallback, useMemo } from 'react';

import { alpha, Stack } from '@mui/material';
import { noop } from 'lodash-es';

import { activityTracker } from '@ll-platform/frontend/core/analytics/activityTracker';
import { ActivityType } from '@ll-platform/frontend/core/analytics/events';
import { useCommentNumberRegister } from '@ll-platform/frontend/features/projectComments/contexts/CommentNumberRegisterProvider';
import { useHoveredCommentContext } from '@ll-platform/frontend/features/projectComments/contexts/HoveredCommentContext';
import type { ProjectCommentMetadata } from '@ll-platform/frontend/features/projectComments/types';
import { CommentEditorProjectWrapper } from '@ll-platform/frontend/features/textEditor/comments/components/CommentEditor/CommentEditorProjectWrapper';
import type { TextEditorCommentsPluginProjectConfig } from '@ll-platform/frontend/features/textEditor/comments/types';
import type { TextEditorEditMode } from '@ll-platform/frontend/features/textEditor/types';

type CommentSidebarThreadProps = {
  threadId: string;
  threadMetadata: ProjectCommentMetadata;
  editMode: TextEditorEditMode;
  onEditModeChange: (editMode: TextEditorEditMode) => void;
  onFinish: () => void;
  showBorderTop?: boolean;
  onThreadLoad: (threadId: string) => void;
};

export const CommentSidebarThread = ({
  threadId,
  threadMetadata,
  editMode,
  onEditModeChange,
  onFinish,
  showBorderTop,
  onThreadLoad,
}: CommentSidebarThreadProps) => {
  const hoveredCommentContext = useHoveredCommentContext({ threadId });
  const { commentNumberRegister } = useCommentNumberRegister();
  const index = commentNumberRegister[threadId];
  const commentsConfig: TextEditorCommentsPluginProjectConfig = useMemo(() => {
    return {
      metadata: threadMetadata,
    };
  }, [threadMetadata]);

  const handleDiscard = useCallback(async () => {
    activityTracker.log({
      type: ActivityType.WizardOutputConfirmedDiscardingComment,
      metadata: threadMetadata,
    });
    onFinish();
  }, [threadMetadata, onFinish]);

  return (
    <>
      <Stack
        ref={hoveredCommentContext.threadElementRef}
        sx={{
          ...(hoveredCommentContext.isHovered && {
            background: (theme) =>
              alpha(
                theme.palette.secondary.main,
                theme.palette.action.selectedOpacity,
              ),
          }),

          ...(showBorderTop && {
            borderTop: (theme) => `2px solid ${theme.palette.divider}`,
          }),
        }}
      >
        <CommentEditorProjectWrapper
          index={index}
          threadId={threadId}
          editMode={editMode}
          isInSidebar
          commentsConfig={commentsConfig}
          onEditModeChange={onEditModeChange}
          onCancel={handleDiscard}
          onFinish={onFinish}
          onInputChange={noop}
          onThreadLoad={onThreadLoad}
        />
      </Stack>
    </>
  );
};
