import { CircleCheck } from 'lucide-react';

import { TwSpinner } from '@ll-platform/frontend/components/tw/TwSpinner/TwSpinner';
import { useDocumentBuilder } from '@ll-platform/frontend/hero/documentBuilder/core/builder/DocumentBuilderContext';

export function DocumentBuilderStatusIndicator() {
  const { isSaving, isDirty, isLoading } = useDocumentBuilder();

  if (isSaving || isLoading) {
    return <TwSpinner className="w-4 h-4 text-muted-foreground" />;
  }

  if (!isDirty) {
    return <CircleCheck className="w-4 h-4 text-muted-foreground" />;
  }

  return null;
}
