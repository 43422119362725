import type { ReactNode } from 'react';

import { NavigationBar } from '@ll-platform/frontend/components/NavigationBar';

type NavigationBarLayoutProps = {
  children: ReactNode;
};

export const NavigationBarLayout = ({ children }: NavigationBarLayoutProps) => {
  return (
    <>
      <NavigationBar />
      {children}
    </>
  );
};
