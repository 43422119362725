import { useCallback } from 'react';

import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';

import { CommentEditMessage } from '@ll-platform/frontend/features/projectComments/components/CommentEditMessage';
import { CommentMessage } from '@ll-platform/frontend/features/projectComments/components/CommentMessage';
import { CommentThread } from '@ll-platform/frontend/features/projectComments/components/CommentThread';
import type {
  CommentMessageType,
  ProjectComment,
} from '@ll-platform/frontend/features/projectComments/types';
import type {
  TextEditorComment,
  TextEditorCommentsPluginConfig,
} from '@ll-platform/frontend/features/textEditor/comments/types';
import type { TextEditorEditMode } from '@ll-platform/frontend/features/textEditor/types';
import { assertDefined } from '@ll-platform/frontend/utils/types/types';

type CommentEditorProps = {
  _debugGetComment?: (data: TextEditorComment) => ProjectComment;
  onAddReply: (
    data: Pick<CommentMessageType, 'message' | 'threadId'> & {
      userId?: string;
    },
  ) => Promise<void>;
  onAddThread: (
    data: Pick<CommentMessageType, 'message'> & {
      userId?: string;
    },
  ) => Promise<void>;
  onUpdate: (
    data: {
      id: string;
    } & Partial<CommentMessageType>,
  ) => Promise<CommentMessageType | null>;
  onRemoveCommentAnchor?: (data: TextEditorComment) => void;
  onUpdateCommentAnchor: (data: TextEditorComment) => void;
  onDelete: (data: TextEditorComment & { threadId: string }) => Promise<void>;
  onResolve: (data: TextEditorComment & { threadId: string }) => Promise<void>;
  onReopen: (data: TextEditorComment & { threadId: string }) => Promise<void>;
  onCancel: () => void;
  onFinish: () => void;
  onEditModeChange: (editMode: TextEditorEditMode) => void;
  onEditStart: () => void;
  onInputChange?: (value: string) => void;
  onThreadLoad?: (threadId: string) => void;
  threadId?: string;
  editMode: TextEditorEditMode;
  isInSidebar?: boolean;
  isReadOnly?: boolean;
  commentsConfig?: TextEditorCommentsPluginConfig;
  index?: number;
};

export function CommentEditor({
  _debugGetComment,
  onAddReply,
  onAddThread,
  onUpdate,
  onRemoveCommentAnchor,
  onUpdateCommentAnchor,
  onDelete,
  onResolve,
  onReopen,
  onCancel,
  onFinish,
  onEditModeChange,
  onEditStart,
  onInputChange,
  onThreadLoad,
  editMode,
  isReadOnly,
  isInSidebar,
  commentsConfig,
  threadId,
  index,
}: CommentEditorProps) {
  const { mutateAsync: doReopen, isPending: isReopening } = useMutation({
    mutationFn: onReopen,
    meta: {
      supressErrorToast: true,
    },
  });
  const { mutateAsync: doResolve, isPending: isResolving } = useMutation({
    mutationFn: onResolve,
    meta: {
      supressErrorToast: true,
    },
  });

  const handleEditFinish = useCallback(() => {
    onEditModeChange('viewComment');
  }, [onEditModeChange]);

  const handleRemoveCommentThread = useCallback(async () => {
    assertDefined(threadId);

    // id of the founding comment is the threadId
    const commentData = {
      id: threadId,
      threadId,
    };
    onEditModeChange('hover');
    onRemoveCommentAnchor?.(commentData);
    await onDelete(commentData);
    onFinish();
  }, [threadId, onDelete, onFinish, onEditModeChange, onRemoveCommentAnchor]);

  const handleAddReply = useCallback(
    async ({ message }: { message: string }) => {
      assertDefined(threadId);

      const commentData = {
        message,
        threadId,
      };

      await onAddReply(commentData);
    },
    [threadId, onAddReply],
  );

  const handleUpdateComment = useCallback(
    async (data: { id: string } & Partial<CommentMessageType>) => {
      await onUpdate(data);
    },
    [onUpdate],
  );

  const handleResolveComment = useCallback(async () => {
    assertDefined(threadId);

    onEditModeChange('hover');

    onUpdateCommentAnchor({ id: threadId, isResolved: true });
    await doResolve({ id: threadId, threadId });
    toast.success('Comment has been resolved.');
    onFinish();
  }, [doResolve, threadId, onEditModeChange, onFinish, onUpdateCommentAnchor]);

  const handleReopenComment = useCallback(async () => {
    assertDefined(threadId);

    onUpdateCommentAnchor({ id: threadId, isResolved: false });
    await doReopen({ id: threadId, threadId });
    toast.success('Comment has been reopened.');
  }, [doReopen, threadId, onUpdateCommentAnchor]);

  const handleCancel = useCallback(async () => {
    onCancel();
  }, [onCancel]);

  const handleRemoveComment = useCallback(
    async ({ id }: { id: string }) => {
      assertDefined(threadId);

      if (id === threadId) {
        await handleRemoveCommentThread();
      } else {
        const commentData = {
          id,
          threadId,
        };
        await onDelete(commentData);
      }

      toast.success('Comment has been deleted.');
    },
    [handleRemoveCommentThread, threadId, onDelete],
  );

  if (editMode === 'newComment') {
    return (
      <CommentEditMessage
        editMode={editMode}
        onSubmit={onAddThread}
        onCancel={handleCancel}
        onInputChange={onInputChange}
        sx={{ p: 2 }}
      />
    );
  }

  if (!threadId) {
    return null;
  }

  return _debugGetComment ? (
    <CommentMessage
      index={index}
      comment={_debugGetComment({ id: threadId })}
      commentsConfig={commentsConfig}
      editMode={editMode}
      isReadOnly={isReadOnly}
      onSubmit={handleUpdateComment}
      onRemove={handleRemoveComment}
      onEditStart={onEditStart}
      onEditFinish={handleEditFinish}
      onInputChange={onInputChange}
      sx={{ p: 2 }}
    />
  ) : (
    <CommentThread
      index={index}
      editMode={editMode}
      commentsConfig={commentsConfig}
      threadId={threadId}
      isReadOnly={isReadOnly}
      isInSidebar={isInSidebar}
      onAddReply={handleAddReply}
      onCancel={handleCancel}
      onUpdate={handleUpdateComment}
      onResolve={handleResolveComment}
      onReopen={handleReopenComment}
      isTogglingResolve={isReopening || isResolving}
      onInputChange={onInputChange}
      onRemoveComment={handleRemoveComment}
      onEditStart={onEditStart}
      onEditFinish={handleEditFinish}
      onLoad={onThreadLoad}
    />
  );
}
