import { Stack, Typography, type TypographyProps } from '@mui/material';

import { UserAvatar } from '@ll-platform/frontend/components/User/UserAvatar';
import type { User } from '@ll-platform/frontend/core/auth/types';

type TeamMemberComponentSize = 'md' | 'sm';

type TeamMemberProps = {
  user: User;
  size?: TeamMemberComponentSize;
};

const nameVariant: Record<TeamMemberComponentSize, TypographyProps['variant']> =
  {
    md: 'body1',
    sm: 'subtitle2',
  };
const positionTypographyProps: Record<
  TeamMemberComponentSize,
  TypographyProps
> = {
  md: {
    variant: 'body2',
    sx: {
      color: (theme) => theme.palette.text.secondary,
      lineHeight: '120%',
    },
  },
  sm: {
    sx: {
      fontSize: 12,
      lineHeight: '100%',
      letterSpacing: '0.15px',
      fontWeight: 400,
      color: (theme) => theme.palette.text.secondary,
    },
  },
};

export const TeamMember = ({ user, size = 'md' }: TeamMemberProps) =>
  user ? (
    <Stack gap={1} direction={'row'} alignItems={'center'}>
      <UserAvatar size={32} user={user} />
      <Stack justifyContent="center">
        {(user.firstName || user.lastName) && (
          <Typography
            variant={nameVariant[size]}
            fontWeight={600}
            lineHeight={'125%'}
          >
            {user.firstName} {user.lastName}
          </Typography>
        )}
        {user.accountType && (
          <Typography {...positionTypographyProps[size]}>
            {user.accountType}
          </Typography>
        )}
      </Stack>
    </Stack>
  ) : null;
