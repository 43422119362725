import { createEditor, type Editor } from 'slate';
import { withReact } from 'slate-react';

import { textEditorPluginMap } from '@ll-platform/frontend/features/textEditor/consts';
import type { TextEditorConfig } from '@ll-platform/frontend/features/textEditor/types';
import { typedEntries } from '@ll-platform/frontend/utils/types/types';

export const createConfiguredEditor = ({ plugins }: TextEditorConfig) => {
  let editor: Editor = withReact(createEditor());

  const enabledPlugins = typedEntries(plugins)
    .filter(([, enabled]) => enabled)
    .map(([pluginName]) => textEditorPluginMap[pluginName]);

  enabledPlugins.forEach((withPlugin) => {
    editor = withPlugin(editor);
  });

  return editor;
};
